import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
// import Select from "react-select";
// import CKEditors from "react-ckeditor-component";
// import Datepicker from 'react-datepicker';

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// import Dropzone from "react-dropzone";
// import Resizer from "react-image-file-resizer";

// koneksi
import { Post } from "../../function/Koneksi";
import { DataToken } from "../../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import ReactDatePicker from "react-datepicker";

//json file
// var data = require('../assets/json/company');

const frmDef = {
  id: 0,
  idbaru: "",
  id_kk: "",
  nama: "",
  alamat: "",
  id_keldes: "",
  tempat_lahir: "",
  tanggal_lahir: new Date(),
  jk: "",
  shdk: "",
  agama: "",
  pendidikan_terakhir: "",
  pekerjaan: "",
  nama_ibu_kandung: "",
  status_penduduk: "",
  status_perkawinan: ""
};
const frmBerkas = {
  uuid: "",
  nik: "",
  jenis: "",
  link: "",
  status: 0
};
// const id_keldes = ID_DESA;

class Penduduk extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.updateContent = this.updateContent.bind(this);
    this.onChange = this.onChange.bind(this);
    this.afterPaste = this.afterPaste.bind(this);
    this.state = {
      data: [],
      pagination: [],
      status: {
        formBerkas: false,
        formExport: false,
        form: false,
        btnForm: false,
        btnAksi: false,
        select: false
      },
      manualPage: "",
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      wilayah: "inherit",
      dt: frmDef,
      databerkas: [],
      berkas: frmBerkas,
      judul: "Tambah Penduduk",
      foto_berkas: "",
      foto_info: "",
      // =========== Select 2 ============
      content: "",
      // =================================
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: null,
      frmKbkt: [],
      kbktStat: null,
      frmKec: [],
      kecStat: null,
      frmKlds: [],
      kldsStat: null,
      frmShdk: [],
      shdkStat: null,
      frmAgm: [],
      agmStat: null,
      frmPtr: [],
      ptrStat: null,
      frmPkr: [],
      pkrStat: null,
      frmKk: [],
      kkStat: null,
      frmStp: [],
      stpStat: null,
      frmJk: [],
      jkStat: null,
      frmSpk: [],
      spkStat: null,
      frmJbk: [],
      jbkStat: null,
      frmSbk: [],
      sbkStat: null,
      frmSkh: [],
      skhStat: null,
      statusberkas: [],
      // =================================
      // =========== Alert ============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // ==============================
      // ============ Tanggal ==============
      startDate: new Date(),
      // ===================================
      akun: DataToken(sessionStorage.getItem("access_token")),
      dataPenduduk: {
        nik: ""
      },
      detailData: null
    };
  }

  // ================== Tanggal ===================

  gantiTgl = date => {
    this.setState({ startDate: date });
  };

  // ==============================================

  // ================== CKEditor ==================

  updateContent(newContent) {
    this.state.dt.alamat = newContent;
    this.forceUpdate();
  }

  onChange(evt) {
    var newContent = evt.editor.getData();
    this.state.dt.alamat = newContent;
    this.forceUpdate();
  }

  onBlur(evt) {
    //console.log("onBlur event called with event info: ", evt);
  }

  afterPaste(evt) {
    //console.log("afterPaste event called with event info: ", evt);
  }

  // ==============================================
  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Post(
      "penduduk/perubahan-data/nik-kk" + link,
      null,
      this.state.dataPenduduk,
      dtkat => {
        //console.log(dtkat);
        this.setState({
          data: dtkat.data.results.data,
          pagination: dtkat.data.results
        });
        this.pagination(dtkat.data.results);
      }
    );
  };

   // Pagination
   pagination = data => {
    // const data = this.state.pagination;
    // //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a href={() => false} key={i}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }

      if (n > 0  && data.last_page>n) {
        setelah = (
          <a 
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let dataPenduduk = {
        q: e.target.value
      };
      Post("penduduk/perubahan-data/nik-kk", null, dataPenduduk, data => {
        this.setState({
          data: data.data.results.data,
          pagination: data.data.results,
          dataPenduduk
        });
        this.pagination(data.data.results);
      });
    }
  };



    // ================== simpan data ==================
    simpan = e => {
        e.preventDefault();
            this.state.status.btnForm = true;
            this.forceUpdate();
            let addPenduduk = {
                old_nik: document.getElementById("oldnik").value,
                old_kk: document.getElementById("oldkk").value,
                nik: document.getElementById("nik").value,
                kk: document.getElementById("kk").value,
            };
            let resstat = 201;
           //console.log(JSON.stringify(addPenduduk));
            Post('penduduk/perubahan-data/update', null, addPenduduk, (res) => {
                this.state.status.btnForm = false;
                //console.log(res)
                if (res.status === resstat) {
                    this.state.status.form = false;
                    this.state.status.btnAksi = false;
                    this.setState({ show: true, basicType: 'success', basicTitle: 'Data Penduduk', pesanAlert: 'Berhasil  Perbarui Data' });
                }
                else {
                    this.setState({ show: true, basicType: 'danger', basicTitle: 'Gagal Perbarui Data', pesanAlert: res.data.message });
                }
                this.forceUpdate();
                this.componentDidMount();
            });
     
    }
  // ============================== Ubah Data ===============================

  ubahData = data => {
    //console.log(data);
    this.state.status.btnAksi = true;
    this.state.judul = "Ubah Penduduk";
    this.state.detailData = data;
    this.state.status.form = true;

    this.forceUpdate();
  };

  // ========================================================================

  bukaForm = () => {
    this.state.status.form = true;
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.status.form = false;
    this.forceUpdate();
  };

  tombolAksi = (cell, row) => {
    return (
      <>
        {/* <Button size="xs" color="info" className="btn-icon" onClick={() => this.bukaFormBerkas(row.id)}><i className="fa fa-files-o"></i></Button> &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row)}
          disabled={this.state.status.btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        {/* <Button size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.id)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button> */}
      </>
    );
  };

  componentDidMount() {
    this.fetch({ page: null, where: [] });
  }

  render() {
    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;
    // const frmDef = {
    //   id: 0,
    //   idbaru: "",
    //   id_kk: "",
    //   nama: "",
    //   alamat: "",
    //   id_keldes: "",
    //   tempat_lahir: "",
    //   tanggal_lahir: new Date(),
    //   jk: "",
    //   shdk: "",
    //   agama: "",
    //   pendidikan_terakhir: "",
    //   pekerjaan: "",
    //   nama_ibu_kandung: "",
    //   status_penduduk: "",
    //   status_perkawinan: ""
    // };

    // const { SearchBar } = Search;
    const columns = [
      {
        dataField: "id",
        text: "NIK",
        sort: false
      },
      {
        dataField: "id_kk",
        text: "No KK",
        sort: false
      },
      // {
      //     dataField: 'nama_keldes',
      //     text: 'Kelurahan/Desa',
      //     isDummyField: true,
      //     formatter: (cell, obj) => { return obj.data_keldes ? obj.data_keldes.nama : "-" },
      //     sort: true
      // },
      {
        dataField: "nama",
        text: "Nama Lengkap",
        sort: true
      },
      // {
      //     dataField: 'alamat',
      //     text: 'Alamat',
      //     sort: true
      // },
      // {
      //     dataField: 'tempat_lahir',
      //     text: 'Tempat Lahir',
      //     sort: true
      // },
      // {
      //     dataField: 'tanggal_lahir',
      //     text: 'Tanggal Lahir',
      //     sort: true
      // },
      // {
      //     dataField: 'status_perkawinan',
      //     text: 'Status Perkawinan',
      //     sort: true
      // },
      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-success";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href={() => false} onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };

    return (
      <div>
        {/* <Breadcrumb title="Perubahan Data NIK/KK" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Penduduk</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <div className="row">
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className="form-control"
                        id="cariTable"
                        placeholder="Cari Data berdasarkan nama pemohon, atau nomor surat"
                        onKeyPress={this.cariData}
                      />
                    </div>
                  </div>
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          styles={{ modal: { width: "80%" } }}
          onClose={this.tutupForm}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpan}>
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <p>
                Perubahan data nomor Kartu Keluarga akan berpengaruh terhadap
                nomor Kartu Keluarga anggota keluarga yang lain.
              </p>

              {this.state.detailData !== null ? (
                <>
                  <input
                    className="form-control"
                    id="oldnik"
                    type="hidden"
                    placeholder="Penduduk"
                    defaultValue={this.state.detailData.id}
                  /><input
                  className="form-control"
                  id="oldkk"
                  type="hidden"
                  placeholder="Penduduk"
                  defaultValue={this.state.detailData.id_kk}
                />
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Nama Penduduk
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nama"
                        type="text"
                        placeholder="nama"
                        readOnly
                        defaultValue={this.state.detailData.nama}
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      NIK
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="nik"
                        type="number"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.id}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      KK
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control"
                        id="kk"
                        type="number"
                        placeholder="NIK"
                        defaultValue={this.state.detailData.id_kk}
                      />
                    </Col>
                  </FormGroup>

                  <table className="table table-bordered table-stripes table-hovered">
                    <thead>
                      <tr key={0}>
                        <th>NIK</th>
                        <th>No KK</th>
                        <th>Nama</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.detailData.data_kk!==null? this.state.detailData.data_kk.data_keluarga.map(
                        (dt, i) => {
                          return (
                            <tr key={i + 1}>
                              <td>{dt.id}</td>
                              <td>{dt.id_kk}</td>
                              <td>{dt.nama}</td>
                            </tr>
                          );
                        }
                      ):''}
                    </tbody>
                  </table>
                </>
              ) : (
                <></>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default Penduduk;