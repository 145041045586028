import React, { useContext } from 'react';
// koneksi
import { JENIS_DESA,} from "./../../function/Koneksi";
import Datetime from 'react-datetime';
import Modal from 'react-responsive-modal';
import {
	Button,
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
// import { Link } from 'react-router-dom';

function EditPindahDatang({ detail_surat_context }) {
	const detailSuratProps = useContext(detail_surat_context);

	// const dateFormat = require('dateformat');

	//console.log(detailSuratProps);
	//console.log("Detail Coba");
	//console.log(detailSuratProps.dtPindah);
	//console.log(detailSuratProps.detailKeluarga);

	var no = 0;
	const listKeluargaIn = detailSuratProps.detailKeluarga.map((dt, i) => {
		no++;
		return (
		  <tr key={i}>
			<td>{no}</td>
			<td>{dt.nama}</td>
			{/* <td>{jk}</td>
			<td>
			  {dt.tempat_lahir}, {Tanggal(dt.tanggal_lahir)}
			</td> */}
			{/* <td>{dt.pekerjaan}</td> */}
			<td>{dt.nik}</td>
			<td>{dt.shdk.label}</td>
			<td>
			  <Button
				size="xs"
				onClick={() => detailSuratProps.hapusDetailKeluarga(i)} 
				color="danger"
				className="btn-icon"
			  >
				<i className="fa fa-trash"></i>
			  </Button>
			</td>
		  </tr>
		);
	  });

	


	return (
		<div>
			<Modal
				open={detailSuratProps.status.formUpdateSurat}
				onClose={detailSuratProps.tutupForm}
				closeOnEsc={false}
				closeOnOverlayClick={false}
			>
				<Form
					className="theme-form"
					onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}
				>
					<div className="modal-header">
						<h5 className="modal-title">Detail {detailSuratProps.detailSurat.jenis_surat}</h5>
					</div>
					<div className="modal-body">
          <input
							className="form-control"
							id="uuid"
							type="hidden"
							placeholder=""
							defaultValue={detailSuratProps.detailSurat.uuid}
						/>
					 {/* Data Daerah Asal */}
					<FormGroup className="row" style={{ marginBottom: '10px' }}>
						<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
						<b>DATA DAERAH ASAL</b>
						</Label>
					</FormGroup>
					<FormGroup className="row" style={{ marginBottom: '10px' }}>
						<Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
						<b>Nomor KK</b>
						</Label>
						<div className="col-sm-8">
						<Select
							id="noKKPenduduk"
							// isDisabled={this.state.status.select}
							classNamePrefix="select"
							onChange={(e) => {
								detailSuratProps.changeSelectValuePindahDatang(e,'kk');
							}}
							defaultValue={detailSuratProps.detailSurat.kkStat}
							value={detailSuratProps.detailSurat.kkStat}
							name="No KK"
							options={detailSuratProps.detailSurat.frmKk}
							placeholder="Pilih KK"
							onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'kk')}
							isClearable
						/>
						</div>
					</FormGroup>

				{detailSuratProps.detailKK && detailSuratProps.detailSurat.kepalaKeluarga !== '' ? (
                <>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama Kepala Keluarga</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={detailSuratProps.detailSurat.kepalaKeluarga}
                        value={detailSuratProps.detailSurat.kepalaKeluarga}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
				  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Alamat</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={detailSuratProps.detailKK.alamat}
                        value={detailSuratProps.detailKK.alamat}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>RT</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={detailSuratProps.detailKK.rt}
                        value={detailSuratProps.detailKK.rt}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>RW</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={detailSuratProps.detailKK.rw}
                        value={detailSuratProps.detailKK.rw}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>

				  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>{JENIS_DESA} / Kelurahan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={detailSuratProps.detailKK.keldes}
                        value={detailSuratProps.detailKK.keldes}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>Kecamatan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={detailSuratProps.detailKK.kecamatan}
                        value={detailSuratProps.detailKK.kecamatan}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>Kab/Kota</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={detailSuratProps.detailKK.kabkota}
                        value={detailSuratProps.detailKK.kabkota}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>Provinsi</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit',
                          backgroundColor: '#c9d1d8'
                        }}
                        defaultValue={detailSuratProps.detailKK.provinsi}
                        value={detailSuratProps.detailKK.provinsi}
                        required
                        type="text"
                        disabled
                        readOnly
                      />
                    </div>
                  </FormGroup>
                  
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>Kode POS</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        id="kode_pos"
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                        defaultValue={detailSuratProps.detailSurat.kode_pos}
                        required
                        type="number"
                        placeholder="Masukkan kode pos (Contoh : 28285)"
                      />
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                      <b>Telepon</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <input
                        id="telepon"
                        className="form-control"
                        style={{
                          marginBottom: '10px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                        defaultValue={detailSuratProps.detailSurat.telepon}
                        // required
                        type="text"
                        placeholder="Masukkan nomor telepon rumah jika ada (Contoh : 0761252532)"
                      />
                    </div>
                  </FormGroup>
				  <FormGroup className="row" style={{ marginBottom: '10px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Pemohon</b>
                    </Label>
                    <div className="col-sm-8">
                      <Select
                        classNamePrefix="select"
                        onChange={e => {
							detailSuratProps.changeSelectValuePindahDatang(e, "nik");
                        }}
                        defaultValue={detailSuratProps.detailSurat.nikStat}
                        value={detailSuratProps.detailSurat.nikStat}
                        name="NIK3"
                        options={detailSuratProps.detailSurat.frmNik3}
                        placeholder="Pilih NIK Pemohon"
                        isClearable
                      />
                    </div>
                  </FormGroup>
                  {detailSuratProps.detailPemohon ? (
                    <>
                      <FormGroup className="row" style={{ marginBottom: '10px' }}>
                        <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                          <b>Nama Pemohon</b>
                        </Label>
                        <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            className="form-control"
                            style={{
                              marginBottom: '10px',
                              fontWeight: 400,
                              fontSize: 'inherit',
                              backgroundColor: '#c9d1d8'
                            }}
                            defaultValue={detailSuratProps.detailPemohon.nama_pemohon}
                            value={detailSuratProps.detailPemohon.nama_pemohon}
                            required
                            type="text"
                            disabled
                            readOnly
                          />
                        </div>
                      </FormGroup>
                      <FormGroup className="row" style={{ marginBottom: '10px' }}>
                        <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                          <b>NIK Pemohon</b>
                        </Label>
                        <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <input
                            className="form-control"
                            style={{
                              marginBottom: '10px',
                              fontWeight: 400,
                              fontSize: 'inherit',
                              backgroundColor: '#c9d1d8'
                            }}
                            defaultValue={detailSuratProps.detailPemohon.nik}
                            value={detailSuratProps.detailPemohon.nik}
                            required
                            type="text"
                            disabled
                            readOnly
                          />
                        </div>
                      </FormGroup>
                    </>
                  ) : ''}
                </>
              ) : ''}

			  {/* Data Kepindahan */}
              <FormGroup className="row" style={{ marginBottom: '10px', marginTop: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>DATA KEPINDAHAN</b>
                </Label>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Alasan Pindah</b>
                </Label>
                <div className="col-sm-8">
					<Select
						classNamePrefix="select"
						onChange={(e) => {
							detailSuratProps.changeSelectValuePindahDatang(e, 'alasan pindah');
						}}
						defaultValue={detailSuratProps.dtPindah.alasanPindah}
						value={detailSuratProps.dtPindah.alasanPindah}
						name="Alasan Pindah"
						options={[
						{value: '1', label: 'Pekerjaan'},
						{value: '2', label: 'Pendidikan'},
						{value: '3', label: 'Keamanan'},
						{value: '4', label: 'Kesehatan'},
						{value: '5', label: 'Perumahan'},
						{value: '6', label: 'Keluarga'},
						{value: '7', label: 'Lainnya'},
						]}
						placeholder="Pilih Alasan Pindah"
						isClearable
					/>
                </div>
              </FormGroup>
              {detailSuratProps.dtPindah.alasanPindah && detailSuratProps.dtPindah.alasanPindah.label === 'Lainnya' ? (
                <FormGroup className="row" style={{ marginBottom: '0px', marginTop: '10px' }}>
                  <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                    <b>Keterangan Alasan Pindah</b>
                  </Label>
                  <div className="col-sm-8">
                    <input
                      id="alasan_pindah"
                      className="form-control"
                      style={{
                        marginBottom: '10px',
                        fontWeight: 400,
                        fontSize: 'inherit'
                      }}
                      defaultValue={detailSuratProps.dtPindah.alasan_pindah}
                      required
                      type="text"
                      placeholder="Ketikkan alasan pindah (Contoh : Pindah Tugas)"
                    />
                  </div>
                </FormGroup>
              ) : ''}
              <FormGroup className="row" style={{ marginBottom: '0px', marginTop: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Alamat</b>
                </Label>
                <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    id="alamat_pindah"
                    className="form-control"
                    style={{
                      marginBottom: '10px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                    defaultValue={detailSuratProps.dtPindah.alamat}
                    required
                    type="text"
                    placeholder="Masukkan alamat pindah (Contoh : Jl. Sudirman No. 17A)"
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>RT</b>
                </Label>
                <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    id="rt_pindah"
                    className="form-control"
                    style={{
                      marginBottom: '10px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                    defaultValue={detailSuratProps.dtPindah.rt}
                    required
                    type="number"
                    placeholder="Masukkan RT (Contoh : 002)"
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>RW</b>
                </Label>
                <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    id="rw_pindah"
                    className="form-control"
                    style={{
                      marginBottom: '10px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                    defaultValue={detailSuratProps.dtPindah.rw}
                    required
                    type="number"
                    placeholder="Masukkan RW (Contoh : 007)"
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>Provinsi</b>
                </Label>
                <div className="col-sm-8">
                  <Select
                    classNamePrefix="select"
                    onChange={(e) => {
                      detailSuratProps.getChildPindahDatang(e, 'provinsi', 'prov', 'kab-kota');
                    }}
                    defaultValue={detailSuratProps.detailSurat.prvStat}
                    value={detailSuratProps.detailSurat.prvStat}
                    name="provinsi"
                    options={detailSuratProps.detailSurat.frmPrv}
                    placeholder="Pilih Provinsi"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>Kab/Kota</b>
                </Label>
                <div className="col-sm-8">
                  <Select
                    classNamePrefix="select"
                    onChange={(e) => {
                      detailSuratProps.getChildPindahDatang(e, 'kab-kota', 'kab', 'kecamatan');
                    }}
                    defaultValue={detailSuratProps.detailSurat.kbktStat}
                    value={detailSuratProps.detailSurat.kbktStat}
                    name="kabkota"
                    options={detailSuratProps.detailSurat.frmKbkt}
                    placeholder="Pilih Kabupaten/Kota"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>Kecamatan</b>
                </Label>
                <div className="col-sm-8">
                  <Select
                    classNamePrefix="select"
                    onChange={(e) => {
                      detailSuratProps.getChildPindahDatang(e, 'kecamatan', 'kec', 'keldes');
                    }}
                    defaultValue={detailSuratProps.detailSurat.kecStat}
                    value={detailSuratProps.detailSurat.kecStat}
                    name="kecamatan"
                    options={detailSuratProps.detailSurat.frmKec}
                    placeholder="Pilih Kecamatan"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>
                    {JENIS_DESA.toLowerCase() === 'kelurahan' ? (
                      'Kelurahan'
                    ) : (
                      'Kelurahan / ' + JENIS_DESA
                    )}
                  </b>
                </Label>
                <div className="col-sm-8">
                  <Select
                    classNamePrefix="select"
                    onChange={(e) => {
                      detailSuratProps.getChildPindahDatang(e, 'keldes', 'kel', 'dusun');
                    }}
                    defaultValue={detailSuratProps.detailSurat.kldsStat}
                    value={detailSuratProps.detailSurat.kldsStat}
                    name="keldes"
                    options={detailSuratProps.detailSurat.frmKel}
                    placeholder={
                      JENIS_DESA.toLowerCase() === 'kelurahan' ? (
                        'Pilih Kelurahan'
                      ) : (
                          'Pilih Kelurahan / ' + JENIS_DESA
                        )
                    }
                    isClearable
                    required
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>Kode POS</b>
                </Label>
                <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    id="kode_pos_pindah"
                    className="form-control"
                    style={{
                      marginBottom: '10px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                    defaultValue={detailSuratProps.dtPindah.kode_pos}
                    required
                    type="number"
                    placeholder="Masukkan kode pos (Contoh : 28285)"
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600, paddingLeft: 35 }}>
                  <b>Telepon</b>
                </Label>
                <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <input
                    id="telepon_pindah"
                    className="form-control"
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                    defaultValue={detailSuratProps.dtPindah.telepon}
                    type="text"
                    placeholder="Masukkan nomor telepon rumah jika ada (Contoh : 0761252532)"
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Klasifikasi Pindah</b>
                </Label>
                <div className="col-sm-8">
                  <Select
                    classNamePrefix="select"
                    onChange={(e) => {
						            detailSuratProps.changeSelectValuePindahDatang(e, 'klasifikasi pindah');
                    }}
                    defaultValue={detailSuratProps.detailSurat.klasifikasiPindah}
                    value={detailSuratProps.detailSurat.klasifikasiPindah}
                    name="Klasifikasi Pindah"
                    options={[
                      {value: '1', label: 'Dalam satu ' + JENIS_DESA + ' / Kelurahan'},
                      {value: '2', label: 'Antar ' + JENIS_DESA + ' / Kelurahan'},
                      {value: '3', label: 'Antar Kecamatan'},
                      {value: '4', label: 'Antar Kab/Kota'},
                      {value: '5', label: 'Antar Provinsi'},
                    ]}
                    placeholder="Pilih Klasifikasi Pindah"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Jenis Kepindahan</b>
                </Label>
                <div className="col-sm-8">
                  <Select
                    classNamePrefix="select"
                    onChange={(e) => {
						            detailSuratProps.changeSelectValuePindahDatang(e, 'jenis pindah');
                    }}
                    defaultValue={detailSuratProps.detailSurat.jenisPindah}
                    value={detailSuratProps.detailSurat.jenisPindah}
                    name="Jenis Kepindahan"
                    options={[
                      {value: '1', label: 'Kep. Keluarga'},
                      {value: '2', label: 'Kep. Keluarga dan Seluruh Anggota Keluarga'},
                      {value: '3', label: 'Kep. Keluarga dan Sbg. Angg. keluarga'},
                      {value: '4', label: 'Angg. Keluarga'},
                    ]}
                    placeholder="Pilih Jenis Kepindahan"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Status Nomor KK Bagi Yang Tidak Pindah</b>
                </Label>
                <div className="col-sm-8">
                  <Select
                    classNamePrefix="select"
                    onChange={(e) => {
						            detailSuratProps.changeSelectValuePindahDatang(e, 'status tidak pindah');
                    }}
                    defaultValue={detailSuratProps.detailSurat.statusTidakPindah}
                    value={detailSuratProps.detailSurat.statusTidakPindah}
                    name="Status Tidak Pindah"
                    options={[
                      {value: '1', label: 'Numpang KK'},
                      {value: '2', label: 'Membuat KK Baru'},
                      {value: '3', label: 'Tidak Ada Angg. Keluarga Yang Ditinggal'},
                      {value: '4', label: 'Nomor KK Tetap'},
                    ]}
                    placeholder="Pilih Status Nomor KK yang Tidak Pindah"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Status Nomor KK Bagi Yang Pindah</b>
                </Label>
                <div className="col-sm-8">
                  <Select
                    classNamePrefix="select"
                    onChange={(e) => {
					              detailSuratProps.changeSelectValuePindahDatang(e, 'status pindah');
                    }}
                    defaultValue={detailSuratProps.detailSurat.statusPindah}
                    value={detailSuratProps.detailSurat.statusPindah}
                    name="Status Tidak Pindah"
                    options={[
                      {value: '1', label: 'Numpang KK'},
                      {value: '2', label: 'Membuat KK Baru'},
                      {value: '3', label: 'Nama Kep. Keluarga dan Nomor KK Tetap'},
                    ]}
                    placeholder="Pilih Status Nomor KK yang Pindah"
                    isClearable
                  />
                </div>
              </FormGroup>
              <FormGroup className="row" style={{ marginBottom: '10px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                  <b>Rencana Tanggal Pindah</b>
                </Label>
                <div className="col-sm-8">
                  <Datetime
                    input={true}
                    timeFormat={false}
                    dateFormat="DD/MM/yyyy"
                    closeOnSelect={true}
                    initialValue={detailSuratProps.dtPindah.tgl_pindah}
                    value={detailSuratProps.dtPindah.tgl_pindah}
                    onChange={(e) => detailSuratProps.gantiTgl(e, 'tanggal_pindah')}
                    inputProps={{
                      placeholder: 'Tanggal Pindah',
                      required: true
                    }}
                  />
                </div>
              </FormGroup>

			  {detailSuratProps.detailSurat.kkStat ? (
                <FormGroup className="row">
                  <Label className="col-sm-12 col-form-label">
                    Daftar Keluarga Yang Ikut
                  </Label>
                  <Col sm="5">
				  	<Select
						classNamePrefix="select"
						onChange={(e) => {
							detailSuratProps.getPenduduk(
								e,
								detailSuratProps.detailSurat.jenis_surat,
								'nik3'
							);
						}}
						defaultValue={detailSuratProps.dataNikStat.nik3Stat}
						value={detailSuratProps.dataNikStat.nik3Stat}
						name="NIK3"
						options={detailSuratProps.detailSurat.frmNik3}
						placeholder="Pilih NIK Anggota Keluarga yang Ikut"
						onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik3')}
						isClearable
					/>
                  </Col>
                  <Col sm="4">
				  	<Select
						classNamePrefix="select"
						onChange={(e) => {
							detailSuratProps.changeSelectValuePindahDatang(e, 'shdk_aw');
						}}
						value={detailSuratProps.detailSurat.shdk}
						id="shdk"
						options={[
							{ value: 'Suami', label: 'Suami' },
							{ value: 'Isteri', label: 'Isteri' },
							{ value: 'Orang Tua', label: 'Orang Tua' },
							{ value: 'Anak', label: 'Anak' },
							{ value: 'Kakak', label: 'Kakak' },
							{ value: 'Adik', label: 'Adik' },
							{ value: 'Mertua', label: 'Mertua' },
							{ value: 'Menantu', label: 'Menantu' },
							{ value: 'Cucu', label: 'Cucu' },
							{ value: 'Pembantu', label: 'Pembantu' },
							{ value: 'Famili Lain', label: 'Famili Lain' }
						]}
						placeholder="Hubungan Keluarga"
					/>
                  </Col>
                  <Col sm="3">
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={(e) => detailSuratProps.tambahAw(e, 'pindah-datang')}
                    >
                      Tambah
                    </button>
                  </Col>
                  <Col sm="12">
                    <table className="table table-bordered table-stripes table-hovered">
                      <thead>
                        <tr>
                          <th>No</th>
                          <th>Nama</th>
                          <th>NIK</th>
                          {/* <th>Tempat/Tanggal Lahir</th>
                          <th>Pekerjaan</th>
                          <th>Pendidikan</th> */}
                          <th>Hubungan Keluarga</th>
                          <th>
                            <i className="fa fa-trash"></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody>{listKeluargaIn}</tbody>
                    </table>
                  </Col>
                </FormGroup>
              ) : ''}

              					


					</div>
          
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-warning"
							onClick={detailSuratProps.tutupForm}
						>
							Tutup
						</button>
						<button
							type="submit"
							className="btn btn-success"
						>
							Simpan
						</button>
					</div>
				</Form>
			</Modal>
			
		</div>
	);
}

export default EditPindahDatang;