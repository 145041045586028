import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datepicker from "react-datepicker";
import { Link, Redirect } from "react-router-dom";

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, Nama_DESA, NAMA_KABUPATEN, KECAMATAN, ALAMAT_KANTOR_DESA, PROVINSI,  JENIS_DUSUN} from "../function/Koneksi";
import { Tanggal, } from "../function/Format";
import { logo } from "./asset/base64img";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

//json file
// var data = require('../assets/json/company');

const frmDef = {
  uuid: "",
  id_jenis: "",
  id_pengaju: "",
  tanggal_surat: new Date(),
  nik: "",
  nama_pemohon: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  agama: "",
  jenis_kelamin: "",
  status_perkawinan: "",
  alamat: "",
  kewarganegaraan: "Indonesia",
  tujuan_pembuatan: "",
  lokasi: "",
  kk: "",
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",

  //detail
  tgl_mulai: "",
  tgl_selesai: "",
  waktu_mulai: "",
  waktu_selesai: "",
  tempat: "",
  hiburan: ""
};
const cetak = {
  nama_desa: "",
  tanggal_surat: "",
  nomor_surat: "",
  nik: "",
  jenis_kelamin: "",
  nama_pemohon: "",
  kepada: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  pendidikan_terakhir: "",
  status_perkawinan: "",
  alamat: "",
  kewarganegaraan: "Indonesia",
  lokasi: "",
  kk: "",

  tujuan_pembuatan: "",
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: "",

  //detail
  //detail
  tgl_mulai: "",
  tgl_selesai: "",
  waktu_mulai: "",
  waktu_selesai: "",
  tempat: "",
  hiburan: "",
  detail: [{ jenis_usaha: "", lokasi: "", umur: "", luas_kebun: "", merk: "" }]
};
class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: true,
        btnAksi: []
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Surat Keterangan Usaha",
      // ============ Alert ==============
      frmJam: [],
      jamStat: [],
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,

      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      // ===================================
      // ============ Cetak ==============
      cetak: cetak,
      // ===================================
      // ==============penduduk=========
      pendPrv: [],
      prvPend: [],
      showBio: false,
      tanggal_mulai: new Date(),
      tanggal_selesai: null,
      usaha: [
        { jenis_usaha: "", lokasi: "", umur: "", luas_kebun: "", merk: "" }
      ],
      //acc
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none",
      datDisposisi: [],
      riwayatDisposisi: [],
      jenisForm: "usaha",
      dataUsaha: []
    };
  }

  // tambha usaha
  addUsaha = e => {
    e.preventDefault();
    if (this.state.jenisForm ===  "usaha") {
      if (
        document.getElementById("jenis_usaha").value !==  "" &&
        document.getElementById("nama_tempat_usaha").value !==  "" &&
        document.getElementById("lokasi").value !==  ""
      ) {
        let data = {
          jenis_usaha: document.getElementById("jenis_usaha").value,
          nama_tempat_usaha: document.getElementById("nama_tempat_usaha").value,
          lokasi: document.getElementById("lokasi").value
        };
        this.setState({ dataUsaha: [...this.state.dataUsaha, data] });
        document.getElementById("jenis_usaha").value = "";
        document.getElementById("nama_tempat_usaha").value = "";
        document.getElementById("lokasi").value = "";
        // document.getElementById("umur").value = "";
        // document.getElementById("lokasi").value = "";
        // document.getElementById("luas_kebun").value = "";
        
        // For testing
        if (this.props.getDataUsaha) {
          this.props.getDataUsaha(this.state.dataUsaha)
        }
      } else {
        this.setState({
          show: true,
          basicType: "warning",
          basicTitle: "Data Belum Lengkap",
          pesanAlert: "Silahkan lengkapi data usaha"
        });
      }
    } else {
      if (
        document.getElementById("jenis_usaha").value !==  "" &&
        document.getElementById("umur").value !==  "" &&
        document.getElementById("lokasi").value !==  "" &&
        document.getElementById("luas_kebun").value !==  ""
      ) {
        let data = {
          jenis_usaha: document.getElementById("jenis_usaha").value,
          umur: document.getElementById("umur").value,
          lokasi: document.getElementById("lokasi").value,
          luas_kebun: document.getElementById("luas_kebun").value
        };
        this.setState({ dataUsaha: [...this.state.dataUsaha, data] });
        document.getElementById("jenis_usaha").value = "";
        // document.getElementById("nama_tempat_usaha").value = "";
        document.getElementById("lokasi").value = "";
        document.getElementById("umur").value = "";
        document.getElementById("lokasi").value = "";
        document.getElementById("luas_kebun").value = "";

         
        // For testing
        if (this.props.getDataUsaha) {
          this.props.getDataUsaha(this.state.dataUsaha)
        }
      } else {
        this.setState({
          show: true,
          basicType: "warning",
          basicTitle: "Data Belum Lengkap",
          pesanAlert: "Silahkan lengkapi data usaha"
        });
      }
    }
  };

  // hapus usaha
  hapusUsaha = id => {
    let data = this.state.dataUsaha;
    data.splice(id, 1);
    this.setState({ dataUsaha: data });
  };
  // ================== dinamic form ===================
  handleNameChange = evt => {
    this.setState({ name: evt.target.value });
  };

  handleShareholderNameChange = idx => evt => {
    const newusaha = this.state.usaha.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, name: evt.target.value };
    });

    this.setState({ usaha: newusaha });
  };
  handleAddShareholder = () => {
    this.setState({
      usaha: this.state.usaha.concat([{ name: "" }])
    });
  };

  handleRemoveShareholder = idx => () => {
    this.setState({
      usaha: this.state.usaha.filter((s, sidx) => idx !== sidx)
    });
  };
  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        this.setState({ tanggal_surat: date });
        break;
      case "tanggal_lahir_pasangan":
        this.setState({ tanggal_lahir_pasangan: date });
        break;
      default:
        // no default
        break;
    }
  };

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      btnForm: true,
      successAlert: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get(
      "surat-keterangan/grup/" + this.props.location.state.uuid + "" + link,
      null,
      dtkat => {
        if(dtkat.results){
          this.setState({ data: dtkat.results.data, pagination: dtkat.results });
          this.pagination(dtkat.results);

          // For testing
          if (this.props.getListSuratUsaha) {
            this.props.getListSuratUsaha(dtkat.results.data)
          }
        }
      }
    );
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i ===  data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  //change select
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jk":
          this.setState({ 
            jkStat: e, 
            dt:{
              ...this.state.dt,
              jk: e.value,
            },
          });
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "jk":
          this.setState({ 
            jkStat: e, 
            jk: 0,
          });
          break;
        default:
          // no default
          break;
      }
    }

    this.setState({
      status:{
        ...this.state.status,
        select: false,
      },
    })
    this.forceUpdate();
  };
  // Cari Data Table
  cariData = e => {
    if (e.key ===  "Enter") {
      let dataSuket = {
        q: e.target.value
      };
      Post(
        "surat-keterangan/grup/" + this.props.location.state.uuid,
        null,
        dataSuket,
        data => {
          this.setState({
            data: data.data.results.data,
            pagination: data.data.results
          });
        }
      );
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    if (this.state.dataUsaha.length ===  0) {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Belum Lengkap",
        pesanAlert: "Silahkan isi data usaha"
      });
    } else if (this.state.pendStat ===  null || this.state.pendStat ===  []) {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Belum Lengkap",
        pesanAlert: "Silahkan Pilih NIK penduduk"
      });
    } else {
      this.setState({
        status:{
          ...this.state.status,
          btnForm: true,
        },
      })
      this.forceUpdate();

      let uuid = document.getElementById("uuid").value;
      //detail
      //
      // let detail = [];

      let frmDetail = [
        {
          nama_item: "usaha",
          isi_item: JSON.stringify(this.state.dataUsaha)
        }
      ];
      let addSkck = {
        id_jenis: this.props.location.state.uuid,

        // nik: document.getElementById("nik").value,
        nik_pelapor: this.state.pendStat.value,
        nik: this.state.pendStat.value,
        nama_pemohon: document.getElementById("nama_pemohon").value,
        tempat_lahir: document.getElementById("tempat_lahir").value,
        tanggal_lahir: document.getElementById("tanggal_lahir").value,
        pendidikan_terakhir: document.getElementById("pendidikan_terakhir")
          .value,
        pekerjaan: document.getElementById("pekerjaan").value,
        agama: document.getElementById("agama").value,
        status_perkawinan: document.getElementById("status_perkawinan").value,
        alamat: document.getElementById("alamat").value,
        jenis_kelamin: document.getElementById("jenis_kelamin").value,
        kewarganegaraan: document.getElementById("kewarganegaraan").value,
        tujuan_pembuatan: "Surat Keterangan Usaha",
        kk: document.getElementById("kk").value,

        detail: frmDetail
      };

      //console.log("Data Simpan Usaha")
      //console.log(addSkck)

      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (uuid === "") {
        psn = "Tambah";
        resstat = 201;
        uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      Post(
        "surat-keterangan/grup/" + metode + "/with-detail",
        uuid,
        addSkck,
        res => {
          // For testing
          if (this.props.resSubmit) {
            this.props.resSubmit(res.status)
          }

          this.tutupForm();

          this.setState({
            status:{
              ...this.state.status,
              btnForm: false,
              form: false,
            },
          })
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              pendStat: [],
              pendStat2: [],
              status: {
                ...this.state.status,
                btnForm: true
              },
              showBio: false,
              show: true,
              basicType: "success",
              basicTitle: "Data Permohonan Keterangan Usaha",
              pesanAlert: "Berhasil " + psn + " Data",
              successAlert: true
            });
          } else {
            this.setState({
              pendStat: [],
              pendStat2: [],
              status: {
                ...this.state.status,
                btnForm: true
              },
              showBio: false,
              show: true,
              basicType: "danger",
              basicTitle: "Data Permohonan Keterangan Usaha",
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        }
      );
    }
  };
  // ========================================================================
  // ========================================================================
  // list disposisi

  listDisposisi = data => {
    //console.log(data);
    Get("alur-disposisi/" + data.id_jenis, null, dtkat => {
      //console.log(dtkat);
      if (dtkat) {
        //console.log(dtkat.results);

        this.setState({
          idSurat: data.uuid,
          datDisposisi: dtkat.results,
          formDisposisi: true,
          riwayatDisposisi: data.data_status_disposisi
        });
      }
    });
  };
  //disposisi
  disposisi = (label, id) => {
    //console.log(label);
    Get(
      "disposisi/all/" +
        this.props.location.state.uuid +
        "/" +
        label +
        "/" +
        id,
      null,
      dtkat => {
        if (dtkat.code === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Data Disposisi",
            pesanAlert: "Berhasil  Disposisi Data"
          });
          this.componentDidMount();
          this.tutupForm();
        } else {
          this.setState({
            show: true,
            basicType: "danger",
            basicTitle: "Data Disposisi",
            pesanAlert: "Disposisi telah dilakukan"
          });
          this.tutupForm();
        }
      }
    );
    //console.log(id);
  };
  // ============================== Ubah Data ===============================

  ubahData = id => {
    // this.state.status.btnAksi = true;
    // this.componentDidMount();
    Get("surat-keterangan/grup/find", id, data => {
      if (typeof data.results !==  "undefined") {
        data.results.data_detail_suket.forEach(dt => {
          if (dt.nama_item ===  "usaha") {
            this.setState({
              dataUsaha: JSON.parse(dt.isi_item),
            })
          }
          this.forceUpdate();
        });
        //console.log(data);
        this.setState({
          dt:{
            ...this.state.dt,
            uuid: data.results.uuid,
            nomor: data.results.nomor,
            tujuan_pembuatan: data.results.tujuan_pembuatan,
            lokasi: data.results.lokasi,
            kk: data.results.kk,
            nik: data.results.nik,
            nama_pemohon: data.results.nama_pemohon,
            nomor_surat: data.results.nomor_surat,
            tempat_lahir: data.results.tempat_lahir,
            tanggal_lahir: data.results.tanggal_lahir,
            pekerjaan: data.results.pekerjaan,
            pendidikan_terakhir: data.results.pendidikan_terakhir,
            agama: data.results.agama,
            jenis_kelamin: data.results.jenis_kelamin,
            status_perkawinan: data.results.status_perkawinan,
            alamat: data.results.alamat,
          },
          status:{
            ...this.state.status,
            btnForm: false,
          },
          tanggal_surat: new Date(data.results.tanggal_surat),
          pendStat: { value: data.results.nik, label: data.results.nik + ' (' + data.results.nama_pemohon + ')' },
          showBio: true,
          judul: "Ubah Permohonan Keterangan Usaha",
        })
        this.forceUpdate();
        // this.state.status.btnAksi = false;
        // this.componentDidMount();
        // this.handleInputChangeNIK(data.results.nik, "nik");

        this.bukaForm();
      }
    });
  };

  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-keterangan/grup/delete", id, res => {
      if (res ===  200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Surat Izin",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Izin",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.setState({
      status:{
        ...this.state.status,
        form: true,
      },
    })

    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      dt:{
        ...this.state.dt,
        nik: "",
        nama_pemohon: "",
        tempat_lahir: "",
        tanggal_lahir: "",
        pekerjaan: "",
        pendidikan_terakhir: "",
        agama: "",
        status_perkawinan: "",
        alamat: "",
      },
      status:{
        ...this.state.status,
        btnForm: true,
        form: false,
      },
      usaha: [
        { jenis_usaha: "", lokasi: "", umur: "", luas_kebun: "", merk: "" }
      ],
      dataUsaha: [],
      showBio: false,
      pendStat: [],
      pendStat2: [],
      formDisposisi: false,
      formAcc: false,
      tableAcc: "none",
      accStat: [],
    })

    this.forceUpdate();
  };

  persetujuan = (id, jawaban) => {
    this.hideAlert();
    Get(
      "surat-keterangan/grup/persetujuan/" + jawaban + "/" + id,
      null,
      res => {
        if (res.code === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  tombolAksi = (cell, row) => {
    // let btnApproval = false;
    // let btnCetak = false;
    let btnAksi = false;
    let status = "";
    row.data_status.forEach(dt => {
      status = dt.status;
    });
    if (status ===  "Diajukan") {
      // btnCetak = true;
      btnAksi = true;
      // btnApproval = true;
    } else {
      btnAksi = true;
      // btnApproval = true;
      if (status ===  "Ditolak") {
        // btnCetak = true;
      } else if (status ===  "Disetujui") {
        btnAksi = false;
        // btnCetak = true;
        // btnApproval = false;
      } else if ((status ===  "Menunggu Penerbitan") | (status ===  "Selesai")) {
        // btnAksi = false;
        // btnCetak = false;
        // btnApproval = false;
      } else {
        // btnCetak = true;
      }
    }
    return (
      <>
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={() => this.konfirmApprove(row.uuid)}
          disabled={btnApproval}
        >
          <i className="fa fa-check"></i>
        </Button>{" "}
        &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        {/* &nbsp;&nbsp;
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          // disabled={btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "} */}
        &nbsp;&nbsp;
        {/* 
        <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.cetakSurat(e, row)}
          disabled={btnCetak}
        >
          <i className="fa fa-print"></i>
        </Button>
        &nbsp;&nbsp; */}
        {/* //list */}
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.listDisposisi(row)}
        >
          <i className="fa fa-list"></i>
        </Button> */}
      </>
    );
  };

  changeAcara = e => {
    document.getElementById(e.target.id).value = e.target.value;
  };

  //change nik
  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld ===  "nik") {
        let pendStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend = [];
          datanik.data.results.data.forEach(dt => {
            frmPend.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmPend, pendStat });
        });
      } else {
        let nik2Stat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik2 = [];
          datanik.data.results.data.forEach(dt => {
            frmNik2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmNik2, nik2Stat });
        });
      }
    }
  };
  // ambil data penduduk
  getPenduduk = e => {
    if (e) {
      Get("penduduk/find/" + e.value, null, data => {
        let alamat = '';
        // if (JENIS_DESA.toLowerCase() ===  'kelurahan') {
        //   alamat =
        //   " RT. " +
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes
        //     .data_kecamatan.data_kabkota.nama; 
        // } else {
        //   alamat =
        //   " RT. " + 
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " + JENIS_DUSUN + " " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.data_kabkota.nama; 
        // }
        if (localStorage.getItem('JENIS_DESA').toLowerCase() ===  'kelurahan') {
          alamat =
          " RT. " +
          data.results.data_kk.data_rt.nama +
          "/RW. " +
          data.results.data_kk.data_rt.data_rw.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes.nama +
          ", Kec. " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.data_kabkota.nama; 
        } else {
          if (data.results.data_kk.id_rt ===  '0') {
            alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
            ", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
          } else {
            alamat =
            " RT. " + 
            data.results.data_kk.data_rt.nama +
            "/RW. " +
            data.results.data_kk.data_rt.data_rw.nama +
            ", " + JENIS_DUSUN + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.nama +
            ", " + localStorage.getItem('JENIS_DESA') + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
            ", Kec. " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.nama +
            ", " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.data_kabkota.nama; 
          }
        }

        // let daerah = data.results.data_kk.data_rt.data_rw.data_dusun.nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
        //       ", " +
        //       data.results.data_kk.results.dataKkbj.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota
        //         .nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota
        //         .data_provinsi.nama

        Get("catatan-penduduk/nik", data.results.id, dpddk => {
          let pctt = 0;
          let catatan = "";
          if (dpddk.results.data) {
            dpddk.results.data.forEach(dtp => {
              if (dtp.status ===  0 && dtp.penalti ===  1) {
                pctt = 1;
                catatan = dtp.catatan;
              }
            });
          }
          if (pctt ===  0) {
            this.setState({
              dt:{
                ...this.state.dt,
                nik: data.results.id,
                kk: data.results.id_kk,
                nama_pemohon: data.results.nama,
                tempat_lahir: data.results.tempat_lahir,
                tanggal_lahir: data.results.tanggal_lahir,
                pekerjaan: data.results.data_pekerjaan.nama,
                jenis_kelamin: data.results.jk,
                pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
                agama: data.results.data_agama.nama,
                status_perkawinan: data.results.status_perkawinan,
                alamat: alamat,
              },
              status:{
                ...this.state.status,
                btnForm: false,
              },
              showBio: true,
              pendStat: { value: e.value, label: e.label },
            })

            // this.state.status.btnAksi = false;
            this.forceUpdate();
          } else {
            this.setState({
              dt:{
                ...this.state.dt,
                nik: "",
                nama_pemohon: "",
                tempat_lahir: "",
                tanggal_lahir: "",
                pekerjaan: "",
                pendidikan_terakhir: "",
                agama: "",
                status_perkawinan: "",
                alamat: "",
              },
              showBio: false,
              btnForm: true,
              show: true,
              basicType: "danger",
              basicTitle: "Peringatan Catatan Warga",
              pesanAlert:
                "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                catatan,
              pendStat: null,
              // frmPend: []
            });
            this.forceUpdate();
          }
          
          // For testing
          if (this.props.selectOnChange) {
            this.props.selectOnChange(this.state.dt)
          }
        });
      });
    } else {
      this.setState({
        dt:{
          ...this.state.dt,
          nik: "",
          nama_pemohon: "",
          tempat_lahir: "",
          tanggal_lahir: "",
          pekerjaan: "",
          pendidikan_terakhir: "",
          agama: "",
          status_perkawinan: "",
          alamat: "",
        },
        status:{
          ...this.state.status,
          btnForm: true,
        },
        showBio: false,
        pendStat: [],
      })
      // this.state.status.btnAksi = false;
      this.forceUpdate();
    }
  };
  ///acc
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  //change select
  changAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      if (e.value ===  "Disetujui") {
        this.setState({
          tableAcc: "",
          btnFormAcc: true,
        })
        if (this.state.accButton.length > 0) {
          this.setState({
            btnFormAcc: false,
          })
        }
      } else {
        this.setState({
          tableAcc: "none",
          btnFormAcc: false,
        })
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      this.setState({
        tableAcc: "none",
        btnFormAcc: true,
      })
    }
    this.setState({
      status:{
        ...this.state.status,
        select: false,
      },
    })
    this.forceUpdate();
  };
  simpanAcc = e => {
    e.preventDefault();
    this.setState({
      btnFormAcc: true,
    })

    let add = {
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10),

      uuid: this.state.accuuid
    };
    Post(
      "surat-keterangan/grup/persetujuan/Selesai/" + this.state.idaccSurat,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.setState({
            formAcc: false,
            show: true,
            basicType: "success",
            tableAcc: "none",
            accStat: [],
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            btnFormAcc: false,

            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.map(x => 1);
    temp[id] = 0;
    this.setState({
      accButton: temp,
      accuuid: uuid,
    })
    if (this.state.accStat) {
      this.setState({
        btnFormAcc: false,
      })
    }
    this.forceUpdate();
  };
  componentDidMount() {
    if (this.props.location.state ===  undefined) {
      this.setState({ redirect: true });
    } else {
      // //console.log()
      this.fetch({ page: null, where: [] });

      Get("penduduk", null, data => {
        let frmPend = [];
        data.results.data.forEach(dt => {
          frmPend.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
        });
        this.setState({ frmPend });

        // For testing
        if (this.props.getListNIK) {
          this.props.getListNIK(frmPend)
        }
      });
      Get("select-enum/penduduk/jk", null, data => {
        let frmJk = [];
        data.forEach(dt => {
          frmJk.push({ value: dt, label: dt });
        });
        this.setState({ frmJk });
      });
      Get("tanda-tangan/list", null, data => {
        data.results.forEach((dt, i) => {
          if (dt.aktif ===  1) {
            let temp = this.state.accButton.map(x => 1);
            temp[i] = 0;
            this.setState({
              accButton: temp,
              accuuid: dt.uuid,
            })
          }
        });
        this.forceUpdate();
        this.setState({ frmTTD: data.results });
      });
    }
  }

  cetakSurat = (e, data) => {
    e.preventDefault();
    let detail = null;

    data.data_detail_suket.forEach(dt => {
      if (dt.nama_item ===  "usaha") {
        detail = JSON.parse(dt.isi_item);
      }
    });
    //console.log(detail);
    this.setState({
      cetak: {
        nama_desa: Nama_DESA,
        tanggal_surat: Tanggal(data.tanggal_surat),
        nomor_surat: data.nomor,
        nik: data.nik,
        nama_pemohon: data.nama_pemohon.toLowerCase(),
        tempat_lahir: data.tempat_lahir.toLowerCase(),
        tanggal_lahir: Tanggal(data.tanggal_lahir),
        pekerjaan: data.pekerjaan.toLowerCase(),
        pendidikan_terakhir: data.pendidikan_terakhir.toLowerCase(),
        status_perkawinan: data.status_perkawinan.toLowerCase(),
        agama: data.agama.toLowerCase(),
        alamat: data.alamat.toLowerCase(),
        jenis_kelamin: data.jenis_kelamin.toLowerCase(),
        kewarganegaraan: data.kewarganegaraan.toLowerCase(),
        tujuan_pembuatan: data.tujuan_pembuatan.toLowerCase(),
        lokasi: data.lokasi,
        kk: data.kk,
        status_ttd: data.status_ttd,
        jabatan_ttd: data.jabatan_ttd,
        jabatan_pengganti_ttd: data.jabatan_pengganti_ttd,
        nama_ttd: data.nama_ttd,
        nip_ttd: data.nip_ttd ? "NIP. " + data.nip_ttd : "",
        detail: detail
      }
    });
    setTimeout(function() {
      const content = document.getElementById("cetak-surat").innerHTML;
      const mywindow = window.open("", "Print", "height=600,width=1000");

      mywindow.document.write("<html><head><title>Print</title>");
      mywindow.document.write("</head><body >");
      mywindow.document.write(content);
      mywindow.document.write("</body></html>");

      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      setTimeout(function() {
        mywindow.close();
      }, 2000);
    }, 200);
  };

  //
  gantiTglMulai = date => {
    this.setState({ tanggal_mulai: date });
  };
  gantiTglSelesai = date => {
    this.setState({ tanggal_selesai: date });
  };
  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };
  render() {
    //console.log("Data Render")
    //console.log("Data Usaha")
    //console.log("====================")
    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;

    let frmDef = {
      uuid: "",
      id_jenis: "",
      id_pengaju: "",
      tanggal_surat: "",
      nik: "",
      nama_pemohon: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      pekerjaan: "",
      agama: "",
      status_perkawinan: "",
      alamat: "",
      kewarganegaraan: "Indonesia",
      tujuan_pembuatan: "",
      lokasi: "",
      kk: "",
      detail: [],
      jenis_kelamin: "",
      status_ttd: "",
      jabatan_ttd: "",
      jabatan_pengganti_ttd: "",
      nama_ttd: "",
      nip_ttd: "",
      //detail
      //detail
      tgl_mulai: "",
      tgl_selesai: "",
      waktu_mulai: "",
      waktu_selesai: "",
      tempat: "",
      hiburan: ""
    };
    // const { SearchBar } = Search;
    const columns = [
      {
        dataField: "tanggal_surat",
        headerAlign: 'center',
				align: 'center',
        text: "Tanggal",
        formatter: (cell, obj) => {
          return obj.tanggal_surat ===  null
            ? "-"
            : new Date(obj.tanggal_surat).toLocaleDateString("id-ID");
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "nomor",
        headerAlign: 'center',
				align: 'center',
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor ===  "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "nama_pemohon",
        headerAlign: 'center',
				align: 'center',
        text: "Nama Pemohon",
        sort: true
      },
      {
        dataField: "tujuan_pembuatan",
        headerAlign: 'center',
				align: 'center',
        text: "Tujuan",
        formatter: (cell, obj) => {
          let tujuan = obj.tujuan_pembuatan.split(",");
          return tujuan[0];
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "status",
        headerAlign: 'center',
				align: 'center',
        text: "Status",
        formatter: (cell, obj) => {
          let status = "-";
          obj.data_status.forEach(dt => {
            status = dt.status;
          });
          if (status ===  "Diajukan") {
            this.setState({
              status:{
                ...this.state.status,
                btnAksi: true,
              },
            })
          } else {
            this.setState({
              status:{
                ...this.state.status,
                btnAksi: false,
              },
            })
          }
          this.forceUpdate();
          return status;
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-primary-custom";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href="#" onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };
    // const usaha = this.state.usaha.map((dt, idx) => (
    //   <div className="shareholder">
    //     <Label className="col-sm-2 col-form-label" htmlFor="inputEmail3">
    //       Usaha {idx + 1}
    //     </Label>

    //     <FormGroup className="row">
    //       <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
    //         Jenis
    //       </Label>
    //       <Col sm="9">
    //         <input
    //           className="form-control"
    //           id={"jenis_usaha" + idx}
    //           type="text"
    //           required
    //           placeholder="Jenis"
    //           defaultValue={dt.jenis}
    //         />
    //       </Col>
    //     </FormGroup>
    //     <FormGroup className="row">
    //       <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
    //         Lokasi
    //       </Label>
    //       <Col sm="9">
    //         <input
    //           className="form-control"
    //           id={"lokasi" + idx}
    //           type="text"
    //           required
    //           placeholder="Lokasi"
    //           defaultValue={dt.lokasi}
    //         />
    //       </Col>
    //     </FormGroup>
    //     <FormGroup className="row">
    //       <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
    //         Lama Usaha
    //       </Label>
    //       <Col sm="9">
    //         <input
    //           className="form-control"
    //           id={"umur" + idx}
    //           type="text"
    //           required
    //           placeholder=" Keterangan"
    //           defaultValue={dt.umur}
    //         />
    //       </Col>
    //     </FormGroup>
    //     <FormGroup className="row">
    //       <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
    //         Luas
    //       </Label>
    //       <Col sm="9">
    //         <input
    //           className="form-control"
    //           id={"luas_kebun" + idx}
    //           type="text"
    //           required
    //           placeholder=" Luas"
    //           defaultValue={dt.luas_kebun}
    //         />
    //       </Col>
    //     </FormGroup>
    //     <FormGroup className="row">
    //       <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
    //         Merk
    //       </Label>
    //       <Col sm="9">
    //         <input
    //           className="form-control"
    //           id={"merk" + idx}
    //           type="text"
    //           required
    //           placeholder=" Merk"
    //           defaultValue={dt.merk}
    //         />
    //       </Col>
    //     </FormGroup>
    //     <div className="float-right">
    //       <button
    //         type="button"
    //         className="btn btn-danger small"
    //         onClick={this.handleRemoveShareholder(idx)}
    //       >
    //         -
    //       </button>
    //     </div>
    //   </div>
    // ));
    let no = 0;
    const tableTTD = this.state.frmTTD.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] ===  0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] ===  0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <div data-testid="page-surat keterangan usaha">
        {this.renderRedirect()}

        {/* <Breadcrumb title="Data Surat Keterangan Usaha" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  this.bukaForm();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                color="info"
                className="btn btn-secondary-custom"
                onClick={() => {
                  this.closeAlert();
                }}
              >
                <Link
                  data-testid="btn-eksekusi_surat"
                  onClick={() => {
                    // For testing
                    if (this.props.clickEksekusiSurat) {
                      this.props.clickEksekusiSurat()
                    }
                  }}
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat Surat Keterangan Usaha</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        data-testid="tblCreateSuratUsaha"
                        size="sm"
                        color="success"
                        // className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Surat Keterangan Usaha",
                            dt: frmDef,
                            usaha: [
                              { jenis: "", lokasi: "", umur: "", luas: "" }
                            ],
                            dataUsaha: []
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <div className="row">
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className="form-control"
                        id="cariTable"
                        placeholder="Cari Data"
                        onKeyPress={this.cariData}
                      />
                    </div>
                  </div>
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form 
            data-testid="form-create" 
            className="theme-form" 
            onSubmit={this.simpan}
          >
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="nik"
                >
                  NIK{" "}
                </Label>
                <Col sm="9">
                  <div data-testid="selectNIK">
                    <Select
                      inputId="nik"
                      isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e);
                      }}
                      defaultValue={this.state.pendStat}
                      value={this.state.pendStat}
                      name="penduduk"
                      options={this.state.frmPend}
                      placeholder="Pilih NIK"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                      isClearable
                    />
                  </div>
                </Col>
              </FormGroup>

              <hr />
              <div
                style={{ display: this.state.showBio ===  false ? "none" : "" }}
              >
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="nik"
                  type="hidden"
                  readOnly
                  placeholder="NIK"
                  value={this.state.dt.nik}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    KK
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="kk"
                  type="hidden"
                  readOnly
                  placeholder="kk"
                  value={this.state.dt.kk}
                />
                {/* </Col>
                </FormGroup> */}

                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pemohon
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama_pemohon"
                      readOnly
                      type="text"
                      placeholder="nama_pemohon"
                      value={this.state.dt.nama_pemohon}
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      readOnly
                      id="tempat_lahir"
                      type="text"
                      placeholder="Tempat lahir"
                      value={this.state.dt.tempat_lahir}
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir"
                      readOnly
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir}
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Jenis Kelamin
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="jenis_kelamin"
                  readOnly
                  type="hidden"
                  placeholder="jenis_kelamin"
                  value={this.state.dt.jenis_kelamin}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  readOnly
                  id="pekerjaan"
                  type="hidden"
                  placeholder="Pekerjaan"
                  value={this.state.dt.pekerjaan}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pendidikan Terakhir
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="pendidikan_terakhir"
                  readOnly
                  type="hidden"
                  placeholder="Pendidikan Terakhir"
                  value={this.state.dt.pendidikan_terakhir}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="agama"
                  readOnly
                  type="hidden"
                  placeholder="Agama"
                  value={this.state.dt.agama}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Status Perkawinan
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="status_perkawinan"
                  type="hidden"
                  readOnly
                  placeholder="Status Perkawinan"
                  value={this.state.dt.status_perkawinan}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="alamat"
                  type="hidden"
                  readOnly
                  placeholder="Alamat"
                  value={this.state.dt.alamat}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Kewarganegaraan
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="kewarganegaraan"
                  type="hidden"
                  readOnly={true}
                  placeholder="Kewarganegaraan"
                  value={this.state.dt.kewarganegaraan}
                />
                {/* </Col>
                </FormGroup> */}
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-6 col-form-label text-center"
                  htmlFor="inputEmail3"
                >
                  <Link
                    to={{
                      pathname: this.props.myroute,
                      state: this.props.location.state
                    }}
                    data-testid="btnInputUsaha"
                    onClick={e => {
                      this.setState({ jenisForm: "usaha" });
                      if (this.props.getJenisInput) {
                        this.props.getJenisInput("usaha")
                      }
                    }}
                  >
                    Usaha
                  </Link>
                </Label>
                <Label
                  className="col-sm-6 col-form-label text-center"
                  htmlFor="inputEmail3"
                >
                  <Link
                    to={{
                      pathname: this.props.myroute,
                      state: this.props.location.state
                    }}
                    data-testid="btnInputKebun"
                    onClick={e => {
                      this.setState({ jenisForm: "kebun" });
                      if (this.props.getJenisInput2) {
                        this.props.getJenisInput2("kebun")
                      }
                    }}
                  >
                    Perkebunan
                  </Link>
                </Label>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-2 col-form-label" htmlFor="inputEmail3">
                  Form {this.state.jenisForm}
                </Label>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Jenis
                </Label>
                <Col sm="9">
                  <input
                    data-testid="jenis_usaha"
                    name="jenis_usaha"
                    className="form-control"
                    id="jenis_usaha"
                    type="text"
                    // required
                    placeholder="Jenis"
                    defaultValue=""
                  />
                </Col>
              </FormGroup>
              {this.state.jenisForm ===  "usaha" ? (
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Tempat Usaha
                  </Label>
                  <Col sm="9">
                    <input
                      data-testid="nama_tempat_usaha"
                      name="nama_tempat_usaha"
                      className="form-control"
                      id="nama_tempat_usaha"
                      type="text"
                      // required
                      placeholder=" Merk"
                      defaultValue=""
                    />
                  </Col>
                </FormGroup>
              ) : (
                <></>
              )}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Lokasi
                </Label>
                <Col sm="9">
                  <input
                    data-testid="lokasi"
                    name="lokasi"
                    className="form-control"
                    id="lokasi"
                    type="text"
                    // required
                    placeholder="Lokasi"
                    defaultValue=""
                  />
                </Col>
              </FormGroup>
              {this.state.jenisForm ===  "kebun" ? (
                <>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Umur
                    </Label>
                    <Col sm="9">
                      <input
                        data-testid="umur"
                        name="umur"
                        className="form-control"
                        id="umur"
                        type="text"
                        // required
                        placeholder=" Keterangan"
                        defaultValue=""
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      Luas lahan
                    </Label>
                    <Col sm="9">
                      <input
                        data-testid="luas_kebun"
                        name="luas_kebun"
                        className="form-control"
                        id="luas_kebun"
                        type="text"
                        // required
                        placeholder=" Luas"
                        defaultValue=""
                      />
                    </Col>
                  </FormGroup>
                </>
              ) : (
                <></>
              )}
              <FormGroup className="row">
                <Label
                  className="col-sm-12 col-form-label text-right"
                  htmlFor="inputEmail3"
                >
                  <button
                    data-testid="btnTambahUsaha"
                    type="button"
                    onClick={this.addUsaha}
                    className="btn btn-success small"
                  >
                    Tambah
                  </button>
                </Label>
              </FormGroup>
              {this.state.dataUsaha.length !==  0 ? (
                this.state.dataUsaha.map((dt, index) => {
                  //console.log(this.state.dataUsaha);
                  return (
                    <>
                      <Label
                        className="col-sm-8 col-form-label"
                        htmlFor="inputEmail3"
                      >
                        {"Usaha " + (index + 1)}
                      </Label>
                      <Label
                        className="col-sm-4 col-form-label"
                        htmlFor="inputEmail3"
                      >
                        <Button
                          size="xs"
                          color="danger"
                          className="btn-icon"
                          onClick={() => this.hapusUsaha(index)}
                          // disabled={btnAksi}
                        >
                          <i className="fa fa-trash"></i>
                        </Button>{" "}
                      </Label>

                      {typeof dt.jenis_usaha !==  "undefined" ? (
                        dt.jenis_usaha !==  "" ? (
                          <>
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              Jenis Usaha
                            </Label>
                            <Label
                              className="col-sm-2 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              :
                            </Label>
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              {dt.jenis_usaha}
                            </Label>
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      {typeof dt.nama_tempat_usaha !==  "undefined" ? (
                        dt.nama_tempat_usaha !==  "" ? (
                          <>
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              Nama Tempat usaha
                            </Label>
                            <Label
                              className="col-sm-2 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              :
                            </Label>
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              {dt.nama_tempat_usaha}
                            </Label>
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      {typeof dt.lokasi !==  "undefined" ? (
                        dt.lokasi !==  "" ? (
                          <>
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              Lokasi
                            </Label>
                            <Label
                              className="col-sm-2 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              :
                            </Label>
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              {dt.lokasi}
                            </Label>
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                      {typeof dt.umur !==  "undefined" ? (
                        dt.umur !==  "" ? (
                          <>
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              umur
                            </Label>
                            <Label
                              className="col-sm-2 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              :
                            </Label>
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              {dt.umur}
                            </Label>
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}

                      {typeof dt.luas_kebun !==  "undefined" ? (
                        dt.luas_kebun !==  "" ? (
                          <>
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              luas_kebun
                            </Label>
                            <Label
                              className="col-sm-2 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              :
                            </Label>
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              {dt.luas_kebun}
                            </Label>
                          </>
                        ) : (
                          <></>
                        )
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })
              ) : (
                <>
                  <Label
                    className="col-sm-12 col-form-label text-center"
                    htmlFor="inputEmail3"
                  >
                    Data usaha belum ada
                  </Label>
                </>
              )}
              {/* <button
                type="button"
                onClick={this.handleAddShareholder}
                className="btn btn-primary-custom small"
              >
                Tambah Usaha
              </button> */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                // disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                data-testid="btnSubmitCreate"
                type="submit"
                className="btn btn-primary-custom"
                // disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  <Datepicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  />
                </Col>
              </FormGroup>

              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary-custom">
                Selesai
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Disposisi Surat</h5>
            </div>
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Jabatan</th>
                    <th>Level</th>
                    <th>TTD</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.datDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{dt.data_jabatan.nama}</td>
                        <td>{dt.level}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={() => {
                              this.disposisi(dt.uuid, this.state.idSurat);
                            }}
                            // disabled={this.state.status.btnForm}
                          >
                            Disposisi
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Pejabat</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.riwayatDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {dt.status ===  0
                            ? "Menunggu Disposisi"
                            : dt.status ===  1
                            ? "Telah di disposisi"
                            : "Ditolak"}
                        </td>
                        <td>{dt.data_perangkat.data_jabatan.nama}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <div id="cetak-surat" style={{ display: "none" }}>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    "
            }}
          />
          <table width="100%" border="0">
            <tr>
              <td width="100" align="center">
                <img alt="logo" src={logo} width="80px" />
              </td>
              <td align="center">
                <div style={{ fontWeight: "bold" }}>
                  <span
                    style={{ fontSize: "24px", textTransform: "uppercase" }}
                  >
                    pemerintah {NAMA_KABUPATEN}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "22px", textTransform: "uppercase" }}
                  >
                    kecamatan {KECAMATAN}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "28px", textTransform: "uppercase" }}
                  >
                    kantor kepala {Nama_DESA}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "16px", textTransform: "capitalize" }}
                  >
                    {ALAMAT_KANTOR_DESA}
                  </span>
                </div>
              </td>
            </tr>
          </table>
          <hr style={{ border: "2px solid black" }} />
          <table
            border="0"
            width="90%"
            style={{
              marginTop: "30px",
              marginLeft: "auto",
              marginRight: "auto",
              verticalAlign: "top"
            }}
          >
            <tr>
              <td align="center" style={{ paddingBottom: "20px" }}>
                {" "}
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    textDecoration: "underline"
                  }}
                >
                  surat keterangan Usaha
                </span>
                <br />
                {this.state.cetak.nomor_surat}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p style={{ textIndent: "50px" }}>
                  Yang bertanda tangan dibawah ini Kepala {Nama_DESA},
                  Kecamatan {KECAMATAN}, {NAMA_KABUPATEN}, Provinsi {PROVINSI},
                  dengan ini menerangkan bahwa :{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <table border="0" width="100%">
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.nama_pemohon}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat / Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.tempat_lahir},
                      {Tanggal(this.state.cetak.tanggal_lahir)}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.pekerjaan}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Status Perkawinan
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.status_perkawinan}
                    </td>
                  </tr>

                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Agama
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.agama}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.alamat}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <span style={{ paddingLeft: "50px" }}>
                  Selanjutnya kami terangkan bahwa yang bersangkutan benar-benar
                  memiliki usaha yakni:
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify", paddingLeft: "60px" }}>
                <ol>
                  {this.state.cetak.detail.map((dt, idx) => {
                    return (
                      <div>
                        <li>
                          <table border="0" width="100%">
                            {dt.jenis_usaha !==  "" ? (
                              <tr style={{ textTransform: "capitalize" }}>
                                <td width="20%" style={{ paddingLeft: "20px" }}>
                                  Jenis
                                </td>
                                <td width="5%">:</td>
                                <td
                                  width="75%"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {dt.jenis_usaha.toLowerCase()}
                                </td>
                              </tr>
                            ) : (
                              <> </>
                            )}

                            {dt.lokasi !==  "" ? (
                              <tr>
                                <td width="20%" style={{ paddingLeft: "20px" }}>
                                  Lokasi
                                </td>
                                <td width="5%">:</td>
                                <td
                                  width="75%"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {dt.lokasi.toLowerCase()}
                                </td>
                              </tr>
                            ) : (
                              <> </>
                            )}
                            {dt.luas_kebun !==  "" ? (
                              <tr>
                                <td width="20%" style={{ paddingLeft: "20px" }}>
                                  Lama Usaha
                                </td>
                                <td width="5%">:</td>
                                <td
                                  width="75%"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {dt.umur.toLowerCase()}
                                </td>
                              </tr>
                            ) : (
                              <> </>
                            )}
                            {dt.luas_kebun !==  "" ? (
                              <tr>
                                <td width="20%" style={{ paddingLeft: "20px" }}>
                                  Luas Kebun
                                </td>
                                <td width="5%">:</td>
                                <td
                                  width="75%"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {dt.luas_kebun.toLowerCase()}
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                            {dt.merk !==  "" ? (
                              <tr>
                                <td width="20%" style={{ paddingLeft: "20px" }}>
                                  Merk
                                </td>
                                <td width="5%">:</td>
                                <td
                                  width="75%"
                                  style={{ textTransform: "capitalize" }}
                                >
                                  {dt.merk.toLowerCase()}
                                </td>
                              </tr>
                            ) : (
                              <></>
                            )}
                          </table>
                        </li>
                      </div>
                    );
                  })}
                </ol>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p style={{ textIndent: "50px" }}>
                  Demikianlah Surat Keterangan Usaha ini kami buat berdasarkan
                  keterangan yang bersangkutan untuk dapat dipergunakan
                  sebagaimana mestinya
                </p>
              </td>
            </tr>
          </table>
          <table border="0" width="100%">
            <tr>
              <td style={{ textAlign: "justify" }} width="50%">
                &nbsp;
              </td>
              <td style={{ textAlign: "justify" }} width="50%">
                <table border="0" width="100%" cellpadding="0" cellspacing="0">
                  <tr>
                    <td width="35%">Dikeluarkan di</td>
                    <td>:</td>
                    <td>{this.state.cetak.nama_desa}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ borderBottom: "1px solid black" }}>
                      Dikeluarkan pada
                    </td>
                    <td style={{ borderBottom: "1px solid black" }}>:</td>
                    <td style={{ borderBottom: "1px solid black" }}>
                      {Tanggal(new Date(this.state.cetak.tanggal_surat))}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table border="0" width="100%" style={{ marginTop: "20px" }}>
            <tr>
              <td width="50%">&nbsp;</td>
              <td
                width="50%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {this.state.cetak.status_ttd}{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {this.state.cetak.jabatan_ttd}
                  </span>
                  {this.state.cetak.jabatan_pengganti_ttd ? (
                    <>
                      <br />
                      {this.state.cetak.jabatan_pengganti_ttd}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td height="50" colspan="2">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td align="center">&nbsp;</td>
              <td align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama_ttd}
                </span>
                <br />
                {this.state.cetak.nip_ttd}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default BasicTable;
