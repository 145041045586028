import React, {Component} from 'react';

// import Custom Componenets
// import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Select from 'react-select';

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import {Post, Get, Delete, } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

//json file
// var data = require('../assets/json/company');


const frmDef = {
    id: 0,
    idbaru: '',
    id_provinsi: '',
    nama  : '',
}
class BasicTable extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            pagination: [],
            status: {
                form:false,
                btnForm:false,
                btnAksi:false,
            },
            awal: "",
            sebelum: "",
            hal: [],
            setelah: "",
            akhir: "",
            dt:frmDef,
            judul: "Tambah KabKota",
            // =========== Select 2 ============
            provinsi: [],
            frmPrv: [],
            prvStat: [],
            // =================================
            // =========== Alert ============
            alert:null,
            show: false,
            basicTitle:'',
            basicType:"default",
            pesanAlert:'',
            // ==============================
        };
    }
    
    // ================== Sweet Alert ==================
    closeAlert = () => {
        this.setState({
            show: false
        });
    }

    onRecieveInput = (value) => {
        this.setState({
            alert: (
                <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
                    You wrote: {value}
                </SweetAlert>
            )
        });
    }

    hideAlert = () => {
        this.setState({
            alert: null
        });
    }

    // =================================================

    // ================== Ambil data dari db untuk table ==================
    fetch = (params = {}) => 
    {    
        let link = "";
        // let where = [];
        // if (params.where)
        // {
        //     where = params.where;
        // }
        if (params.page)
        {
            switch(params.page)
            {
                case '>>': link = "?page="+(Math.ceil(this.state.pagination.total / this.state.pagination.per_page));break;
                case '>': link = "?page="+(parseInt(this.state.pagination.current_page) + 1);break;
                case '<': link = "?page="+(parseInt(this.state.pagination.current_page) - 1);break;
                case '<<': link = "?page=1";break;
                default: link = "?page="+params.page;break;
            }
        }
        Get('kab-kota'+link, null, (dtkat) => {
            this.setState({data:dtkat.results.data, pagination:dtkat.results});
            this.pagination(dtkat.results);
        });
    }

    // Pagination
    pagination = (data) => {
        // const data = this.state.pagination;
        //console.log(data);
        let awal = "";
        let hal = [];
        let sebelum = "";
        let setelah = "";
        let akhir = "";
        if (data.total > 0)
        {
            let start = 1;
            let end = 5;
            let n = 0;
            let p = 0;
            if (data.current_page <= 3)
            {   
                start = 1;
                end = 5;
                if (data.last_page > data.current_page)
                {
                    n = data.current_page + 1;
                }
            }
            else 
            {
                p = data.current_page - 1;
                n = data.current_page + 1;
                start = data.current_page - 2;
                end = data.current_page + 2;
            }
            if (end >= data.last_page - 2)
            {
                p = data.current_page - 1;
                if (start >= 5)
                {
                    start = data.last_page - 4;
                }
                end = data.last_page;
            }

            for (let i=start;i<=end;i++) 
            {
                let warna = "primary-custom";
                if (i === data.current_page)
                {
                    warna = "danger"
                }
                hal.push(<a href={() => false} onClick={()=>this.fetch({page:i})} className={"btn btn-"+warna+" btn-square btn-sm pr-3 pl-3 pt-2 pb-2"}>{i}</a>)
            }
            if (p > 0)
            {
                sebelum = (<a href={() => false} onClick={()=>this.fetch({page:p})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<"}</a>)
            }
            if (n > 0)
            {
                setelah = (<a href={() => false} onClick={()=>this.fetch({page:n})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">"}</a>)
            }
            awal = (<a href={() => false} onClick={()=>this.fetch({page:1})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{"<<"}</a>)
            akhir = (<a href={() => false} onClick={()=>this.fetch({page:data.last_page})} className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2">{">>"}</a>)
        }
        this.setState({awal,sebelum,hal,setelah,akhir});
    }
    // Cari Data Table
    cariData = e => {
        if (e.key === "Enter")
        {
            let addKabKota = {
                nama : e.target.value,
            };
            Post('kab-kota', null, addKabKota, (data) => {
                this.setState({data:data.data.results.data, pagination:data.data.results});
            });
        }
    }

    // ========================================================================

    // ================== simpan data ==================
    simpan = e => {
        e.preventDefault();
        if (this.state.dt.id_provinsi > 0)
        {
            this.setState({
                status:{
                    ...this.state.status,
                    btnForm: true,
                },
            })
            this.forceUpdate();
            let id = parseInt(document.getElementById("id").value);
            let addKabKota = {
                id : document.getElementById("idbaru").value,
                id_provinsi : this.state.dt.id_provinsi,
                nama : document.getElementById("nama").value,
            };
            
            let psn = '';
            let resstat = 204;
            let metode = "create";
            if (id === 0)
            {
                psn = "Tambah"; 
                resstat = 201;
                id = null;
                
            } 
            else 
            {
                psn = "Ubah"; 
                resstat = 200;
                metode = "update";
            }
            Post('kab-kota/'+metode,id,addKabKota, (res) => {
                this.setState({
                    status:{
                        ...this.state.status,
                        btnForm: false,
                        form: false,
                    },
                })
                this.forceUpdate();
                if (res.status === resstat)
                {
                    this.setState({ show: true, basicType:'success', basicTitle:'Data KabKota', pesanAlert:'Berhasil '+psn+' Data' });
                }
                else
                {
                    this.setState({ show: true, basicType:'danger', basicTitle:'Data KabKota', pesanAlert:'Gagal '+psn+' Data' });
                }
                this.componentDidMount();
            });
        }
        else
        {
            this.setState({ show: true, basicType:'danger', basicTitle:'Data KabKota', pesanAlert:"Provinsi tidak boleh kosong" });
        }
    }
    // ========================================================================
    
    // ============================== Ubah Data ===============================
    
    ubahData = (id) => {
        this.setState({
            status:{
                ...this.state.status,
                btnAksi: true,
            },
            judul: "Ubah KabKota",
        })
        this.forceUpdate();
        Get('kab-kota/find',id, (data) => {
            this.setState({
                dt:{
                    ...this.state.dt,
                    id: data.results.id,
                    idbaru: data.results.id,
                    id_provinsi: data.results.id_provinsi,
                    nama: data.results.nama,
                },
                status:{
                    ...this.state.status,
                    btnAksi: false,
                },
            })
            this.forceUpdate();
            this.ubahPilihan('provinsi', data.results.id_provinsi);
        });
    }
    // ========================================================================
    // ============================= Hapus Data ===============================
    konfirmHapus = (id) => {
        this.setState({
            alert: (
                <SweetAlert
                    showCancel
                    confirmBtnText="Hapus"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    type="danger"
                    title="Yakin ingin hapus data ?"
                    onCancel={this.hideAlert}
                    onConfirm={()=> this.hapusData(id)}
                >
                    Proses ini tidak dapat dibatalkan!
                </SweetAlert>
            )
        });
    }

    hapusData = (id) => {
        this.hideAlert();
        let psn = "Hapus";
        Delete('kab-kota/delete', id, (res) => {
            if (res === 200)
            {
                this.setState({ show: true, basicType:'success', basicTitle:'Data KabKota', pesanAlert:'Berhasil '+psn+' Data' });
            }
            else
            {
                this.setState({ show: true, basicType:'danger', basicTitle:'Data KabKota', pesanAlert:'Gagal '+psn+' Data' });
            }
            this.componentDidMount();
        })
    }
    // ========================================================================

    bukaForm = () => {
        this.setState({
            status:{
                ...this.state.status,
                form: true,
            },
        })
        this.forceUpdate();
    }

    tutupForm = () => {
        this.setState({
            status:{
                ...this.state.status,
                form: false,
            },
        })
        this.forceUpdate();
    }

    tombolAksi = (cell, row) => {
        return (
            <>
                <Button  size="xs" color="success" className="btn-icon" onClick={() => this.ubahData(row.id)} disabled={this.state.status.btnAksi}><i className="fa fa-pencil"></i></Button> &nbsp;&nbsp;
                <Button  size="xs" color="danger" className="btn-icon" onClick={() => this.konfirmHapus(row.id)} disabled={this.state.status.btnAksi}><i className="fa fa-trash"></i></Button>  
            </>
        )
    }

    ubahPilihan = (table, id) => 
    {
        Get(table+'/find', id, (data) => {
            switch(table)
            {
                case 'provinsi': this.setState({prvStat:{value:data.results.id, label:data.results.nama}});
                this.bukaForm(); break;
                default:
                    // no default
                    break;
            }
        });
    }

    changeSelectValue = (e,sel) =>
    {
        this.setState({prvStat:e});
        if (e){
            switch(sel)
            {
                case 'provinsi': 
                    this.setState({
                        dt:{
                            ...this.state.dt,
                            id_provinsi: e.value,
                        },
                    })
                    break;
                default:
                    // no default
                    break;
            }
        }
        else 
        {
            switch(sel)
            {
                case 'provinsi': 
                    this.setState({
                        dt:{
                            ...this.state.dt,
                            id_provinsi: 0,
                        },
                    })
                    break;
                default:
                    // no default
                    break;
            }
        }
        this.forceUpdate();
    }

    componentDidMount() {
        this.fetch({page:null,where:[]});
        Get('provinsi/list', null, (data) => {
            let frmPrv = [];
            let provinsi = [];
            data.results.forEach((dt) => {
                provinsi[dt.id] = dt.nama;
                frmPrv.push({value:dt.id, label:dt.nama});
            });
            this.setState({provinsi,frmPrv});
        })
    }

    render() {
        // var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
        // var size = this.state.pagination.total;
        // var from = this.state.pagination.from;
        // var page = this.state.pagination.current;
        // var to = this.state.pagination.to;
        // var total = this.state.pagination.total;
        var data = this.state.data;
        const frmDef = {
            id: 0,
            idbaru: '',
            id_provinsi: '',
            nama  : '',
        };
        // const { SearchBar } = Search;
        const columns = [
            {
                dataField: 'id',
                text: 'ID',
                sort: false
            },
            {
                dataField: 'nama_provinsi',
                text: 'Provinsi',
                isDummyField: true,
                formatter: (cell, obj)=> {return obj.data_provinsi.nama},
                sort: true
            },
            {
                dataField: 'nama',
                text: 'KabKota',
                sort: true
            },
            {
                dataField: 'aksi',
                text: 'Aksi',
                isDummyField: true,
                csvExport: false,
                formatter: this.tombolAksi,
            },
        ];

        // const defaultSorted = [{
        //     dataField: 'nama',
        //     order: 'asc'
        // }];

        // const pageButtonRenderer = ({
        //                                 page,
        //                                 active,
        //                                 disable,
        //                                 title,
        //                                 onPageChange
        //                             }) => {
        //     const handleClick = (e) => {
        //         e.preventDefault();
        //         this.fetch({where:[],page:page});
        //     };
        //     var classname = '';
        //         classname = 'btn btn-success';
        //     return (
        //         <li className="page-item pl-1" key={page}>
        //             <a href="#" onClick={ handleClick } className={ classname }>{ page }</a>
        //         </li>
        //     );
        // };

        // const options = {
        //     alwaysShowAllBtns:true,
        //     pageButtonRenderer,
        //     // custom: true,
        //     paginationTotalRenderer: () => (
        //         <span className="react-bootstrap-table-pagination-total">
        //             Showing { from } to { to } of { total } Results
        //         </span>
        //     ),
        //     paginationSize: size, 
        //     totalSize: total
        //   };

        return (
            <div>
                {/* <Breadcrumb title="Data Kabupaten/Kota" parent="Admin"/> */}
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    confirmBtnBsStyle="success"
                    onConfirm={this.closeAlert}
                >
                    {this.state.pesanAlert}
                </SweetAlert>

                {this.state.alert}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card mb-0">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            {/* <h5>Data Kabupaten/Kota</h5> */}
                                            {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                                        </div>
                                        <div className="col-sm-6 text-right">
                                            <Button  size="sm" color="success" className="btn-square" onClick={()=> {
        this.setState({judul:"Tambah KabKota", dt:frmDef});this.bukaForm();}}>Tambah Data</Button>                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body datatable-react">
                                    <BootstrapTable
                                        keyField="id"
                                        data={ data }
                                        columns={ columns }
                                        />
                                    <div className="pull-right text-white">
                                        {this.state.awal}{this.state.sebelum}{this.state.hal.map(dt=> { return dt})}{this.state.setelah}{this.state.akhir}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
                <Modal open={this.state.status.form} onClose={this.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
                    <Form className="theme-form" onSubmit={this.simpan}>
                        <div className="modal-header">
                            <h5 className="modal-title">{this.state.judul}</h5>
                        </div>
                        <div className="modal-body">
                            <input className="form-control" id="id" type="hidden" placeholder="KabKota" defaultValue={this.state.dt.id}/>
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">ID KabKota</Label>
                                <Col sm='9'>
                                    <input className="form-control" id="idbaru" type="text" placeholder="ID KabKota" defaultValue={this.state.dt.idbaru}/>
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Provinsi</Label>
                                <Col sm='9'>
                                    <Select
                                        classNamePrefix="select"
                                        onChange={((e)=>{ this.changeSelectValue(e,'provinsi')})}
                                        defaultValue={this.state.prvStat} 
                                        value={this.state.prvStat} 
                                        name="provinsi"
                                        options={this.state.frmPrv}
                                        placeholder="Pilih Provinsi"
                                        isClearable
                                        />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama KabKota</Label>
                                <Col sm='9'>
                                    <input className="form-control" id="nama" type="text" placeholder="Nama KabKota" defaultValue={this.state.dt.nama}/>
                                </Col>
                            </FormGroup>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-warning" disabled={this.state.status.btnForm} onClick={this.tutupForm}>Tutup</button>
                            <button type="submit" className="btn btn-success" disabled={this.state.status.btnForm} onClick={this.simpan}>Simpan</button>
                        </div>
                    </Form>
                </Modal>

            </div>
        )
    }
}


export default BasicTable;
