import React, { Component } from 'react';

// import Custom Componenets
// import Breadcrumb from '../components/common/breadcrumb.component';
import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
// 	PaginationProvider,
// 	PaginationTotalStandalone,
// 	PaginationListStandalone
// } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
// import Dropzone from 'react-dropzone';
// import Resizer from 'react-image-file-resizer';
// import axios from 'axios';

// import Select from 'react-select';

// koneksi
import { Post, Delete,} from '../function/Koneksi';
import { DataToken } from '../function/Format';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

class Wisata extends Component {
	constructor(props) {
		super(props);
		this.state = {
			data: [],
			data_cek: '',
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			awal: '',
			sebelum: '',
			hal: [],
			setelah: '',
			akhir: '',
			dt: null,
			judul: 'Tambah Banner Desa',
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			token: DataToken(sessionStorage.getItem('access_token'))
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();

		if (this.state.link_gambar === '') {
			this.setState({
				show: true,
				basicType: 'warning',
				basicTitle: 'Data Banner Desa',
				pesanAlert: 'Silahkan lengkapi data!'
			});
		} else {
			this.setState({
				status:{
					...this.state.status,
					btnForm: true,
				}
			})
			this.forceUpdate();
			let linknya = this.state.link_gambar;
			let addData = {
				uuid: document.getElementById('uuid').value,
				link: linknya
			};

			let psn = '';
			let resstat = 204;
			let metode = 'create';
			if (addData.uuid === '') {
				psn = 'Tambah';
				resstat = 201;
				addData.uuid = null;
			} else {
				psn = 'Ubah';
				resstat = 200;
				metode = 'update';
			}

			Post('banner/' + metode, addData.uuid, addData, (res) => {
				this.setState({
					status:{
						...this.state.status,
						btnForm: false,
						form: false,
					}
				})
				this.forceUpdate();
				if (res.status === resstat) {
					this.setState({
						show: true,
						basicType: 'success',
						basicTitle: 'Data Banner Desa',
						pesanAlert: 'Berhasil ' + psn + ' Data'
					});
					this.componentDidMount();
				} else {
					this.setState({
						show: true,
						basicType: 'danger',
						basicTitle: 'Data Banner Desa',
						pesanAlert: 'Gagal ' + psn + ' Data'
					});
					this.componentDidMount();
				}
			});
		}
	};
	// ========================================================================

	// ============================== Ubah Data ===============================
	ubahData = (id) => {
		let gambar = this.state.link_gambar
		this.setState({
			status:{
				...this.state.status,
				btnAksi: true,
			},
			dt:{
				...this.state.dt,
				uuid: this.state.data.uuid,
			},
			judul: 'Ubah Banner Desa',
			link_gambar: gambar,
		})
		this.forceUpdate();

		this.bukaForm();
	};
	// ========================================================================
	// ============================= Hapus Data ===============================
	konfirmHapus = (id) => {
		this.setState({
			alert: (
				<SweetAlert
					showCancel
					confirmBtnText="Hapus"
					confirmBtnBsStyle="danger"
					cancelBtnBsStyle="success"
					type="danger"
					title="Yakin ingin hapus data ?"
					onCancel={this.hideAlert}
					onConfirm={() => this.hapusData(id)}
				>
					Proses ini tidak dapat dibatalkan!
				</SweetAlert>
			)
		});
	};

	hapusData = (id) => {
		this.hideAlert();
		let psn = 'Hapus';
		Delete('banner/delete', id, (res) => {
			if (res === 200) {
				this.setState({
					show: true,
					basicType: 'success',
					basicTitle: 'Data Banner Desa',
					pesanAlert: 'Berhasil ' + psn + ' Data'
				});
			} else {
				this.setState({
					show: true,
					basicType: 'danger',
					basicTitle: 'Data Banner Desa',
					pesanAlert: 'Gagal ' + psn + ' Data'
				});
			}
			this.componentDidMount();
		});
	};
	// ========================================================================

	bukaForm = () => {
		this.setState({
			status:{
				...this.state.status,
				form: true,
			},
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			formDisposisi: false,
			status:{
				...this.state.status,
				form: false,
			},
		})

		this.forceUpdate();
	};

	componentDidMount() {
		// Get("banner", null, dtkat => {
		// 	if (dtkat.results === null || dtkat.results === 'undefined') {
		// 		this.setState({
		// 			data_cek: 'Kosong'
		// 		});
		// 	}
		// 	else {
		// 		this.setState({
		// 			data: dtkat.results,
		// 			link_gambar: dtkat.results.link,
		// 			data_cek: 'Tidak Kosong'
		// 		});
		// 	}
		// });
	}

	render() {
    var dataWisata = this.state.data;
    
    const columns = [
      {
				dataField: 'id',
				text: 'Lokasi Wisata',
				sort: false
			},
      {
				dataField: 'lat',
				text: 'Latitude',
				sort: false
      },
      {
				dataField: 'long',
				text: 'Longitude',
				sort: false
      },
      {
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				// formatter: this.tombolAksi
			}
    ]

		return (
			<div>
				{/* <Breadcrumb title="Data Banner Desa" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										{/* <div className="col-sm-6" /> */}
                    <div className="col-sm-2">
                      <Button
                        size="md"
                        color="success"
                        className="btn-square"
                        onClick={() => {
                          this.setState({
                          	judul: 'Tambah Lokasi Wisata',
                          	// dt: frmDef,
                          	// link_gambar: ''
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data Wisata
                      </Button>
                    </div>
										{this.state.data_cek === 'Kosong' ? (
											<div className="col-sm-6 text-right">
												<Button
													size="sm"
													color="success"
													className="btn-square"
													onClick={() => {
														// this.setState({
														// 	judul: 'Tambah Banner Desa',
														// 	dt: frmDef,
														// 	link_gambar: ''
														// });
														this.bukaForm();
													}}
												>
													Tambah Data
												</Button>
											</div>
										) : (
											''
										)}
									</div>
								</div>

								<div className="card-body datatable-react">
                  <div className="row">
                    <div className="col-md-12">
                      <BootstrapTable
                        keyField="id"
                        data={dataWisata}
                        columns={columns}
                      />
                    </div>
                  </div>
									{this.state.data_cek === 'Kosong' ? (
										<div>
											<h3 align="center">Data Banner Belum Ada</h3>
											<br />
											<br />
										</div>
									) : (
										<div>
											<img src={this.state.link_gambar} alt="" style={{ width: '100%' }} />
											<br />
											<div align="center">
												<Button
													size="md"
													color="success"
													className="btn-icon"
													onClick={() => this.ubahData(this.state.data.uuid)}
												>
													<i className="fa fa-pencil" />
													&nbsp; Ubah
												</Button>{' '}
												&nbsp;&nbsp;
												<Button
													size="md"
													color="danger"
													className="btn-icon"
													onClick={() => this.konfirmHapus(this.state.data.uuid)}
												>
													<i className="fa fa-trash" />
													&nbsp; Hapus
												</Button>
											</div>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: '80%' } }}
				>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							{/* <input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="UUID"
								defaultValue={this.state.dt.uuid}
								value={this.state.dt.uuid}
							/> */}

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Pilih Lokasi Wisata
								</Label>
								<Col sm="9" />
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success" disabled={this.state.status.btnForm}>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default Wisata;
