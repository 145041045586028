import React, { Component, } from "react";

// import Custom Componenets
// import Breadcrumb from "../../../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import Datetime from 'react-datetime';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";

import SweetAlert from "react-bootstrap-sweetalert";
// import Select from "react-select";
// import Datepicker from "react-datepicker";

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, } from "../../../function/Koneksi";
// import { Tanggal, Hari, HariTanggal, HariTanggalJam } from "../../../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import ReactDatePicker from "react-datepicker";
// import { Link } from "react-router-dom";

//json file
// var data = require('../assets/json/company');

const frmDef = {
  id: 0,
  idbaru: "",
  id_keldes: "",
  nama: ""
};


class DisdukcapilKartuKeluarga extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Data table
      data: [],
      dataVerifikasi: [],
      dataDisetujui: [],
      dataDitolak: [],
      dataSelesai: [],

      data_detail: [],

      // Pagination data table
      pagination: [],
      paginationDitolak: [],
      paginationDiverifikasi: [],
      paginationDisetujui: [],
      paginationSelesai: [],

      status: {
        form: false,
        formTambahVerifikasi: false,
        formKeterangan: false,
        formDetailGambar: false,
        formDetailKeluarga: false,
        btnForm: false,
        btnAksi: false,
        btnAksiTidakGelar: false,
        select: false,
      },

      // Diajukan
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      // Diverifikasi
      awal2: "",
      sebelum2: "",
      hal2: [],
      setelah2: "",
      akhir2: "",
      // Disetujui
      awal3: "",
      sebelum3: "",
      hal3: [],
      setelah3: "",
      akhir3: "",
      // Ditolak
      awal4: "",
      sebelum4: "",
      hal4: [],
      setelah4: "",
      akhir4: "",
      // Selesai
      awal5: "",
      sebelum5: "",
      hal5: [],
      setelah5: "",
      akhir5: "",

      dt: frmDef,
      judul: "",
      // =========== Select 2 ============
      frmPrv: [],
      prvStat: [],
      frmKbkt: [],
      kbktStat: [],
      frmKec: [],
      kecStat: [],
      frmKlds: [],
      kldsStat: [],
      frmAgm: [],
      // =================================
      // =========== Alert ============
      alert: null,
      show: false,
      showCetakSurat: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      // ==============================
      active_tab_icon: "1",

      // Cari Data Gelar
      dataCariGelar: {
				nama: ''
			},

      // Detail Layanan
      detailLayanan: [],
      // Detail Keluarga
      detail_keluarga: [],

      cek_posisi:'Pertama'
    };
  }

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      showCetakSurat: false,
      alert: null
    });
  };

  // Bootstrap tabs function
  toggle_icon(tab) {
    if (this.state.active_tab_icon !== tab) {
      if(tab === '1'){
        this.state.cek_posisi = 'Pertama'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '2'){
        this.state.cek_posisi = 'Kedua'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '3'){
        this.state.cek_posisi = 'Ketiga'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '4'){
        this.state.cek_posisi = 'Keempat'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      else if(tab === '5'){
        this.state.cek_posisi = 'Kelima'
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      
      this.setState({
        active_tab_icon: tab,
      });
    }
    
    this.forceUpdate();
    this.componentDidMount();
  }

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" +
                Math.ceil(
                  this.state.pagination.total / this.state.pagination.per_page
                );
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDiverifikasi.total /
                  this.state.paginationDiverifikasi.per_page
                );
              break;

            case "Disetujui":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDisetujui.total /
                  this.state.paginationDisetujui.per_page
                );
              break;

            case "Ditolak":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationDitolak.total /
                  this.state.paginationDitolak.per_page
                );
              break;
            case "Selesai":
              link =
                "&page=" +
                Math.ceil(
                  this.state.paginationSelesai.total /
                  this.state.paginationSelesai.per_page
                );
              break;
            default:
              // no default
              break;
          }
          break;
        case ">":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" + (parseInt(this.state.pagination.current_page) + 1);
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                (parseInt(this.state.paginationDiverifikasi.current_page) +
                  1);
              break;

            case "Disetujui":
              link =
                "&page=" +
                (parseInt(this.state.paginationDisetujui.current_page) + 1);
              break;

            case "Ditolak":
              link =
                "&page=" +
                (parseInt(this.state.paginationDitolak.current_page) + 1);
              break;
            case "Selesai":
              link =
                "&page=" +
                (parseInt(this.state.paginationSelesai.current_page) + 1);
              break;
            default:
              // no default
              break;

          }
          break;
        case "<":
          switch (params.tab) {
            case "Diajukan":
              link =
                "&page=" + (parseInt(this.state.pagination.current_page) - 1);
              break;

            case "Diverifikasi":
              link =
                "&page=" +
                (parseInt(this.state.paginationDiverifikasi.current_page) -
                  1);
              break;

            case "Disetujui":
              link =
                "&page=" +
                (parseInt(this.state.paginationDisetujui.current_page) - 1);
              break;

            case "Ditolak":
              link =
                "&page=" +
                (parseInt(this.state.paginationDitolak.current_page) - 1);
              break;
            case "Selesai":
              link =
                "&page=" +
                (parseInt(this.state.paginationSelesai.current_page) - 1);
              break;
            default:
              // no default
              break;
          }
          break;
        case "<<":
          link = "&page=1";
          break;
        default:
          link = "&page=" + params.page;
          break;
      }
    }

    // Get data from db
    if(this.state.cek_posisi === "Pertama"){
      params.tab = "Diajukan"
    }
    else if(this.state.cek_posisi === "Kedua"){
      params.tab = "Diverifikasi"
    }
    else if(this.state.cek_posisi === "Ketiga"){
      params.tab = "Disetujui"
    }
    else if(this.state.cek_posisi === "Keempat"){
      params.tab = "Ditolak"
    }
    else if(this.state.cek_posisi === "Kelima"){
      params.tab = "Selesai"
    }

    Get("pelayanan-external?jenis_layanan=Penerbitan Kartu Keluarga&status=" + params.tab + link, null, (dtkat) => {
      //console.log("COba tampil bos")
      //console.log(dtkat)
      switch (params.tab) {
        case "Diajukan":
          this.setState({
            data: dtkat.results.data,
            pagination: dtkat.results
          });
          break;

        case "Diverifikasi":
          this.setState({
            dataVerifikasi: dtkat.results.data,
            paginationDiverifikasi: dtkat.results
          });
          break;
        
        case "Disetujui":
          this.setState({
            dataDisetujui: dtkat.results.data,
            paginationDisetujui: dtkat.results
          });
          break;
        case "Ditolak":
          this.setState({
            dataDitolak: dtkat.results.data,
            paginationDitolak: dtkat.results
          });
          break;
        case "Selesai":
          this.setState({
            dataSelesai: dtkat.results.data,
            paginationSelesai: dtkat.results
          });
          break;
        default:
          // no default
          break;
      }
      // //console.log(dtkat.results);
      this.pagination(dtkat.results, params.tab);
    });
  };

  // Pagination
  pagination = (data, tab) => {
    // const data = this.state.pagination;
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            key={i}
            onClick={() => this.fetch({ page: i, tab: tab })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n, tab: tab })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }

      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page, tab: tab })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }

    switch (tab) {
      case "Diajukan":
        this.setState({ awal, sebelum, hal, setelah, akhir });
        break;
      case "Diverifikasi":
        this.setState({
          awal2: awal,
          sebelum2: sebelum,
          hal2: hal,
          setelah2: setelah,
          akhir2: akhir
        });
        break;
      case "Disetujui":
        this.setState({
          awal3: awal,
          sebelum3: sebelum,
          hal3: hal,
          setelah3: setelah,
          akhir3: akhir
        });
        break;
      case "Ditolak":
        this.setState({
          awal4: awal,
          sebelum4: sebelum,
          hal4: hal,
          setelah4: setelah,
          akhir4: akhir
        });
        break;
      case "Selesai":
        this.setState({
          awal5: awal,
          sebelum5: sebelum,
          hal5: hal,
          setelah5: setelah,
          akhir5: akhir
        });
        break;
      default:
        // no default
        break;
    }
  };


  // Tambah Verifikasi
  tambahDataVerifikasi = (data) => {
    this.state.status.formTambahVerifikasi = true;
    this.state.judul = "Verifikasi Pendaftaran Layanan";
    
    //console.log("PARSING DATA DETAIL Verifikasi")
    //console.log(data)
    
    // Data Pelapor
    this.state.data_detail.nik = data.nik_pelapor;
    this.state.data_detail.nama = data.nama_pelapor;
    this.state.data_detail.alamat = data.alamat_pelapor;
    this.state.data_detail.no_hp = data.no_hp_pelapor;
    this.state.data_detail.pekerjaan = data.pekerjaan_pelapor;
    // Data Detail Layanan
    this.state.data_detail.uuid= data.uuid;
    this.state.data_detail.tgl_layanan = data.tgl_melapor;
    this.state.data_detail.jenis_layanan = data.jenis_layanan;
    this.state.data_detail.status_layanan = data.data_status_baru.status;
    let kett = ""
    if(data.data_status_baru.status==="Ditolak"){
      kett = data.data_status_baru.keterangan
    }
    this.state.data_detail.keterangan_layanan = kett;

    // Data Pelayanan
    let detailLayanan = null
    detailLayanan = JSON.parse(data.data_pelayanan)
    this.state.data_detail.all_layanan = data.data_pelayanan
    this.state.data_detail.jenis_perubahan = detailLayanan.jenis_perubahan

    let lampiran = JSON.parse(data.dokumen_input)

    if(detailLayanan.jenis_perubahan==="Penambahan Anak"){
      this.state.data_detail.set_jenis = detailLayanan.jenis_perubahan
      // Data Administrasi
      Get('pelayanan-external/perangkat-penduduk',data.nik_pelapor, (data) => {
        //console.log("Data Perangkat Penduduk")
        //console.log(data)
        if(data.results){
          // Data RT
          if(data.results.rt){
            this.state.data_detail.nama_ketua_rt_administrasi = data.results.rt.data_penduduk.nama
          }
          else{
            this.state.data_detail.nama_ketua_rt_administrasi = ""
          }
          // Data RW
          if(data.results.rw){
            this.state.data_detail.nama_ketua_rw_administrasi = data.results.rw.data_penduduk.nama
          }
          else{
            this.state.data_detail.nama_ketua_rw_administrasi = ""
          }
          this.forceUpdate();
        }
        else{
          this.state.data_detail.nama_ketua_rt_administrasi = ""
          this.state.data_detail.nama_ketua_rw_administrasi = ""
          this.forceUpdate();
        }
      });
      
      // Data Administrasi
      this.state.data_detail.get_nama_rt_administrasi = detailLayanan.data_f1_01.nama_ketua_rt
      this.state.data_detail.get_nama_rw_administrasi = detailLayanan.data_f1_01.nama_ketua_rw


      this.state.data_detail.set_jenis = detailLayanan.jenis_perubahan
      // Data Kepala Keluarga
      this.state.data_detail.data_keluarga = detailLayanan.data_f1_01.data_keluarga
      this.state.data_detail.alamat_kepala_keluarga = detailLayanan.data_f1_01.data_kepala_keluarga.alamat_kepala_keluarga
      this.state.data_detail.jumlah_anggota = detailLayanan.data_f1_01.data_kepala_keluarga.jumlah_anggota
      this.state.data_detail.kode_pos_kepala_keluarga = detailLayanan.data_f1_01.data_kepala_keluarga.kode_pos_kepala_keluarga
      this.state.data_detail.nama_kepala_keluarga = detailLayanan.data_f1_01.data_kepala_keluarga.nama_kepala_keluarga
      this.state.data_detail.rt_kepala_keluarga = detailLayanan.data_f1_01.data_kepala_keluarga.rt_kepala_keluarga
      this.state.data_detail.rw_kepala_keluarga = detailLayanan.data_f1_01.data_kepala_keluarga.rw_kepala_keluarga
      this.state.data_detail.telepon_kepala_keluarga = detailLayanan.data_f1_01.data_kepala_keluarga.telepon_kepala_keluarga
      // Data Lampiran
      this.state.data_detail.foto_f1_03=lampiran.foto_f1_03
      this.state.data_detail.foto_kk=lampiran.foto_kk
      this.state.data_detail.surat_pernyataan_anak_kandung=lampiran.surat_pernyataan_anak_kandung
      this.state.data_detail.foto_surat_nikah_orang_tua=lampiran.foto_surat_nikah_orang_tua 
    }
    else if(detailLayanan.jenis_perubahan==="Pecah Kartu Keluarga"){
      this.state.data_detail.set_jenis = detailLayanan.jenis_perubahan
      // Data Administrasi
      Get('pelayanan-external/perangkat-penduduk',data.nik_pelapor, (data) => {
        //console.log("Data Perangkat Penduduk")
        //console.log(data)
        if(data.results){
          // Data RT
          if(data.results.rt){
            this.state.data_detail.nama_ketua_rt_administrasi = data.results.rt.data_penduduk.nama
          }
          else{
            this.state.data_detail.nama_ketua_rt_administrasi = ""
          }
          // Data RW
          if(data.results.rw){
            this.state.data_detail.nama_ketua_rw_administrasi = data.results.rw.data_penduduk.nama
          }
          else{
            this.state.data_detail.nama_ketua_rw_administrasi = ""
          }
          this.forceUpdate();
        }
        else{
          this.state.data_detail.nama_ketua_rt_administrasi = ""
          this.state.data_detail.nama_ketua_rw_administrasi = ""
          this.forceUpdate();
        }
      });
      
      // Data Administrasi
      this.state.data_detail.get_nama_rt_administrasi = detailLayanan.data_f1_01.nama_ketua_rt
      this.state.data_detail.get_nama_rw_administrasi = detailLayanan.data_f1_01.nama_ketua_rw

      // Data Kepala Keluarga
      this.state.data_detail.data_keluarga = detailLayanan.data_f1_01.data_keluarga
      this.state.data_detail.alamat_kepala_keluarga = detailLayanan.data_f1_01.data_kepala_keluarga.alamat_kepala_keluarga
      this.state.data_detail.jumlah_anggota = detailLayanan.data_f1_01.data_kepala_keluarga.jumlah_anggota
      this.state.data_detail.kode_pos_kepala_keluarga = detailLayanan.data_f1_01.data_kepala_keluarga.kode_pos_kepala_keluarga
      this.state.data_detail.nama_kepala_keluarga = detailLayanan.data_f1_01.data_kepala_keluarga.nama_kepala_keluarga
      this.state.data_detail.rt_kepala_keluarga = detailLayanan.data_f1_01.data_kepala_keluarga.rt_kepala_keluarga
      this.state.data_detail.rw_kepala_keluarga = detailLayanan.data_f1_01.data_kepala_keluarga.rw_kepala_keluarga
      this.state.data_detail.telepon_kepala_keluarga = detailLayanan.data_f1_01.data_kepala_keluarga.telepon_kepala_keluarga
      // Data Lampiran
      this.state.data_detail.foto_akta=lampiran.foto_akta
      this.state.data_detail.foto_ijazah_terakhir=lampiran.foto_ijazah_terakhir
      this.state.data_detail.foto_kk_pihak_kedua=lampiran.foto_kk_pihak_kedua
      this.state.data_detail.foto_kk_pihak_pertama=lampiran.foto_kk_pihak_pertama
      this.state.data_detail.foto_surat_lahir_dari_bidan=lampiran.foto_surat_lahir_dari_bidan
      this.state.data_detail.foto_surat_nikah_orang_tua=lampiran.foto_surat_nikah_semua_lembaran
    }
    else if(detailLayanan.jenis_perubahan==="Perubahan Data"){
      this.state.data_detail.set_jenis = detailLayanan.jenis_perubahan
      // Data Administrasi
      Get('pelayanan-external/perangkat-penduduk',data.nik_pelapor, (data) => {
        //console.log("Data Perangkat Penduduk")
        //console.log(data)
        if(data.results){
          // Data RT
          if(data.results.rt){
            this.state.data_detail.nama_ketua_rt_administrasi = data.results.rt.data_penduduk.nama
          }
          else{
            this.state.data_detail.nama_ketua_rt_administrasi = ""
          }
          // Data RW
          if(data.results.rw){
            this.state.data_detail.nama_ketua_rw_administrasi = data.results.rw.data_penduduk.nama
          }
          else{
            this.state.data_detail.nama_ketua_rw_administrasi = ""
          }
          this.forceUpdate();
        }
        else{
          this.state.data_detail.nama_ketua_rt_administrasi = ""
          this.state.data_detail.nama_ketua_rw_administrasi = ""
          this.forceUpdate();
        }
      });

      // Data Administrasi
      this.state.data_detail.get_nama_rt_administrasi = detailLayanan.data_administrasi.nama_ketua_rt
      this.state.data_detail.get_nama_rw_administrasi = detailLayanan.data_administrasi.nama_ketua_rw
      
      // Data Individu
      this.state.data_detail.agama_individu = detailLayanan.data_individu.agama
      this.state.data_detail.akta_kelahiran_individu = detailLayanan.data_individu.akta_kelahiran
      this.state.data_detail.akta_perceraian_individu = detailLayanan.data_individu.akta_perceraian
      this.state.data_detail.akta_perkawinan_individu = detailLayanan.data_individu.akta_perkawinan
      this.state.data_detail.golongan_darah_individu = detailLayanan.data_individu.golongan_darah
      this.state.data_detail.jenis_kelamin_individu = detailLayanan.data_individu.jenis_kelamin
      this.state.data_detail.jenis_pekerjaan_individu = detailLayanan.data_individu.jenis_pekerjaan
      this.state.data_detail.kelainan_fisik_dan_mental_individu = detailLayanan.data_individu.kelainan_fisik_dan_mental
      this.state.data_detail.nama_lengkap_individu = detailLayanan.data_individu.nama_lengkap
      this.state.data_detail.no_ktp_individu = detailLayanan.data_individu.no_ktp
      this.state.data_detail.nomor_akta_kelahiran_individu = detailLayanan.data_individu.nomor_akta_kelahiran
      this.state.data_detail.nomor_akta_perceraian_individu = detailLayanan.data_individu.nomor_akta_perceraian
      this.state.data_detail.nomor_akta_perkawinan_individu = detailLayanan.data_individu.nomor_akta_perkawinan
      this.state.data_detail.nomor_paspor_individu = detailLayanan.data_individu.nomor_paspor
      this.state.data_detail.pendidikan_terakhir_individu = detailLayanan.data_individu.pendidikan_terakhir
      this.state.data_detail.penyandang_cacat_individu = detailLayanan.data_individu.penyandang_cacat
      this.state.data_detail.shdk_individu = detailLayanan.data_individu.shdk
      this.state.data_detail.status_perkawinan_individu = detailLayanan.data_individu.status_perkawinan
      this.state.data_detail.tanggal_berakhir_paspor_individu = detailLayanan.data_individu.tanggal_berakhir_paspor
      this.state.data_detail.tanggal_lahir_individu = detailLayanan.data_individu.tanggal_lahir
      this.state.data_detail.tanggal_perceraian_individu = detailLayanan.data_individu.tanggal_perceraian
      this.state.data_detail.tanggal_perkawinan_individu = detailLayanan.data_individu.tanggal_perkawinan
      this.state.data_detail.tempat_lahir_individu = detailLayanan.data_individu.tempat_lahir
      this.state.data_detail.umur_individu = detailLayanan.data_individu.umur
      this.state.data_detail.gelar_akademis_individu = detailLayanan.data_individu.gelar.akademis
      this.state.data_detail.gelar_keagamaan_individu = detailLayanan.data_individu.gelar.keagamaan
      this.state.data_detail.gelar_kebangsawanan_individu = detailLayanan.data_individu.gelar.kebangsawanan
      this.state.data_detail.alamat_sebelumnya_individu = detailLayanan.data_individu.alamat_sebelumnya.alamat
      this.state.data_detail.kode_pos_sebelumnya_individu = detailLayanan.data_individu.alamat_sebelumnya.kode_pos
      this.state.data_detail.rt_sebelumnya_individu = detailLayanan.data_individu.alamat_sebelumnya.rt
      this.state.data_detail.rw_sebelumnya_individu = detailLayanan.data_individu.alamat_sebelumnya.rw
      this.state.data_detail.telepon_sebelumnya_individu = detailLayanan.data_individu.alamat_sebelumnya.telepon
      // Data Keluarga
      this.state.data_detail.alamat_data_keluarga = detailLayanan.data_keluarga.alamat_keluarga
      this.state.data_detail.kode_pos_data_keluarga = detailLayanan.data_keluarga.kode_pos
      this.state.data_detail.nama_kepala_keluarga_data_keluarga = detailLayanan.data_keluarga.nama_kepala_keluarga
      this.state.data_detail.no_kk_data_keluarga = detailLayanan.data_keluarga.no_kk
      this.state.data_detail.rt_data_keluarga = detailLayanan.data_keluarga.rt
      this.state.data_detail.rw_data_keluarga = detailLayanan.data_keluarga.rw
      this.state.data_detail.telepon_data_keluarga = detailLayanan.data_keluarga.telepon
      // Data Orang Tua
      this.state.data_detail.nama_ayah_data_ortu = detailLayanan.data_orang_tua.nama_ayah
      this.state.data_detail.nama_ibu_data_ortu = detailLayanan.data_orang_tua.nama_ibu
      this.state.data_detail.nik_ayah_data_ortu = detailLayanan.data_orang_tua.nik_ayah
      this.state.data_detail.nik_ibu_data_ortu = detailLayanan.data_orang_tua.nik_ibu
      
      // Data Lampiran
      this.state.data_detail.foto_akta=lampiran.foto_akta
      this.state.data_detail.foto_blanko_f1_05=lampiran.foto_blanko_f1_05
      this.state.data_detail.foto_ijazah=lampiran.foto_ijazah
      this.state.data_detail.foto_kk_1=lampiran.foto_kk_1
      this.state.data_detail.foto_surat_nikah_orang_tua=lampiran.foto_surat_nikah_semua_lembaran
    }

    //console.log("Lampiran")
    //console.log(lampiran)
    //console.log("Detail Pelayanan")
    //console.log(detailLayanan)

    this.forceUpdate();
    
  }

  // Simpan Verifikasi
  simpanVerifikasi = (e) => {
		e.preventDefault();
      this.forceUpdate();
   
      if(this.state.data_detail.jenis_perubahan === "Perubahan Data"){
        let id = this.state.data_detail.uuid;
        let data_layanan = JSON.parse(this.state.data_detail.all_layanan)

        data_layanan.data_administrasi.nama_ketua_rt = document.getElementById("nama_rt").value
        data_layanan.data_administrasi.nama_ketua_rw = document.getElementById("nama_rw").value 

        let addData = {
          status: "Diverifikasi",
          keterangan: "-",
          data_pelayanan: JSON.stringify(data_layanan)
        };

        //console.log("Simpan Data")
        //console.log(addData)

        let psn = "Verifikasi";
        let resstat = 200;
            
        Post('pelayanan-external/verifikasi/'+ id, null , addData, (res) => {
            this.forceUpdate();
            //console.log(res.status);
            if (res.status === resstat) {
                this.setState({ show: true, basicType: 'success', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Berhasil ' + psn + ' Data' });
                this.state.status.formTambahVerifikasi = false;
                this.forceUpdate()
            }
            else {
                this.setState({ show: true, basicType: 'danger', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Gagal ' + psn + ' Data' });
            }
            this.componentDidMount();
        });
      }
      else if(this.state.data_detail.jenis_perubahan === "Pecah Kartu Keluarga"){
        let id = this.state.data_detail.uuid;
        let data_layanan = JSON.parse(this.state.data_detail.all_layanan)

        data_layanan.data_f1_01.nama_ketua_rt = document.getElementById("nama_rt").value
        data_layanan.data_f1_01.nama_ketua_rw = document.getElementById("nama_rw").value 

        let addData = {
          status: "Diverifikasi",
          keterangan: "-",
          data_pelayanan: JSON.stringify(data_layanan)
        };

        //console.log("Simpan Data")
        //console.log(addData)

        let psn = "Verifikasi";
        let resstat = 200;
            
        Post('pelayanan-external/verifikasi/'+ id, null , addData, (res) => {
            this.forceUpdate();
            //console.log(res.status);
            if (res.status === resstat) {
                this.setState({ show: true, basicType: 'success', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Berhasil ' + psn + ' Data' });
                this.state.status.formTambahVerifikasi = false;
                this.forceUpdate()
            }
            else {
                this.setState({ show: true, basicType: 'danger', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Gagal ' + psn + ' Data' });
            }
            this.componentDidMount();
        });
      }
      else if(this.state.data_detail.jenis_perubahan === "Penambahan Anak"){
        // let id = this.state.data_detail.uuid;
        // let addData = {
        //     status: "Diverifikasi",
        //     keterangan: "-",
        // };
        let id = this.state.data_detail.uuid;
        let data_layanan = JSON.parse(this.state.data_detail.all_layanan)

        data_layanan.data_f1_01.nama_ketua_rt = document.getElementById("nama_rt").value
        data_layanan.data_f1_01.nama_ketua_rw = document.getElementById("nama_rw").value 

        let addData = {
          status: "Diverifikasi",
          keterangan: "-",
          data_pelayanan: JSON.stringify(data_layanan)
        };

        //console.log("Simpan Data")
        //console.log(addData)
            let psn = "Verifikasi";
            let resstat = 200;
            
        Post('pelayanan-external/verifikasi/'+ id, null , addData, (res) => {
            this.forceUpdate();
            //console.log(res.status);
            if (res.status === resstat) {
                this.setState({ show: true, basicType: 'success', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Berhasil ' + psn + ' Data' });
                this.state.status.formTambahVerifikasi = false;
                this.forceUpdate()
            }
            else {
                this.setState({ show: true, basicType: 'danger', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Gagal ' + psn + ' Data' });
            }
            this.componentDidMount();
        });
      }
	};
 
  // ========================================================================

  // ====================== Hapus Data =============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("kesukuan/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Suku",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Suku",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ====================== Hapus Data =============================

  bukaForm = jenis => {
    switch (jenis) {
      case "catatan":
        this.state.status.form = true;
        break;

      case "update":
        this.state.status.formUpdateSurat = true;
        break;

      default:
        break;
    }
    this.forceUpdate();
  };

  tutupForm = () => {
    this.state.status.formTambahVerifikasi = false;
    this.state.status.form = false;
    this.state.data_detail=[]

    this.state.status.accStat = false;
    this.state.status.tableAcc = "none";

    this.forceUpdate();
  };


  tombolAksiVerifikasi = (cell, row) => {
    return (
      <>
      {this.state.cek_posisi==="Pertama"
        ?
        <div>
            <button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-check"></i> Verifikasi</button>
        </div>
        :this.state.cek_posisi==="Kedua"
        ?
        <div>
            <button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i> Detail</button>
        </div>
         :this.state.cek_posisi==="Ketiga"
         ?
         <div>
             <button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i> Detail</button>
         </div>
         :this.state.cek_posisi==="Keempat"
         ?
         <div>
             <button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i>  Detail</button>
         </div>
         :this.state.cek_posisi==="Kelima"
         ?
         <div>
             <button onClick={() => this.tambahDataVerifikasi(row)} color="info" className="btn  btn-secondary-custom"><i className="fa fa-eye"></i>  Detail</button> {'  '}
             <button onClick={() => this.downloadDokumen(row)} color="info" className="btn  btn-info"><i className="fa fa-download"></i>  Download</button>
         </div>
         :
         <>
         </>
      }
     
    </>
    )
}

  componentDidMount() {
    if (typeof this.props.location.tabProps !== "undefined") {
      this.state.active_tab_icon = this.props.location.tabProps;
    }
    if (typeof this.props.location.state !== "undefined") {
      this.state.active_tab_icon = this.props.location.state.tab;

    }

    let tabnya = ""
    if(this.state.cek_posisi==="Pertama"){
      tabnya="Diajukan"
    }
    else if(this.state.cek_posisi==="Kedua"){
      tabnya="Diverifikasi"
    }
    else if(this.state.cek_posisi==="Ketiga"){
      tabnya="Disetujui"
    }
    else if(this.state.cek_posisi==="Keempat"){
      tabnya="Ditolak"
    }
    else if(this.state.cek_posisi==="Kelima"){
      tabnya="Selesai"
    }
    // Fetching data diajukan
    this.fetch({ page: null, where: [], tab: tabnya });
    // Fetcing data diverifikasi
    // this.fetch({ page: null, where: [], tab: "Diverifikasi" });
    // // Fetcing data Disetujui
    // this.fetch({ page: null, where: [], tab: "Disetujui" });
    // // Fetcing data Ditolak
    // this.fetch({ page: null, where: [], tab: "Ditolak" });
    
    
    this.forceUpdate();
  }


  // Cari Data penduduk gelar
	cariDataGelar = (e) => {
    
    //console.log("Tab Keberapa")
    //console.log(this.state.active_tab_icon)

    if(this.state.active_tab_icon===1){
      this.state.cek_posisi = "Pertama"
    }
    else{
      this.state.cek_posisi = "Non-Gelar"
    }

		if (e.key === 'Enter') {
      //console.log("Search")
			//console.log(e.target.value)
			let dataCariGelar = {
        q: e.target.value
			};
			this.setState({ dataCariGelar });
			setTimeout(
        function() {
          // mywindow.close();
					this.fetch();
				}.bind(this),
				500
        );
      }
      else{
        this.state.dataCariGelar= {
          nama: ''
        }
      }
      this.forceUpdate();
	};


  // ============================== Ubah Data ===============================
    
//   ubahData = (id) => {
//     this.state.status.btnAksi = true;
//     this.state.status.formTambahVerifikasi = true;
//     this.state.judul = "Ubah Suku";
//     this.forceUpdate();
//     Get('kesukuan/find',id, (data) => {
//       //console.log("Data Ubah")
//       //console.log(data)
//       this.state.data_detail.uuid = data.results.uuid;
//       this.state.data_detail.nik = data.results.nik;
//       this.state.data_detail.nama = data.results.data_penduduk.nama;
//       this.state.id_suku= { value: data.results.nama_suku, label: data.results.nama_suku }
//       this.state.id_datuak= { value: data.results.nama_datuak, label: data.results.nama_datuak }
//       this.state.data_detail.nama_gelar = data.results.nama_gelar;

//           this.forceUpdate();
//         this.state.status.btnAksi = false;
//         this.forceUpdate();
//         this.bukaForm();
//     });
// }


  formKeterangan = (e) => {
		e.preventDefault();
			this.state.status.formKeterangan = true;
      this.state.data_detail.keterangan_layanan = "";
			this.forceUpdate();
	};

  detailGambar = (id, judul) => {
		// e.preventDefault();
			this.state.status.formDetailGambar = true;
      this.state.data_detail.show_gambar = id
      this.state.data_detail.judul_gambar = judul
      //console.log("Detail Link Gambar")
      //console.log(id)
			this.forceUpdate();
	};


  tutupKeterangan = (e) => {
		e.preventDefault();
		this.state.status.formKeterangan = false;
    document.getElementById("keterangan_tolak").value = ""
		this.forceUpdate();
	};

  tutupDetailGambar = (e) => {
		e.preventDefault();
		this.state.status.formDetailGambar = false;
		this.forceUpdate();
	};

  tambahKeterangan = (e) => {
		e.preventDefault();
      this.forceUpdate();
      let id = this.state.data_detail.uuid;
      let addData = {
          status: "Ditolak",
          keterangan: document.getElementById("keterangan_tolak").value,
      };

          let psn = "Tolak";
          let resstat = 200;

          //console.log("Data Tambah Keterangan")
          //console.log(id)
          //console.log(addData)
          
      Post('pelayanan-external/verifikasi/'+ id, null , addData, (res) => {
          this.forceUpdate();
          //console.log(res.status);
          if (res.status === resstat) {
              this.setState({ show: true, basicType: 'success', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Berhasil ' + psn + ' Verifikasi' });
		          this.state.status.formKeterangan = false;
              this.state.status.formTambahVerifikasi = false;
              document.getElementById("keterangan_tolak").value = ""
              this.forceUpdate()
          }
          else {
              this.setState({ show: true, basicType: 'danger', basicTitle: 'Verifikasi Pendaftaran Layanan', pesanAlert: 'Gagal ' + psn + ' Verifikasi' });
          }
          this.componentDidMount();
      });
	};

  detailKeluarga = (data) => {
		// e.preventDefault();
			this.state.status.formDetailKeluarga = true;
      this.state.detail_keluarga.judul = "Detail Data Keluarga"
      this.state.detail_keluarga.agama = data.agama
      this.state.detail_keluarga.akta_cerai = data.akta_cerai
      this.state.detail_keluarga.akte_lahir = data.akte_lahir
      this.state.detail_keluarga.akte_perkawinan = data.akte_perkawinan
      this.state.detail_keluarga.alamat_sebelum = data.alamat_sebelum
      this.state.detail_keluarga.gelar = data.gelar
      this.state.detail_keluarga.golongan_darah = data.golongan_darah
      this.state.detail_keluarga.jenis_kelamin = data.jenis_kelamin
      this.state.detail_keluarga.kelainan_fisik = data.kelainan_fisik
      this.state.detail_keluarga.kepercayaan_kpd_tuhan = data.kepercayaan_kpd_tuhan
      this.state.detail_keluarga.nama = data.nama
      this.state.detail_keluarga.nama_ayah = data.nama_ayah
      this.state.detail_keluarga.nama_ibu = data.nama_ibu
      this.state.detail_keluarga.nama_ketua_rt = data.nama_ketua_rt
      this.state.detail_keluarga.nama_ketua_rw = data.nama_ketua_rw
      this.state.detail_keluarga.nik_ayah = data.nik_ayah
      this.state.detail_keluarga.nik_ibu = data.nik_ibu
      this.state.detail_keluarga.no_akta_cerai = data.no_akta_cerai
      this.state.detail_keluarga.no_akta_kelahiran = data.no_akta_kelahiran
      this.state.detail_keluarga.no_akta_perkawinan = data.no_akta_perkawinan
      this.state.detail_keluarga.no_ktp = data.no_ktp
      this.state.detail_keluarga.no_paspor = data.no_paspor
      this.state.detail_keluarga.no_urut = data.no_urut
      this.state.detail_keluarga.pekerjaan = data.pekerjaan
      this.state.detail_keluarga.pendidikan_terakhir = data.pendidikan_terakhir
      this.state.detail_keluarga.penyandang_cacat = data.penyandang_cacat
      this.state.detail_keluarga.status_hub_keluarga = data.status_hub_keluarga
      this.state.detail_keluarga.status_perkawinan = data.status_perkawinan
      this.state.detail_keluarga.tgl_berakhir_paspor = data.tgl_berakhir_paspor
      this.state.detail_keluarga.tgl_cerai = data.tgl_cerai
      this.state.detail_keluarga.tgl_lahir = data.tgl_lahir
      this.state.detail_keluarga.tgl_perkawinan = data.tgl_perkawinan
      this.state.detail_keluarga.tmpt_lahir = data.tmpt_lahir
      this.state.detail_keluarga.umur = data.umur
      //console.log("Detail Keluarganya")
      //console.log(data)
			this.forceUpdate();
	};

  tutupDetailKeluarga = (e) => {
		e.preventDefault();
		this.state.status.formDetailKeluarga = false;
		this.forceUpdate();
	};

  // Download File Kelahiran
  downloadDokumen = (data) => {

    // Data Download 
    let dokumen_download = JSON.parse(data.dokumen_output)
    
      const response = {
        file: dokumen_download.kartu_keluarga,
      };

    // console.log("Data DOwnload")
    // console.log(dokumen_download)
    // console.log(response)
      // server sent the url to the file!
      // now, let's download:
      window.open(response.file);
      // you could also do:
      // window.location.href = response.file;
  

    this.forceUpdate();
    
  }
  
  render() {
    // //console.log("Detail Layanan")
    // //console.log(this.state.detailLayanan[0])
    // var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
    // var size = this.state.pagination.total;
    // //console.log("Tab Keberapa?")
    // //console.log(this.state.active_tab_icon)
    // //console.log(this.state.cek_posisi)

    // //console.log("Datanya")
    // //console.log(this.state.data)
    // //console.log(this.state.dataVerifikasi)
    // //console.log(this.state.dataDisetujui)
    // //console.log(this.state.dataDitolak)

    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;
    var dataVerifikasi = this.state.dataVerifikasi;
    var dataDisetujui = this.state.dataDisetujui;
    var dataDitolak = this.state.dataDitolak;
    var dataSelesai = this.state.dataSelesai;
    var data_keluarga = []
    var detail_data_lampiran_suratnikahortu = []

    // var no = 0;
    // const frmDef = {
    //   id: 0,
    //   idbaru: "",
    //   id_keldes: "",
    //   nama: ""
    // };
    // const { SearchBar } = Search;

    // Data diproses
    const columns = [

      {
        dataField: "tgl_melapor",
        text: "Tanggal Melapor"
        // sort: true
      },

      {
        dataField: "nik_pelapor",
        text: "NIK"
        // sort: true
      },

      {
        dataField: "nama_pelapor",
        text: "Nama"
        // sort: true
      },

      {
				dataField: 'data_pelayanan',
				text: 'Jenis Layanan',
				sort: true,
				formatter: (value) => {
					let dataPelayanan = JSON.parse(value);

					return dataPelayanan.jenis_perubahan;
				}
			},

      {
        dataField: "data_status_baru.status",
        text: "Status"
        // sort: true
      },

      {
        dataField: "aksi",
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksiVerifikasi
      }
    ];


    if(this.state.data_detail.data_keluarga){
      data_keluarga = this.state.data_detail.data_keluarga.map((dt, i) => {
       return (
         <tr>
           <td style={{ fontSize: '12px', border:'3'  }}>{i + 1}</td>
           <td style={{ fontSize: '12px', border:'3'  }}>{dt.no_ktp}</td>
           <td style={{ fontSize: '12px', border:'3'  }}>{dt.nama}</td>
           <td style={{ fontSize: '12px', border:'3'  }}>{dt.pekerjaan}</td>
           <td style={{ fontSize: '12px', border:'3'  }}>{dt.status_hub_keluarga}</td>
           <td>
             <Button size="xs" onClick={() => this.detailKeluarga(dt)} color="info" className="btn-icon btn-info">
               <i className="fa fa-eye" />
             </Button>
           </td>
         </tr>
         );
       });
    }

    if(this.state.data_detail.foto_surat_nikah_orang_tua){
      detail_data_lampiran_suratnikahortu = this.state.data_detail.foto_surat_nikah_orang_tua.map((dt, i) => {
       return (
          <div className="col-md-4">
          <br/>
            <p>Foto Surat Nikah</p>
                <img src={dt} style={{ width: 150, height: 250 }} alt="" />
                <br/><br/>
                <button
                  type="button"
                  className="btn btn-info"
                  onClick={() => this.detailGambar(dt, "Foto Surat Nikah Orang Tua")}
                >
                  Lihat
                </button>
          </div>
         );
       });
    }


    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];

  

    return (
      <div>
        {/* <Breadcrumb title="Disposisi Surat" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Disposisi Surat</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      {/* <Button size="sm" color="success" className="btn-square" onClick={() => {
                        this.setState({ judul: "Tambah Dusun", dt: frmDef, prvStat: [], kbktStat: [], kecStat: [], kldsStat: [] }); this.bukaForm();
                      }}>Tambah Data</Button> */}
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <div className="row">
										<div className="col-sm-4">
											&nbsp;
										</div>
										<div className="col-sm-8 text-right">
											<button onClick={this.props.history.goBack} color="info" className="btn btn-info"><i className="fa fa-arrow-left"></i> Kembali</button>
										</div>
									</div>

                  <Nav tabs className="tabs-color">
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "1"
                        })}
                        onClick={() => {
                          this.toggle_icon("1");
                        }}
                      >
                        <i className="fa fa-hourglass-half"></i>
                        
                        Diajukan
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "2"
                        })}
                        onClick={() => {
                          this.toggle_icon("2");
                        }}
                      >
                        <i className="fa fa-tasks"></i>

                        Diverifikasi
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "3"
                        })}
                        onClick={() => {
                          this.toggle_icon("3");
                        }}
                      >
                        <i className="fa fa-check"></i>

                        Disetujui
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "4"
                        })}
                        onClick={() => {
                          this.toggle_icon("4");
                        }}
                      >
                        <i className="fa fa-times"></i>

                        Ditolak
                      </NavLink>
                    </NavItem>
                    <NavItem style={{ cursor: "pointer" }}>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === "5"
                        })}
                        onClick={() => {
                          this.toggle_icon("5");
                        }}
                      >
                        <i className="fa fa-check"></i>

                        Selesai
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.active_tab_icon}>
                    <TabPane tabId="1">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                          {/* <div className="row">
                            <div className="col-lg-12">
                              <input
                                type="text"
                                className="form-control"
                                id="cariTable"
                                placeholder="Cari Data berdasarkan nama penduduk"
                                onKeyPress={this.cariDataGelar}
                              />
                            </div>
                          </div> */}
                            <BootstrapTable
                              keyField="uuid"
                              data={data}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal}
                              {this.state.sebelum}
                              {this.state.hal.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah}
                              {this.state.akhir}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">
                          <div className="row">
                            <div className="col-lg-12">
                                  <div>
                                    {/* <input
                                      type="text"
                                      className="form-control"
                                      id="cariTable"
                                      placeholder="Cari Data berdasarkan nama penduduk"
                                      onKeyPress={this.cariDataGelar}
                                    /> */}
                                    <BootstrapTable
                                    keyField="uuid_surat"
                                    data={dataVerifikasi}
                                    columns={columns}
                                    />
                                    <div className="pull-right text-white">
                                      {this.state.awal2}
                                      {this.state.sebelum2}
                                      {this.state.hal2.map(dt => {
                                        return dt;
                                      })}
                                      {this.state.setelah2}
                                      {this.state.akhir2}
                                    </div>
                                  </div>
                                </div>
                              </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">

                          {/* <div className="row">
                            <div className="col-lg-12">
                              <input
                                type="text"
                                className="form-control"
                                id="cariTable"
                                placeholder="Cari Data berdasarkan nama penduduk"
                                onKeyPress={this.cariDataGelar}
                              />
                            </div>
                          </div> */}
                              <BootstrapTable
                              keyField="uuid_surat"
                              data={dataDisetujui}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal3}
                              {this.state.sebelum3}
                              {this.state.hal3.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah3}
                              {this.state.akhir3}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="4">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">

                          {/* <div className="row">
                            <div className="col-lg-12">
                              <input
                                type="text"
                                className="form-control"
                                id="cariTable"
                                placeholder="Cari Data berdasarkan nama penduduk"
                                onKeyPress={this.cariDataGelar}
                              />
                            </div>
                          </div> */}
                            <BootstrapTable
                              keyField="uuid_surat"
                              data={dataDitolak}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal4}
                              {this.state.sebelum4}
                              {this.state.hal4.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah4}
                              {this.state.akhir4}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="5">
                      <div style={{ marginTop: 15 }}>
                        <Row>
                          <Col sm="12">

                          {/* <div className="row">
                            <div className="col-lg-12">
                              <input
                                type="text"
                                className="form-control"
                                id="cariTable"
                                placeholder="Cari Data berdasarkan nama penduduk"
                                onKeyPress={this.cariDataGelar}
                              />
                            </div>
                          </div> */}
                            <BootstrapTable
                              keyField="uuid_surat"
                              data={dataSelesai}
                              columns={columns}
                            />
                            <div className="pull-right text-white">
                              {this.state.awal5}
                              {this.state.sebelum5}
                              {this.state.hal5.map(dt => {
                                return dt;
                              })}
                              {this.state.setelah5}
                              {this.state.akhir5}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                  </TabContent>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />


        {/* Modal Tambah Gelar */}
        
        <Modal
          open={this.state.status.formTambahVerifikasi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanVerifikasi}>
            <div className="modal-header">
              <h4 className="modal-title">{this.state.judul}</h4>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.data_detail.uuid}
              />

            {this.state.data_detail.jenis_perubahan === "Penambahan Anak"
              ? 
              <div>
                <div style={{ border: '3px outset #e3641b', borderRadius: '25px', padding:'20px'}}>
                  <h5>
                    <b>Data Kepala Keluarga</b>
                  </h5>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama Kepala Keluarga</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.nama_kepala_keluarga? this.state.data_detail.nama_kepala_keluarga:'-'}
                      </h6>
                    </div>
                  </FormGroup>


                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Jumlah Anggota Keluarga</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.jumlah_anggota? this.state.data_detail.jumlah_anggota:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Alamat</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.alamat_kepala_keluarga? this.state.data_detail.alamat_kepala_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>RT</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.rt_kepala_keluarga? this.state.data_detail.rt_kepala_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>RW</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.rw_kepala_keluarga? this.state.data_detail.rw_kepala_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>Kode Pos</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.kode_pos_kepala_keluarga? this.state.data_detail.kode_pos_kepala_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>No. Telp</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.telepon_kepala_keluarga? this.state.data_detail.telepon_kepala_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  { this.state.data_detail.data_keluarga
                    ?
                    <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                        <b>Data Keluarga</b>
                      </Label>
                        <Col sm="12">
                          <table
                            className="table table-bordered table-stripes table-hovered"
                            style={{ fontSize: '12px', border:'3', width: '100%' }}
                          >
                            <thead>
                              <tr>
                                <th style={{ fontSize: '12px', border:'3'  }}>No</th>
                                <th style={{ fontSize: '12px', border:'3'  }}>NIK</th>
                                <th style={{ fontSize: '12px', border:'3'  }}>Nama</th>
                                <th style={{ fontSize: '12px', border:'3'  }}>Pekerjaan</th>
                                <th style={{ fontSize: '12px', border:'3'  }}>Hubungan Keluarga</th>
                                <th style={{ fontSize: '12px', border:'3'  }}>Detail</th>
                              </tr>
                            </thead>
                            <tbody>{data_keluarga}</tbody>
                          </table>
                        </Col>
                    </FormGroup>
                    :
                      <>
                      </>
                  }
                </div>

                <br/>         
                <div style={{ border: '3px outset #e3c21b', borderRadius: '25px', padding:'20px'}}>
                  <h5>
                    <b>Data Administrasi</b>
                  </h5>
                  {this.state.data_detail.status_layanan==="Diajukan"
                  ?
                  <div>
                    <FormGroup className="row">
                      <Label className="col-sm-4 col-form-label" htmlFor="inputEmail3">Nama RT</Label>
                      <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <input className="form-control" id="nama_rt" type="text" placeholder="Nama RT" defaultValue={this.state.data_detail.nama_ketua_rt_administrasi} required/>
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label className="col-sm-4 col-form-label" htmlFor="inputEmail3">Nama RW</Label>
                        <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <input className="form-control" id="nama_rw" type="text" placeholder="Nama RW" defaultValue={this.state.data_detail.nama_ketua_rw_administrasi} required/>
                        </div>
                    </FormGroup>
                  </div>
                  :
                  <div>
                    <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                        <b>Nama RT</b>
                      </Label>
                      <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                        <h6
                          style={{
                            marginBottom: '0px',
                            fontWeight: 400,
                            fontSize: 'inherit'
                          }}
                        >
                        {this.state.data_detail.get_nama_rt_administrasi? this.state.data_detail.get_nama_rt_administrasi:'-'}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                        <b>Nama Rw</b>
                      </Label>
                      <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                        <h6
                          style={{
                            marginBottom: '0px',
                            fontWeight: 400,
                            fontSize: 'inherit'
                          }}
                        >
                        {this.state.data_detail.get_nama_rw_administrasi? this.state.data_detail.get_nama_rw_administrasi:'-'}
                        </h6>
                      </div>
                    </FormGroup>
                  </div>
                  }
                </div>
                
                <br/>     
                <div style={{ border: '3px outset #b4e31b', borderRadius: '25px', padding:'20px'}}>
                  <h5>
                    <b>Data Pelapor</b>
                  </h5>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>NIK</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.nik? this.state.data_detail.nik:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.nama? this.state.data_detail.nama:'-'}
                    </h6>
                          </div>
                  </FormGroup>
        
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Alamat</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.alamat? this.state.data_detail.alamat:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>No. HP</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.no_hp? this.state.data_detail.no_hp:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Pekerjaan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.pekerjaan? this.state.data_detail.pekerjaan:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                </div>

                <br/>                         
                <div style={{ border: '3px outset #1be31b', borderRadius: '25px', padding:'20px'}}>
                  <h5>
                    <b>Data Layanan</b>
                  </h5>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Layanan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.jenis_layanan? this.state.data_detail.jenis_layanan:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Jenis Layanan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.jenis_perubahan? this.state.data_detail.jenis_perubahan:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Tanggal Melapor</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.tgl_layanan? this.state.data_detail.tgl_layanan:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Status</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.status_layanan? this.state.data_detail.status_layanan:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  
                  {this.state.data_detail.status_layanan === "Ditolak"
                    ?
                    <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                        <b>Keterangan</b>
                      </Label>
                      <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                              <h6
                                style={{
                                  marginBottom: '0px',
                                  fontWeight: 400,
                                  fontSize: 'inherit'
                                }}
                              >  {this.state.data_detail.keterangan_layanan? this.state.data_detail.keterangan_layanan:'-'}
                      </h6>
                            </div>
                    </FormGroup>
                    :
                    <>
                    </>
                    }
                  </div>
                  <hr/>
                  <br/>
               
                <h5 align="center" style={{ fontWeight: 1000 }}>Detail Data Lampiran</h5>
                <hr/>
                <br/>
                <div className="col-md-12" align="center">
                  <div className="row">
                    <div className="col-md-4">
                      <br/>
                        <p>Foto KK</p>
                        <br/>
                        {this.state.data_detail.foto_kk
                          ?
                          <div>
                            <img src={this.state.data_detail.foto_kk} style={{ width: 150, height: 250 }} alt="" />
                            <br/><br/>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() => this.detailGambar(this.state.data_detail.foto_kk, "Kartu Keluarga")}
                            >
                              Lihat
                            </button>
                          </div>
                          :
                            <div align="center"><font color="red"><b>Data Tidak Ditemukan</b></font></div>
                        }
                      </div>
                      <div className="col-md-4">
                      <br/>
                        <p>Foto F1.03</p><br/>
                        {this.state.data_detail.foto_f1_03
                          ?
                          <div>
                            <img src={this.state.data_detail.foto_f1_03} style={{ width: 150, height: 250 }} alt="" />
                            <br/><br/>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() => this.detailGambar(this.state.data_detail.foto_f1_03, "KTP Ayah")}
                            >
                              Lihat
                            </button>
                          </div>
                          :
                            <div align="center"><font color="red"><b>Data Tidak Ditemukan</b></font></div>
                        }
                      </div>
                      <div className="col-md-4">
                      <br/>
                        <p>Pernyataan Anak Kandung</p>
                        {this.state.data_detail.surat_pernyataan_anak_kandung
                          ?
                          <div>
                            <img src={this.state.data_detail.surat_pernyataan_anak_kandung} style={{ width: 150, height: 250 }} alt="" />
                            <br/><br/>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() => this.detailGambar(this.state.data_detail.surat_pernyataan_anak_kandung, "Surat Pernyataan Anak Kandung")}
                            >
                              Lihat
                            </button>
                          </div>
                          :
                            <div align="center"><font color="red"><b>Data Tidak Ditemukan</b></font></div>
                        }
                      </div>
                      {detail_data_lampiran_suratnikahortu}
                  </div>
                </div>
              </div>
              : this.state.data_detail.jenis_perubahan === "Pecah Kartu Keluarga"
              ?
              <div>
                <div style={{ border: '3px outset #e3641b', borderRadius: '25px', padding:'20px'}}>
                  <h5>
                    <b>Data Kepala Keluarga</b>
                  </h5>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama Kepala Keluarga</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.nama_kepala_keluarga? this.state.data_detail.nama_kepala_keluarga:'-'}
                      </h6>
                    </div>
                  </FormGroup>


                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Jumlah Anggota Keluarga</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.jumlah_anggota? this.state.data_detail.jumlah_anggota:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Alamat</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.alamat_kepala_keluarga? this.state.data_detail.alamat_kepala_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>RT</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.rt_kepala_keluarga? this.state.data_detail.rt_kepala_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>RW</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.rw_kepala_keluarga? this.state.data_detail.rw_kepala_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>Kode Pos</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.kode_pos_kepala_keluarga? this.state.data_detail.kode_pos_kepala_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>No. Telp</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.telepon_kepala_keluarga? this.state.data_detail.telepon_kepala_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  { this.state.data_detail.data_keluarga
                    ?
                    <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                        <b>Data Keluarga</b>
                      </Label>
                        <Col sm="12">
                          <table
                            className="table table-bordered table-stripes table-hovered"
                            style={{ fontSize: '12px', border:'3', width: '100%' }}
                          >
                            <thead>
                              <tr>
                                <th style={{ fontSize: '12px', border:'3'  }}>No</th>
                                <th style={{ fontSize: '12px', border:'3'  }}>NIK</th>
                                <th style={{ fontSize: '12px', border:'3'  }}>Nama</th>
                                <th style={{ fontSize: '12px', border:'3'  }}>Pekerjaan</th>
                                <th style={{ fontSize: '12px', border:'3'  }}>Hubungan Keluarga</th>
                                <th style={{ fontSize: '12px', border:'3'  }}>Detail</th>
                              </tr>
                            </thead>
                            <tbody>{data_keluarga}</tbody>
                          </table>
                        </Col>
                    </FormGroup>
                    :
                      <>
                      </>
                    }
                </div>

                <br/>         
                <div style={{ border: '3px outset #e3c21b', borderRadius: '25px', padding:'20px'}}>
                  <h5>
                    <b>Data Administrasi</b>
                  </h5>
                  {this.state.data_detail.status_layanan==="Diajukan"
                  ?
                  <div>
                    <FormGroup className="row">
                      <Label className="col-sm-4 col-form-label" htmlFor="inputEmail3">Nama RT</Label>
                      <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <input className="form-control" id="nama_rt" type="text" placeholder="Nama RT" defaultValue={this.state.data_detail.nama_ketua_rt_administrasi} required/>
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label className="col-sm-4 col-form-label" htmlFor="inputEmail3">Nama RW</Label>
                        <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <input className="form-control" id="nama_rw" type="text" placeholder="Nama RW" defaultValue={this.state.data_detail.nama_ketua_rw_administrasi} required/>
                        </div>
                    </FormGroup>
                  </div>
                  :
                  <div>
                    <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                        <b>Nama RT</b>
                      </Label>
                      <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                        <h6
                          style={{
                            marginBottom: '0px',
                            fontWeight: 400,
                            fontSize: 'inherit'
                          }}
                        >
                        {this.state.data_detail.get_nama_rt_administrasi? this.state.data_detail.get_nama_rt_administrasi:'-'}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                        <b>Nama Rw</b>
                      </Label>
                      <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                        <h6
                          style={{
                            marginBottom: '0px',
                            fontWeight: 400,
                            fontSize: 'inherit'
                          }}
                        >
                        {this.state.data_detail.get_nama_rw_administrasi? this.state.data_detail.get_nama_rw_administrasi:'-'}
                        </h6>
                      </div>
                    </FormGroup>
                  </div>
                  }
                </div>

                <br/>        
                <div style={{ border: '3px outset #b4e31b', borderRadius: '25px', padding:'20px'}}>
                  <h5>
                    <b>Data Pelapor</b>
                  </h5>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>NIK</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.nik? this.state.data_detail.nik:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.nama? this.state.data_detail.nama:'-'}
                    </h6>
                          </div>
                  </FormGroup>
        
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Alamat</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.alamat? this.state.data_detail.alamat:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>No. HP</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.no_hp? this.state.data_detail.no_hp:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Pekerjaan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.pekerjaan? this.state.data_detail.pekerjaan:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                </div>
              
                <br/>     
                <div style={{ border: '3px outset #1be31b', borderRadius: '25px', padding:'20px'}}>
                  <h5>
                    <b>Data Layanan</b>
                  </h5>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Layanan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.jenis_layanan? this.state.data_detail.jenis_layanan:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Jenis Layanan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.jenis_perubahan? this.state.data_detail.jenis_perubahan:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Tanggal Melapor</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.tgl_layanan? this.state.data_detail.tgl_layanan:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Status</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.status_layanan? this.state.data_detail.status_layanan:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  
                  {this.state.data_detail.status_layanan === "Ditolak"
                    ?
                    <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                        <b>Keterangan</b>
                      </Label>
                      <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                              <h6
                                style={{
                                  marginBottom: '0px',
                                  fontWeight: 400,
                                  fontSize: 'inherit'
                                }}
                              >  {this.state.data_detail.keterangan_layanan? this.state.data_detail.keterangan_layanan:'-'}
                      </h6>
                            </div>
                    </FormGroup>
                    :
                    <>
                    </>
                  }
                </div>
                  <hr/>
                  <br/>

                <h5 align="center" style={{ fontWeight: 1000 }}>Detail Data Lampiran</h5>
                <hr/>
                <br/>
                <div className="col-md-12" align="center">
                  <div className="row">
                    <div className="col-md-4">
                      <br/>
                        <p>Foto Akta</p>
                        {this.state.data_detail.foto_akta
                          ?
                          <div>
                            <img src={this.state.data_detail.foto_akta} style={{ width: 150, height: 250 }} alt="" />
                            <br/><br/>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() => this.detailGambar(this.state.data_detail.foto_akta, "Foto Akta")}
                            >
                              Lihat
                            </button>
                          </div>
                          :
                            <div align="center"><font color="red"><b>Data Tidak Ditemukan</b></font></div>
                        }
                      </div>
                      <div className="col-md-4">
                      <br/>
                        <p>Foto Ijazah Terakhir</p>
                        {this.state.data_detail.foto_ijazah_terakhir
                          ?
                          <div>
                            <img src={this.state.data_detail.foto_ijazah_terakhir} style={{ width: 150, height: 250 }} alt="" />
                            <br/><br/>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() => this.detailGambar(this.state.data_detail.foto_ijazah_terakhir, "Ijazah Terakhir")}
                            >
                              Lihat
                            </button>
                          </div>
                          :
                            <div align="center"><font color="red"><b>Data Tidak Ditemukan</b></font></div>
                        }
                      </div>
                      
                      <div className="col-md-4">
                      <br/>
                        <p>Foto KK Pihak 1</p>
                        {this.state.data_detail.foto_kk_pihak_pertama
                          ?
                          <div>
                            <img src={this.state.data_detail.foto_kk_pihak_pertama} style={{ width: 150, height: 250 }} alt="" />
                            <br/><br/>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() => this.detailGambar(this.state.data_detail.foto_kk_pihak_pertama, "Foto KK Pihak Pertama")}
                            >
                              Lihat
                            </button>
                          </div>
                          :
                            <div align="center"><font color="red"><b>Data Tidak Ditemukan</b></font></div>
                        }
                      </div>

                      <div className="col-md-4">
                      <br/>
                        <p>Foto KK Pihak 2</p>
                        {this.state.data_detail.foto_kk_pihak_kedua
                          ?
                          <div>
                            <img src={this.state.data_detail.foto_kk_pihak_kedua} style={{ width: 150, height: 250 }} alt="" />
                            <br/><br/>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() => this.detailGambar(this.state.data_detail.foto_kk_pihak_kedua, "Foto KK Pihak Kedua")}
                            >
                              Lihat
                            </button>
                          </div>
                          :
                            <div align="center"><font color="red"><b>Data Tidak Ditemukan</b></font></div>
                        }
                      </div>

                      <div className="col-md-4">
                      <br/>
                        <p>Foto Surat Lahir</p>
                        {this.state.data_detail.foto_surat_lahir_dari_bidan
                          ?
                          <div>
                            <img src={this.state.data_detail.foto_surat_lahir_dari_bidan} style={{ width: 150, height: 250 }} alt="" />
                            <br/><br/>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() => this.detailGambar(this.state.data_detail.foto_surat_lahir_dari_bidan, "Foto Surat Lahir Bidan")}
                            >
                              Lihat
                            </button>
                          </div>
                          :
                            <div align="center"><font color="red"><b>Data Tidak Ditemukan</b></font></div>
                        }
                      </div>
                      {detail_data_lampiran_suratnikahortu}
                  </div>
                </div>
              </div>
              : this.state.data_detail.jenis_perubahan === "Perubahan Data"
              ?
              <div>
                 <div style={{ border: '3px outset #e3641b', borderRadius: '25px', padding:'20px'}}>
                  <h5>
                    <b>Data Keluarga</b>
                  </h5>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama Kepala Keluarga</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.nama_kepala_keluarga_data_keluarga? this.state.data_detail.nama_kepala_keluarga_data_keluarga:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>No. KK</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.no_kk_data_keluarga? this.state.data_detail.no_kk_data_keluarga:'-'}
                      </h6>
                    </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Alamat</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.alamat_data_keluarga? this.state.data_detail.alamat_data_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>RT</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.rt_data_keluarga? this.state.data_detail.rt_data_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>RW</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.rw_data_keluarga? this.state.data_detail.rw_data_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>Kode Pos</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.kode_pos_data_keluarga? this.state.data_detail.kode_pos_data_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>No. Telp</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.telepon_data_keluarga? this.state.data_detail.telepon_data_keluarga:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                </div>

                <br/>        
                <div style={{ border: '3px outset #e3c21b', borderRadius: '25px', padding:'20px'}}>
                  <h5>
                    <b>Data Individu</b>
                  </h5>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama Lengkap</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.nama_lengkap_individu? this.state.data_detail.nama_lengkap_individu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Gelar</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      &nbsp;
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>Gelar Akademis</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.gelar_akademis_individu? this.state.data_detail.gelar_akademis_individu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>Gelar Kebangsawanan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.gelar_kebangsawanan_individu? this.state.data_detail.gelar_kebangsawanan_individu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>Gelar Keagamaan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.gelar_keagamaan_individu? this.state.data_detail.gelar_keagamaan_individu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>No. KTP</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.no_ktp_individu? this.state.data_detail.no_ktp_individu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Alamat Sebelumnya</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.alamat_sebelumnya_individu? this.state.data_detail.alamat_sebelumnya_individu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>RT</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.rt_sebelumnya_individu? this.state.data_detail.rt_sebelumnya_individu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>RW</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.rw_sebelumnya_individu? this.state.data_detail.rw_sebelumnya_individu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>Kode Pos</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.kode_pos_sebelumnya_individu? this.state.data_detail.kode_pos_sebelumnya_individu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label
												className="col-sm-4 col-form-label pb-1"
												style={{ fontWeight: 600, paddingLeft: 35 }}
											>
                      <b>Telepon</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.telepon_sebelumnya_individu? this.state.data_detail.telepon_sebelumnya_individu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>No. Paspor</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.nomor_paspor_individu? this.state.data_detail.nomor_paspor_individu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Tanggal Berakhir Paspor</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.tanggal_berakhir_paspor_individu? this.state.data_detail.tanggal_berakhir_paspor_individu:'-'}
                      </h6>
                    </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Jenis Kelamin</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.jenis_kelamin_individu? this.state.data_detail.jenis_kelamin_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Tempat Lahir</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.tempat_lahir_individu? this.state.data_detail.tempat_lahir_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Tanggal Lahir</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.tanggal_lahir_individu? this.state.data_detail.tanggal_lahir_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Umur</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.umur_individu? this.state.data_detail.umur_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Akta Kelahiran</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.akta_kelahiran_individu? this.state.data_detail.akta_kelahiran_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>No. Akta Kelahiran</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.nomor_akta_kelahiran_individu? this.state.data_detail.nomor_akta_kelahiran_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Golongan Darah</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.golongan_darah_individu? this.state.data_detail.golongan_darah_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Agama/Kepercayaan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.agama_individu? this.state.data_detail.agama_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Status Perkawinan </b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.status_perkawinan_individu? this.state.data_detail.status_perkawinan_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Akta Perkawinan </b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.akta_perkawinan_individu? this.state.data_detail.akta_perkawinan_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>No. Akta Perkawinan </b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.nomor_akta_perkawinan_individu? this.state.data_detail.nomor_akta_perkawinan_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Tanggal Perkawinan </b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.tanggal_perkawinan_individu? this.state.data_detail.tanggal_perkawinan_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Akta Perceraian</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.akta_perceraian_individu? this.state.data_detail.akta_perceraian_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>No. Akta Perceraian</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.nomor_akta_perceraian_individu? this.state.data_detail.nomor_akta_perceraian_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Tanggal Perceraian</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.tanggal_perceraian_individu? this.state.data_detail.tanggal_perceraian_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Status Hubungan Keluarga</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.shdk_individu? this.state.data_detail.shdk_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Kelainan Fisik&Mental</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.kelainan_fisik_dan_mental_individu? this.state.data_detail.kelainan_fisik_dan_mental_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Penyandang Cacat</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.penyandang_cacat_individu? this.state.data_detail.penyandang_cacat_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Pendidikan Terakhir</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.pendidikan_terakhir_individu? this.state.data_detail.pendidikan_terakhir_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Jenis Pekerjaan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.jenis_pekerjaan_individu? this.state.data_detail.jenis_pekerjaan_individu:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                </div>

                <br/>
                          
                <div style={{ border: '3px outset #b4e31b', borderRadius: '25px', padding:'20px'}}>
                  <h5>
                    <b>Data Orang Tua</b>
                  </h5>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>NIK Ibu</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.nik_ibu_data_ortu? this.state.data_detail.nik_ibu_data_ortu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama Ibu</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.nama_ibu_data_ortu? this.state.data_detail.nama_ibu_data_ortu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>NIK Ayah</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.nik_ayah_data_ortu? this.state.data_detail.nik_ayah_data_ortu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama Ayah</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                      <h6
                        style={{
                          marginBottom: '0px',
                          fontWeight: 400,
                          fontSize: 'inherit'
                        }}
                      >
                      {this.state.data_detail.nama_ayah_data_ortu? this.state.data_detail.nama_ayah_data_ortu:'-'}
                      </h6>
                    </div>
                  </FormGroup>
                </div>

                <br/>
                          
                <div style={{ border: '3px outset #1be31b', borderRadius: '25px', padding:'20px'}}>
                  <h5>
                    <b>Data Administrasi</b>
                  </h5>
                  {this.state.data_detail.status_layanan==="Diajukan"
                  ?
                  <div>
                    <FormGroup className="row">
                      <Label className="col-sm-4 col-form-label" htmlFor="inputEmail3">Nama RT</Label>
                      <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                          <input className="form-control" id="nama_rt" type="text" placeholder="Nama RT" defaultValue={this.state.data_detail.nama_ketua_rt_administrasi} required/>
                      </div>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label className="col-sm-4 col-form-label" htmlFor="inputEmail3">Nama RW</Label>
                        <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <input className="form-control" id="nama_rw" type="text" placeholder="Nama RW" defaultValue={this.state.data_detail.nama_ketua_rw_administrasi} required/>
                        </div>
                    </FormGroup>
                  </div>
                  :
                  <div>
                    <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                        <b>Nama RT</b>
                      </Label>
                      <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                        <h6
                          style={{
                            marginBottom: '0px',
                            fontWeight: 400,
                            fontSize: 'inherit'
                          }}
                        >
                        {this.state.data_detail.get_nama_rt_administrasi? this.state.data_detail.get_nama_rt_administrasi:'-'}
                        </h6>
                      </div>
                    </FormGroup>
                    <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                        <b>Nama Rw</b>
                      </Label>
                      <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                        <h6
                          style={{
                            marginBottom: '0px',
                            fontWeight: 400,
                            fontSize: 'inherit'
                          }}
                        >
                        {this.state.data_detail.get_nama_rw_administrasi? this.state.data_detail.get_nama_rw_administrasi:'-'}
                        </h6>
                      </div>
                    </FormGroup>
                  </div>
                  }
                </div>
                  
                <br/>      
                <div style={{ border: '3px outset #1be3e0', borderRadius: '25px', padding:'20px'}}>
                  <h5>
                    <b>Data Pelapor</b>
                  </h5>
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>NIK</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.nik? this.state.data_detail.nik:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Nama</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.nama? this.state.data_detail.nama:'-'}
                    </h6>
                          </div>
                  </FormGroup>
        
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Alamat</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.alamat? this.state.data_detail.alamat:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>No. HP</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.no_hp? this.state.data_detail.no_hp:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Pekerjaan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.pekerjaan? this.state.data_detail.pekerjaan:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                </div>

                <br/>
                          
                <div style={{ border: '3px outset #1b86e3', borderRadius: '25px', padding:'20px'}}>
                  <h5>
                    <b>Data Layanan</b>
                  </h5>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Layanan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.jenis_layanan? this.state.data_detail.jenis_layanan:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Jenis Layanan</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.jenis_perubahan? this.state.data_detail.jenis_perubahan:'-'}
                    </h6>
                          </div>
                  </FormGroup>

                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Tanggal Melapor</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.tgl_layanan? this.state.data_detail.tgl_layanan:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  
                  <FormGroup className="row" style={{ marginBottom: '0px' }}>
                    <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                      <b>Status</b>
                    </Label>
                    <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                            <h6
                              style={{
                                marginBottom: '0px',
                                fontWeight: 400,
                                fontSize: 'inherit'
                              }}
                            > {this.state.data_detail.status_layanan? this.state.data_detail.status_layanan:'-'}
                    </h6>
                          </div>
                  </FormGroup>
                  
                  {this.state.data_detail.status_layanan === "Ditolak"
                    ?
                    <FormGroup className="row" style={{ marginBottom: '0px' }}>
                      <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
                        <b>Keterangan</b>
                      </Label>
                      <div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                              <h6
                                style={{
                                  marginBottom: '0px',
                                  fontWeight: 400,
                                  fontSize: 'inherit'
                                }}
                              >  {this.state.data_detail.keterangan_layanan? this.state.data_detail.keterangan_layanan:'-'}
                      </h6>
                            </div>
                    </FormGroup>
                    :
                    <>
                    </>
                    }
                  </div>
                  <hr/>
                  <br/>

                  
                <h5 align="center" style={{ fontWeight: 1000 }}>Detail Data Lampiran</h5>
                <hr/>
                <br/>
              
                <div className="col-md-12" align="center">
                  <div className="row">
                    <div className="col-md-4">
                      <br/>
                        <p>Foto Akta</p>
                        {this.state.data_detail.foto_akta
                          ?
                          <div>
                            <img src={this.state.data_detail.foto_akta} style={{ width: 150, height: 250 }} alt="" />
                            <br/><br/>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() => this.detailGambar(this.state.data_detail.foto_akta, "Foto Akta")}
                            >
                              Lihat
                            </button>
                          </div>
                          :
                            <div align="center"><font color="red"><b>Data Tidak Ditemukan</b></font></div>
                        }
                      </div>
                      <div className="col-md-4">
                      <br/>
                        <p>Foto F1.05</p>
                        {this.state.data_detail.foto_blanko_f1_05
                          ?
                          <div>
                            <img src={this.state.data_detail.foto_blanko_f1_05} style={{ width: 150, height: 250 }} alt="" />
                            <br/><br/>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() => this.detailGambar(this.state.data_detail.foto_blanko_f1_05, "Foto Blanko F1.05")}
                            >
                              Lihat
                            </button>
                          </div>
                          :
                            <div align="center"><font color="red"><b>Data Tidak Ditemukan</b></font></div>
                        }
                      </div>
                      
                      <div className="col-md-4">
                      <br/>
                        <p>Foto Ijazah</p>
                        {this.state.data_detail.foto_ijazah
                          ?
                          <div>
                            <img src={this.state.data_detail.foto_ijazah} style={{ width: 150, height: 250 }} alt="" />
                            <br/><br/>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() => this.detailGambar(this.state.data_detail.foto_ijazah, "Foto Ijazah")}
                            >
                              Lihat
                            </button>
                          </div>
                          :
                            <div align="center"><font color="red"><b>Data Tidak Ditemukan</b></font></div>
                        }
                      </div>

                      <div className="col-md-4">
                      <br/>
                        <p>Foto KK</p>
                        {this.state.data_detail.foto_kk_1
                          ?
                          <div>
                            <img src={this.state.data_detail.foto_kk_1} style={{ width: 150, height: 250 }} alt="" />
                            <br/><br/>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() => this.detailGambar(this.state.data_detail.foto_kk_1, "Foto Kartu Keluarga")}
                            >
                              Lihat
                            </button>
                          </div>
                          :
                            <div align="center"><font color="red"><b>Data Tidak Ditemukan</b></font></div>
                        }
                      </div>

                      {detail_data_lampiran_suratnikahortu}
                  </div>
                </div>
              </div>
              :
                <>
                </>
            }
            
            </div>
                 
            {this.state.cek_posisi==="Pertama"
              ?
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.formKeterangan}
                >
                  Tolak
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  // disabled={this.state.status.btnFormAcc}
                >
                  Setujui
                </button>
              </div>
              : this.state.cek_posisi==="Kedua"
              ?
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={this.tutupForm}
                >
                  Tutup
                </button>
              </div>
              : this.state.cek_posisi==="Ketiga"
              ?
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={this.tutupForm}
                >
                  Tutup
                </button>
              </div>
              : this.state.cek_posisi==="Keempat"
              ?
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={this.tutupForm}
                >
                  Tutup
                </button>
              </div>
              : this.state.cek_posisi==="Kelima"
              ?
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={this.tutupForm}
                >
                  Tutup
                </button>
              </div>
              :
              <>
              </>
            }
          </Form>
        
        </Modal>

        
        <Modal
					open={this.state.status.formDetailKeluarga}
					onClose={this.tutupDetailKeluarga}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: "100%" } }}
				>
            <div className="modal-header" align="center">
							<h5 className="modal-title">{this.state.detail_keluarga.judul}</h5>
						</div>

            <Form className="theme-form">
					
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>NIK</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.no_ktp? this.state.detail_keluarga.no_ktp:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Nama</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.nama? this.state.detail_keluarga.nama:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Gelar</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.gelar? this.state.detail_keluarga.gelar:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Alamat Sebelumnya</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.alamat_sebelum? this.state.detail_keluarga.alamat_sebelum:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>No. Paspor</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.no_paspor? this.state.detail_keluarga.no_paspor:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Tanggal Berakhir Paspor</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.tgl_berakhir_paspor? this.state.detail_keluarga.tgl_berakhir_paspor:'-'}
                   </h6>
								</div>
							</FormGroup>


              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Jenis Kelamin</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.jenis_kelamin? this.state.detail_keluarga.jenis_kelamin:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Tempat Lahir</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.tmpt_lahir? this.state.detail_keluarga.tmpt_lahir:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Tanggal Lahir</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.tgl_lahir? this.state.detail_keluarga.tgl_lahir:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Umur</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.umur? this.state.detail_keluarga.umur:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Akte Lahir</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.akte_lahir? this.state.detail_keluarga.akte_lahir:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>No. Akte Lahir</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.no_akta_kelahiran? this.state.detail_keluarga.no_akta_kelahiran:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Golongan Darah</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.golongan_darah? this.state.detail_keluarga.golongan_darah:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Agama</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.agama? this.state.detail_keluarga.agama:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Kepercayaan Kepada Tuhan Yang Maha Esa</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.kepercayaan_kpd_tuhan? this.state.detail_keluarga.kepercayaan_kpd_tuhan:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Status Perkawinan</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.status_perkawinan? this.state.detail_keluarga.status_perkawinan:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Akte Perkawinan</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.akte_perkawinan? this.state.detail_keluarga.akte_perkawinan:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>No.Akte Perkawinan</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.no_akta_perkawinan? this.state.detail_keluarga.no_akta_perkawinan:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Tanggal Perkawinan</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.tgl_perkawinan? this.state.detail_keluarga.tgl_perkawinan:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Akte Cerai</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.akta_cerai? this.state.detail_keluarga.akta_cerai:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>No.Akte Cerai</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.no_akta_cerai? this.state.detail_keluarga.no_akta_cerai:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Tanggal Cerai</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.tgl_cerai? this.state.detail_keluarga.tgl_cerai:'-'}
                   </h6>
								</div>
							</FormGroup>


              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Status Hubungan Keluarga</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.status_hub_keluarga? this.state.detail_keluarga.status_hub_keluarga:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Kelainan Fisik&Mental</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.kelainan_fisik? this.state.detail_keluarga.kelainan_fisik:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Penyandang Cacat</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.penyandang_cacat? this.state.detail_keluarga.penyandang_cacat:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Pendidikan Terakhir</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.pendidikan_terakhir? this.state.detail_keluarga.pendidikan_terakhir:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Pekerjaan</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.pekerjaan? this.state.detail_keluarga.pekerjaan:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>NIK Ibu</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.nik_ibu? this.state.detail_keluarga.nik_ibu:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Nama Ibu</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.nama_ibu? this.state.detail_keluarga.nama_ibu:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>NIK Ayah</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.nik_ayah? this.state.detail_keluarga.nik_ayah:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Nama Ayah</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.nama_ayah? this.state.detail_keluarga.nama_ayah:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Nama Ketua RT</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.nama_ketua_rt? this.state.detail_keluarga.nama_ketua_rt:'-'}
                   </h6>
								</div>
							</FormGroup>
              <FormGroup className="row" style={{ marginBottom: '0px' }}>
                <Label className="col-sm-4 col-form-label" style={{ fontWeight: 600 }}>
									<b>Nama Ketua RW</b>
								</Label>
								<div className="col-sm-8" style={{ display: 'flex', alignItems: 'center' }}>
                  <h6
                    style={{
                      marginBottom: '0px',
                      fontWeight: 400,
                      fontSize: 'inherit'
                    }}
                  >
                   {this.state.detail_keluarga.nama_ketua_rw? this.state.detail_keluarga.nama_ketua_rw:'-'}
                   </h6>
								</div>
							</FormGroup>
              
              
						
						<div className="modal-footer" align="center">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupDetailKeluarga}
							>
								Tutup
							</button>
						</div>
            </Form>
				</Modal>
				
        

        <Modal
					open={this.state.status.formDetailGambar}
					onClose={this.tutupDetailGambar}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: "100%" } }}
				>
          <div className="modal-header" align="center">
							<h5 className="modal-title">{this.state.data_detail.judul_gambar}</h5>
						</div>
					<Form className="theme-form">
            <div className="modal-body">	
              <div className="col-md-12" align="center">
                <div className="row">
                  <img src={this.state.data_detail.show_gambar} style={{width: "100%"}}alt=""/>
                </div>
              </div>
            </div>
						
						<div className="modal-footer" align="center">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupDetailGambar}
							>
								Tutup
							</button>
						</div>
					</Form>
				</Modal>
				
        <Modal
					open={this.state.status.formKeterangan}
					onClose={this.tutupKeterangan}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					styles={{ modal: { width: "60%" } }}
				>
					<Form className="theme-form" onSubmit={this.tambahKeterangan}>
						<div className="modal-header">
							<h5 className="modal-title">Keterangan Tolak Verifikasi</h5>
						</div>

            <FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Keterangan
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="keterangan_tolak"
										type="text"
										placeholder="Keterangan Tolak"
										defaultValue={this.state.data_detail.keterangan_layanan}
										required
									/>
								</Col>
							</FormGroup>
						
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								onClick={this.tutupKeterangan}
							>
								Tutup
							</button>
							<button type="submit" className="btn btn-success">
								Kirim
							</button>
						</div>
					</Form>
				</Modal>
				
      </div>
    );
  }
}

export default DisdukcapilKartuKeluarga;