
/* TODO APP*/
export const GET_LIST = "GET_LIST";
export const GET_LIST_SUCCESS = "GET_LIST_SUCCESS";
export const FILTER_WITH_CATEGORY = "FILTER_WITH_CATEGORY";
export const FILTER_WITH_LABEL = "FILTER_WITH_LABEL";
export const FILTER_WITH_STATUS = "FILTER_WITH_STATUS";
export const MARK_ALL_ITEMS = "MARK_ALL_ITEMS";
export const ADD_NEW_ITEM = "ADD_NEW_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const SELECTED_ITEM = "SELECTED_ITEM";



/* CHAT APP*/
export const GET_MEMBERS = "GET_MEMBERS";
export const GET_MEMBERS_SUCCESS = "GET_MEMBERS_SUCCESS";
export const GET_CHATS = "GET_CHATS";
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS";
export const GET_CHATS_ERROR = "GET_CHATS_ERROR";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEARCH_MEMBER = "SEARCH_MEMBER";
export const CHANGE_CHAT = "CHANGE_CHAT";
export const CREATE_CHAT = "CREATE_CHAT";
export const UPDATE_STATUS = "UPDATE_STATUS";

