import React, { useContext } from 'react'
import Modal from 'react-responsive-modal';
import { Form, FormGroup, Label, Col, } from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';

function EditKepemilikanRumah({ detail_surat_context }) {

  const detailSuratProps = useContext(detail_surat_context);

  const dateFormat = require('dateformat');

  return (
    <div>
      <Modal open={detailSuratProps.status.formUpdateSurat} onClose={detailSuratProps.tutupForm} closeOnEsc={false} closeOnOverlayClick={false}>
        <Form className="theme-form" onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}>
          <div className="modal-header">
            <h5 className="modal-title">Detail {detailSuratProps.detailSurat.jenis_surat}</h5>
          </div>
          <div className="modal-body">
            <p>Data identitas akan terisi secara otomatis saat NIK dipilih. Jika ada ketidak sesuaian data, silakan ubah di data master penduduk.</p>
            <input className="form-control" id="uuid" type="hidden" placeholder="" defaultValue={detailSuratProps.detailSurat.uuid} />
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">NIK</Label>
              <Col sm='9'>
                <Select
                  classNamePrefix="select"
                  onChange={((e) => { detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, 'nik') })}
                  defaultValue={detailSuratProps.dataNikStat.nikStat}
                  value={detailSuratProps.dataNikStat.nikStat}
                  name="NIK"
                  options={detailSuratProps.dataNik.frmNik}
                  placeholder="Pilih NIK"
                  onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik')}
                  isClearable
                />
              </Col>
            </FormGroup>
            <div style={{ display: detailSuratProps.status.dataPenduduk }}>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nama Pemohon</Label>
                <Col sm='9'>
                  <input className="form-control" id="nama" type="text" placeholder="Nama Pemohon" value={detailSuratProps.detailSurat.nama_pemohon} readOnly style={{ backgroundColor:"#c9d1d8" }} />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tempat Lahir</Label>
                <Col sm='9'>
                  <input className="form-control" id="tempat_lahir" type="text" placeholder="Tempat Lahir" value={detailSuratProps.detailSurat.tempat_lahir} readOnly style={{ backgroundColor:"#c9d1d8" }} />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Tanggal Lahir</Label>
                <Col sm='9'>
                  <input className="form-control" id="tanggal_lahir" type="text" placeholder="Tanggal Lahir" value={dateFormat(detailSuratProps.detailSurat.tanggal_lahir, 'dd/mm/yyyy')} readOnly style={{ backgroundColor:"#c9d1d8" }} />
                </Col>
              </FormGroup>
            </div>
            <div style={{ display: "none" }}>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">KK</Label>
                <Col sm='9'>
                  <input className="form-control" id="kk" type="text" placeholder="Nomor KK" value={detailSuratProps.detailSurat.kk} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Jenis Kelamin</Label>
                <Col sm='9'>
                  <input className="form-control" id="jenis_kelamin" type="text" placeholder="Jenis Kelamin" value={detailSuratProps.detailSurat.jenis_kelamin} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Pekerjaan</Label>
                <Col sm='9'>
                  <input className="form-control" id="pekerjaan" type="text" placeholder="Pekerjaan" value={detailSuratProps.detailSurat.pekerjaan} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Pendidikan Terakhir</Label>
                <Col sm='9'>
                  <input className="form-control" id="pendidikan_terakhir" type="text" placeholder="Pendidikan Terakhir" value={detailSuratProps.detailSurat.pendidikan_terakhir} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Kewarganegaraan</Label>
                <Col sm='9'>
                  <input className="form-control" id="kewarganegaraan" type="text" placeholder="Pekerjaan" value={detailSuratProps.detailSurat.kewarganegaraan} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Status Perkawinan</Label>
                <Col sm='9'>
                  <input className="form-control" id="status_perkawinan" type="text" placeholder="Status Perkawinan" value={detailSuratProps.detailSurat.status_perkawinan} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Agama</Label>
                <Col sm='9'>
                  <input className="form-control" id="agama" type="text" placeholder="Agama" value={detailSuratProps.detailSurat.agama} readOnly />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Alamat</Label>
                <Col sm='9'>
                  <input className="form-control" id="alamat" type="text" placeholder="Alamat" value={detailSuratProps.detailSurat.alamat} readOnly />
                </Col>
              </FormGroup>
            </div>
            <FormGroup className="row">
              <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Alamat Rumah</Label>
              <Col sm='9'>
                <input className="form-control" id="tujuan_pembuatan" type="text" placeholder="Alamat Rumah" defaultValue={detailSuratProps.detailSurat.tujuan_pembuatan} required />
              </Col>
            </FormGroup>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-warning" onClick={detailSuratProps.tutupForm}>Tutup</button>
            <button type="submit" className="btn btn-success"
            // disabled={detailSuratProps.status.btnForm}
            >Simpan</button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default EditKepemilikanRumah
