import { combineReducers } from 'redux';
import ChatApp from './chat.reducer';
import TodoApp from './todo.reducer';


const reducers = combineReducers({
    TodoApp,
    ChatApp
});

export default reducers;