import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datepicker from "react-datepicker";
import { Link, Redirect } from "react-router-dom";
import Datetime from "react-datetime";

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, Nama_DESA, JENIS_DUSUN } from "../function/Koneksi";
import { Tanggal, HariTanggal } from "../function/Format";
// import { logo } from "./asset/base64img";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

//json file
// var data = require('../assets/json/company');

const frmDef = {
  uuid: "",
  id_jenis: "",
  id_pengaju: "",
  tanggal_surat: new Date(),
  nik: "",
  nama_pemohon: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  agama: "",
  jenis_kelamin: "",
  status_perkawinan: "",
  alamat: "",
  kewarganegaraan: "Indonesia",
  tujuan_pembuatan: "",
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: ""
};
const cetak = {
  nama_desa: "",
  tanggal_surat: "",
  nomor_surat: "",
  nik: "",
  jenis_kelamin: "",
  nama_pemohon: "",
  kepada: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  pekerjaan: "",
  pendidikan_terakhir: "",
  status_perkawinan: "",
  alamat: "",
  kewarganegaraan: "Indonesia",
  tujuan_pembuatan: "",
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: ""
};
class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: true,
        btnAksi: []
      },
      dt: frmDef,
      judul: "Tambah Pernyataan Tidak Menikah",
      // ============ Alert ==============
      frmJam: [],
      jamStat: [],
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,

      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      tanggal_surat_akta: new Date(),
      // ===================================
      // ============ Cetak ==============
      cetak: cetak,
      // ===================================
      // ==============penduduk=========
      pendPrv: [],
      prvPend: [],
      showBio: false,
      //acc
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none",
      //status cerai
      labelAkta: "",
      datDisposisi: [],
      riwayatDisposisi: [],
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      frmStatusPerkawinan: []
    };
  }
  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        this.setState({ tanggal_surat: date });
        break;
      case "tanggal_surat_akta":
        if (typeof date._isValid != 'undefined') {
          this.setState({ tanggal_surat_akta: date });
        }
        break;
      case "tanggal_acara":
        this.setState({ tanggal_acara: date });
        break;
      default:
        // no default
        break;
    }
  };

  // ==============================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      successAlert: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get(
      "surat-keterangan/grup/" + this.props.location.state.uuid + "" + link,
      null,
      dtkat => {
        if(dtkat.results){
          this.setState({ data: dtkat.results.data, pagination: dtkat.results });
          this.pagination(dtkat.results);

          // For testing
          if (this.props.getListSuratTidakNikah) {
            this.props.getListSuratTidakNikah(dtkat.results.data)
          }
        }

      }
    );
  };
 // Pagination
 pagination = data => {
  // const data = this.state.pagination;
  //console.log(data);
  let awal = "";
  let hal = [];
  let sebelum = "";
  let setelah = "";
  let akhir = "";
  if (data.total > 0) {
    let start = 1;
    let end = 5;
    let n = 0;
    let p = 0;
    if (data.current_page <= 3) {
      start = 1;
      end = 5;
      if (data.last_page > data.current_page) {
        n = data.current_page + 1;
      }
    } else {
      p = data.current_page - 1;
      n = data.current_page + 1;
      start = data.current_page - 2;
      end = data.current_page + 2;
    }
    if (end >= data.last_page - 2) {
      p = data.current_page - 1;
      if (start >= 5) {
        start = data.last_page - 4;
      }
      end = data.last_page;
    }

    for (let i = start; i <= end; i++) {
      let warna = "primary-custom";
      if (i === data.current_page) {
        warna = "danger";
      }
      hal.push(
        <a
          href={() => false} onClick={() => this.fetch({ page: i })}
          className={
            "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          }
        >
          {i}
        </a>
      );
    }
    if (p > 0) {
      sebelum = (
        <a
          href={() => false} onClick={() => this.fetch({ page: p })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<"}
        </a>
      );
    }
    if (n > 0) {
      setelah = (
        <a
          href={() => false} onClick={() => this.fetch({ page: n })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">"}
        </a>
      );
    }
    awal = (
      <a
        href={() => false} onClick={() => this.fetch({ page: 1 })}
        className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
      >
        {"<<"}
      </a>
    );
    akhir = (
      <a
        href={() => false} onClick={() => this.fetch({ page: data.last_page })}
        className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
      >
        {">>"}
      </a>
    );
  }
  this.setState({ awal, sebelum, hal, setelah, akhir });
};
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let dataSuket = {
        q: e.target.value
      };
      Post(
        "surat-keterangan/grup/" + this.props.location.state.uuid,
        null,
        dataSuket,
        data => {
          this.setState({
            data: data.data.results.data,
            pagination: data.data.results
          });
        }
      );
    }
  };

  // ========================================================================

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    // if (
    //   document.getElementById("status_perkawinan").value === "Cerai hidup" ||
    //   document.getElementById("status_perkawinan").value === "Cerai mati"
    // ) {
      if (this.state.dt.status_perkawinan === null) {
        this.setState({
          show: true,
          basicType: "info",
          basicTitle: "Data Permohonan Pernyataan Belum Menikah",
          pesanAlert: "Pilih Status Perkawinan terlebih dahulu"
        });
      } else if (this.state.pendStat === null) {
        this.setState({
          show: true,
          basicType: "info",
          basicTitle: "Data Permohonan Pernyataan Belum Menikah",
          pesanAlert: "Pilih NIK terlebih dahulu"
        });
      } else {
        this.setState({
          status:{
            ...this.state.status,
            btnForm: true,
          },
        })
        this.forceUpdate();
  
        let uuid = document.getElementById("uuid").value;
        let addSkck = {
          id_jenis: this.props.location.state.uuid,
          // nik: document.getElementById("nik").value,
          nik_pelapor: this.state.pendStat.value,
          nik: this.state.pendStat.value,
          nama_pemohon: document.getElementById("nama_pemohon").value,
          tempat_lahir: document.getElementById("tempat_lahir").value,
          tanggal_lahir: document.getElementById("tanggal_lahir").value,
          pendidikan_terakhir: document.getElementById("pendidikan_terakhir")
            .value,
          pekerjaan: document.getElementById("pekerjaan").value,
          agama: document.getElementById("agama").value,
          // status_perkawinan: document.getElementById("status_perkawinan").value,
          status_perkawinan: this.state.dt.status_perkawinan.value,
          alamat: document.getElementById("alamat").value,
          jenis_kelamin: document.getElementById("jenis_kelamin").value,

          kewarganegaraan: document.getElementById("kewarganegaraan").value,
          tujuan_pembuatan: "Surat Tidak Pernah Menikah",
          nomor_surat_akta: document.getElementById("nomor_surat_akta").value,
          nama_saksi: document.getElementById("nama_saksi").value,
          hubungan_saksi: document.getElementById("hubungan_saksi").value,
  
          tanggal_surat_akta: this.state.tanggal_surat_akta
        };

        
        //console.log("Data Simpan Tidak Nikah")
        //console.log(addSkck)

        let psn = "";
        let resstat = 204;
        let metode = "create";
        if (uuid === "") {
          psn = "Tambah";
          resstat = 201;
          uuid = null;
        } else {
          psn = "Ubah";
          resstat = 200;
          metode = "update";
        }
        // //console.log(JSON.stringify(addSkck));
        Post(
          "surat-keterangan/grup/" + metode + "/tidak-menikah",
          uuid,
          addSkck,
          res => {
            // For testing
            if (this.props.resSubmit) {
              this.props.resSubmit(res.status)
            }
            this.tutupForm();
            this.setState({
              status:{
                ...this.state.status,
                btnForm: false,
                form: false,
              },
            })
            this.forceUpdate();
            if (res.status === resstat) {
              this.setState({
                showBio: false,
                show: true,
                basicType: "success",
                basicTitle: "Data Permohonan Pernyataan Tidak Menikah",
                pesanAlert: "Berhasil " + psn + " Data",
                successAlert: true
              });
            } else {
              this.setState({
                showBio: false,
                show: true,
                basicType: "danger",
                basicTitle: "Data Permohonan Pernyataan Tidak Menikah",
                pesanAlert: "Gagal " + psn + " Data"
              });
            }
            this.componentDidMount();
          }
        );
      }
    // } else {
    //   this.setState({
    //     // showBio: false,
    //     show: true,
    //     basicType: "warning",
    //     basicTitle: "Data Permohonan Pernyataan Belum Menikah",
    //     pesanAlert: "Status Perkawinan Penduduk Belum Cerai"
    //   });
    // }
  };
  // ========================================================================
  // list disposisi

  listDisposisi = data => {
    //console.log(data);
    Get("alur-disposisi/" + data.id_jenis, null, dtkat => {
      //console.log(dtkat);
      if (dtkat) {
        //console.log(dtkat.results);

        this.setState({
          idSurat: data.uuid,
          datDisposisi: dtkat.results,
          formDisposisi: true,
          riwayatDisposisi: data.data_status_disposisi
        });
      }
    });
  };
  //disposisi
  disposisi = (label, id) => {
    //console.log(label);
    Get("disposisi/tidak-mampu/" + label + "/" + id, null, dtkat => {
      if (dtkat.code === 201) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Disposisi",
          pesanAlert: "Berhasil  Disposisi Data"
        });
        this.componentDidMount();
        this.tutupForm();
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Disposisi",
          pesanAlert: "Disposisi telah dilakukan"
        });
        this.tutupForm();
      }
    });
    //console.log(id);
  };
  // ============================== Ubah Data ===============================

  ubahData = id => {
    // this.state.status.btnAksi = true;
    // this.componentDidMount();
    Get("surat-keterangan/grup/find", id, data => {
      if (typeof data.results != "undefined") {
        let nomor_surat_akta;
        let tanggal_surat_akta;
        let nama_saksi;
        let hubungan_saksi;
        data.results.data_detail_suket.forEach(dt => {
          if (dt.nama_item === "nomor_surat_akta") {
            nomor_surat_akta = dt.isi_item;
          }
          if (dt.nama_item === "tanggal_surat_akta") {
            tanggal_surat_akta = dt.isi_item;
          }
          if (dt.nama_item === "nama_saksi") {
            nama_saksi = dt.isi_item;
          }
          if (dt.nama_item === "hubungan_saksi") {
            hubungan_saksi = dt.isi_item;
          }
        });

        this.setState({
          dt:{
            ...this.state.dt,
            uuid: data.results.uuid,
            nomor: data.results.nomor,
            tujuan_pembuatan: data.results.tujuan_pembuatan,
            nik: data.results.nik,
            nama_pemohon: data.results.nama_pemohon,
            tempat_lahir: data.results.tempat_lahir,
            tanggal_lahir: data.results.tanggal_lahir,
            pekerjaan: data.results.pekerjaan,
            pendidikan_terakhir: data.results.pendidikan_terakhir,
            agama: data.results.agama,
            status_perkawinan: {value: data.results.status_perkawinan, label: data.results.status_perkawinan},
            alamat: data.results.alamat,
            nomor_surat_akta: nomor_surat_akta,
            nama_saksi: nama_saksi,
            hubungan_saksi: hubungan_saksi,
          },
          status:{
            ...this.state.status,
            btnForm: false,
          },
          tanggal_surat: new Date(data.results.tanggal_surat),
          pendStat: { value: data.results.nik, label: data.results.nik + ' (' + data.results.nama_pemohon + ')' },
          tanggal_surat_akta: new Date(tanggal_surat_akta),
          showBio: true,
          judul: "Ubah Permohonan Pernyataan Tidak Menikah",
        })


        // this.state.dt.status_perkawinan = data.results.status_perkawinan;
        this.forceUpdate();
        // this.state.status.btnAksi = false;
        this.forceUpdate();
        // this.componentDidMount();
        this.bukaForm();
      }
    });
  };

  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-keterangan/grup/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Surat Keterangan",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Keterangan",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.setState({
      status:{
        ...this.state.status,
        form: true,
      },
    })

    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      status:{
        ...this.state.status,
        form: false,
        btnForm: true,
      },
      tanggal_surat_akta: new Date(),
      showBio: false,
      pendStat: [],
      formDisposisi: false,
      pendStat2: [],
      formAcc: false,
      tableAcc: "none",
      accStat: [],
    })

    this.forceUpdate();
  };

  persetujuan = (id, jawaban) => {
    this.hideAlert();
    Get(
      "surat-keterangan/grup/persetujuan/" + jawaban + "/" + id,
      null,
      res => {
        if (res.code === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  tombolAksi = (cell, row) => {
    // let btnApproval = false;
    // let btnCetak = false;
    let btnAksi = false;
    let status = "";
    row.data_status.forEach(dt => {
      status = dt.status;
    });
    if (status === "Diajukan") {
      // btnCetak = true;
      btnAksi = true;
      // btnApproval = true;
    } else {
      btnAksi = true;
      // btnApproval = true;
      if (status === "Ditolak") {
        // btnCetak = true;
      } else if (status === "Disetujui") {
        btnAksi = false;
        // btnCetak = true;
        // btnApproval = false;
      } else if ((status === "Menunggu Penerbitan") | (status === "Selesai")) {
        // btnAksi = false;
        // btnCetak = false;
        // btnApproval = false;
      } else {
        // btnCetak = true;
      }
    }
    return (
      <>
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={() => this.konfirmApprove(row.uuid)}
          disabled={btnApproval}
        >
          <i className="fa fa-check"></i>
        </Button>{" "}
        &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        {/* &nbsp;&nbsp;
        <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.cetakSurat(e, row)}
          disabled={btnCetak}
        >
          <i className="fa fa-print"></i>
        </Button>
        &nbsp;&nbsp; */}
        {/* //list */}
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.listDisposisi(row)}
        >
          <i className="fa fa-list"></i>
        </Button> */}
      </>
    );
  };

  changeAcara = e => {
    document.getElementById(e.target.id).value = e.target.value;
  };

  changeStatusPerkawinan = (e) => {
    if (e) {
      this.setState({
        dt:{
          ...this.state.dt,
          status_perkawinan: {value: e.label, label: e.label},
        },
      })
  
      // For testing
      if (this.props.selectOnChangeKawin) {
        this.props.selectOnChangeKawin(e.label)
      }
      this.forceUpdate();
    } else {
      this.setState({
        dt:{
          ...this.state.dt,
          status_perkawinan: null,
        },
      })
      this.forceUpdate();
    }
  }

  //change nik
  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld === "nik") {
        let pendStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmPend = [];
          datanik.data.results.data.forEach(dt => {
            frmPend.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmPend, pendStat });
        });
      } else {
        let nik2Stat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik2 = [];
          datanik.data.results.data.forEach(dt => {
            frmNik2.push({ value: dt.id, label: dt.id });
          });
          this.setState({ frmNik2, nik2Stat });
        });
      }
    }
  };
  // ambil data penduduk
  getPenduduk = e => {
    if (e) {
      Get("penduduk/find/" + e.value, null, data => {
        let alamat = '';
        // if (JENIS_DESA.toLowerCase() === 'kelurahan') {
        //   alamat =
        //   " RT. " +
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes
        //     .data_kecamatan.data_kabkota.nama; 
        // } else {
        //   alamat =
        //   " RT. " + 
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " + JENIS_DUSUN + " " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.data_kabkota.nama; 
        // }
        if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
          alamat =
          " RT. " +
          data.results.data_kk.data_rt.nama +
          "/RW. " +
          data.results.data_kk.data_rt.data_rw.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes.nama +
          ", Kec. " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.data_kabkota.nama; 
        } else {
          if (data.results.data_kk.id_rt === '0') {
            alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
            ", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
          } else {
            alamat =
            " RT. " + 
            data.results.data_kk.data_rt.nama +
            "/RW. " +
            data.results.data_kk.data_rt.data_rw.nama +
            ", " + JENIS_DUSUN + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.nama +
            ", " + localStorage.getItem('JENIS_DESA') + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
            ", Kec. " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.nama +
            ", " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.data_kabkota.nama; 
          }
        }

        // let daerah = data.results.data_kk.data_rt.data_rw.data_dusun.nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
        //       ", " +
        //       data.results.data_kk.results.dataKkbj.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota
        //         .nama +
        //       ", " +
        //       data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota
        //         .data_provinsi.nama

        Get("catatan-penduduk/nik", data.results.id, dpddk => {
          let pctt = 0;
          let catatan = "";
          if (dpddk.results.data) {
            dpddk.results.data.forEach(dtp => {
              if (dtp.status === 0 && dtp.penalti === 1) {
                pctt = 1;
                catatan = dtp.catatan;
              }
            });
          }
          if (pctt === 0) {
            this.setState({
              dt:{
                ...this.state.dt,
                nik: data.results.id,
                nama_pemohon: data.results.nama,
                tempat_lahir: data.results.tempat_lahir,
                tanggal_lahir: data.results.tanggal_lahir,
                pekerjaan: data.results.data_pekerjaan.nama,
                jenis_kelamin: data.results.jk,
                pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
                agama: data.results.data_agama.nama,
              },
            })
            // this.state.dt.status_perkawinan = data.results.status_perkawinan;
            if (data.results.status_perkawinan === "Cerai hidup") {
              this.setState({
                labelAkta: "cerai",
              })
            }
            if (data.results.status_perkawinan === "Cerai mati") {
              this.setState({
                labelAkta: "kematian",
              })
            }
            this.setState({
              dt:{
                ...this.state.dt,
                alamat: alamat,
              },
              status:{
                ...this.state.status,
                btnForm: false,
              },
              showBio: true,
              pendStat: { value: e.value, label: e.label },
            })

            this.forceUpdate();
          } else {
            this.setState({
              dt:{
                ...this.state.dt,
                nik: "",
                nama_pemohon: "",
                tempat_lahir: "",
                tanggal_lahir: "",
                pekerjaan: "",
                pendidikan_terakhir: "",
                agama: "",
                status_perkawinan: null,
                alamat: "",
              },
              status:{
                ...this.state.status,
                btnForm: true,
              },
              showBio: false,
              pendStat: [],
              labelAkta: "",
            })

            // this.state.status.btnAksi = false;
            this.forceUpdate();

            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Peringatan Catatan Warga",
              pesanAlert:
                "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                catatan,
              // pendStat: null,
            });
          }

          // For testing
          if (this.props.selectOnChange) {
            this.props.selectOnChange(this.state.dt)
          }

        });
      });
    } else {
      this.setState({
        dt:{
          ...this.state.dt,
          nik: "",
          nama_pemohon: "",
          tempat_lahir: "",
          tanggal_lahir: "",
          pekerjaan: "",
          pendidikan_terakhir: "",
          agama: "",
          status_perkawinan: null,
          alamat: "",
        },
        status:{
          ...this.state.status,
          btnForm: true,
        },
        showBio: false,
        pendStat: [],
        labelAkta: "",
      })

      // this.state.status.btnAksi = false;
      this.forceUpdate();
    }
  };

  //select status
  selecStatus = e => {
    if (e) {
      this.setState({ cerStat: e });
    } else {
      this.setState({ cerStat: [] });
    }
  };
  ///acc
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  //change select
  changAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      if (e.value === "Disetujui") {
        this.setState({
          tableAcc: "",
          btnFormAcc: true,
        })
        if (this.state.accButton.length > 0) {
          this.setState({
            btnFormAcc: false,
          })
        }
      } else {
        this.setState({
          tableAcc: "none",
          btnFormAcc: false,
        })
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      this.setState({
        tableAcc: "none",
        btnFormAcc: true,
      })
    }
    this.setState({
      status:{
        ...this.state.status,
        select: false,
      }
    })
    this.forceUpdate();
  };
  simpanAcc = e => {
    e.preventDefault();
    this.setState({
      btnFormAcc: true,
    })

    let add = {
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10),
      uuid: this.state.accuuid
    };
    Post(
      "surat-keterangan/grup/persetujuan/Selesai" + this.state.idaccSurat,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.setState({
            formAcc: false,
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            btnFormAcc: false,
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.map(x => 1);
    temp[id] = 0;
    this.setState({
      accButton: temp,
      accuuid: uuid,
    })
    if (this.state.accStat) {
      this.setState({
        btnFormAcc: false,
      })
    }
    this.forceUpdate();
  };
  componentDidMount() {
    if (this.props.location.state === undefined) {
      this.setState({ redirect: true });
    } else {
      this.fetch({ page: null, where: [] });
      Get("tanda-tangan/list", null, data => {
        data.results.forEach((dt, i) => {
          if (dt.aktif === 1) {
            let temp = this.state.accButton.map(x => 1);
            temp[i] = 0;
            this.setState({
              accButton: temp,
              accuuid: dt.uuid,
            })
          }
        });
        this.forceUpdate();
        this.setState({ frmTTD: data.results });
      });
      Get("penduduk", null, data => {
        let frmPend = [];
        data.results.data.forEach(dt => {
          frmPend.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
        });
        this.setState({ frmPend });
         
        // For testing
        if (this.props.getListNIK) {
          this.props.getListNIK(frmPend)
        }
      });
    }
  }

  cetakSurat = (e, data) => {
    e.preventDefault();
    let nama_ayah_kandung;
    let nama_saksi;
    let hubungan_saksi;
    let tanggal_surat_akta;
    let nomor_surat_akta;

    data.data_detail_suket.forEach(dt => {
      if (dt.nama_item === "nama_ayah") {
        nama_ayah_kandung = dt.isi_item;
      }
      if (dt.nama_item === "tanggal_surat_akta") {
        tanggal_surat_akta = dt.isi_item;
      }
      if (dt.nama_item === "nomor_surat_akta") {
        nomor_surat_akta = dt.isi_item;
      }
      if (dt.nama_item === "nama_saksi") {
        nama_saksi = dt.isi_item;
      }
      if (dt.nama_item === "hubungan_saksi") {
        hubungan_saksi = dt.isi_item;
      }
    });
    this.setState({
      cetak: {
        nama_desa: Nama_DESA,
        tanggal_surat: Tanggal(data.tanggal_surat),
        nomor_surat: data.nomor,
        nik: data.nik,
        tanggal_surat_akta: tanggal_surat_akta,
        nomor_surat_akta: nomor_surat_akta,
        nama_pemohon: data.nama_pemohon.toLowerCase(),
        nama_ayah_kandung: nama_ayah_kandung.toLowerCase(),
        tempat_lahir: data.tempat_lahir.toLowerCase(),
        tanggal_lahir: Tanggal(data.tanggal_lahir),
        pekerjaan: data.pekerjaan.toLowerCase(),
        pendidikan_terakhir: data.pendidikan_terakhir.toLowerCase(),
        status_perkawinan: data.status_perkawinan.toLowerCase(),
        agama: data.agama.toLowerCase(),
        alamat: data.alamat.toLowerCase(),
        jenis_kelamin: data.jenis_kelamin.toLowerCase(),
        kewarganegaraan: data.kewarganegaraan.toLowerCase(),
        tujuan_pembuatan: data.tujuan_pembuatan.toLowerCase(),
        status_ttd: data.status_ttd,
        jabatan_ttd: data.jabatan_ttd,
        jabatan_pengganti_ttd: data.jabatan_pengganti_ttd,
        nama_ttd: data.nama_ttd,
        nip_ttd: data.nip_ttd ? "NIP. " + data.nip_ttd : "",
        nama_saksi: nama_saksi.toLowerCase(),
        hubungan_saksi: hubungan_saksi.toLowerCase()
      }
    });
    //console.log(this.state.cetak);
    setTimeout(function() {
      const content = document.getElementById("cetak-surat").innerHTML;
      const mywindow = window.open("", "Print", "height=600,width=1000");

      mywindow.document.write("<html><head><title>Print</title>");
      mywindow.document.write("</head><body >");
      mywindow.document.write(content);
      mywindow.document.write("</body></html>");

      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      setTimeout(function() {
        mywindow.close();
      }, 1000);
    }, 100);
  };

  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };
  render() {
    //console.log("Data Render")
    //console.log("Data Tidak Nikah")
    //console.log("====================")
    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;

    let frmDef = {
      uuid: "",
      id_jenis: "",
      id_pengaju: "",
      tanggal_surat: "",
      nik: "",
      nama_pemohon: "",
      tempat_lahir: "",
      tanggal_lahir: "",
      pekerjaan: "",
      agama: "",
      status_perkawinan: "",
      alamat: "",
      kewarganegaraan: "Indonesia",
      tujuan_pembuatan: "",
      jenis_kelamin: "",
      status_ttd: "",
      jabatan_ttd: "",
      jabatan_pengganti_ttd: "",
      nama_ttd: "",
      nip_ttd: ""
    };
    // const { SearchBar } = Search;
    const columns = [
      {
        dataField: "tanggal_surat",
        headerAlign: 'center',
				align: 'center',
        text: "Tanggal",
        formatter: (cell, obj) => {
          return obj.tanggal_surat === null
            ? "-"
            : new Date(obj.tanggal_surat).toLocaleDateString("id-ID");
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "nomor",
        headerAlign: 'center',
				align: 'center',
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor === "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "nama_pemohon",
        headerAlign: 'center',
				align: 'center',
        text: "Nama Pemohon",
        sort: true
      },
      {
        dataField: "status",
        headerAlign: 'center',
				align: 'center',
        text: "Status",
        formatter: (cell, obj) => {
          let status = "-";
          obj.data_status.forEach(dt => {
            status = dt.status;
          });
          if (status === "Diajukan") {
            this.setState({
              status:{
                ...this.state.status,
                btnAksi: true,
              }
            })
          } else {
            this.setState({
              status:{
                ...this.state.status,
                btnAksi: false,
              }
            })
          }
          this.forceUpdate();
          return status;
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-primary-custom";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href="#" onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };
    let no = 0;
    const tableTTD = this.state.frmTTD.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] === 0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] === 0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });
    return (
      <div data-testid="page-surat pernyataan tidak menikah">
        {this.renderRedirect()}

        {/* <Breadcrumb
          title="Data Surat Pernyataan Tidak Menikah"
          parent="Admin"
        /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  this.bukaForm();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                color="info"
                className="btn btn-secondary-custom"
                onClick={() => {
                  this.closeAlert();
                }}
              >
                <Link
                  data-testid="btn-eksekusi_surat"
                  onClick={() => {
                    // For testing
                    if (this.props.clickEksekusiSurat) {
                      this.props.clickEksekusiSurat()
                    }
                  }}
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat Pernyataan Tidak Menikah</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        data-testid="tblCreateSuratTidakNikah"
                        size="sm"
                        color="success"
                        // className="btn-square"
                        onClick={() => {
                          this.setState({
                            judul: "Tambah Surat Pernyataan Tidak Menikah",
                            dt: frmDef,
                            pendStat: []
                          });
                          this.bukaForm();
                        }}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <div className="row">
                    <div className="col-lg-4">
                      <input
                        type="text"
                        className="form-control"
                        id="cariTable"
                        placeholder="Cari Data"
                        onKeyPress={this.cariData}
                      />
                    </div>
                  </div>
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
        >
          <Form 
            data-testid="form-create" 
            className="theme-form" 
            onSubmit={this.simpan}
          >
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="nik"
                >
                  NIK{" "}
                </Label>
                <Col sm="9">
                  <div data-testid="selectNIK">
                    <Select
                      inputId="nik"
                      isDisabled={this.state.status.select}
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e);
                      }}
                      defaultValue={this.state.pendStat}
                      value={this.state.pendStat}
                      name="penduduk"
                      options={this.state.frmPend}
                      placeholder="Pilih NIK"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                      isClearable
                    />
                  </div>
                </Col>
              </FormGroup>
              <div
                style={{ display: this.state.showBio === false ? "none" : "" }}
              >
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="nik"
                  type="hidden"
                  readOnly
                  placeholder="NIK"
                  value={this.state.dt.nik}
                />
                {/* </Col>
                </FormGroup> */}

                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pemohon
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama_pemohon"
                      readOnly
                      type="text"
                      placeholder="nama_pemohon"
                      value={this.state.dt.nama_pemohon}
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      readOnly
                      id="tempat_lahir"
                      type="text"
                      placeholder="Tempat lahir"
                      value={this.state.dt.tempat_lahir}
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir"
                      readOnly
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir}
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Jenis Kelamin
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="jenis_kelamin"
                  readOnly
                  type="hidden"
                  placeholder="jenis_kelamin"
                  value={this.state.dt.jenis_kelamin}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  readOnly
                  id="pekerjaan"
                  type="hidden"
                  placeholder="Pekerjaan"
                  value={this.state.dt.pekerjaan}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pendidikan Terakhir
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="pendidikan_terakhir"
                  readOnly
                  type="hidden"
                  placeholder="Pendidikan Terakhir"
                  value={this.state.dt.pendidikan_terakhir}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="agama"
                  readOnly
                  type="hidden"
                  placeholder="Agama"
                  value={this.state.dt.agama}
                />
                {/* </Col>
                </FormGroup> */}
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="status_perkawinan"
                  >
                    Status Perkawinan
                  </Label>
                  <Col sm="9">
                    {/* <input
                      className="form-control"
                      id="status_perkawinan"
                      type="text"
                      readOnly
                      placeholder="Status Perkawinan"
                      value={this.state.dt.status_perkawinan}
                      style={{ backgroundColor:"#c9d1d8" }}
                    /> */}
                    <div data-testid="selectKawin">
                      <Select
                        inputId="status_perkawinan"
                        name="status_perkawinan"
                        id="status_perkawinan"
                        classNamePrefix="select"
                        onChange={((e) => { this.changeStatusPerkawinan(e) })}
                        value={this.state.dt.status_perkawinan}
                        options={[
                          {value: 'Cerai Hidup', label: 'Cerai Hidup'},
                          {value: 'Cerai Mati', label: 'Cerai Mati'},
                        ]}
                        placeholder="Pilih Status Perkawinan"
                        isClearable
                      />
                    </div>
                  </Col>
                </FormGroup>
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="alamat"
                  type="hidden"
                  readOnly
                  placeholder="Alamat"
                  value={this.state.dt.alamat}
                />
                {/* </Col>
                </FormGroup> */}
                {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Kewarganegaraan
                  </Label>
                  <Col sm="9"> */}
                <input
                  className="form-control"
                  id="kewarganegaraan"
                  type="hidden"
                  readOnly={true}
                  placeholder="Kewarganegaraan"
                  value={this.state.dt.kewarganegaraan}
                />
                {/* </Col>
                </FormGroup> */}
              </div>

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nomor Surat Akta {this.state.labelAkta}
                </Label>
                <Col sm="9">
                  <input
                    data-testid="nomor surat akta"
                    name="nomor_surat_akta"
                    className="form-control"
                    id="nomor_surat_akta"
                    type="text"
                    placeholder="Nomor Surat Akta "
                    defaultValue={this.state.dt.nomor_surat_akta}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="tanggal_surat_akta"
                >
                  Tanggal Surat Akta {this.state.labelAkta}
                </Label>
                <Col sm="9">
                  {/* <Datepicker
                    id="tanggal_surat_akta"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat_akta}
                    onChange={e => this.gantiTgl(e, "tanggal_surat_akta")}
                    className="form-control"
                  /> */}
                  <Datetime
                    inputProps={{ "data-testid": "tanggal_surat_akta", placeholder: 'Tanggal Surat Akta', required: true }}
                    data-testid="tanggal_surat_akta"
                    // name="rencana_berangkat"
                    inputId="tanggal_surat_akta"
                    name="tanggal_surat_akta"
                    id="tanggal_surat_akta"
                    locale="id-ID"
                    input={true}
                    closeOnTab={true}
                    timeFormat={false}
                    dateFormat="DD/MM/yyyy"
                    closeOnSelect={true}
                    initialValue={this.state.tanggal_surat_akta}
                    onChange={e => {
                      this.gantiTgl(e, "tanggal_surat_akta");
                    }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Saksi
                </Label>
                <Col sm="9">
                  <input
                    data-testid="nama saksi"
                    name="nama_saksi"
                    className="form-control"
                    id="nama_saksi"
                    type="text"
                    placeholder="Nama Saksi"
                    defaultValue={this.state.dt.nama_saksi}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Hubungan Saksi
                </Label>
                <Col sm="9">
                  <input
                    data-testid="hubungan saksi"
                    name="hubungan_saksi"
                    className="form-control"
                    id="hubungan_saksi"
                    type="text"
                    placeholder="Hubungan Saksi"
                    defaultValue={this.state.dt.hubungan_saksi}
                    required
                  />
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.status.btnForm}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                data-testid="btnSubmitCreate"
                type="submit"
                className="btn btn-primary-custom"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  <Datepicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  />
                </Col>
              </FormGroup>

              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary-custom">
                Selesai
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Disposisi Surat</h5>
            </div>
            {/* <div className="modal-body">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Level</th>
                      <th>TTD</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.datDisposisi.map((dt,i)=>{
                  return(
                    <tr key={i}>
                      <td>{i+1}</td>
                      <td>{dt.data_jabatan.nama}</td>
                      <td>{dt.level}</td>
                      <td>

                      <button
                      type="button"
                      className="btn btn-info"
                      onClick={() => {
                        this.disposisi(dt.uuid,this.state.idSurat)}
                      }
                      disabled={this.state.status.btnForm}
                    >
                      Disposisi
                    </button>
                      </td>
                    </tr>
                    )})}
                  </tbody>
                </table>
              </div>
              */}
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Pejabat</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.riwayatDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {dt.status === 0
                            ? "Menunggu Disposisi"
                            : dt.status === 1
                            ? "Telah di disposisi"
                            : "Ditolak"}
                        </td>
                        <td>{dt.data_perangkat.data_jabatan.nama}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <div id="cetak-surat" style={{ display: "none" }}>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    "
            }}
          />
          <table
            border="0"
            width="90%"
            style={{
              marginTop: "30px",
              marginLeft: "auto",
              marginRight: "auto",
              verticalAlign: "top"
            }}
          >
            <tr>
              <td align="center" style={{ paddingBottom: "20px" }}>
                {" "}
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    textDecoration: "underline"
                  }}
                >
                  surat pernyataan tidak menikah
                </span>
                {/* <br />
                {this.state.cetak.nomor_surat} */}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p style={{ textIndent: "0px" }}>
                  Yang bertanda tangan dibawah ini yakni :{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <table border="0" width="100%">
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.nama_pemohon}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      {this.state.cetak.jenis_kelamin === "Laki-Laki"
                        ? "Bin"
                        : "Binti"}
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.nama_ayah_kandung}
                    </td>
                  </tr>

                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.nik}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat / Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.tempat_lahir},{" "}
                      {this.state.cetak.tanggal_lahir}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.pekerjaan}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Status Perkawinan
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.status_perkawinan}
                    </td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td style={{ textTransform: "capitalize" }}>
                      {this.state.cetak.alamat}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <span style={{ paddingLeft: "50px" }}>
                  Dengan ini menyatakan sesungguhnya bahwa saya sampai saat ini
                  berstatus {this.state.cetak.status_perkawinan} dan tercatat
                  pada{" "}
                  {this.state.cetak.status_perkawinan === "Cerai hidup"
                    ? "Surat Akta Cerai"
                    : "Surat Akta Kematian"}{" "}
                  {this.state.cetak.nomor_surat_akta}, pada{" "}
                  {HariTanggal(this.state.cetak.tanggal_surat_akta)}
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <span style={{ paddingLeft: "50px" }}>
                  Apabila dikemudian hari ternyata tidak benar atau palsu maka
                  saya bersedia di tuntut secara hukum/undang-undang yang
                  berlaku dan tidak melibatkan pejabat yang ikut bertanda tangan
                  pada surat pernyataan ini.
                </span>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <span style={{ paddingLeft: "50px" }}>
                  Demikianlah surat keterangan pernyataan ini saya buat dengan
                  benar dan sungguh-sungguh dan dapat dipergunakan seperlunya.
                </span>
              </td>
            </tr>
          </table>
          <table border="0" width="100%">
            <tr>
              <td style={{ textAlign: "justify" }} width="50%">
                &nbsp;
              </td>
              <td style={{ textAlign: "center" }} width="50%">
                {this.state.cetak.nama_desa},{" "}
                {Tanggal(new Date(this.state.cetak.tanggal_surat))}{" "}
              </td>
            </tr>
          </table>
          <table border="0" width="100%" style={{ marginTop: "20px" }}>
            <tr>
              <td
                width="50%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>Saksi keluarga,</span>
              </td>
              <td
                width="50%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  Saya yang menyatakan,
                </span>
              </td>
            </tr>
            <tr>
              <td
                width="50%"
                align="center"
                style={{
                  paddingLeft: "20px",
                  textAlign: "center",
                  textTransform: "capitalize"
                }}
              >
                {this.state.cetak.hubungan_saksi}
              </td>
              <td
                width="50%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              ></td>
            </tr>
            <tr>
              <td height="50" colspan="2">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td width="50%" align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama_saksi}
                </span>
              </td>
              <td width="50%" align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama_pemohon}
                </span>
              </td>
            </tr>
          </table>

          <table border="0" width="100%" style={{ marginTop: "20px" }}>
            <tr>
              <td
                width="100%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span>Mengetahui :</span>
              </td>
            </tr>
            <tr>
              <td
                width="100%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {this.state.cetak.status_ttd}{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {this.state.cetak.jabatan_ttd}
                  </span>
                  {this.state.cetak.jabatan_pengganti_ttd ? (
                    <>
                      <br />
                      {this.state.cetak.jabatan_pengganti_ttd}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td height="50" colspan="2">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td width="100%" align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama_ttd}
                </span>
                <br />
                {this.state.cetak.nip_ttd}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default BasicTable;
