import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
// import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

// import Dropzone from "react-dropzone";
// import Resizer from "react-image-file-resizer";
import Switch from "react-switch"; 

// koneksi
import { Post, Get, } from "../function/Koneksi";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

//json file
// var data = require('../assets/json/company');

const frmDef = {
	nomor_wa_perangkat: [{isi:[""]}],
};

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
			dt: frmDef,
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: false
      },
      foto_desa: "",
      foto_info: "",
      input: "none",
      view: "inherit",
      judul: "Tambah Profile",
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      sidebar: false,
      response_pengajuan_layanan: "",
      riwayat_disposisi: "",
      notif_global: false,
      notif_wa: false,
      nomor_wa_perangkat: "",
      get_all_nomor: null,

			// Loading
			loading: true,
      data_cek: '',
    };
		this.handleChange = this.handleChange.bind(this);
  }


  
  inputChange = (event) => {
		// //console.log(event.target.value);
		this.setState({ 
			dt: {...this.state.dt, 
				nomor_wa_perangkat: {...this.state.dt.nomor_wa_perangkat,
				}
			}
		})
		// //console.log(this.state.dt);
	}


  openCloseSidebar = (type) => {
    let data = null

    if (type === "sidebar") {
      data = this.state.sidebar
    } else if (type === "notif_global") {
      data = this.state.notif_global
    } else if (type === "notif_wa") {
      data = this.state.notif_wa
    }

    // Jika data === true
    if (data) {
      if (type === "sidebar") {
        this.setState({ sidebar: false });
      } else if (type === "notif_global") {
        this.setState({ notif_global: false });
      } else if (type === "notif_wa") {
        this.setState({ notif_wa: false });
      }
    } else {
      if (type === "sidebar") {
        this.setState({ sidebar: true });
      } else if (type === "notif_global") {
        this.setState({ notif_global: true });
      } else if (type === "notif_wa") {
        this.setState({ notif_wa: true });
      }
    }
  };
  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  handleChange(checked, jenis_konfig) {
		//console.log("CHANGE JENIS Konfig")
		//console.log(jenis_konfig)
		switch (jenis_konfig) {
			// PPOB MOBILE
			case "sidebar":
				if(this.state.sidebar === true){
          this.setState({
            sidebar: checked
          })
				}
				else{
          this.setState({
            sidebar: checked
          })
				}
			break;
			case "notif_global":
				if(this.state.notif_global === true){
          this.setState({
            notif_global: checked
          })
				}
				else{
          this.setState({
            notif_global: checked
          })
				}
			break;
			case "notif_wa":
				if(this.state.notif_wa === true){
          this.setState({
            notif_wa: checked,
          })
				}
				else{
          this.setState({
            notif_wa: checked,
          })
				}
			break;
			default:
				// no default
				break;
		  }

		this.forceUpdate();
		this.setState({ checked });
	}

  // ================== Ambil data dari db untuk table ==================
  fetch = () => {
    Get("pengaturan-aplikasi", null, dtkat => {
      // //console.log(dtkat);
      let data_cek = ""
      if (dtkat.results !== null) {
        let nomor_wa_perangkat = JSON.parse(dtkat.results.nomor_wa_perangkat)
        data_cek = "Tidak Kosong"

        this.setState({
          data: dtkat.results,
          response_pengajuan_layanan: dtkat.results.response_pengajuan_layanan,
          nomor_wa_perangkat: dtkat.results.nomor_wa_perangkat,
          get_all_nomor: nomor_wa_perangkat, 
          sidebar: dtkat.results.riwayat_disposisi === 0 ? false : true,
          notif_global: dtkat.results.notif_global_perangkat === 1 ? true : false,
          notif_wa: dtkat.results.notif_wa === 1 ? true : false,
          loading: false,
          data_cek: data_cek,
        });
      }
      else{
        data_cek = "Kosong"
        this.setState({
          loading: false,
          data_cek: data_cek,
        });
      }
    });
  };

  // ========================================================================

  tutupForm = e => {
    this.setState({ 
      input: "none", 
      view: "inherit",
      status:{
        ...this.state.status,
        form: false,
      },
    });
    this.forceUpdate()
  };

  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();
    this.setState({
      status:{
          ...this.state.status,
          btnForm: true,
      },
    })
    this.forceUpdate();
    // let uuid = null;

    let get_nomor_wa = JSON.stringify(this.state.dt.nomor_wa_perangkat);
    let pch = get_nomor_wa.split(':');
    let pch2 = pch[1].split('}');
    let nomor_wa = pch2[0];
    let set = JSON.parse(nomor_wa)

    const depArray = set;
    var newNomorWa = [];
    newNomorWa = depArray.filter(function(elem, pos) {
            return depArray.indexOf(elem) === pos;
    });

    let addProfile = {
      uuid: document.getElementById("uuid").value,
    //   foto_desa: this.state.foto_desa,
      response_pengajuan_layanan: document.getElementById("response_pengajuan_layanan").value,
      nomor_wa_perangkat: JSON.stringify(newNomorWa),
      riwayat_disposisi: this.state.sidebar ? 1 : 0,
      notif_global_perangkat: this.state.notif_global ? 1 : 0,
      notif_wa: this.state.notif_wa ? 1 : 0,
    };
    
    let psn = "";
    let resstat = 204;
    let metode = "create";
    if (addProfile.uuid === "") {
      psn = "Tambah";
      resstat = 201;
      addProfile.uuid = null;
    } else {
      psn = "Ubah";
      resstat = 200;
      metode = "update";
    }

    Post("pengaturan-aplikasi/" + metode, addProfile.uuid, addProfile, res => {
      if (res.status === resstat) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Pengaturan Aplikasi",
          pesanAlert: "Berhasil " + psn + " Data"
        });
        this.tutupForm()
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Pengaturan Aplikasi",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.setState({ input: "none", view: "inherit" });
      this.fetch();
    });
  };
  // ========================================================================

  // ============================== Ubah Data ===============================

  ubahData = (status, data) => {
    this.set({
      status:{
          ...this.state.status,
          form: true,
      },
    })
    if(status==="update"){
      let nomor_wa_perangkat = [
        { 
          isi:[""]
        }
      ]

      if (data.nomor_wa_perangkat !== '') {
        nomor_wa_perangkat = JSON.parse(data.nomor_wa_perangkat)
      }

      
      this.setState({
        data: data,
        dt:{
            ...this.state.dt,
            nomor_wa_perangkat: [
              { 
                isi:nomor_wa_perangkat
              }
            ],
        },
      })

      this.forceUpdate()
    }
    else{
      this.setState({
        data:{

        },
        judul: 'Ubah Pengaturan Aplikasi',
      })
    }
		// this.setState({ view: 'none', input: 'inherit' });
		this.forceUpdate();
	};
  // ========================================================================

  componentDidMount() {
    this.fetch({ page: null, where: [] });
  }

 

  render() {

    return (
      <div>
        {/* <Breadcrumb title="Pengaturan Aplikasi Desa" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          onConfirm={this.closeAlert}
        >
          {this.state.pesanAlert}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
              <div className="card-header">
									<div
										className="row"
										style={{ border: '3px outset black', borderRadius: '25px', padding: '20px' }}
									>
                    {this.state.loading ? (
                      <div className="col-sm-12" align="center">
                        <img 
                        alt="loading"
                        src={require("../assets/images/loading-bos.gif")} 
                        style={{
                          // width: "100%",
                          borderRadius: "10px",
                          width: '70px'
                        }}/> 
                      </div>
                    ) : 
                    <>
											<div className="col-md-12 text-right">
											{
												this.state.data.uuid
												?
													<Button  size="md" color="success" className="btn-icon" onClick={() => this.ubahData('update',this.state.data)} disabled={this.state.status.btnAksi}><i className="fa fa-edit"></i> Ubah Pengaturan</Button> 
												:
													<Button  size="md" color="success" className="btn-icon" onClick={() => this.ubahData('create','')} disabled={this.state.status.btnAksi}><i className="fa fa-plus"></i> Tambah Pengaturan</Button> 
											}
											</div>
											<div>
												<br/>&nbsp;<br/>
											</div>
                      {
                      this.state.data.uuid
                      ?
                        <>
                          <div className="col-md-12" align="center">
                            {/* <img src={this.state.data.foto_desa} style={{ width: '350px' }} /> */}
                            <br/><br/>
                          </div>

                          <div className="col-6">

                          <div className="col-md-12">
                              <b>Response pengajuan layanan</b>
                            </div>
                            <div className="col-md-12">
                              {this.state.response_pengajuan_layanan}
                            </div>

                            <div className="col-md-12">
                              <b>&nbsp;</b>
                            </div>
                            <div className="col-md-12">
                              &nbsp;
                              </div>

                            <div className="col-md-12">
                              <b>Status Riwayat Disposisi</b>
                            </div>
                            <div className="col-md-12">
                              {this.state.sidebar === false ? "Tidak Aktif" : "Aktif"}
                            </div>

                            <div className="col-md-12">
                              <b>&nbsp;</b>
                            </div>
                            <div className="col-md-12">
                              &nbsp;
                            </div>

                            <div className="col-md-12">
                              <b>Notifikasi Layanan Global</b>
                            </div>
                            <div className="col-md-12">
                              {this.state.notif_global === false ? "Tidak Aktif" : "Aktif"}
                            </div>

                            <div className="col-md-12">
                              <b>&nbsp;</b>
                            </div>
                            <div className="col-md-12">
                              &nbsp;
                            </div>

                          </div>
                        {/* ======================= */}
                          <div className="col-6">
                          <div className="col-md-12">
                              <b>Notifikasi Whatsapp</b>
                            </div>
                            <div className="col-md-12">
                              {this.state.notif_wa === false ? "Tidak Aktif" : "Aktif"}
                            </div>

                            <div className="col-md-12">
                              <b>&nbsp;</b>
                            </div>
                            <div className="col-md-12">
                              &nbsp;
                            </div>

                            <div className="col-md-12">
                              <b>Nomor Whatsapp</b>
                            </div>
                            <div className="col-md-12">
                              {
                                this.state.get_all_nomor.map((dt, i) => {
                                  return (
                                    <tr>
                                      <td style={{ padding: '5px 5px 5px 5px' }}>{i + 1}.</td>
                                      <td style={{ padding: '5px 5px 5px 5px' }}>{dt}</td>
                                    </tr>
                                  );
                                })
                              }
                            </div>

                            <div className="col-md-12">
                              <b>&nbsp;</b>
                            </div>
                            <div className="col-md-12">
                              &nbsp;
                              </div>
                          </div>
                        </>
                      :
                      (
                        <div className="col-md-12 text-center">
                          <h4 align="center">Pengaturan Aplikasi Belum Ada</h4>
                        </div>
                      )
                      }
                    </>
                  }
									</div>
								</div>

                <Modal
									open={this.state.status.form}
									onClose={this.tutupForm}
									styles={{ modal: { width: '80%' } }}
									closeOnEsc={false}
									closeOnOverlayClick={false}
								>
									<Form className="theme-form" onSubmit={this.simpan}>
										<div className="modal-header">
											<h4 className="modal-title">{this.state.judul}</h4>
										</div>

										<div className="modal-body">
											<input className="form-control" id="uuid" type="hidden" placeholder="UUID" defaultValue={this.state.data.uuid}/>

											<div className="row">
                        <div className="col-6">
                          <FormGroup className="row">
                            <Label className="col-sm-9 col-form-label" htmlFor="inputEmail3"><b>Riwayat Disposisi Perangkat</b></Label>
                            <Col sm='3'>
                              <Switch 
                                onChange={(e) => {
                                this.handleChange(e,'sidebar');
                                }}
                                checked={this.state.sidebar} 
                              />
                            </Col>
                          </FormGroup>	
                        </div>

                        <div className="col-6">
                          <FormGroup className="row">
                            <Label className="col-sm-9 col-form-label" htmlFor="inputEmail3"><b>Notifikasi Layanan Global</b></Label>
                            <Col sm='3'>
                              <Switch 
                                onChange={(e) => {
                                this.handleChange(e,'notif_global');
                                }}
                                checked={this.state.notif_global} 
                              />
                            </Col>
                          </FormGroup>	
                        </div>

                        <div className="col-6">
                          <FormGroup className="row">
                            <Label className="col-sm-9 col-form-label" htmlFor="inputEmail3"><b>Notifikasi Whatsapp</b></Label>
                            <Col sm='3'>
                              <Switch 
                                onChange={(e) => {
                                this.handleChange(e,'notif_wa');
                                }}
                                checked={this.state.notif_wa} 
                              />
                            </Col>
                          </FormGroup>	
                        </div>

												{/* <div className="col-lg-6 pl-3">
													<FormGroup className="row">
                              <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                             <b> Riwayat disposisi perangkat </b>
														</Label>
                            <br/>
														<div className="media-body text-right switch-sm">
                              <span>
                                {this.state.sidebar === false
                                  ? "Tidak aktif"
                                  : "Aktif"}
                              </span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="sidebar-toggle"
                                  checked={this.state.sidebar}
                                  style={{width: '150px'}}
                                  onClick={() => this.openCloseSidebar("sidebar")}
                                />
                                <span className="switch-state"></span>
                              </label>
                            </div>
													</FormGroup>
												</div>
                        <div className="col-lg-6 pl-3">
													<FormGroup className="row">
                              <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                              <b>Notifikasi Layanan Global</b>
														</Label>
														<div className="media-body text-right switch-sm">
                              <span>
                                {this.state.notif_global === false
                                  ? "Tidak aktif"
                                  : "Aktif"}
                              </span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="notif-toggle"
                                  checked={this.state.notif_global}
                                  onClick={() => this.openCloseSidebar("notif_global")}
                                />
                                <span className="switch-state"></span>
                              </label>
                            </div>
													</FormGroup>
												</div>
                        <div className="col-lg-6 pl-3">
													<FormGroup className="row">
                              <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                              <b>Notifikasi Whatsapp</b>
														</Label>
														<div className="media-body text-right switch-sm">
                              <span>
                                {this.state.notif_wa === false
                                  ? "Tidak aktif"
                                  : "Aktif"}
                              </span>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  id="notif-toggle"
                                  checked={this.state.notif_wa}
                                  onClick={() => this.openCloseSidebar("notif_wa")}
                                />
                                <span className="switch-state"></span>
                              </label>
                            </div>
													</FormGroup>
												</div> */}

                        <div className="col-lg-12 pr-3">
                          <FormGroup className="row">
                            <Label className="col-sm-4 col-form-label" htmlFor="inputEmail3">
                            <b>Response Pengajuan Layanan</b>
                            </Label>
                           <Col sm="8">
													{/*<FormGroup className="row">
                              <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                              Response Pengajuan Layanan
														</Label> */}
                              <input
                                className="form-control"
                                id="response_pengajuan_layanan"
                                type="text"
                                placeholder="response"
                                defaultValue={this.state.response_pengajuan_layanan}
                              />
                            </Col>
													</FormGroup>
												</div>

                        <div className="col-lg-12 pl-3">
                        {this.state.dt.nomor_wa_perangkat.length > 0 ? (
                          <>
                            <FormGroup className="row">
                              <Label className="col-sm-6 col-form-label" htmlFor="inputEmail3">
                                <b>Nomor HP Perangkat</b>
                              </Label>
                            </FormGroup>
                            {this.state.dt.nomor_wa_perangkat.map((dti, i) => {
                              return (
                                <div style={{ border: '1px solid black', borderRadius: '10px', padding: '10px', marginBottom: '15px' }}>
                                  {/* Tombol hapus petunjuk */}
                                  
                                  {dti.isi.map((dt_isi, j) => {
                                    return (
                                      <>
                                        <FormGroup className="row">
                                          <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
                                            Nomor HP {j+1}
                                          </Label>
                                          <Col sm="8">
                                            <input
                                              className="form-control"
                                              type="text"
                                              placeholder="Contoh: 62823xxxxx"
                                              required
                                              defaultValue={dt_isi}
                                              onChange={(event2) => {
                                                let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
                                                let get_data = event2.target.value
                                                let cek = get_data.substring(0, 2);
                                                let set_data = null
                                                if(cek === '08'){
                                                  set_data = get_data.replace("08", "628")
                                                }
                                                else{
                                                  set_data = get_data
                                                }
                                                isi[j] = set_data

                                                let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
                                                nomor_wa_perangkat[i] = {...nomor_wa_perangkat[i], isi}
                
                                                this.setState({ 
                                                  dt: {...this.state.dt, 
                                                    nomor_wa_perangkat
                                                  }
                                                })
                                              }}
                                            />
                                          </Col>
                                          {j > 0 ? (
                                            <Col lg="1">
                                              <button
                                                className="btn btn-danger"
                                                title="Hapus detail petunjuk ini"
                                                style={{ padding: '6px 15px' }}
                                                onClick={() => {
                                                  let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
                                                  isi.splice(j, 1)

                                                  let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
                                                  nomor_wa_perangkat[i] = {...nomor_wa_perangkat[i], isi}
                  
                                                  this.setState({ 
                                                    dt: {...this.state.dt, 
                                                      nomor_wa_perangkat
                                                    }
                                                  })
                                                }}
                                              >-</button>
                                            </Col>
                                          ) : ''}
                                        </FormGroup>
                                      </>
                                    )
                                  })}
                                  <div className="row">
                                    <div className="col-sm-12">
                                      <button
                                        type="button"
                                        className="btn btn-info"
                                        // disabled={this.state.status.btnForm}
                                        onClick={() => {
                                          let isi = [...this.state.dt.nomor_wa_perangkat[i].isi]
                                          isi.push('')
                                          let nomor_wa_perangkat = [...this.state.dt.nomor_wa_perangkat]
                                          nomor_wa_perangkat[i].isi = isi
                                          this.setState({ 
                                            dt: {...this.state.dt,
                                              nomor_wa_perangkat
                                            }
                                          })
                                        }}
                                      >
                                        Tambah No HP Perangkat
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                            })}
                          </>
                        ) : ''}
												</div>
												
											</div>
										</div>

										<div className="modal-footer">
											<button type="button" className="btn btn-warning" onClick={this.tutupForm}>
												Batal
											</button>
											<button
												type="submit"
												className="btn btn-success"
												// disabled={this.state.status.btnFormAcc}
											>
												Simpan
											</button>
										</div>
									</Form>
								</Modal>
								
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BasicTable;