import {
    GET_MEMBERS,
    GET_MEMBERS_SUCCESS,
    GET_CHATS,
    GET_CHATS_SUCCESS,
    // ADD_MESSAGE_TO_CONVERSATION,
    // CREATE_CONVERSATION,
    SEARCH_MEMBER,
    CHANGE_CHAT,
    CREATE_CHAT,
    SEND_MESSAGE,
    UPDATE_STATUS
} from '../constants/actionTypes';

const initial_state = {
    allMembers: null,
    members: null,
    chats: null,
    error: '',
    searchKeyword: '',
    loading: false,
    currentUser: null,
    selectedUser: null
};

const ABC = (state = initial_state, action) => {
    switch (action.type) {

        case GET_MEMBERS:
            return { ...state, loading: true };

        case GET_MEMBERS_SUCCESS:
            return { ...state, loading: false, allMembers: action.payload.members, members: action.payload.members, currentUser: action.payload.currentUser };


        case GET_CHATS:
            return { ...state, loading: true };

        case GET_CHATS_SUCCESS:
            return { ...state, loading: false, chats: action.payload.chats, selectedUser: state.allMembers.find(x => x.id === action.payload.selectedUser) };

        case CHANGE_CHAT:
            return { ...state, selectedUser: state.allMembers.find(x => x.id === action.payload) };

        case SEARCH_MEMBER:
            if (action.payload === '') {
                return { ...state, members: state.allMembers };
            } else {
                const keyword = action.payload.toLowerCase();
                const searchedMembers = state.allMembers.filter((member) => member.name.toLowerCase().indexOf(keyword) > -1);
                return { ...state, members: searchedMembers }
            }

        case SEND_MESSAGE:
            return { ...state };

        case CREATE_CHAT:
            return { ...state };

        case UPDATE_STATUS:
            return { ...state, currentUser: {...state.currentUser, status:action.payload}};

        default: return { ...state };
    }
}

export default ABC;
