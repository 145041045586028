import React, { useContext } from 'react';
import Modal from 'react-responsive-modal';
import {
	Form,
	FormGroup,
	Label,
	Col,
} from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
import Datetime from 'react-datetime';

function EditTidakMenikah({ detail_surat_context }) {
	const detailSuratProps = useContext(detail_surat_context);

	return (
		<div>
			<Modal
				open={detailSuratProps.status.formUpdateSurat}
				onClose={detailSuratProps.tutupForm}
				styles={{ modal: { width: '80%' } }}
			>
				<Form
					className="theme-form"
					onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}
				>
					<div className="modal-header">
						<h5 className="modal-title">Detail {detailSuratProps.detailSurat.jenis_surat}</h5>
					</div>
					<div className="modal-body">
						<input
							className="form-control"
							id="uuid"
							type="hidden"
							placeholder=""
							defaultValue={detailSuratProps.detailSurat.uuid}
						/>

						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								NIK{' '}
							</Label>
							<Col sm="9">
								<Select
									isDisabled={detailSuratProps.status.select}
									classNamePrefix="select"
									onChange={(e) => {
										detailSuratProps.getPenduduk(
											e,
											detailSuratProps.detailSurat.jenis_surat,
											'nik'
										);
									}}
									defaultValue={detailSuratProps.detailSurat.pendStat}
									value={detailSuratProps.detailSurat.pendStat}
									name="penduduk"
									options={detailSuratProps.dataNik.frmNik}
									placeholder="Pilih NIK"
									onInputChange={(e) => detailSuratProps.handleInputChangeNIK(e, 'nik')}
									required={true}
									isClearable
								/>
							</Col>
						</FormGroup>
						<div style={{ display: detailSuratProps.status.showBio === false ? 'none' : '' }}>
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="nik"
								type="hidden"
								readOnly
								placeholder="NIK"
								value={detailSuratProps.detailSurat.nik}
							/>
							{/* </Col>
                </FormGroup> */}

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Pemohon
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_pemohon"
										readOnly
										type="text"
										placeholder="nama_pemohon"
										value={detailSuratProps.detailSurat.nama_pemohon}
										style={{ backgroundColor:"#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tempat Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										readOnly
										id="tempat_lahir"
										type="text"
										placeholder="Tempat lahir"
										value={detailSuratProps.detailSurat.tempat_lahir}
										style={{ backgroundColor:"#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Tanggal Lahir
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tanggal_lahir"
										readOnly
										type="text"
										placeholder="Tanggal Lahir"
										value={detailSuratProps.detailSurat.tanggal_lahir}
										style={{ backgroundColor:"#c9d1d8" }}
									/>
								</Col>
							</FormGroup>
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Jenis Kelamin
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="jenis_kelamin"
								readOnly
								type="hidden"
								placeholder="jenis_kelamin"
								value={detailSuratProps.detailSurat.jenis_kelamin}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								readOnly
								id="pekerjaan"
								type="hidden"
								placeholder="Pekerjaan"
								value={detailSuratProps.detailSurat.pekerjaan}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pendidikan Terakhir
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="pendidikan_terakhir"
								readOnly
								type="hidden"
								placeholder="Pendidikan Terakhir"
								value={detailSuratProps.detailSurat.pendidikan_terakhir}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="agama"
								readOnly
								type="hidden"
								placeholder="Agama"
								value={detailSuratProps.detailSurat.agama}
							/>
							{/* </Col>
                </FormGroup> */}
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Status Perkawinan
								</Label>
								<Col sm="9">
									{/* <input
										className="form-control"
										id="status_perkawinan"
										type="text"
										readOnly
										placeholder="Status Perkawinan"
										value={detailSuratProps.detailSurat.status_perkawinan}
									/> */}
									<Select
										id="status_perkawinan"
										classNamePrefix="select"
										onChange={(e) => { detailSuratProps.changeSelectValue2(e, 'status_perkawinan') }}
										value={detailSuratProps.detailSurat.status_perkawinan}
										name="Agama"
										options={[
											{value: 'Cerai Hidup', label: 'Cerai Hidup'},
											{value: 'Cerai Mati', label: 'Cerai Mati'},
										]}
										placeholder="Pilih Status Perkawinan"
										isClearable
									/>
								</Col>
							</FormGroup>
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="alamat"
								type="hidden"
								readOnly
								placeholder="Alamat"
								value={detailSuratProps.detailSurat.alamat}
							/>
							{/* </Col>
                </FormGroup> */}
							{/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Kewarganegaraan
                  </Label>
                  <Col sm="9"> */}
							<input
								className="form-control"
								id="kewarganegaraan"
								type="hidden"
								readOnly={true}
								placeholder="Kewarganegaraan"
								value={detailSuratProps.detailSurat.kewarganegaraan}
							/>
							{/* </Col>
                </FormGroup> */}
						</div>

						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Nomor Surat Akta {detailSuratProps.detailSurat.labelAkta}
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="nomor_surat_akta"
									type="text"
									placeholder="Nomor Surat Akta "
									defaultValue={detailSuratProps.detailSurat.nomor_surat_akta}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Tanggal Surat Akta {detailSuratProps.detailSurat.labelAkta}
							</Label>
							<Col sm="9">
								{/* <Datepicker
                  id="tanggal_surat_akta"
                  todayButton={"Today"}
                  dateFormat="dd/MM/yyyy"
                  selected={detailSuratProps.detailSurat.tanggal_surat_akta}
                  onChange={e => detailSuratProps.gantiTgl(e, "tanggal_surat_akta")}
                  className="form-control"
                /> */}
								<Datetime
									id="tanggal_surat_akta"
									locale="id-ID"
									input={true}
									closeOnTab={true}
									timeFormat={false}
									dateFormat="DD/MM/yyyy"
									closeOnSelect={true}
									initialValue={detailSuratProps.detailSurat.tanggal_surat_akta}
									onChange={(e) => {
										detailSuratProps.gantiTgl(e, 'tanggal_surat_akta');
									}}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Nama Saksi
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="nama_saksi"
									type="text"
									placeholder="Nama Saksi"
									defaultValue={detailSuratProps.detailSurat.nama_saksi}
								/>
							</Col>
						</FormGroup>
						<FormGroup className="row">
							<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
								Hubungan Saksi
							</Label>
							<Col sm="9">
								<input
									className="form-control"
									id="hubungan_saksi"
									type="text"
									placeholder="Hubungan Saksi"
									defaultValue={detailSuratProps.detailSurat.hubungan_saksi}
								/>
							</Col>
						</FormGroup>
					</div>
					<div className="modal-footer">
						<button
							type="button"
							className="btn btn-warning"
							// disabled={detailSuratProps.status.btnForm}
							onClick={detailSuratProps.tutupForm}
						>
							Tutup
						</button>
						<button
							type="submit"
							className="btn btn-success"
							// disabled={detailSuratProps.state.status.btnForm}
						>
							Simpan
						</button>
					</div>
				</Form>
			</Modal>
		</div>
	);
}

export default EditTidakMenikah;
