import React, { useContext } from 'react'
import Modal from 'react-responsive-modal';
import { Button, Form, FormGroup, Label, Col,  } from 'reactstrap';
// import Datepicker from 'react-datepicker';
import Select from 'react-select';
import Datetime from "react-datetime";

function EditPindahNikah({ detail_surat_context }) {

  const detailSuratProps = useContext(detail_surat_context);

  const dateFormat = require('dateformat');

  return (
    <div>
      <Modal
        open={detailSuratProps.status.formUpdateSurat}
        onClose={detailSuratProps.tutupForm}
        styles={{ modal: { width: "80%" } }}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <Form className="theme-form" onSubmit={(e) => detailSuratProps.simpanUpdate(e, detailSuratProps.detailSurat.jenis_surat)}>
          <div className="modal-header">
            <h5 className="modal-title">Ubah {detailSuratProps.detailSurat.jenis_surat}</h5>
          </div>
          <div className="modal-body">
            <input
              className="form-control"
              id="uuid"
              type="hidden"
              placeholder=""
              defaultValue={detailSuratProps.detailSurat.uuid}
            />
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                NIK Pemohon{" "}
              </Label>
              <Col sm="9">
                <Select
                  // isDisabled={this.state.status.select}
                  classNamePrefix="select"
                  onChange={e => {
                    detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, 'nik');
                  }}
                  defaultValue={detailSuratProps.detailSurat.pendStat}
                  value={detailSuratProps.detailSurat.pendStat}
                  name="penduduk"
                  onInputChange={e => detailSuratProps.handleInputChangeNIK(e, "nik")}
                  options={detailSuratProps.dataNik.frmNik}
                  placeholder="Pilih NIK "
                  isClearable
                />
              </Col>
            </FormGroup>

            <div
              style={{ display: detailSuratProps.status.showBio === false ? "none" : "" }}
            >
              {/* <Label
                className="col-sm-12 col-form-label"
                htmlFor="inputEmail3"
              >
                Detail Data
                </Label> */}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Pemohon
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama_pemohon"
                    readOnly
                    type="text"
                    placeholder="nama_pemohon"
                    value={detailSuratProps.detailSurat.nama_pemohon}
                    style={{ backgroundColor: "#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Nasab
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="nama_nasab_p1"
                    type="text"
                    required
                    readOnly
                    placeholder="Nama Nasab"
                    defaultValue={detailSuratProps.detailSurat.nama_nasab_p1}
                    style={{ backgroundColor: "#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK
                  </Label>
                  <Col sm="9"> */}
              <input
                className="form-control"
                id="nik"
                type="hidden"
                readOnly
                placeholder="NIK"
                value={detailSuratProps.detailSurat.nik}
              />
              {/* </Col>
                </FormGroup> */}
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    KK
                  </Label>
                  <Col sm="9"> */}
              <input
                className="form-control"
                id="kk"
                type="hidden"
                readOnly
                placeholder="kk"
                value={detailSuratProps.detailSurat.kk}
              />
              {/* </Col>
                </FormGroup> */}

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat Lahir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    readOnly
                    id="tempat_lahir"
                    type="text"
                    placeholder="Tempat lahir"
                    value={detailSuratProps.detailSurat.tempat_lahir}
                    style={{ backgroundColor: "#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Lahir
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="tanggal_lahir"
                    readOnly
                    type="text"
                    placeholder="Tanggal Lahir"
                    value={detailSuratProps.detailSurat.tanggal_lahir}
                    style={{ backgroundColor: "#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Jenis Kelamin
                  </Label>
                  <Col sm="9"> */}
              <input
                className="form-control"
                id="jenis_kelamin"
                readOnly
                type="hidden"
                placeholder="jenis_kelamin"
                value={detailSuratProps.detailSurat.jenis_kelamin}
              />
              {/* </Col>
                </FormGroup> */}
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9"> */}
              <input
                className="form-control"
                readOnly
                id="pekerjaan"
                type="hidden"
                placeholder="Pekerjaan"
                value={detailSuratProps.detailSurat.pekerjaan}
              />
              {/* </Col>
                </FormGroup> */}
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pendidikan Terakhir
                  </Label>
                  <Col sm="9"> */}
              <input
                className="form-control"
                id="pendidikan_terakhir"
                readOnly
                type="hidden"
                placeholder="Pendidikan Terakhir"
                value={detailSuratProps.detailSurat.pendidikan_terakhir}
              />
              {/* </Col>
                </FormGroup> */}
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9"> */}
              <input
                className="form-control"
                id="agama"
                readOnly
                type="hidden"
                placeholder="Agama"
                value={detailSuratProps.detailSurat.agama}
              />
              {/* </Col>
                </FormGroup> */}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Status Perkawinan
                  </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="status_perkawinan"
                    type="text"
                    readOnly
                    placeholder="Status Perkawinan"
                    value={detailSuratProps.detailSurat.status_perkawinan}
                    style={{ backgroundColor: "#c9d1d8" }}
                  />
                </Col>
              </FormGroup>
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9"> */}
              <input
                className="form-control"
                id="alamat"
                type="hidden"
                readOnly
                placeholder="Alamat"
                value={detailSuratProps.detailSurat.alamat}
              />
              {/* </Col>
                </FormGroup> */}
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Kewarganegaraan
                  </Label>
                  <Col sm="9"> */}
              <input
                className="form-control"
                id="kewarganegaraan"
                type="hidden"
                readOnly={true}
                placeholder="Kewarganegaraan"
                value={detailSuratProps.detailSurat.kewarganegaraan}
              />
              {/* </Col>
                </FormGroup> */}
            </div>
            <hr />
            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                NIK Pasangan{" "}
              </Label>
              <Col sm="9">
                {detailSuratProps.detailSurat.formPasangan === "none" ? (
                  <Select
                    // isDisabled={this.state.status.select}
                    classNamePrefix="select"
                    onChange={e => {
                      detailSuratProps.getPenduduk(e, detailSuratProps.detailSurat.jenis_surat, 'nik2');
                    }}
                    defaultValue={detailSuratProps.detailSurat.pendStat2}
                    value={detailSuratProps.detailSurat.pendStat2}
                    name="penduduk"
                    onInputChange={e => detailSuratProps.handleInputChangeNIK(e, "nik2")}
                    options={detailSuratProps.dataNik.frmNik2}
                    placeholder="Pilih NIK "
                    isClearable
                  />
                ) : (
                  <input
                    className="form-control"
                    id="nik_pasangan"
                    type={
                      detailSuratProps.detailSurat.formPasangan === "none" ? "hidden" : "number"
                    }
                    required
                    placeholder="NIK Pasangan"
                    // defaultValue={detailSuratProps.detailSurat.nik_pasangan}
                    value={detailSuratProps.detailSurat.nik_pasangan}
                    onChange={e => detailSuratProps.changeInputText(e, "nik_pasangan")}
                  />
                )}
                <small id="emailHelp" className="form-text text-muted">
									Data NIK {detailSuratProps.detailSurat.formPasangan === "none" ? "tidak" : ""}{" "}
									tersedia?{" "}
									<Button
										className="btn btn-xs"
										// to={{
										// 	pathname: this.props.myroute,
										// 	state: this.props.location.state
										// }}
										onClick={detailSuratProps.dataPasangan}
									>
										{" "}
										Klik disini
									</Button>
								</small>
              </Col>
            </FormGroup>
            <div
              style={{ display: detailSuratProps.status.showBio2 === false ? "none" : "" }}
            >
              {/* <Label
                className="col-sm-12 col-form-label"
                htmlFor="inputEmail3"
              >
                Detail Data
                </Label> */}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Pasangan
                  </Label>
                <Col sm="9">
                  {/* <input
                    className="form-control"
                    id="nama_pasangan"
                    type="text"
                    readOnly
                    required
                    placeholder=" Nama Pasangan"
                    value={detailSuratProps.detailSurat.nama_pasangan}
                  /> */}
                  <input
                    className="form-control"
                    id="nama_pasangan"
                    type="text"
                    required
                    readOnly={detailSuratProps.detailSurat.formPasangan === "" ? false : true}
                    placeholder=" Nama Pasangan"
                    defaultValue={detailSuratProps.detailSurat.nama_pasangan}
                    value={detailSuratProps.detailSurat.nama_pasangan}
                    onChange={e => detailSuratProps.changeInputText(e, "nama_pasangan")}

                    style={detailSuratProps.detailSurat.formPasangan === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
                    disabled={detailSuratProps.detailSurat.formPasangan === "" ? false : true}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Nasab Pasangan
                  </Label>
                <Col sm="9">
                  {/* <input
                    className="form-control"
                    id="nama_nasab_p2"
                    type="text"
                    required
                    readOnly
                    placeholder="Nama Nasab"
                    value={detailSuratProps.detailSurat.nama_nasab_p2}
                  /> */}
                  <input
                    className="form-control"
                    id="nama_nasab_p2"
                    type="text"
                    required
                    readOnly={detailSuratProps.detailSurat.formPasangan === "" ? false : true}
                    placeholder="Nama Nasab"
                    defaultValue={detailSuratProps.detailSurat.nama_nasab_p2}
                    value={detailSuratProps.detailSurat.nama_nasab_p2}
                    onChange={e => detailSuratProps.changeInputText(e, "nama_nasab_p2")}

                    style={detailSuratProps.detailSurat.formPasangan === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
                    disabled={detailSuratProps.detailSurat.formPasangan === "" ? false : true}
                  />
                </Col>
              </FormGroup>
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Jenis Kelamin
                  </Label>
                  <Col sm="9"> */}
              {/* <input
                className="form-control"
                id="jenis_kelamin_pasangan"
                readOnly
                type="hidden"
                placeholder="jenis_kelamin"
                value={detailSuratProps.detailSurat.jenis_kelamin_pasangan}
              /> */}
              {/* </Col>
                </FormGroup> */}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Jenis Kelamin Pasangan
                </Label>
                <Col sm="9">
                  {detailSuratProps.detailSurat.formPasangan === "" ? (
                    <Select
                      id="jenis_kelamin_pasangan"
                      isDisabled={detailSuratProps.detailSurat.formPasangan === "" ? false : true}
                      classNamePrefix="select"
                      onChange={((e) => { detailSuratProps.changeSelectValue2(e, 'jenis_kelamin_pasangan') })}
                      value={detailSuratProps.detailSurat.jkkkStat}
                      name="Jenis Kelamin"
                      options={detailSuratProps.dataJk}
                      placeholder="Pilih Jenis Kelamin"
                      isClearable
                    />
                  ) : (
                    <input 
                      id="jenis_kelamin_pasangan"
                      type="text"
                      className="form-control"
                      readOnly
                      value={detailSuratProps.detailSurat.jenis_kelamin_pasangan}
                      style={{backgroundColor: "#c9d1d8"}}
                    />
                  )}
                </Col>
              </FormGroup>

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat Lahir Pasangan
                  </Label>
                <Col sm="9">
                  {/* <input
                    className="form-control"
                    id="tempat_lahir_pasangan"
                    type="text"
                    readOnly
                    required
                    placeholder="Tempat Lahir Pasangan"
                    value={detailSuratProps.detailSurat.tempat_lahir_pasangan}
                  /> */}
                  <input
                    className="form-control"
                    id="tempat_lahir_pasangan"
                    type="text"
                    required
                    readOnly={detailSuratProps.detailSurat.formPasangan === "" ? false : true}
                    placeholder="Tempat Lahir Pasangan"
                    defaultValue={detailSuratProps.detailSurat.tempat_lahir_pasangan}
                    value={detailSuratProps.detailSurat.tempat_lahir_pasangan}
                    onChange={e =>
                      detailSuratProps.changeInputText(e, "tempat_lahir_pasangan")
                    }

                    style={detailSuratProps.detailSurat.formPasangan === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
                    disabled={detailSuratProps.detailSurat.formPasangan === "" ? false : true}
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Lahir Pasangan
                  </Label>
                <Col sm="9">
                  {detailSuratProps.detailSurat.formPasangan === "" ? (
                    <Datetime
                      locale="id-ID"
                      input={true}
                      timeFormat={false}
                      closeOnTab={true}
                      closeOnSelect={true}
                      dateFormat="DD/MM/yyyy"
                      initialValue={detailSuratProps.detailSurat.tanggal_lahir_pasangan}
                      value={detailSuratProps.detailSurat.tanggal_lahir_pasangan}
                      onChange={(e) => {
                        detailSuratProps.gantiTgl(e, 'tanggal_lahir_pasangan');
                      }}
                      inputProps={{
                        required: true,
                        placeholder: 'Tanggal Lahir Pasangan',
                      }}
                    />
                  ) : (
                    <input
                      className="form-control"
                      id="tanggal_lahir_pasangan"
                      type="text"
                      readOnly
                      placeholder="Tanggal Lahir"
                      value={ dateFormat(detailSuratProps.detailSurat.tanggal_lahir_pasangan, 'yyyy-mm-dd')}
                      // defaultValue={detailSuratProps.detailSurat.tanggal_lahir_pasangan}
                      style={detailSuratProps.detailSurat.formPasangan === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
                      disabled={detailSuratProps.detailSurat.formPasangan === "" ? false : true}
                    />
                  )}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Pekerjaan Pasangan
                </Label>
                <Col sm="9">
                  {detailSuratProps.detailSurat.formPasangan === "" ? (
                    <Select
                      id="pekerjaan_pasangan"
                      classNamePrefix="select"
                      onChange={e => {
                        detailSuratProps.changeSelectValue2(e, "pekerjaan_pasangan");
                      }}
                      value={detailSuratProps.detailSurat.pekerjaan_pasangan}
                      name="pekerjaan_pasangan"
                      options={detailSuratProps.listPekerjaan}
                      placeholder="Pilih Pekerjaan Pasangan"
                      isClearable
                    />
                  ) : (
                    <input
                      className="form-control"
                      id="pekerjaan_pasangan"
                      type="text"
                      readOnly
                      placeholder="Pekerjaan Pasangan"
                      value={detailSuratProps.detailSurat.pekerjaan_pasangan != null ? detailSuratProps.detailSurat.pekerjaan_pasangan.label : ''}
                      // defaultValue={detailSuratProps.detailSurat.tanggal_lahir_pasangan}
                      style={{ backgroundColor: "#c9d1d8" }}
                      disabled={true}
                    />
                  )}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Agama Pasangan
                </Label>
                <Col sm="9">
                  {detailSuratProps.detailSurat.formPasangan === "" ? (
                    <Select
                      id="agama_pasangan"
                      isDisabled={detailSuratProps.detailSurat.formPasangan === "" ? false : true}
                      classNamePrefix="select"
                      onChange={((e) => { detailSuratProps.changeSelectValue2(e, 'agama_pasangan') })}
                      // defaultValue={detailSuratProps.detailSurat.agmStat}
                      value={detailSuratProps.detailSurat.agmStat}
                      name="Agama"
                      options={detailSuratProps.dataAgama}
                      placeholder="Pilih Agama"
                      isClearable
                    />
                  ) : (
                    <input 
                      id="agama_pasangan"
                      type="text"
                      className="form-control"
                      readOnly
                      value={detailSuratProps.detailSurat.agama_pasangan}
                      style={{backgroundColor: "#c9d1d8"}}
                    />
                  )}
                  
                </Col>
              </FormGroup>
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    NIK Pasangan
                  </Label>
                  <Col sm="9"> */}
              {/* <input
                className="form-control"
                id="nik_pasangan"
                readOnly
                type="hidden"
                required
                placeholder="NIK Pasangan"
                defaultValue={detailSuratProps.detailSurat.nik_pasangan}
              /> */}
              {/* </Col>
                </FormGroup> */}
              {/* <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat Pasangan
                  </Label>
                  <Col sm="9"> */}
              {/* <input
                className="form-control"
                id="alamat_pasangan"
                type="hidden"
                readOnly
                required
                placeholder=" Alamat Pasangan "
                defaultValue={detailSuratProps.detailSurat.alamat_pasangan}
              /> */}
              {/* </Col>
                </FormGroup> */}
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Alamat Pasangan
                </Label>
                <Col sm="9">
                  <input
                    className="form-control"
                    id="alamat_pasangan"
                    type="text"
                    readOnly={detailSuratProps.detailSurat.formPasangan === "" ? false : true}
                    required
                    placeholder=" Alamat Pasangan "
                    defaultValue={detailSuratProps.detailSurat.alamat_pasangan}
                    value={detailSuratProps.detailSurat.alamat_pasangan}
                    onChange={e => detailSuratProps.changeInputText(e, "alamat_pasangan")}

                    style={detailSuratProps.detailSurat.formPasangan === "" ? { backgroundColor: "" } : { backgroundColor: "#c9d1d8" }}
                    disabled={detailSuratProps.detailSurat.formPasangan === "" ? false : true}
                  />
                </Col>
              </FormGroup>
            </div>
            <hr />

            <FormGroup className="row">
              <Label
                className="col-sm-3 col-form-label"
                htmlFor="inputEmail3"
              >
                Tempat Nikah
                </Label>
              <Col sm="9">
                <input
                  className="form-control"
                  id="tempat_nikah"
                  type="text"
                  required
                  placeholder="Tempat Nikah"
                  defaultValue={detailSuratProps.detailSurat.tempat_nikah}
                  onFocus={e => e.currentTarget.select()}
                />
              </Col>
            </FormGroup>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-warning"
              // disabled={detailSurat.status.btnForm}
              onClick={detailSuratProps.tutupForm}
            >
              Tutup
              </button>
            <button
              type="submit"
              className="btn btn-success"
            // disabled={detailSuratProps.status.btnForm}
            >
              Simpan
              </button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default EditPindahNikah
