import React, { Component } from "react";

// Import authService
import { Link, Redirect } from "react-router-dom";
import { DataToken } from "../../../function/Format";
// import { JENIS_DESA } from "../../../function/Koneksi";
import MenuAdmin from "./menu.admin";
import MenuSuper from "./menu.superadmin";
import Notificationbaru from "./notification.baru";
import UserMenu from "./user-menu.component";
// import { borderRadius } from "react-select/src/theme";

class HeaderBaru extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      layanan: [],
      perizinan: [],
      navMenus: false,
      sidebar: false,
      navigate: false,
      akun: DataToken(sessionStorage.getItem("access_token"))
    };
  }
  logOut(e) {
    e.preventDefault();
    sessionStorage.removeItem("access_token");
    // localStorage.clear();
    this.setState({ navigate: true });
  }

  openCloseSidebar = () => {
    if (this.state.sidebar) {
      this.setState({ sidebar: false });
      document
        .querySelector(".page-body-wrapper")
        .classList.add("sidebar-close");
    } else {
      this.setState({ sidebar: true });
      document
        .querySelector(".page-body-wrapper")
        .classList.remove("sidebar-close");
    }
  };
  componentDidMount() {
    var contentwidth = window.innerWidth;
    if (contentwidth > 1) {
      document
        .querySelector(".page-body-wrapper")
        .classList.add("sidebar-close");
    }
  }

  render() {
    const { navigate} = this.state;
    if (navigate) {
      return <Redirect to="/" push={true} />;
    }

    let logoAplikasi = (
      <img
        className="align-self-center"
        src={ require("../../../assets/images/logo/digital_desa_rohil.png")}
        alt=""
      />
    )

    switch (localStorage.getItem('JENIS_APLIKASI').toLowerCase()) {
      case 'halaman kampung':
        logoAplikasi = (
          <img
            className="align-self-center"
            src={ require("../../../assets/images/hk_icon.png")}
            alt=""
          />
        )
        break;
      case 'amanat nagari':
        logoAplikasi = (
          <img
            className="align-self-center"
            src={ require("../../../assets/images/amanat_nagari_logo.png")}
            alt=""
          />
        )
        break;
      default:
        logoAplikasi = (
          <img
            className="align-self-center"
            src={ require("../../../assets/images/logo/digital_desa_rohil.png")}
            alt=""
          />
        )
        break; 
    }

    return (
      <div className="bayu-main-header">
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="bayu-header-right" style={{ width: "fit-content" }}>
            <div className="mobile-sidebar">
              <div className="media-body text-right switch-sm">
                <i onClick={this.openCloseSidebar} className="fa fa-bars" />
              </div>
            </div>
          </div>

          <div className="bayu-header-left">
            <div className="logo-wrapper">
              {/* <a href="/"> */}
              <Link data-testid="btnLogoAplikasi" to={process.env.PUBLIC_URL}>
                {logoAplikasi}
              </Link>
            </div>
          </div>
          <div className="bayu-header-right" style={{ width: "fit-content" }}>
            <div className="nav-right">
              <ul>
                <UserMenu />
                <Notificationbaru />

                <li>
                  <a href="/" onClick={this.logOut.bind(this)}>
                    <i className="icofont icofont-logout pow"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {this.state.akun.sub.data_role.nama_role === "Admin" ? (
          <MenuAdmin paid={this.props.paid} lisensi={this.props.lisensi} />
        ) : (
          <MenuSuper />
        )}
      </div>
    );
  }
}

export default HeaderBaru;
