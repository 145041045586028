import React, { Component } from 'react';

import Modal from 'react-responsive-modal';
import { Col, FormGroup, Label, Form } from 'reactstrap';
import '../assets/css/kiosk.css';
import { Get, JENIS_DUSUN, Post } from '../function/Koneksi';
// import Keyboard from 'react-simple-keyboard';
import Keyboard from 'react-simple-keyboard';
import Select from "react-select";
import 'react-simple-keyboard/build/css/index.css';

export default class SuratBelumMemilikiRumah extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Basic: false,
			status: {
				view: false,
				form: false,
				btnForm: false,
				btnAksi: false,
				keyboard: false
			},

			//keyboard
			layoutName: 'default',
			inputName: 'input1',
			inputName1: 'kk',
			input: {},
			inputPattern: /^\d+$/,
			display: 'none',
			focus: false,
			hasFocus: false,
			style: {},

			
			// Pilih Data Keluarga
			frmNikKeluarga: [],
			nikStatTerlapor: null,
			dt_keluarga:[],
			dataKeluarga: "none",
		};
		this.showKeyboard = this.showKeyboard.bind(this);
	}

	onOpenModal = (key) => {
		//console.log(key);
		//console.log(this.props.data.results);
		this.setState({
			[key]: true,
			regis: true
		});
	};

	onCloseModal = (key) => {
		this.setState({
			[key]: false,
			display: 'none',
			regis: 'false',
			input: {},
			dt_keluarga:{
				nik: '',
				kk: '',
				nama: '',
				agama: '',
				alamat: '',
				jenis_kelamin: '',
				pekerjaan: '',
				status_perkawinan: '',
				tanggal_lahir: '',
				tempat_lahir: '',
				pendidikan_terakhir: '',
			},
			dataKeluarga:'none',
			nikStatTerlapor:null,
		});
	};

	handleClick = (event) => {
		alert(event);
	};

	simpan = (e) => {
		e.preventDefault();
		// this.state.status.btnForm = true;
		// this.forceUpdate();

		if (this.state.nikStatTerlapor) {
			// let alamat = '';
			// if (JENIS_DESA.toLowerCase() === 'kelurahan') {
			// 	alamat =
			// 		' RT. ' +
			// 		this.props.data.results.data_kk.data_rt.nama +
			// 		'/RW. ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 		', ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
			// 		', Kec. ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
			// 		', ' +
			// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
			// } else {
			// 	// alamat =
			// 	// 	' RT. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.nama +
			// 	// 	'/RW. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 	// 	', ' +
			// 	// 	JENIS_DUSUN +
			// 	// 	' ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 	// 	', ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 	// 	', Kec. ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
			// 	// 	', ' +
			// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;

			// 		if (this.props.data.results.data_kk.id_rt === '0') {
			// 			alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
			// 			", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
			// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
			// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
			// 		} else {
			// 			alamat =
			// 			" RT. " + 
			// 			this.props.data.results.data_kk.data_rt.nama +
			// 			"/RW. " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.nama +
			// 			", " + JENIS_DUSUN + " " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
			// 			", " + JENIS_DESA + " " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
			// 			", Kec. " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
			// 				.data_kecamatan.nama +
			// 			", " +
			// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
			// 				.data_kecamatan.data_kabkota.nama; 
			// 		}
			// }

			let addData = {
				id_jenis: this.props.id_jenis,

				nik_pelapor: this.props.data.results.id,
				nik: this.state.dt_keluarga.nik,
				kk: this.state.dt_keluarga.kk,
				nama_pemohon: this.state.dt_keluarga.nama,
				tempat_lahir: this.state.dt_keluarga.tempat_lahir,
				tanggal_lahir: this.state.dt_keluarga.tanggal_lahir,
				pekerjaan: this.state.dt_keluarga.pekerjaan,
				agama: this.state.dt_keluarga.agama,
				status_perkawinan: this.state.dt_keluarga.status_perkawinan,
				alamat: this.state.dt_keluarga.alamat,
				jenis_kelamin: this.state.dt_keluarga.jenis_kelamin,
				pendidikan_terakhir: this.state.dt_keluarga.pendidikan_terakhir,

				kewarganegaraan: 'Indonesia',
				tujuan_pembuatan: document.getElementById('tujuan_pembuatan').value,
				lokasi: document.getElementById('tempat_tinggal_sekarang').value,
			};
			//console.log(addData);

			// let psn = '';
			let resstat = 201;
			let metode = 'create';

			Post('surat-keterangan/grup/' + metode, null, addData, (res) => {
				this.onCloseModal('Basic');

				let dataAntrian = {
					nama: this.props.data.results.nama,
					nik: this.props.data.results.id,
					keterangan: '',
					uuid_surat:res.data.results,
				};

				if (res.status === resstat) {
					setTimeout(function () {
						Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
							//console.log("Hasil Update Status")
							//console.log(res2)
							if (res2.code === 201) {
								//console.log("Berhasil Ganti Status Menjadi Selesai")
	
								this.props.getAntrian('Surat Keterangan Belum Memiliki Rumah', 'berhasil', false, dataAntrian);
							}
							else{
								//console.log("Gagal Ganti Status Menjadi Selesai")
	
								this.props.getAntrian('Surat Keterangan Belum Memiliki Rumah', 'gagal');
							}
						});
					}.bind(this),1000)
					// this.props.getAntrian('Surat Keterangan Belum Memiliki Rumah', 'berhasil', false, dataAntrian);
				} else {
					this.props.getAntrian('Surat Keterangan Belum Memiliki Rumah', 'gagal');
				}
				// this.componentDidMount();
				// reset cache
				document.getElementById('tujuan_pembuatan').value = '';
				document.getElementById('tempat_tinggal_sekarang').value = '';
			});
		} else {
			this.props.getAntrian('Surat Keterangan Belum Memiliki Rumah', 'gagal', true);
		}
	};

	componentDidMount() {
		// Get list anggota keluarga from parent
		if (this.props.anggotaKeluarga) {
			this.setState({ frmNikKeluarga: this.props.anggotaKeluarga })
		}

		// Get("penduduk/kk/" + this.props.data.results.id_kk, null, data => {
		// 	console.log("Data Keluarga")
		// 	console.log(data)
		// 	if(data.results){
		// 	  let frmNikKeluarga = [];
		// 	  data.results.forEach(dt => {
		// 		frmNikKeluarga.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
		// 	  });
		// 	  this.setState({ frmNikKeluarga });
			  
		// 	  // For testing
		// 	  if (this.props.getListNIKTerlapor) {
		// 		this.props.getListNIKTerlapor(frmNikKeluarga)
		// 	  }
		// 	}
		// });
	}

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld === "nikTerlapor") {
				let newArray = this.state.frmNikKeluarga;
	
				newArray = newArray.filter(day => day !== e);

				this.setState({
					frmNikKeluarga:newArray,
				})
			}
		}
	  };
	
	getPenduduk = (e, sel) => {
		if (e) {
		  this.changeSelectValue(e, sel);
		  Get("penduduk/find", e.value, data => {
			let alamat = '';
			if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
			  alamat =
			  " RT. " +
			  data.results.data_kk.data_rt.nama +
			  "/RW. " +
			  data.results.data_kk.data_rt.data_rw.nama +
			  ", " +
			  data.results.data_kk.data_rt.data_rw.data_keldes.nama +
			  ", Kec. " +
			  data.results.data_kk.data_rt.data_rw.data_keldes
				.data_kecamatan.nama +
			  ", " +
			  data.results.data_kk.data_rt.data_rw.data_keldes
				.data_kecamatan.data_kabkota.nama; 
			} else {
			  if (data.results.data_kk.id_rt === '0') {
				alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
				", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
				", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
				", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
			  } else {
				alamat =
				" RT. " + 
				data.results.data_kk.data_rt.nama +
				"/RW. " +
				data.results.data_kk.data_rt.data_rw.nama +
				", " + JENIS_DUSUN + " " +
				data.results.data_kk.data_rt.data_rw.data_dusun.nama +
				", " + localStorage.getItem('JENIS_DESA') + " " +
				data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
				", Kec. " +
				data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
				  .data_kecamatan.nama +
				", " +
				data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
				  .data_kecamatan.data_kabkota.nama; 
			  }
			}
	
			
			if (sel === "nikTerlapor") {
				this.setState({
					dt_keluarga:{
						nik: e.value,
						kk: data.results.id_kk,
						nama: data.results.nama,
						agama: data.results.data_agama.nama,
						alamat: alamat,
						jenis_kelamin: data.results.jk,
						pekerjaan: data.results.data_pekerjaan.nama,
						status_perkawinan: data.results.status_perkawinan,
						tanggal_lahir: data.results.tanggal_lahir,
						tempat_lahir: data.results.tempat_lahir,
						pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
					},
					dataKeluarga: 'block',
				})
			}
			this.forceUpdate();
		  });
		} else {
			this.setState({
				dt_keluarga:{
					nik: '',
					kk: '',
					nama: '',
					agama: '',
					alamat: '',
					jenis_kelamin: '',
					pekerjaan: '',
					status_perkawinan: '',
					tanggal_lahir: '',
					tempat_lahir: '',
					pendidikan_terakhir: '',
				},
				dataKeluarga: 'none',
				nikStatTerlapor:null,
			});
		  	this.forceUpdate();
		}
	};

	changeSelectValue = (e, sel) => {
		if (e) {
		  switch (sel) {
			case "nikTerlapor":
			  this.setState({ nikStatTerlapor: e });
			  break;
			default:
			  // no default
			  break;
		  }
		} else {
		  switch (sel) {
			case "nikTerlapor":
			  this.setState({ nikStatTerlapor: e });
			  break;
			default:
			  // no default
			  break;
		  }
		}
	
		this.forceUpdate();
	};

	// EMBED KEyBOARD

	// on focus
	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				//console.log('Active input', inputName);
			}
		);
	};

	// end on focus

	// ketika Klik Keyboard atau mengisi data
	onChangeAll = (inputObj) => {
		this.setState({
			input: inputObj
		});

		//console.log('Input changed', inputObj);
	};

	onKeyPress = (button) => {
		//console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	// End ketika Klik Keyboard atau mengisi data

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}
		// this.setState({
		// 	display: '',
		// 	// onFocus:true

		//   });
		this.forceUpdate();
	};

	showKeyboard1 = () => {
		this.setState({
			display: 'none',
			regis: 'false'
		});
		this.forceUpdate();
	};

	// Input keyboard
	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard != 'undefined') {
					this.keyboard.setInput(inputVal);
				} else {
				}
			}
		);
	};

	onChangeInput1 = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				this.keyboard.setInput(inputVal);
			}
		);
	};

	// end input keyboard

	render() {
		const footerStyle = {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		};

		const { Basic } = this.state;

		return (
			<div>
				<Modal
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					// styles={{ modal: { marginBottom: '200px', width: '80%' } }}
					styles={{ modal: { width: '80%' } }}
				>
					<Form onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">Surat Keterangan Belum Memiliki Rumah</h5>
						</div>

						<div className="modal-body">
							{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label">No Antrian Anda</Label>
								<Col sm="9">
									<input
										className="form-control"
										type="text"
										id="no_antrian_anda"
										defaultValue="12"
										disabled
									/>
								</Col>
							</FormGroup> */}

							{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label">NIK Pemohon</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nik_pemohon"
										disabled
										type="text"
										value={this.props.data.results.id}
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label">Nama Lengkap Pemohon</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_lengkap_pemohon"
										disabled
										type="text"
										value={this.props.data.results.nama}
									/>
								</Col>
							</FormGroup> */}

							<FormGroup className="row">
								<Label
								className="col-sm-3 col-form-label"
								htmlFor="nik"
								>
								NIK Terlapor
								</Label>
								<Col sm="9">
								<div data-testid="selectNIKTerlapor">
									<Select
									inputId="nikTerlapor"
									classNamePrefix="select"
									onChange={e => {
										this.getPenduduk(e, "nikTerlapor");
									}}
									defaultValue={this.state.nikStatTerlapor}
									value={this.state.nikStatTerlapor}
									name="NIK"
									options={this.state.frmNikKeluarga}
									placeholder="Pilih NIK Terlapor"
									onInputChange={e => this.handleInputChangeNIK(e, "nikTerlapor")}
									isClearable
									/>
								</div>
								</Col>
							</FormGroup>

							<div style={{ display: this.state.dataKeluarga }}>
								<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Nama Terlapor
								</Label>
								<Col sm="9">
									<input
									className="form-control"
									id="nama"
									type="text"
									placeholder="Nama Terlapor"
									value={this.state.dt_keluarga.nama}
									readOnly

									style={{ backgroundColor:"#c9d1d8" }}
									disabled
									/>
								</Col>
								</FormGroup>
								<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Tempat Lahir
								</Label>
								<Col sm="9">
									<input
									className="form-control"
									id="tempat_lahir"
									type="text"
									placeholder="Tempat Lahir"
									value={this.state.dt_keluarga.tempat_lahir}
									readOnly

									style={{ backgroundColor:"#c9d1d8" }}
									disabled
									/>
								</Col>
								</FormGroup>
								<FormGroup className="row">
								<Label
									className="col-sm-3 col-form-label"
									htmlFor="inputEmail3"
								>
									Tanggal Lahir
								</Label>
								<Col sm="9">
									<input
									className="form-control"
									id="tanggal_lahir"
									type="text"
									placeholder="Tanggal Lahir"
									value={this.state.dt_keluarga.tanggal_lahir}
									readOnly

									style={{ backgroundColor:"#c9d1d8" }}
									disabled
									/>
								</Col>
								</FormGroup>
							</div>


							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label">Tujuan Pembuatan</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tujuan_pembuatan"
										placeholder="Tujuan Pembuatan"
										type="text"
										required
										autoFocus
										onFocus={() => this.setActiveInput('input3')}
										onClick={() => this.showKeyboard1()}
										value={this.state.input['input3']}
									/>
								</Col>
							</FormGroup>

							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label">Tempat Tinggal Sekarang</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="tempat_tinggal_sekarang"
										placeholder="contoh : Menumpang di rumah orang tua"
										type="text"
										required
										onFocus={() => this.setActiveInput('input4')}
										value={this.state.input['input4'] || ''}
										onChange={(e) => this.onChangeInput(e)}
									/>
								</Col>
							</FormGroup>

							<div className="modal-footer" style={footerStyle}>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-success">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>
					{/* {this.state.regis ? (
						<div style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 4, left: 0 }}>
							<Keyboard
								style={{ marginTop: '40px', zIndex: 3 }}
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
							/>
						</div>
					) : (
						<div />
					)} */}
				</Modal>

				{/* keyboard embed */}

				{this.state.display === '' ? (
					<div className="row" style={{ marginTop: '200px' }}>
						<div className="container-fluid" style={{ zIndex: 3, position: 'fixed', bottom: 0 }}>
							<div className="row">
								<Keyboard
									keyboardRef={(r) => (this.keyboard = r)}
									inputName={this.state.inputName}
									layoutName={this.state.layoutName}
									onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
									onKeyPress={(button) => this.onKeyPress(button)}
									inputPattern={this.state.inputPattern}
								/>
							</div>
						</div>
					</div>
				) : (
					<div />
				)}

				{/* end keyboard embed */}

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
						// background: 'linear-gradient(135deg, #72ec76, #86ff01)'
						// background: 'linear-gradient(135deg, #ffffff2e, #5a5a5aa1)'
						// background: 'linear-gradient(135deg, #484848, #000000)'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/belum-memiliki-rumah.png')}
								alt="logo"
								height="65"
								className="imgIcon-layanan"
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p className="mb-0" style={{ fontSize: '20px', fontWeight: '600', color: '#000000' }}>
								Belum memiliki rumah
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
