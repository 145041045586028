import React, { Component } from "react";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";

class Expired extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
  }
  
  render() {
    return (
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-body datatable-react">
                  <img
                    src={require("../../assets/images/banner/notif-expired.jpg")}
                    alt=""
                    style={{
                      maxHeight: "250px",
                      width: "100%",
                      borderRadius: "10px"
                    }}
                  />
                  <br/><br/><br/>
                  <p align="center">
                    <a href="https://wa.me/6282288993664"><button className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"><i className="fa fa-whatsapp"></i> &nbsp; Hubungi Kami</button></a>
                  </p>
                  <br/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
      </div>
    );
  }
}

export default Expired;