import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
//   PaginationProvider,
//   PaginationTotalStandalone,
//   PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
import Modal from "react-responsive-modal";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Col
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Datepicker from "react-datepicker";
import { Link, Redirect } from "react-router-dom";

// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
import { Post, Get, Delete, Nama_DESA, ALAMAT_KANTOR_DESA, KABUPATEN, KECAMATAN, PROVINSI,  JENIS_DUSUN } from "../function/Koneksi";
import { Tanggal, HariTanggal } from "../function/Format";
import { logo } from "./asset/base64img";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// Import the library

import "react-datetime/css/react-datetime.css";
import Datetime from "react-datetime";
//json file
// var data = require('../assets/json/company');

const frmDef = {
  uuid: "",
  id_jenis: "",
  nomor: "",
  tanggal_surat: new Date(),

  nik_anak: "",
  nama_anak: "",
  tempat_lahir_anak: "",
  tanggal_lahir_anak: new Date(),
  jk_anak: "",

  nik_ayah: "",
  nama_ayah: "",
  tempat_lahir_ayah: "",
  // tanggal_lahir_ayah: "",
  tanggal_lahir_ayah: null,
  alamat_ayah: "",
  pekerjaan_ayah: null,

  nik_ibu: "",
  nama_ibu: "",
  tempat_lahir_ibu: "",
  // tanggal_lahir_ibu: "",
  tanggal_lahir_ibu: null,
  alamat_ibu: "",
  pekerjaan_ibu: null,

  nik_pelapor: "",
  nama_pelapor: "",
  tempat_lahir_pelapor: "",
  tanggal_lahir_pelapor: "",
  pekerjaan_pelapor: "",
  agama_pelapor: "",
  alamat_pelapor: "", 
  hubungan_pelapor: "",

  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: ""
};

const cetak = {
  nama_desa: "",
  nomor_surat: "",
  tanggal_surat: new Date(),
  nik: "",
  nama: "",
  tempat_lahir: "",
  tanggal_lahir: "",
  jk: "",
  pekerjaan: "",
  agama: "",
  alamat: "",
  status_perkawinan: "",
  mas_kawin: 0,
  status_ttd: "",
  jabatan_ttd: "",
  jabatan_pengganti_ttd: "",
  nama_ttd: "",
  nip_ttd: ""
};

const dateFormat = require('dateformat');

class BasicTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pagination: [],
      status: {
        form: false,
        btnForm: false,
        btnAksi: [],
        dataPenduduk: "none",
        dataPenduduk2: "none",
        dataPenduduk3: "none"
      },
      awal: "",
      sebelum: "",
      hal: [],
      setelah: "",
      akhir: "",
      dt: frmDef,
      judul: "Tambah Surat Kelahiran",
      // ============ Select 2 ==============
      frmJam: [],
      jamStat: [],
      frmPkr: [],
      pkrStat: [],
      frmAgm: [],
      agmStat: [],
      frmNik: [],
      nikStat: null,
      frmNik2: [],
      nik2Stat: null,
      frmNik3: [],
      nik3Stat: null,
      shdk: null,
      // ===================================
      // ============ Alert ==============
      alert: null,
      show: false,
      basicTitle: "",
      basicType: "default",
      pesanAlert: "",
      successAlert: false,
      // ===================================
      // ============ Tanggal ==============
      tanggal_surat: new Date(),
      tanggal_lahir_anak: new Date(),
      tanggal_lahir: new Date(),
      tanggal_menikah: new Date(),
      // ===================================
      // ============ Cetak ==============
      cetak: cetak,
      // ===================================
      // =============== acc ===============
      formAcc: false,
      formSelectAcc: [
        { value: "Disetujui", label: "Disetujui" },
        { value: "Ditolak", label: "Ditolak" }
      ],
      frmTTD: [],
      listPekerjaan: [],
      accButton: [],
      accuuid: "",
      btnFormAcc: true,
      tableAcc: "none",
      //dispopsisi

      datDisposisi: [],
      riwayatDisposisi: [],
      formIbu: "none",
      formAyah: "none"
    };
  }

  // ================== Terbilang ===================

  terbilang = a => {
    // pastikan kita hanya berususan dengan tipe data numeric
    let angka = parseFloat(a);
    let hasil_bagi = 0;
    let hasil_mod = 0;
    // array bilangan
    // sepuluh dan sebelas merupakan special karena awalan 'se'
    const bilangan = [
      "",
      "Satu",
      "Dua",
      "Tiga",
      "Empat",
      "Lima",
      "Enam",
      "Tujuh",
      "Delapan",
      "Sembilan",
      "Sepuluh",
      "Sebelas"
    ];

    // pencocokan dimulai dari satuan angka terkecil
    if (angka < 12) {
      // mapping angka ke index array bilangan
      return bilangan[angka];
    } else if (angka < 20) {
      // bilangan 'belasan'
      // misal 18 maka 18 - 10 = 8
      return bilangan[angka - 10] + " Belas";
    } else if (angka < 100) {
      // bilangan 'puluhan'
      // misal 27 maka 27 / 10 = 2.7 (integer => 2) 'Dua'
      // untuk mendapatkan sisa bagi gunakan modulus
      // 27 mod 10 = 7 'Tujuh'
      hasil_bagi = parseInt(angka / 10);
      hasil_mod = angka % 10;
      return bilangan[hasil_bagi] + " Puluh " + bilangan[hasil_mod];
    } else if (angka < 200) {
      // bilangan 'seratusan' (itulah indonesia knp tidak satu ratus saja? :))
      // misal 151 maka 151 = 100 = 51 (hasil berupa 'puluhan')
      // daripada menulis ulang rutin kode puluhan maka gunakan
      // saja fungsi rekursif dengan memanggil fungsi terbilang(51)
      return "Seratus " + this.terbilang(angka - 100);
    } else if (angka < 1000) {
      // bilangan 'ratusan'
      // misal 467 maka 467 / 100 = 4,67 (integer => 4) 'Empat'
      // sisanya 467 mod 100 = 67 (berupa puluhan jadi gunakan rekursif terbilang(67))
      hasil_bagi = parseInt(angka / 100);
      hasil_mod = angka % 100;
      return bilangan[hasil_bagi] + " Ratus " + this.terbilang(hasil_mod);
    } else if (angka < 2000) {
      // bilangan 'seribuan'
      // misal 1250 maka 1250 - 1000 = 250 (ratusan)
      // gunakan rekursif terbilang(250)
      return "Seribu " + this.terbilang(angka - 100);
    } else if (angka < 1000000) {
      // bilangan 'ribuan' (sampai ratusan ribu
      hasil_bagi = parseInt(angka / 1000); // karena hasilnya bisa ratusan jadi langsung digunakan rekursif
      hasil_mod = angka % 1000;
      return this.terbilang(hasil_bagi) + " Ribu " + this.terbilang(hasil_mod);
    } else if (angka < 1000000000) {
      // bilangan 'jutaan' (sampai ratusan juta)
      // 'satu puluh' => SALAH
      // 'satu ratus' => SALAH
      // 'satu juta' => BENAR
      // @#$%^ WT*

      // hasil bagi bisa satuan, belasan, ratusan jadi langsung kita gunakan rekursif
      hasil_bagi = parseInt(angka / 1000000);
      hasil_mod = angka % 1000000;
      return this.terbilang(hasil_bagi) + " Juta " + this.terbilang(hasil_mod);
    } else if (angka < 1000000000000) {
      // bilangan 'milyaran'
      hasil_bagi = parseInt(angka / 1000000000);
      // karena batas maksimum integer untuk 32bit sistem adalah 2147483647
      // maka kita gunakan fmod agar dapat menghandle angka yang lebih besar
      hasil_mod = angka % 1000000000;
      return (
        this.terbilang(hasil_bagi) + " Milyar " + this.terbilang(hasil_mod)
      );
    } else if (angka < 1000000000000000) {
      // bilangan 'triliun'
      hasil_bagi = angka / 1000000000000;
      hasil_mod = angka % 1000000000000;
      return (
        this.terbilang(hasil_bagi) + " Triliun " + this.terbilang(hasil_mod)
      );
    } else {
      return "Wow...";
    }
  };
  // ===============================================

  // ================== Tanggal ===================

  gantiTgl = (date, fld) => {
    switch (fld) {
      case "tanggal_surat":
        this.setState({ tanggal_surat: date });
        break;
      case "tanggal_menikah":
        this.setState({ tanggal_menikah: date });
        break;
      case "tanggal_lahir_anak":
        if (typeof date._isValid !== 'undefined') {
          // this.setState({
          //   tanggal_lahir_anak: date.format("YYYY-MM-DD HH:mm:ss")
          // });
          let tgl = date.format('YYYY-MM-DD HH:mm:ss');
					this.setState({ tanggal_lahir_anak: new Date(tgl) });
        }
        break;
      case "tanggal_lahir_ayah":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD');
          this.setState({
            dt:{
              ...this.state.dt,
              tanggal_lahir_ayah: new Date(tgl),
            },
          })
          this.forceUpdate();
					// this.setState({ tanggal_lahir_anak: new Date(tgl) });
        }
        break;
      case "tanggal_lahir_ibu":
        if (typeof date._isValid !== 'undefined') {
          let tgl = date.format('YYYY-MM-DD');
          this.setState({
            dt:{
              ...this.state.dt,
              tanggal_lahir_ibu: new Date(tgl),
            },
          })
          this.forceUpdate();
					// this.setState({ tanggal_lahir_anak: new Date(tgl) });
        }
        break;
      default:
        // no default
        break;
    }
  };

  // ==============================================

  // ================== change input text ==================
  changeInputText = (e, fld) => {
    switch (fld) {
      case "tempat_lahir_anak":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            tempat_lahir_anak: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "nik_ayah":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            nik_ayah: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "nama_ayah":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            nama_ayah: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "tempat_lahir_ayah":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            tempat_lahir_ayah: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "tanggal_lahir_ayah":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            tanggal_lahir_ayah: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "pekerjaan_ayah":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            pekerjaan_ayah: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "alamat_ayah":
        this.setState({
          dt:{
            ...this.state.dt,
            alamat_ayah: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "nik_ibu":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            nik_ibu: e.target.value,
          },
        })
        this.forceUpdate();
        break;

      case "nama_ibu":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            nama_ibu: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "tanggal_lahir_ibu":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            tanggal_lahir_ibu: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "tempat_lahir_ibu":
        this.setState({
          dt:{
            ...this.state.dt,
            tempat_lahir_ibu: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      case "pekerjaan_ibu":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            pekerjaan_ibu: e.target.value,
          },
        })
        this.forceUpdate();
        break;

      case "alamat_ibu":
        //console.log(e.target.value);
        this.setState({
          dt:{
            ...this.state.dt,
            alamat_ibu: e.target.value,
          },
        })
        this.forceUpdate();
        break;
      default:
        // no default
        break;
    }
  };

  // =======================================================

  // ================== Sweet Alert ==================
  closeAlert = () => {
    this.setState({
      show: false,
      successAlert: false
    });
  };

  onRecieveInput = value => {
    this.setState({
      alert: (
        <SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
          You wrote: {value}
        </SweetAlert>
      )
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  // =================================================

  // ================== Ambil data dari db untuk table ==================
  fetch = (params = {}) => {
    let link = "";
    // let where = [];
    // if (params.where) {
    //   where = params.where;
    // }
    if (params.page) {
      switch (params.page) {
        case ">>":
          link =
            "?page=" +
            Math.ceil(
              this.state.pagination.total / this.state.pagination.per_page
            );
          break;
        case ">":
          link = "?page=" + (parseInt(this.state.pagination.current_page) + 1);
          break;
        case "<":
          link = "?page=" + (parseInt(this.state.pagination.current_page) - 1);
          break;
        case "<<":
          link = "?page=1";
          break;
        default:
          link = "?page=" + params.page;
          break;
      }
    }
    Get("surat-keterangan/kelahiran" + link, null, dtkat => {
      if(dtkat.results){
        this.setState({ data: dtkat.results.data, pagination: dtkat.results });
        this.pagination(dtkat.results);
        
        // For testing
        if (this.props.getListSuratKelahiran) {
          this.props.getListSuratKelahiran(dtkat.results.data)
        }
      }
    });
  };

  // Pagination
  pagination = data => {
    // const data = this.state.pagination;
    //console.log(data);
    let awal = "";
    let hal = [];
    let sebelum = "";
    let setelah = "";
    let akhir = "";
    if (data.total > 0) {
      let start = 1;
      let end = 5;
      let n = 0;
      let p = 0;
      if (data.current_page <= 3) {
        start = 1;
        end = 5;
        if (data.last_page > data.current_page) {
          n = data.current_page + 1;
        }
      } else {
        p = data.current_page - 1;
        n = data.current_page + 1;
        start = data.current_page - 2;
        end = data.current_page + 2;
      }
      if (end >= data.last_page - 2) {
        p = data.current_page - 1;
        if (start >= 5) {
          start = data.last_page - 4;
        }
        end = data.last_page;
      }

      for (let i = start; i <= end; i++) {
        let warna = "primary-custom";
        if (i === data.current_page) {
          warna = "danger";
        }
        hal.push(
          <a
            href={() => false}
            onClick={() => this.fetch({ page: i })}
            className={
              "btn btn-" + warna + " btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
            }
          >
            {i}
          </a>
        );
      }
      if (p > 0) {
        sebelum = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: p })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {"<"}
          </a>
        );
      }
      if (n > 0) {
        setelah = (
          <a
            href={() => false}
            onClick={() => this.fetch({ page: n })}
            className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
          >
            {">"}
          </a>
        );
      }
      awal = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: 1 })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {"<<"}
        </a>
      );
      akhir = (
        <a
          href={() => false}
          onClick={() => this.fetch({ page: data.last_page })}
          className="btn btn-primary-custom btn-square btn-sm pr-3 pl-3 pt-2 pb-2"
        >
          {">>"}
        </a>
      );
    }
    this.setState({ awal, sebelum, hal, setelah, akhir });
  };
  // Cari Data Table
  cariData = e => {
    if (e.key === "Enter") {
      let addSuratKeteranganKelahiran = {
        q: e.target.value
      };
      Post(
        "surat-keterangan/kelahiran",
        null,
        addSuratKeteranganKelahiran,
        data => {
          this.setState({
            data: data.data.results.data,
            pagination: data.data.results
          });
        }
      );
    }
  };

  // ========================================================================

  ///data ibu
  dataIbu = () => {
    //console.log(this.props.location.state);
    this.setState({
      dt:{
        ...this.state.dt,
        nik_ibu: "",
        nama_ibu: "",
        tempat_lahir_ibu: "",
        // this.state.dt.tanggal_lahir_ibu = "";
        tanggal_lahir_ibu: null,
        // this.state.dt.pekerjaan_ibu = "";
        pekerjaan_ibu: null,
        alamat_ibu: "",
      },
      status:{
        ...this.state.status,
        dataPenduduk3: this.state.formIbu === "" ? "none" : "",
      },
      formIbu: this.state.formIbu === "" ? "none" : "",
      nik3Stat: null,
    })
      // this.state.status.dataPenduduk3 === "" ? "none" : "";
    this.forceUpdate();
  };
  // data ayah
  dataAyah = () => {
    this.setState({
      dt:{
        ...this.state.dt,
        nik_ayah: "",
        nama_ayah: "",
        tempat_lahir_ayah: "",
        // this.state.dt.tanggal_lahir_ayah = "";
        tanggal_lahir_ayah: null,
        // this.state.dt.pekerjaan_ayah = "";
        pekerjaan_ayah: null,
        alamat_ayah: "",
      },
      status:{
        ...this.state.status,
        dataPenduduk: this.state.formAyah === "" ? "none" : "",
      },
      formAyah: this.state.formAyah === "" ? "none" : "",
      nikStat: null,
    })
    //console.log(this.props.location.state);
      // this.state.status.dataPenduduk === "" ? "none" : "";
    this.forceUpdate();
  };
  // ================== simpan data ==================
  simpan = e => {
    e.preventDefault();

    // //console.log(this.props.location.state);
    // //console.log(this.state.dt.tanggal_lahir_ibu);
    // //console.log(this.state.dt.tanggal_lahir_ayah);
    // //console.log(this.state.nikStat)

    let validasiAyah = false;
    let validasiIbu = false;

    // validasi ayah
    if (this.state.formAyah === "none") {
      if (this.state.nikStat) {
        validasiAyah = true;
      } else {  
        validasiAyah = false;
      }
    } else {  // this.state.formAyah === ''
      if (this.state.dt.nik_ayah !== '' && this.state.dt.pekerjaan_ayah !== null) {
        validasiAyah = true;
      } else {  
        validasiAyah = false;
      }
    }
    
    // validasi ibu
    if (this.state.formIbu === "none") {
      if (this.state.nik3Stat) {
        validasiIbu = true;
      } else {  
        validasiIbu = false;
      }
    } else {  // this.state.formIbu === ''
      if (this.state.dt.nik_ibu !== '' && this.state.dt.pekerjaan_ibu !== null) {
        validasiIbu = true;
      } else {  
        validasiIbu = false;
      }
    }

    if (this.state.formAyah !== "none" && this.state.dt.nik_ayah.toString().length !== 16) {
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Keterangan Kelahiran",
        pesanAlert: "NIK Ayah tidak terdiri dari 16 angka"
      });
    } else if (this.state.formIbu !== "none" && this.state.dt.nik_ibu.toString().length !== 16){
      this.setState({
        show: true,
        basicType: "warning",
        basicTitle: "Data Keterangan Kelahiran",
        pesanAlert: "NIK Ibu tidak terdiri dari 16 angka"
      });
    } else if (
      // ( this.state.formAyah === "none" && this.state.nikStat) ||
      // ( this.state.formIbu === "none" && this.state.nik3Stat) ||
      // this.state.nik2Stat &&
      // this.state.dt.nik_ayah !== "" &&
      // this.state.dt.nik_ibu !== "" &&
      validasiAyah &&
      validasiIbu &&
      this.state.dt.jk_anak !== '' &&
      this.state.shdk !== null 
    ) {

      let tgl_lhr_pl = this.state.dt.tanggal_lahir_pelapor.split("/");

      let tanggal_lahir_pelapor =
        tgl_lhr_pl[2] + "-" + tgl_lhr_pl[1] + "-" + tgl_lhr_pl[0];

      let addSuratKeteranganKelahiran = {
        uuid: document.getElementById("uuid").value,
        id_jenis: this.props.location.state.uuid,
        // nomor : document.getElementById("nomor").value,

        nama_anak: document.getElementById("nama_anak").value,
        jk_anak: this.state.dt.jk_anak,
        tempat_lahir_anak: this.state.dt.tempat_lahir_anak,
        tanggal_lahir_anak: dateFormat(this.state.tanggal_lahir_anak, 'yyyy-mm-dd HH:MM:ss'),

        nama_ayah: this.state.dt.nama_ayah,
        tempat_lahir_ayah: this.state.dt.tempat_lahir_ayah,
        // tanggal_lahir_ayah: tanggal_lahir_ayah,
        tanggal_lahir_ayah: dateFormat(this.state.dt.tanggal_lahir_ayah, 'yyyy-mm-dd'),
        nik_ayah: this.state.dt.nik_ayah,
        // pekerjaan_ayah: this.state.dt.pekerjaan_ayah,
        pekerjaan_ayah: this.state.dt.pekerjaan_ayah.label,
        alamat_ayah: this.state.dt.alamat_ayah,

        nik_ibu: this.state.dt.nik_ibu,
        nama_ibu: this.state.dt.nama_ibu,
        tempat_lahir_ibu: this.state.dt.tempat_lahir_ibu,
        // tanggal_lahir_ibu: tanggal_lahir_ibu,
        tanggal_lahir_ibu: dateFormat(this.state.dt.tanggal_lahir_ibu, 'yyyy-mm-dd'),
        // pekerjaan_ibu: this.state.dt.pekerjaan_ibu,
        pekerjaan_ibu: this.state.dt.pekerjaan_ibu.label,
        alamat_ibu: this.state.dt.alamat_ibu,

        nik_pelapor: this.state.nik2Stat.value,
        nama_pelapor: this.state.dt.nama_pelapor,
        tempat_lahir_pelapor: this.state.dt.tempat_lahir_pelapor,
        tanggal_lahir_pelapor: tanggal_lahir_pelapor,
        jenis_kelamin_pelapor: this.state.dt.jk_pelapor,
        pekerjaan_pelapor: this.state.dt.pekerjaan_pelapor,
        agama_pelapor: this.state.dt.agama_pelapor,
        alamat_pelapor: this.state.dt.alamat_pelapor,
        // hubungan_pelapor: document.getElementById("hubungan_pelapor").value,
        hubungan_pelapor: this.state.shdk.value,

        status_ttd: null
        // jabatan_ttd: "Kepala {Nama_DESA}",
        // nama_ttd: "Rian Adli, S.I.Kom",
        // nip_ttd: "11474242142"
      };
      
      //console.log("Data Simpan Pindah Nikah")
      //console.log(addSuratKeteranganKelahiran)

      let psn = "";
      let resstat = 204;
      let metode = "create";
      if (addSuratKeteranganKelahiran.uuid === "") {
        psn = "Tambah";
        resstat = 201;
        addSuratKeteranganKelahiran.uuid = null;
      } else {
        psn = "Ubah";
        resstat = 200;
        metode = "update";
      }
      Post(
        "surat-keterangan/kelahiran/" + metode,
        addSuratKeteranganKelahiran.uuid,
        addSuratKeteranganKelahiran,
        res => {
          
          // For testing
          if (this.props.resSubmit) {
            this.props.resSubmit(res.status)
          }

          this.tutupForm();
          this.setState({
            status:{
              ...this.state.status,
              btnForm: false,
              form: false,
            },
          })
          this.forceUpdate();
          if (res.status === resstat) {
            this.setState({
              show: true,
              basicType: "success",
              basicTitle: "Data Surat Keterangan Kelahiran",
              pesanAlert: "Berhasil " + psn + " Data",
              successAlert: true
            });
          } else {
            this.setState({
              show: true,
              basicType: "danger",
              basicTitle: "Data Surat Keterangan Kelahiran",
              pesanAlert: "Gagal " + psn + " Data"
            });
          }
          this.componentDidMount();
        }
      );
    } else {
      this.setState({
        show: true,
        basicType: "info",
        basicTitle: "Data Belum Lengkap",
        // pesanAlert: "Mohon pilih jenis kelamin anak, NIK ayah, NIK ibu, NIK Pelapor, dan hubungan pelapor"
        pesanAlert: "Mohon isi seluruh data dengan benar"
      });
    }
  };
  // ========================================================================

  // ============================== Ubah Data ===============================
  tambahData = e => {
    //console.log(this.props.location.state);

    this.setState({
      status:{
        ...this.state.status,
        dataPenduduk: "none",
        dataPenduduk2: "none",
        dataPenduduk3: "none",
      },
      judul: "Tambah Surat Keterangan Kelahiran",
      dt: frmDef,
      nikStat: null,
      nik2Stat: null,
      nik3Stat: null,
      tanggal_lahir_anak: new Date()
    });
    this.forceUpdate();
    this.bukaForm();
  };
  // ========================================================================
  // ========================================================================
  // list disposisi

  listDisposisi = data => {
    //console.log(data);
    Get("alur-disposisi/" + data.id_jenis, null, dtkat => {
      //console.log(dtkat);
      if (dtkat) {
        //console.log(dtkat.results);

        this.setState({
          idSurat: data.uuid,
          datDisposisi: dtkat.results,
          formDisposisi: true,
          riwayatDisposisi: data.data_status_disposisi
        });
      }
    });
  };
  //disposisi
  disposisi = (label, id) => {
    //console.log(label);
    Get("disposisi/kelahiran/" + label + "/" + id, null, dtkat => {
      if (dtkat.code === 201) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Disposisi",
          pesanAlert: "Berhasil  Disposisi Data"
        });
        this.componentDidMount();
        this.tutupForm();
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Disposisi",
          pesanAlert: "Disposisi telah dilakukan"
        });
        this.tutupForm();
      }
    });
    //console.log(id);
  };
  ubahData = id => {
    // this.state.status.btnAksi = true;
    this.setState({
      judul: "Ubah Surat Kelahiran",
    })
    this.forceUpdate();
    // this.componentDidMount();
    Get("surat-keterangan/kelahiran/find", id, data => {
      //console.log("1231231");

      Get("penduduk/find", data.results.nik_ayah, data1 => {
        //console.log(data1.results);
        // let ayahFrom = false;
        if (data1.results === null) {
          // ayahFrom = true;
          this.setState({
            formAyah: "",
          })
        }
        Get("penduduk/find", data.results.nik_ibu, data2 => {
          // let ibuFrom = false;
          //console.log(data);
          if (data2.results === null) {
            // ibuFrom = true;
            this.setState({
              formIbu: "",
            })
          }
          //console.log(data.results.tanggal_lahir_ayah);
          //console.log(data.results.tanggal_lahir_ibu);
          this.setState({
            dt:{
              ...this.state.dt,
              uuid: data.results.uuid,
              id_jenis: data.results.id_jenis,
              nomor: data.results.nomor,
              tanggal_surat: data.results.tanggal_surat,
              nama_anak: data.results.nama_anak,
              tempat_lahir_anak: data.results.tempat_lahir_anak,
              jk_anak: data.results.jk_anak,
              nik_ayah: data.results.nik_ayah,
              nama_ayah: data.results.nama_ayah,
              tempat_lahir_ayah: data.results.tempat_lahir_ayah,
              tanggal_lahir_ayah: new Date(data.results.tanggal_lahir_ayah),
              pekerjaan_ayah: {
                value: data.results.pekerjaan_ayah,
                label: data.results.pekerjaan_ayah,
              },
              alamat_ayah: data.results.alamat_ayah,
              nik_ibu: data.results.nik_ibu,
              nama_ibu: data.results.nama_ibu,
              tempat_lahir_ibu: data.results.tempat_lahir_ibu,
              tanggal_lahir_ibu: new Date(data.results.tanggal_lahir_ibu),
              pekerjaan_ibu: {
                value : data.results.pekerjaan_ibu,
                label : data.results.pekerjaan_ibu,
              },
              alamat_ibu: data.results.alamat_ibu,
              nik_pelapor: data.results.nik_pelapor,
              nama_pelapor: data.results.nama_pelapor,
              tanggal_lahir_pelapor: new Date(
                data.results.tanggal_lahir_pelapor
              ).toLocaleDateString("id-ID"),
              tempat_lahir_pelapor: data.results.tempat_lahir_pelapor,
              jk_pelapor: data.results.jenis_kelamin_pelapor,
              status_perkawinan_pelapor: data.results.status_perkawinan_pelapor,
              agama_pelapor: data.results.agama_pelapor,
              alamat_pelapor: data.results.alamat_pelapor,
              pekerjaan_pelapor: data.results.pekerjaan_pelapor,
              hubungan_pelapor: data.results.hubungan_pelapor,
              status_ttd: data.results.status_ttd,
              jabatan_ttd: data.results.jabatan_ttd,
              jabatan_pengganti_ttd: data.results.jabatan_pengganti_ttd,
              nama_ttd: data.results.nama_ttd,
              nip_ttd: data.results.nip_ttd,
            },
            status:{
              ...this.state.status,
              dataPenduduk: "block",
              dataPenduduk2: "block",
              dataPenduduk3: "block",
            },
            tanggal_surat: new Date(data.results.tanggal_surat),
            tanggal_lahir_anak: new Date(data.results.tanggal_lahir_anak),
            nikStat: { value: data.results.nik_ayah, label: data.results.nik_ayah + ' (' + data.results.nama_ayah + ')' },
            nik2Stat: { value: data.results.nik_pelapor, label: data.results.nik_pelapor + ' (' + data.results.nama_pelapor + ')' },
            nik3Stat: { value: data.results.nik_ibu, label: data.results.nik_ibu + ' (' + data.results.nama_ibu + ')' },
            shdk: {
              value: data.results.hubungan_pelapor,
              label: data.results.hubungan_pelapor,
            },
          })
            
          // this.state.dt.tanggal_lahir_ayah =
          //   ayahFrom !== true
          //     ? new Date(data.results.tanggal_lahir_ayah)
          //       .toLocaleDateString(
          //         "id-ID"
          //       )
          //     : data.results.tanggal_lahir_ayah;
          // this.state.dt.pekerjaan_ayah = data.results.pekerjaan_ayah;

          // this.state.dt.tanggal_lahir_ibu =
          //   ibuFrom !== true
          //     ? new Date(data.results.tanggal_lahir_ibu).toLocaleDateString(
          //         "id-ID"
          //       )
          //     : data.results.tanggal_lahir_ibu;
          // this.state.dt.pekerjaan_ibu = data.results.pekerjaan_ibu;
          
          // this.forceUpdate();
          this.bukaForm();
          //console.log(this.state.dt)
        });
      });
    });
  };
  // ========================================================================
  // ============================= Approve Data ===============================
  konfirmApprove = id => {
    this.setState({
      judul: "Persetujuan Data ",
      formAcc: true,
      btnFormAcc: true,
      idaccSurat: id
    });
  };
  // ========================================================================
  // ============================= Hapus Data ===============================
  konfirmHapus = id => {
    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText="Hapus"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          type="danger"
          title="Yakin ingin hapus data ?"
          onCancel={this.hideAlert}
          onConfirm={() => this.hapusData(id)}
        >
          Proses ini tidak dapat dibatalkan!
        </SweetAlert>
      )
    });
  };

  hapusData = id => {
    this.hideAlert();
    let psn = "Hapus";
    Delete("surat-keterangan/kelahiran/delete", id, res => {
      if (res === 200) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Data Surat Keterangan Kelahiran",
          pesanAlert: "Berhasil " + psn + " Data"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Data Surat Keterangan Kelahiran",
          pesanAlert: "Gagal " + psn + " Data"
        });
      }
      this.componentDidMount();
    });
  };
  // ========================================================================

  bukaForm = () => {
    this.setState({
      status:{
        ...this.state.status,
        form: true,
      },
    })
    this.forceUpdate();
  };

  tutupForm = () => {
    this.setState({
      dt:{
        ...this.state.dt,
        nama_ayah: "",
        // this.state.dt.tanggal_lahir_ayah = "";
        tanggal_lahir_ayah: null,
        tempat_lahir_ayah: "",
        // this.state.dt.pekerjaan_ayah = "";
        pekerjaan_ayah: null,
        alamat_ayah: "",
        nama_pelapor: "",
        tanggal_lahir_pelapor: "",
        tempat_lahir_pelapor: "",
        agama_pelapor: "",
        pekerjaan_pelapor: "",
        alamat_pelapor: "",
        jk_pelapor: "",
        status_perkawinan_pelapor: "",
        nama_ibu: "",
        // this.state.dt.tanggal_lahir_ibu = "";
        tanggal_lahir_ibu: null,
        tempat_lahir_ibu: "",
        // this.state.dt.pekerjaan_ibu = "";
        pekerjaan_ibu: null,
        alamat_ibu: "",
        tempat_lahir_anak: "",
        tanggal_lahir_anak: new Date(),
        nama_anak: "",
        jk_anak: "",
        nik_ayah: "",
        nik_ibu: "",
      },
      status:{
        ...this.state.status,
        dataPenduduk: "none",
        dataPenduduk2: "none",
        dataPenduduk3: "none",
        form: false,
      },
      nikStat: null,
      nik2Stat: null,
      nik3Stat: null,
      shdk: null,
      tanggal_lahir_anak: new Date(),
      formAyah: "none",
      formIbu: "none",
      accStat: null,
      tableAcc: "none",
      formDisposisi: false,
      formAcc: false,
    })

    this.forceUpdate();
  };

  persetujuan = (id, jawaban) => {
    this.hideAlert();
    Get(
      "surat-keterangan/kelahiran/persetujuan/" + jawaban + "/" + id,
      null,
      res => {
        if (res.code === 201) {
          this.setState({
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );
  };

  tombolAksi = (cell, row) => {
    // let btnApproval = false;
    // let btnCetak = false;
    let btnAksi = false;
    let status = "";
    row.data_status.forEach(dt => {
      status = dt.status;
    });
    if (status === "Diajukan") {
      // btnCetak = true;
      btnAksi = true;
      // btnApproval = true;
    } else {
      btnAksi = true;
      // btnApproval = true;
      if (status === "Ditolak") {
        // btnCetak = true;
      } else if (status === "Disetujui") {
        // btnCetak = true;
        btnAksi = false;
        // btnApproval = false;
      } else if ((status === "Menunggu Penerbitan") | (status === "Selesai")) {
        // btnAksi = false;
        // btnCetak = false;
        // btnApproval = false;
      } else {
        // btnCetak = true;
      }
    }
    return (
      <>
        {/* <Button size="xs" color="info" className="btn-icon" onClick={() => this.konfirmApprove(row.uuid)} disabled={btnApproval}><i className="fa fa-check"></i></Button> &nbsp;&nbsp; */}
        <Button
          size="xs"
          color="success"
          className="btn-icon"
          onClick={() => this.ubahData(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-pencil"></i>
        </Button>{" "}
        &nbsp;&nbsp;
        {/* <Button
          size="xs"
          color="danger"
          className="btn-icon"
          onClick={() => this.konfirmHapus(row.uuid)}
          disabled={btnAksi}
        >
          <i className="fa fa-trash"></i>
        </Button>{" "} */}
        {/* &nbsp;&nbsp; */}
        {/* <Button size="xs" color="info" className="btn-icon" onClick={(e) => this.cetakSurat(e, row)} disabled={btnCetak}><i className="fa fa-print"></i></Button> */}
        {/* &nbsp;&nbsp; */}
        {/* //list */}
        {/* <Button
          size="xs"
          color="info"
          className="btn-icon"
          onClick={e => this.listDisposisi(row)}
        >
          <i className="fa fa-list"></i>
        </Button> */}
      </>
    );
  };

  componentDidMount() {
    //console.log(this.props.location.state);
    if (this.props.location.state) {
      this.fetch({ page: null, where: [] });
      let frmJam = [];
      let jamnya = "";
      for (let i = 1; i <= 24; i++) {
        jamnya = i;
        if (i < 10) {
          jamnya = "0" + i;
        }
        jamnya += ":00";
        frmJam.push({ value: jamnya, label: jamnya });
      }
      this.setState({ frmJam });
      Get("pekerjaan/list", null, data => {
        let frmPkr = [];
        data.results.forEach(dt => {
          frmPkr.push({ value: dt.uuid, label: dt.nama });
        });
        this.setState({ frmPkr });
      });
      Get("agama/list", null, data => {
        let frmAgm = [];
        data.results.forEach(dt => {
          frmAgm.push({ value: dt.uuid, label: dt.nama });
        });
        this.setState({ frmAgm });
      });
      Get("penduduk", null, data => {
        let frmNik = [];
        let frmNik2 = [];
        let frmNik3 = [];
        data.results.data.forEach(dt => {
          frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          frmNik2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          frmNik3.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
        });
        this.setState({ frmNik, frmNik2, frmNik3 });

        // For testing
        if (this.props.getListNIK) {
          this.props.getListNIK(frmNik)
        }
      });
      Get("tanda-tangan/list", null, data => {
        data.results.forEach((dt, i) => {
          if (dt.aktif === 1) {
            let temp = this.state.accButton.map(x => 1);
            temp[i] = 0;
            this.setState({
              accButton: temp,
              accuuid: dt.uuid,
            })
          }
        });
        this.forceUpdate();
        this.setState({ frmTTD: data.results });
      });
      Get("pekerjaan/list", null, data => {
        let listPekerjaan = []
        data.results.forEach((dt, i) => {
          listPekerjaan.push({ value: dt.uuid, label: dt.nama })
        });
        this.setState({ listPekerjaan });
         
        // For testing
        if (this.props.getListPekerjaan) {
          this.props.getListPekerjaan(listPekerjaan)
        }
      });
    } else {
      this.setState({ redirect: true });
    }
  }

  handleInputChangeNIK = (e, fld) => {
    if (e) {
      if (fld === "nik") {
        let nikStat = { value: e, label: e };
        let param = {
          nik: e
        };
        Post("penduduk", null, param, datanik => {
          let frmNik = [];
          datanik.data.results.data.forEach(dt => {
            frmNik.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
          });
          this.setState({ frmNik, nikStat });
        });
      } else {
        if (fld === "nik2") {
          let nik2Stat = { value: e, label: e };
          let param = {
            nik: e
          };
          Post("penduduk", null, param, datanik => {
            let frmNik2 = [];
            datanik.data.results.data.forEach(dt => {
              frmNik2.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
            });
            this.setState({ frmNik2, nik2Stat });
          });
        } else {
          let nik3Stat = { value: e, label: e };
          let param = {
            nik: e
          };
          Post("penduduk", null, param, datanik => {
            let frmNik3 = [];
            datanik.data.results.data.forEach(dt => {
              frmNik3.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
            });
            this.setState({ frmNik3, nik3Stat });
          });
        }
      }
    }
  };

  getPenduduk = (e, sel, jns) => {
    // this.state.status.btnForm = true;
    this.forceUpdate();
    if (e) {
      this.changeSelectValue(e, sel);
      Get("penduduk/find", e.value, data => {
        //console.log(data)
        // const data_rt = data.results.data_kk.data_rt;
        // const data_rw = data_rt.data_rw;
        // const data_dusun = data_rw.data_dusun;
        // const data_desa = data_dusun.data_keldes;
        // const data_kecamatan = data_desa.data_kecamatan;
        // const data_kabkota = data_kecamatan.data_kabkota;
        let alamat = '';
        // if (JENIS_DESA.toLowerCase() === 'kelurahan') {
        //   alamat =
        //   " RT. " +
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_keldes
        //     .data_kecamatan.data_kabkota.nama; 
        // } else {
        //   alamat =
        //   " RT. " + 
        //   data.results.data_kk.data_rt.nama +
        //   "/RW. " +
        //   data.results.data_kk.data_rt.data_rw.nama +
        //   ", " + JENIS_DUSUN + " " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
        //   ", Kec. " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.nama +
        //   ", " +
        //   data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
        //     .data_kecamatan.data_kabkota.nama; 
        // }
        if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
          alamat =
          " RT. " +
          data.results.data_kk.data_rt.nama +
          "/RW. " +
          data.results.data_kk.data_rt.data_rw.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes.nama +
          ", Kec. " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.nama +
          ", " +
          data.results.data_kk.data_rt.data_rw.data_keldes
            .data_kecamatan.data_kabkota.nama; 
        } else {
          if (data.results.data_kk.id_rt === '0') {
            alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
            ", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
            ", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
          } else {
            alamat =
            " RT. " + 
            data.results.data_kk.data_rt.nama +
            "/RW. " +
            data.results.data_kk.data_rt.data_rw.nama +
            ", " + JENIS_DUSUN + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.nama +
            ", " + localStorage.getItem('JENIS_DESA') + " " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
            ", Kec. " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.nama +
            ", " +
            data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
              .data_kecamatan.data_kabkota.nama; 
          }
        }

        if (jns === "pelapor") {
          this.setState({
            dt:{
              ...this.state.dt,
              nama_pelapor: data.results.nama,
              tanggal_lahir_pelapor: new Date(
                data.results.tanggal_lahir
              ).toLocaleDateString("id-ID"),
              tempat_lahir_pelapor: data.results.tempat_lahir,
              jk_pelapor: data.results.jk,
              status_perkawinan_pelapor: data.results.status_perkawinan,
              agama_pelapor: data.results.data_agama.nama,
              pekerjaan_pelapor: data.results.data_pekerjaan.nama,
              alamat_pelapor: alamat,
            },
            status:{
              ...this.state.status,
              dataPenduduk2: "block",
            },
            tanggal_lahir_pelapor: data.results.tanggal_lahir,
          })

          // For testing
          if (this.props.selectOnChange2) {
            this.props.selectOnChange2(this.state.dt)
          }
        } else {
          if (jns === "ibu") {
            this.setState({
              dt:{
                ...this.state.dt,
                nama_ibu: data.results.nama,
                nik_ibu: data.results.id,
                tanggal_lahir_ibu: new Date(
                  data.results.tanggal_lahir
                ),
                // .toLocaleDateString("id-ID");
                tempat_lahir_ibu: data.results.tempat_lahir,
                // this.state.dt.pekerjaan_ibu = data.results.data_pekerjaan.nama;
                pekerjaan_ibu: {
                  value : data.results.data_pekerjaan.nama,
                  label : data.results.data_pekerjaan.nama,
                },
                alamat_ibu: alamat,
              },
              status:{
                ...this.state.status,
                dataPenduduk3: "block",
              },
              tanggal_lahir_ibu: data.results.tanggal_lahir,
            })

            // For testing
            if (this.props.selectOnChange3) {
              this.props.selectOnChange3(this.state.dt)
            }
          } else {
            Get("catatan-penduduk/nik", data.results.id, dpddk => {
              let pctt = 0;
              let catatan = "";
              if (dpddk.results.data) {
                dpddk.results.data.forEach(dtp => {
                  if (dtp.status === 0 && dtp.penalti === 1) {
                    pctt = 1;
                    catatan = dtp.catatan;
                  }
                });
              }
              if (pctt === 0) {
                this.setState({
                  dt:{
                    ...this.state.dt,
                    nama_ayah: data.results.nama,
                    nik_ayah: data.results.id,
                    tanggal_lahir_ayah: new Date(
                      data.results.tanggal_lahir
                    ),
                    // .toLocaleDateString("id-ID");
                    tempat_lahir_ayah: data.results.tempat_lahir,
                    // this.state.dt.pekerjaan_ayah = data.results.data_pekerjaan.nama;
                    pekerjaan_ayah: {
                      value : data.results.data_pekerjaan.nama,
                      label : data.results.data_pekerjaan.nama,
                    },
                    alamat_ayah: alamat,
                  },
                  status:{
                    ...this.state.status,
                    dataPenduduk: "block",
                  },
                  tanggal_lahir_ayah: data.results.tanggal_lahir,
                })
                // this.state.status.btnForm = false;
                this.forceUpdate();
              } else {
                // this.state.status.btnForm = true;
                this.setState({
                  nikStat: null,
                  dt:{
                    ...this.state.dt,
                    // this.state.frmNik = [];
                    nama_ayah: "",
                    // this.state.dt.tanggal_lahir_ayah = "";
                    tanggal_lahir_ayah: null,
                    tempat_lahir_ayah: "",
                    pekerjaan_ayah: "",
                    alamat_ayah: "",
                  },
                  status:{
                    ...this.state.status,
                    dataPenduduk: "none",
                  },
                  show: true,
                  basicType: "danger",
                  basicTitle: "Peringatan Catatan Warga",
                  pesanAlert:
                  "Warga ini masih memiliki catatan yang belum diselesaikan sebagai berikut. \r" +
                  catatan
                });
                this.forceUpdate();
              }
               
              // For testing
              if (this.props.selectOnChange) {
                this.props.selectOnChange(this.state.dt)
              }
            });
          }
        }
        // this.state.status.btnForm = true;

        // if (
        //   this.state.nikStat !== null &&
        //   this.state.nik2Stat !== null &&
        //   this.state.nik3Stat !== null
        // ) {
        //   this.state.status.btnForm = false;
        // }
        this.forceUpdate();
      });
    } else {
      if (jns === "pelapor") {
        this.setState({
          dt:{
            ...this.state.dt,
            nama_pelapor: "",
            tanggal_lahir_pelapor: "",
            tempat_lahir_pelapor: "",
            agama_pelapor: "",
            pekerjaan_pelapor: "",
            alamat_pelapor: "",
            jk_pelapor: "",
            status_perkawinan_pelapor: "",
          },
          status:{
            ...this.state.status,
            dataPenduduk2: "none",
          },
          nik2Stat: null,
        })
      } else {
        if (jns === "ibu") {
          this.setState({
            dt:{
              ...this.state.dt,
              nama_ibu: "",
              // this.state.dt.tanggal_lahir_ibu = "";
              tanggal_lahir_ibu: null,
              tempat_lahir_ibu: "",
              pekerjaan_ibu: null,
              alamat_ibu: "",
            },
            status:{
              ...this.state.status,
              dataPenduduk3: "none",
            },
            nik3Stat: null,
          })
        } else {
          this.setState({
            dt:{
              ...this.state.dt,
              nama_ayah: "",
              // this.state.dt.tanggal_lahir_ayah = "";
              tanggal_lahir_ayah: null,
              tempat_lahir_ayah: "",
              pekerjaan_ayah: null,
              alamat_ayah: "",
            },
            status:{
              ...this.state.status,
              dataPenduduk: "none",
            },
            nikStat: null,
          })
        }
      }
      // this.state.status.btnForm = true;

      this.forceUpdate();
    }
  };
  changeSelectValue = (e, sel) => {
    if (e) {
      switch (sel) {
        case "jam":
          this.setState({ 
            jamStat: e, 
            dt:{
              ...this.state.dt,
              jam: e.value,
            },
          });
          break;
        case "pekerjaan":
          this.setState({ 
            pkrStat: e, 
            dt:{
              ...this.state.dt,
              pekerjaan: e.label,
            },
          });
          break;
        case "agama":
          this.setState({ 
            agmStat: e, 
            dt:{
              ...this.state.dt,
              agama: e.label,
            },
          });
          break;
        case "nik":
          this.setState({ 
            nikStat: e, 
            dt:{
              ...this.state.dt,
              nik_ayah: e.value,
            },
          });
          break;
        case "nik2":
          this.setState({ 
            nik2Stat: e, 
            dt:{
              ...this.state.dt,
              nik_pelapor: e.value,
            },
          });
          break;
        case "nik3":
          this.setState({ 
            nik3Stat: e, 
            dt:{
              ...this.state.dt,
              nik_ibu: e.value,
            },
          });
          break;
        case "shdk":
          this.setState({ shdk: e });
          
          // For testing
          if (this.props.selectOnChangeShdk) {
            this.props.selectOnChangeShdk(e)
          }
          break;
        case "pekerjaan_ayah":
          this.setState({ dt : {...this.state.dt, pekerjaan_ayah : e} })
          // For testing
          if (this.props.selectOnChangePekerjaanAyah) {
            this.props.selectOnChangePekerjaanAyah(e)
          }
          break;
        case "pekerjaan_ibu":
          this.setState({ dt : {...this.state.dt, pekerjaan_ibu : e} })
           // For testing
           if (this.props.selectOnChangePekerjaanIbu) {
            this.props.selectOnChangePekerjaanIbu(e)
          }
          break;
        default:
          // no default
          break;
      }
    } else {
      switch (sel) {
        case "jam":
          this.setState({ 
            jamStat: e, 
            dt:{
              ...this.state.dt,
              jam: 0,
            },
          });
          break;
        case "pekerjaan":
          this.setState({ 
            pkrStat: e, 
            dt:{
              ...this.state.dt,
              pekerjaan: "",
            },
          });
          break;
        case "agama":
          this.setState({ 
            agmStat: e, 
            dt:{
              ...this.state.dt,
              agama: "",
            },
          });
          break;
        case "nik":
          this.setState({ 
            nikStat: e, 
            dt:{
              ...this.state.dt,
              nik_ayah: 0,
            },
          });
          break;
        case "nik2":
          this.setState({ 
            nik2Stat: e, 
            dt:{
              ...this.state.dt,
              nik_pelapor: 0,
            },
          });
          break;
        case "nik3":
          this.setState({ 
            nik3Stat: e, 
            dt:{
              ...this.state.dt,
              nik_ibu: 0,
            },
          });
          break;
        case "shdk":
          this.setState({ shdk: null });
          break;
        case "pekerjaan_ayah":
          this.setState({ dt : {...this.state.dt, pekerjaan_ayah : null} })
          break;
        case "pekerjaan_ibu":
          this.setState({ dt : {...this.state.dt, pekerjaan_ibu : null} })
          break;
        default:
          // no default
          break;
      }
    }

    this.forceUpdate();
  };

  ubahJk = e => {
    this.setState({
      dt:{
        ...this.state.dt,
        jk_anak: document.getElementById("jk_anak").value,
      },
    })
    // For testing
    if (this.props.selectOnChangeJenisKelaminAnak) {
      this.props.selectOnChangeJenisKelaminAnak(e)
    }
    this.forceUpdate();
  };

  cetakSurat = (e, data) => {
    e.preventDefault();
    this.setState({
      cetak: {
        nama_desa: Nama_DESA,
        tanggal_surat: data.tanggal_surat,
        nomor_surat: data.nomor,

        nama_anak: data.nama_anak.toLowerCase(),
        tanggal_lahir_anak: data.tanggal_lahir_anak,
        tempat_lahir_anak: data.tempat_lahir_anak.toLowerCase(),
        jk_anak: data.jk_anak.toLowerCase(),

        nik_ayah: data.nik_ayah,
        nama_ayah: data.nama_ayah.toLowerCase(),
        tanggal_lahir_ayah: data.tanggal_lahir_ayah,
        tempat_lahir_ayah: data.tempat_lahir_ayah.toLowerCase(),
        alamat_ayah: data.alamat_ayah.toLowerCase(),
        pekerjaan_ayah: data.pekerjaan_ayah.toLowerCase(),

        nik_ibu: data.nik_ibu.toLowerCase(),
        nama_ibu: data.nama_ibu.toLowerCase(),
        tanggal_lahir_ibu: Tanggal(data.tanggal_lahir_ibu),
        tempat_lahir_ibu: data.tempat_lahir_ibu.toLowerCase(),
        alamat_ibu: data.alamat_ibu.toLowerCase(),
        pekerjaan_ibu: data.pekerjaan_ibu.toLowerCase(),

        nik_pelapor: data.nik_pelapor,
        nama_pelapor: data.nama_pelapor.toLowerCase(),
        tanggal_lahir_pelapor: HariTanggal(data.tanggal_lahir_pelapor),
        tempat_lahir_pelapor: data.tempat_lahir_pelapor.toLowerCase(),
        jk_pelapor: data.jenis_kelamin_pelapor,
        alamat_pelapor: data.alamat_pelapor.toLowerCase(),
        agama_pelapor: data.agama_pelapor.toLowerCase(),
        pekerjaan_pelapor: data.pekerjaan_pelapor.toLowerCase(),
        hubungan_pelapor: data.hubungan_pelapor.toLowerCase(),

        status_ttd: data.status_ttd,
        jabatan_ttd: data.jabatan_ttd,
        jabatan_pengganti_ttd: data.jabatan_pengganti_ttd,
        nama_ttd: data.nama_ttd,
        nip_ttd: data.nip_ttd ? "NIP. " + data.nip_ttd : ""
      }
    });
    setTimeout(function() {
      const content = document.getElementById("cetak-surat").innerHTML;
      const mywindow = window.open("", "Print", "height=600,width=1000");

      mywindow.document.write("<html><head><title>Print</title>");
      mywindow.document.write("</head><body >");
      mywindow.document.write(content);
      mywindow.document.write("</body></html>");

      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
      setTimeout(function() {
        mywindow.close();
      }, 1000);
    }, 100);
  };

  // cek redirect jiksa state kosong
  renderRedirect = () => {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
  };

  //change select
  changeAcc = (e, sel) => {
    if (e) {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      if (e.value === "Disetujui") {
        this.setState({
          tableAcc: "",
          btnFormAcc: true,
        })
        if (this.state.accButton.length > 0) {
          this.setState({
            btnFormAcc: false,
          })
        }
      } else {
        this.setState({
          tableAcc: "none",
          btnFormAcc: false,
        })
      }
    } else {
      switch (sel) {
        case "status-acc":
          this.setState({
            accStat: e,
          })
          break;
        default:
          // no default
          break;
      }
      this.setState({
        tableAcc: "none",
        btnFormAcc: true,
      })
    }
    this.setState({
      status:{
        ...this.state.status,
        select: false,
      },
    })
    this.forceUpdate();
  };

  simpanAcc = e => {
    e.preventDefault();
    this.setState({
      btnFormAcc: true,
    })

    let add = {
      tanggal_surat: new Date(this.state.tanggal_surat)
        .toISOString()
        .slice(0, 10),
      uuid: this.state.accuuid
    };
    Post(
      "surat-keterangan/kelahiran/persetujuan/Selesai/" + this.state.idaccSurat,
      null,
      add,
      res => {
        if (res.status === 201) {
          this.setState({
            formAcc: false,
            show: true,
            basicType: "success",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Berhasil update status"
          });
        } else {
          this.setState({
            btnFormAcc: false,
            show: true,
            basicType: "warning",
            basicTitle: "Menyetujui Surat",
            pesanAlert: "Gagal update status"
          });
        }
        this.componentDidMount();
      }
    );

    this.tutupForm();
  };

  pilih = (uuid, id) => {
    let temp = this.state.accButton.map(x => 1);
    temp[id] = 0;
    this.setState({
      accButton: temp,
      accuuid: uuid,
    })
    if (this.state.accStat) {
      this.setState({
        btnFormAcc: false,
      })
    }
    this.forceUpdate();
  };

  render() {
    //console.log("Data Render")
    //console.log("Data Kelahiran")
    //console.log("====================")
    // var size = Math.ceil(
    //   this.state.pagination.total / this.state.pagination.per_page
    // );
    // var size = this.state.pagination.total;
    // var from = this.state.pagination.from;
    // var page = this.state.pagination.current;
    // var to = this.state.pagination.to;
    // var total = this.state.pagination.total;
    var data = this.state.data;

    // const frmDef = {
    //   uuid: "",
    //   id_jenis: "",
    //   nomor: "",
    //   tanggal_surat: new Date(),

    //   nik_anak: "",
    //   nama_anak: "",
    //   tempat_lahir_anak: "",
    //   tanggal_lahir_anak: new Date(),
    //   jk_anak: "",

    //   nik_ayah: "",
    //   nama_ayah: "",
    //   tempat_lahir_ayah: "",
    //   tanggal_lahir_ayah: "",
    //   alamat_ayah: "",

    //   nik_ibu: "",
    //   nama_ibu: "",
    //   tempat_lahir_ibu: "",
    //   tanggal_lahir_ibu: "",
    //   alamat_ibu: "",

    //   nik_pelapor: "",
    //   nama_pelapor: "",
    //   tempat_lahir_pelapor: "",
    //   tanggal_lahir_pelapor: "",
    //   pekerjaan_pelapor: "",
    //   agama_pelapor: "",
    //   alamat_pelapor: "",
    //   hubungan_pelapor: "",

    //   status_ttd: "",
    //   jabatan_ttd: "",
    //   jabatan_pengganti_ttd: "",
    //   nama_ttd: "",
    //   nip_ttd: ""
    // };

    // const { SearchBar } = Search;
    const columns = [
      {
        dataField: "tanggal_surat",
        headerAlign: 'center',
				align: 'center',
        text: "Tanggal",
        formatter: (cell, obj) => {
          return obj.tanggal_surat === null
            ? "-"
            : new Date(obj.tanggal_surat).toLocaleDateString("id-ID");
        },
        isDummyField: true,
        sort: true
      },
      {
        dataField: "nomor",
        headerAlign: 'center',
				align: 'center',
        text: "Nomor surat",
        formatter: (cell, obj) => {
          return obj.nomor === "" ? "-" : obj.nomor;
        },
        sort: true
      },
      {
        dataField: "nama_anak",
        headerAlign: 'center',
				align: 'center',
        text: "Nama",
        sort: true
      },
      {
        dataField: "ttl",
        headerAlign: 'center',
				align: 'center',
        text: "TTL",
        align: "right",
        isDummyField: true,
        formatter: (cell, obj) => {
          return (
            obj.tempat_lahir_anak + ", " + HariTanggal(obj.tanggal_lahir_anak)
          );
        },
        sort: true
      },
      {
        dataField: "nama_ayah",
        headerAlign: 'center',
				align: 'center',
        text: "Nama Ayah",
        sort: true
      },
      {
        dataField: "nama_ibu",
        headerAlign: 'center',
				align: 'center',
        text: "Nama Ibu",
        sort: true
      },
      {
        dataField: "status",
        headerAlign: 'center',
				align: 'center',
        text: "Status",
        isDummyField: true,
        formatter: (cell, obj) => {
          let status = "-";
          obj.data_status.forEach(dt => {
            status = dt.status;
          });
          return status;
        },
        sort: true
      },
      {
        dataField: "aksi",
        headerAlign: 'center',
				align: 'center',
        text: "Aksi",
        isDummyField: true,
        csvExport: false,
        formatter: this.tombolAksi
      }
    ];

    // const defaultSorted = [
    //   {
    //     dataField: "nama",
    //     order: "asc"
    //   }
    // ];

    // const pageButtonRenderer = ({
    //   page,
    //   active,
    //   disable,
    //   title,
    //   onPageChange
    // }) => {
    //   const handleClick = e => {
    //     e.preventDefault();
    //     this.fetch({ where: [], page: page });
    //   };
    //   var classname = "";
    //   classname = "btn btn-primary-custom";
    //   return (
    //     <li className="page-item pl-1" key={page}>
    //       <a href="#" onClick={handleClick} className={classname}>
    //         {page}
    //       </a>
    //     </li>
    //   );
    // };

    // const options = {
    //   alwaysShowAllBtns: true,
    //   pageButtonRenderer,
    //   // custom: true,
    //   paginationTotalRenderer: () => (
    //     <span className="react-bootstrap-table-pagination-total">
    //       Showing {from} to {to} of {total} Results
    //     </span>
    //   ),
    //   paginationSize: size,
    //   totalSize: total
    // };
    let no = 0;
    const tableTTD = this.state.frmTTD.map((dt, i) => {
      no++;
      return (
        <tr key={i}>
          <td>{no}</td>
          <td>{dt.jabatan}</td>
          <td>{dt.jabatan_pengganti ? dt.jabatan_pengganti : "-"}</td>
          <td>{dt.nama}</td>
          <td>{dt.nip ? dt.nip : "-"}</td>
          <td>{dt.status ? dt.status : "-"}</td>
          <td>
            <Button
              size="xs"
              onClick={() => this.pilih(dt.uuid, i)}
              color={
                this.state.accButton.length > 0
                  ? this.state.accButton[i] === 0
                    ? "default"
                    : "danger"
                  : "danger"
              }
              className="btn-icon"
            >
              {this.state.accButton.length > 0
                ? this.state.accButton[i] === 0
                  ? "Dipilih"
                  : "Pilih"
                : "Pilih"}
            </Button>
          </td>
        </tr>
      );
    });

    return (
      <div data-testid="page-surat keterangan kelahiran">
        {this.renderRedirect()}
        {/* <Breadcrumb title="Data Surat Keterangan Kelahiran" parent="Admin" /> */}
        <SweetAlert
          show={this.state.show}
          type={this.state.basicType}
          title={this.state.basicTitle}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="info"
          onCancel={this.closeAlert}
          showConfirm={false}
        >
          {this.state.pesanAlert}
          {this.state.successAlert ? (
            <div>
              <br />
              <Button
                color="success"
                onClick={() => {
                  this.closeAlert();
                  // this.bukaForm();
                  this.tambahData();
                }}
              >
                Tambah Data
              </Button>{" "}
              <Button
                // color="info"
                className="btn btn-secondary-custom"
                onClick={() => {
                  this.closeAlert();
                }}
              >
                <Link
                  data-testid="btn-eksekusi_surat"
                  onClick={() => {
                    // For testing
                    if (this.props.clickEksekusiSurat) {
                      this.props.clickEksekusiSurat()
                    }
                  }}
                  to={{
                    pathname: process.env.PUBLIC_URL + "/admin/disposisi",
                    tabProps: "2"
                  }}
                >
                  <span style={{ color: "white" }}>Eksekusi Surat</span>
                </Link>
              </Button>
            </div>
          ) : (
            ""
          )}
        </SweetAlert>

        {this.state.alert}

        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card mb-0">
                <div className="card-header">
                  <div className="row">
                    <div className="col-sm-6">
                      {/* <h5>Data Surat Keterangan Kelahiran</h5> */}
                      {/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
                    </div>
                    <div className="col-sm-6 text-right">
                      <Button
                        data-testid="tblCreateSuratKelahiran"
                        size="sm"
                        color="success"
                        // className="btn-square"
                        onClick={this.tambahData}
                      >
                        Tambah Data
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="card-body datatable-react">
                  <BootstrapTable keyField="id" data={data} columns={columns} />
                  <div className="pull-right text-white">
                    {this.state.awal}
                    {this.state.sebelum}
                    {this.state.hal.map(dt => {
                      return dt;
                    })}
                    {this.state.setelah}
                    {this.state.akhir}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <Modal
          open={this.state.status.form}
          onClose={this.tutupForm}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form 
            data-testid="form-create" 
            className="theme-form" 
            onSubmit={this.simpan}
          >
            <div className="modal-header">
              <h5 className="modal-title">{this.state.judul}</h5>
            </div>
            <div className="modal-body">
              <p>
                Data identitas akan terisi secara otomatis saat NIK dipilih.
                Jika ada ketidak sesuaian data, silakan ubah di data master
                penduduk.
              </p>
              <input
                className="form-control"
                id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              {/* <FormGroup className="row">
                                <Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">Nomor</Label>
                                <Col sm='9'>
                                    <input className="form-control" id="nomor" type="text" placeholder="Nomor Surat" defaultValue={this.state.dt.nomor} required/>
                                </Col>
                            </FormGroup> */}

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="tanggal_lahir_anak"
                >
                  Tanggal Lahir Anak
                </Label>
                <Col sm="9">
                  <Datetime
                    inputProps={{ "data-testid": "tanggal_lahir_anak", placeholder: 'Tanggal Lahir Anak', required: true }}
                    inputId="tanggal_lahir_anak"
                    name="tanggal_lahir_anak"
                    locale="id-ID"
                    timeFormat="HH:mm"
                    dateFormat="DD/MM/yyyy"
                    input={true}
                    closeOnTab={true}
                    initialValue={this.state.tanggal_lahir_anak}
                    onChange={e => {
                      this.gantiTgl(e, "tanggal_lahir_anak");
                      // this.setState({ tanggal_lahirkk:})
                      // //console.log(e.format('YYYY-MM-DD'))
                    }}
                  />
                  {/* <Datepicker
                    className="form-control"
                    selected={this.state.tanggal_lahir_anak}
                    onChange={e => this.gantiTgl(e, "tanggal_lahir_anak")}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="tanggal"
                    dateFormat="dd/MM/yyyy HH:mm "
                  /> */}
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tempat Lahir Anak
                </Label>
                <Col sm="9">
                  <input
                    data-testid="tempat lahir anak"
                    name="tempat_lahir_anak"
                    className="form-control"
                    id="tempat_lahir_anak"
                    type="text"
                    placeholder="Tempat Lahir Anak"
                    defaultValue={this.state.dt.tempat_lahir_anak}
                    value={this.state.dt.tempat_lahir_anak}
                    onChange={e => this.changeInputText(e, "tempat_lahir_anak")}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Nama Anak
                </Label>
                <Col sm="9">
                  <input
                    data-testid="nama anak"
                    name="nama_anak"
                    className="form-control"
                    id="nama_anak"
                    type="text"
                    placeholder="Nama Anak"
                    defaultValue={this.state.dt.nama_anak}
                    required
                  />
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Jenis Kelamin Anak
                </Label>
                <Col sm="9">
                  <div data-testid="selectJenisKelaminAnak">
                    <select
                      inputId="jk_anak"
                      data-testid="jk_anak"
                      name="jk_anak"
                      className="form-control"
                      id="jk_anak"
                      placeholder="jk_anak"
                      defaultValue={this.state.dt.jk_anak}
                      onChange={this.ubahJk}
                    >
                      <option value="" disabled selected>Pilih Jenis Kelamin</option>
                      <option value="Laki-laki">Laki-Laki</option>
                      <option value="Perempuan">Perempuan</option>
                    </select>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="nik"
                >
                  NIK Ayah
                </Label>
                <Col sm="9">
                  {this.state.formAyah === "none" ? (
                    <div data-testid="selectNIK">
                      <Select
                        inputId="nik"
                        classNamePrefix="select"
                        onChange={e => {
                          this.getPenduduk(e, "nik", "pemohon");
                        }}
                        defaultValue={this.state.nikStat}
                        value={this.state.nikStat}
                        name="NIK"
                        options={this.state.frmNik}
                        placeholder="Pilih NIK"
                        onInputChange={e => this.handleInputChangeNIK(e, "nik")}
                        isClearable
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <input
                    data-testid="nik_ayah"
                    name="nik_ayah"
                    className="form-control"
                    id="nik_ayah"
                    type={this.state.formAyah === "none" ? "hidden" : "number"}
                    placeholder="Nik ayah"
                    defaultValue={this.state.dt.nik_ayah}
                    value={this.state.dt.nik_ayah}
                    required
                    onChange={e => this.changeInputText(e, "nik_ayah")}
                  />

                  <small id="emailHelp" className="form-text text-muted">
                    Data NIK ayah {this.state.formAyah === "none" ? "tidak" : ""}{" "}
                    tersedia?{" "}
                    <Link
                      to={{
                        pathname: this.props.myroute,
                        state: this.props.location.state
                      }}
                      data-testid="btnInputManual"
                      onClick={() => {
                        if(this.state.formAyah === "none"){
                          if (this.props.getJenisInput) {
                            this.props.getJenisInput("manual")
                          }
                        }
                        else{
                          if (this.props.getJenisInput) {
                            this.props.getJenisInput("auto")
                          }  
                        }
                        this.dataAyah()
                      }}
                    >
                      {" "}
                      Klik disini
                    </Link>
                  </small>
                </Col>
              </FormGroup>

              <div style={{ display: this.state.status.dataPenduduk }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Ayah
                  </Label>
                  <Col sm="9">
                    <input
                      data-testid="nama_ayah"
                      name="nama_ayah"
                      className="form-control"
                      id="nama_ayah"
                      type="text"
                      placeholder="Nama Ayah"
                      value={this.state.dt.nama_ayah}
                      //   readOnly
                      readOnly={this.state.formAyah === "" ? false : true}
                      required
                      onChange={e => this.changeInputText(e, "nama_ayah")}

                      style={this.state.formAyah === "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
                      disabled={this.state.formAyah === "" ? false : true}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir Ayah
                  </Label>
                  <Col sm="9">
                    <input
                      data-testid="tempat_lahir_ayah"
                      name="tempat_lahir_ayah"
                      className="form-control"
                      id="tempat_lahir_ayah"
                      type="text"
                      placeholder="Tempat Lahir Ayah"
                      value={this.state.dt.tempat_lahir_ayah}
                      onChange={e =>
                        this.changeInputText(e, "tempat_lahir_ayah")
                      }
                      readOnly={this.state.formAyah === "" ? false : true}
                      required
                      style={this.state.formAyah === "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
                      disabled={this.state.formAyah === "" ? false : true}
                      //   readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir Ayah
                  </Label>
                  <Col sm="9">
                    {this.state.formAyah === '' ? 
                    (<Datetime
                      inputProps={{ "data-testid": "tanggal_lahir_ayah", placeholder: 'Tanggal Lahir Ayah', required: true }}
                      inputId="tanggal_lahir_ayah"
                      name="tanggal_lahir_ayah"
                      locale="id-ID"
                      timeFormat={false}
                      dateFormat="DD/MM/yyyy"
                      input={true}
                      closeOnTab={true}
                      closeOnSelect={true}
                      initialValue={this.state.dt.tanggal_lahir_ayah}
                      onChange={e => {
                        this.gantiTgl(e,'tanggal_lahir_ayah')
                      }}
                    />)
                    : 
                    (<input
                      className="form-control"
                      id="tanggal_lahir_ayah"
                      //   type="text"
                      type={this.state.formAyah === "" ? "date" : "text"}
                      placeholder="Tanggal Lahir"
                      // value={this.state.dt.tanggal_lahir_ayah}
                      value={dateFormat(this.state.dt.tanggal_lahir_ayah, 'dd/mm/yyyy')}
                      //   readOnly
                      readOnly={this.state.formAyah === "" ? false : true}
                      required
                      onChange={e =>
                        this.changeInputText(e, "tanggal_lahir_ayah")
                      }
                      style={this.state.formAyah === "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
                      disabled={this.state.formAyah === "" ? false : true}
                    />)
                    }
                  </Col>
                </FormGroup>
              </div>
              <div style={{ display: this.state.formAyah }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan Ayah
                  </Label>
                  <Col sm="9">
                    {/* <input
                      className="form-control"
                      id="pekerjaan_ayah"
                      type="text"
                      placeholder="Pekerjaan Ayah"
                      defaultValue={this.state.dt.pekerjaan_ayah}
                      value={this.state.dt.pekerjaan_ayah}
                      readOnly={this.state.formAyah === "" ? false : true}
                      onChange={e => this.changeInputText(e, "pekerjaan_ayah")}
                      required
                      //   readOnly
                    /> */}
                    <div data-testid="selectPekerjaanAyah">
                      <Select
                        inputId="pekerjaan_ayah"
                        classNamePrefix="select"
                        onChange={e => {
                          this.changeSelectValue(e, "pekerjaan_ayah");
                        }}
                        value={this.state.dt.pekerjaan_ayah}
                        name="pekerjaan_ayah"
                        options={this.state.listPekerjaan}
                        placeholder="Pilih Pekerjaan Ayah"
                        isClearable
                      />
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat Ayah
                  </Label>
                  <Col sm="9">
                    <input
                      data-testid="alamat_ayah"
                      name="alamat_ayah"
                      className="form-control"
                      id="alamat_ayah"
                      type="text"
                      placeholder="Alamat Ayah"
                      defaultValue={this.state.dt.alamat_ayah}
                      value={this.state.dt.alamat_ayah}
                      readOnly={this.state.formAyah === "" ? false : true}
                      onChange={e => this.changeInputText(e, "alamat_ayah")}
                      required
                      //   readOnly
                    />
                  </Col>
                </FormGroup>
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="nik3"
                >
                  NIK Ibu
                </Label>
                <Col sm="9">
                  {this.state.formIbu === "none" ? (
                    <div data-testid="selectNIK3">
                      <Select
                        inputId="nik3"
                        classNamePrefix="select"
                        onChange={e => {
                          this.getPenduduk(e, "nik3", "ibu");
                        }}
                        defaultValue={this.state.nik3Stat}
                        value={this.state.nik3Stat}
                        name="NIK3"
                        options={this.state.frmNik3}
                        placeholder="Pilih NIK IBU"
                        onInputChange={e => this.handleInputChangeNIK(e, "nik3")}
                        isClearable
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                  <input
                    data-testid="nik_ibu"
                    name="nik_ibu"
                    className="form-control"
                    id="nik_ibu"
                    type={this.state.formIbu === "none" ? "hidden" : "number"}
                    placeholder="Nik Ibu"
                    defaultValue={this.state.dt.nik_ibu}
                    value={this.state.dt.nik_ibu}
                    onChange={e => this.changeInputText(e, "nik_ibu")}
                    required
                  />
                  <small id="emailHelp" class="form-text text-muted">
                    {" "}
                    Data NIK ibu {this.state.formIbu === "none"
                      ? "tidak"
                      : ""}{" "}
                    tersedia?{" "}
                    <Link
                      to={{
                        pathname: this.props.myroute,
                        state: this.props.location.state
                      }}
                      data-testid="btnInputManual2"
                      onClick={() => {
                        if(this.state.formIbu === "none"){
                          if (this.props.getJenisInput2) {
                            this.props.getJenisInput2("manual")
                          }
                        }
                        else{
                          if (this.props.getJenisInput2) {
                            this.props.getJenisInput2("auto")
                          }  
                        }
                        this.dataIbu()
                      }}
                    >
                      {" "}
                      Klik disini
                    </Link>
                  </small>
                </Col>
              </FormGroup>
              <div style={{ display: this.state.status.dataPenduduk3 }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Ibu
                  </Label>
                  <Col sm="9">
                    <input
                      data-testid="nama_ibu"
                      name="nama_ibu"
                      className="form-control"
                      id="nama_ibu"
                      type="text"
                      placeholder="Nama Ibu"
                      //   value={this.state.dt.nama_ibu}
                      defaultValue={this.state.dt.nama_ibu}
                      value={this.state.dt.nama_ibu}
                      onChange={e => this.changeInputText(e, "nama_ibu")}
                      readOnly={this.state.formIbu === "" ? false : true}
                      required
                      style={this.state.formIbu === "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
                      disabled={this.state.formIbu === "" ? false : true}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir Ibu
                  </Label>
                  <Col sm="9">
                    <input
                      data-testid="tempat_lahir_ibu"
                      name="tempat_lahir_ibu"
                      className="form-control"
                      id="tempat_lahir_ibu"
                      type="text"
                      placeholder="Tempat Lahir Ibu"
                      //   readOnly
                      defaultValue={this.state.dt.tempat_lahir_ibu}
                      value={this.state.dt.tempat_lahir_ibu}
                      onChange={e =>
                        this.changeInputText(e, "tempat_lahir_ibu")
                      }
                      readOnly={this.state.formIbu === "" ? false : true}
                      required
                      style={this.state.formIbu === "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
                      disabled={this.state.formIbu === "" ? false : true}
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir Ibu
                  </Label>
                  <Col sm="9">
                  {this.state.formIbu === '' ? 
                  (<Datetime
                    inputProps={{ "data-testid": "tanggal_lahir_ibu", placeholder: 'Tanggal Lahir Ibu', required: true }}
                    inputId="tanggal_lahir_ibu"
                    name="tanggal_lahir_ibu"
                    locale="id-ID"
                    timeFormat={false}
                    dateFormat="DD/MM/yyyy"
                    input={true}
                    closeOnTab={true}
                    closeOnSelect={true}
                    initialValue={this.state.dt.tanggal_lahir_ibu}
                    onChange={e => {
                      this.gantiTgl(e,'tanggal_lahir_ibu')
                    }}
                  />)
                  :
                  (<input
                    className="form-control"
                    id="tanggal_lahir_ibu"
                    type={this.state.formIbu === "" ? "date" : "text"}
                    placeholder="Tanggal Lahir"
                    // value={this.state.dt.tanggal_lahir_ibu}
                    value={dateFormat(this.state.dt.tanggal_lahir_ibu, 'dd/mm/yyyy')}
                    //   readOnly
                    onChange={e =>
                      this.changeInputText(e, "tanggal_lahir_ibu")
                    }
                    defaultValue={this.state.dt.tanggal_lahir_ibu}
                    readOnly={this.state.formIbu === "" ? false : true}
                    required
                    style={this.state.formIbu === "" ? { backgroundColor:"" } : { backgroundColor:"#c9d1d8"}}
                    disabled={this.state.formIbu === "" ? false : true}
                  />)
                  }
                    
                  </Col>
                </FormGroup>
              </div>
              <div style={{ display: this.state.formIbu }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan Ibu
                  </Label>
                  <Col sm="9">
                    {/* <input
                      className="form-control"
                      id="pekerjaan_ibu"
                      type="text"
                      placeholder="Pekerjaan Ibu"
                      onChange={e => this.changeInputText(e, "pekerjaan_ibu")}
                      defaultValue={this.state.dt.pekerjaan_ibu}
                      value={this.state.dt.pekerjaan_ibu}
                      readOnly={this.state.formIbu === "" ? false : true}
                      required
                    /> */}
                    <div data-testid="selectPekerjaanIbu">
                      <Select
                        inputId="pekerjaan_ibu"
                        classNamePrefix="select"
                        onChange={e => {
                          this.changeSelectValue(e, "pekerjaan_ibu");
                        }}
                        value={this.state.dt.pekerjaan_ibu}
                        name="pekerjaan_ibu"
                        options={this.state.listPekerjaan}
                        placeholder="Pilih Pekerjaan Ibu"
                        isClearable
                      />
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat Ibu
                  </Label>
                  <Col sm="9">
                    <input
                      data-testid="alamat_ibu"
                      name="alamat_ibu"
                      className="form-control"
                      id="alamat_ibu"
                      type="text"
                      placeholder="Alamat Ibu"
                      //   readOnly
                      onChange={e => this.changeInputText(e, "alamat_ibu")}
                      defaultValue={this.state.dt.alamat_ibu}
                      value={this.state.dt.alamat_ibu}
                      readOnly={this.state.formIbu === "" ? false : true}
                      required
                    />
                  </Col>
                </FormGroup>
              </div>

              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="nik2"
                >
                  NIK Pelapor
                </Label>
                <Col sm="9">
                  <div data-testid="selectNIK2">
                    <Select
                      inputId="nik2"
                      classNamePrefix="select"
                      onChange={e => {
                        this.getPenduduk(e, "nik2", "pelapor");
                      }}
                      defaultValue={this.state.nik2Stat}
                      value={this.state.nik2Stat}
                      name="NIK2"
                      options={this.state.frmNik2}
                      placeholder="Pilih NIK Pelapor"
                      onInputChange={e => this.handleInputChangeNIK(e, "nik2")}
                      isClearable
                    />
                  </div>
                </Col>
              </FormGroup>
              <div style={{ display: this.state.status.dataPenduduk2 }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Nama Pelapor
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="nama_pelapor"
                      type="text"
                      placeholder="Nama Pemohon"
                      value={this.state.dt.nama_pelapor}
                      readOnly
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tempat Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tempat_lahir_pelapor"
                      type="text"
                      placeholder="Tempat Lahir"
                      value={this.state.dt.tempat_lahir_pelapor}
                      readOnly
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Tanggal Lahir
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="tanggal_lahir_pelapor"
                      type="text"
                      placeholder="Tanggal Lahir"
                      value={this.state.dt.tanggal_lahir_pelapor}
                      readOnly
                      style={{ backgroundColor:"#c9d1d8" }}
                      disabled
                    />
                  </Col>
                </FormGroup>
              </div>
              <div style={{ display: "none" }}>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Pekerjaan
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="pekerjaan_pelapor"
                      type="text"
                      placeholder="Pekerjaan"
                      value={this.state.dt.pekerjaan_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Agama
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="agama_pelapor"
                      type="text"
                      placeholder="Agama"
                      value={this.state.dt.agama_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
                <FormGroup className="row">
                  <Label
                    className="col-sm-3 col-form-label"
                    htmlFor="inputEmail3"
                  >
                    Alamat
                  </Label>
                  <Col sm="9">
                    <input
                      className="form-control"
                      id="alamat_pelapor"
                      type="text"
                      placeholder="Alamat"
                      value={this.state.dt.alamat_pelapor}
                      readOnly
                    />
                  </Col>
                </FormGroup>
              </div>
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="shdk"
                >
                  Hubungan Pelapor
                </Label>
                <Col sm="9">
                  {/* <input
                    className="form-control"
                    id="hubungan_pelapor"
                    type="text"
                    placeholder="Hubungan Pelapor"
                    defaultValue={this.state.dt.hubungan_pelapor}
                    required
                  /> */}
                  <div data-testid="selectShdk">
                    <Select
                      inputId="shdk"
                      classNamePrefix="select"
                      onChange={(e) => {
                        this.changeSelectValue(e, 'shdk');
                      }}
                      value={this.state.shdk}
                      id="shdk"
                      options={[
                        { value: 'Suami', label: 'Suami' },
                        { value: 'Isteri', label: 'Isteri' },
                        { value: 'Orang Tua', label: 'Orang Tua' },
                        { value: 'Anak', label: 'Anak' },
                        { value: 'Kakak', label: 'Kakak' },
                        { value: 'Adik', label: 'Adik' },
                        { value: 'Mertua', label: 'Mertua' },
                        { value: 'Menantu', label: 'Menantu' },
                        { value: 'Cucu', label: 'Cucu' },
                        { value: 'Pembantu', label: 'Pembantu' },
                        { value: 'Famili Lain', label: 'Famili Lain' }
                      ]}
                      placeholder="Hubungan Keluarga"
                    />
                  </div>
                </Col>
              </FormGroup>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                data-testid="btnSubmitCreate"
                type="submit"
                className="btn btn-primary-custom"
                disabled={this.state.status.btnForm}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formAcc}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form" onSubmit={this.simpanAcc}>
            <div className="modal-header">
              <h5 className="modal-title">Konfirmasi Surat</h5>
            </div>
            <div className="modal-body">
              <input
                className="form-control"
                // id="uuid"
                type="hidden"
                placeholder=""
                defaultValue={this.state.dt.uuid}
              />
              <FormGroup className="row">
                <Label
                  className="col-sm-3 col-form-label"
                  htmlFor="inputEmail3"
                >
                  Tanggal Surat
                </Label>
                <Col sm="9">
                  <Datepicker
                    id="tanggal_surat"
                    todayButton={"Today"}
                    dateFormat="dd/MM/yyyy"
                    selected={this.state.tanggal_surat}
                    onChange={e => this.gantiTgl(e, "tanggal_surat")}
                    className="form-control"
                  />
                </Col>
              </FormGroup>

              <Col sm="12">
                <table className="table table-bordered table-stripes table-hovered">
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Jabatan</th>
                      <th>Jabatan Pengganti</th>
                      <th>Nama</th>
                      <th>NIP</th>
                      <th>Status</th>
                      <th>
                        <i className="fa fa-check"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{tableTTD}</tbody>
                </table>
              </Col>
            </div>
            <div className="modal-footer">
              <button type="submit" className="btn btn-primary-custom">
                Selesai
              </button>
            </div>
          </Form>
        </Modal>
        <Modal
          open={this.state.formDisposisi}
          onClose={this.tutupForm}
          styles={{ modal: { width: "80%" } }}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <Form className="theme-form">
            <div className="modal-header">
              <h5 className="modal-title">Disposisi Surat</h5>
            </div>
            {/* <div className="modal-body">
							<table className="table table-bordered table-stripes table-hovered">
								<thead>
									<tr>
										<th>No</th>
										<th>Jabatan</th>
										<th>Level</th>
										<th>TTD</th>
									</tr>
								</thead>
								<tbody>
									{this.state.datDisposisi.map((dt, i) => {
										return (
											<tr key={i}>
												<td>{i + 1}</td>
												<td>{dt.data_jabatan.nama}</td>
												<td>{dt.level}</td>
												<td>
													<button
														type="button"
														className="btn btn-info"
														onClick={() => {
															this.disposisi(dt.uuid, this.state.idSurat);
														}}
														disabled={this.state.status.btnForm}
													>
														Disposisi
                          </button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
						 */}
            <div className="modal-body">
              <table className="table table-bordered table-stripes table-hovered">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Status</th>
                    <th>Pejabat</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.riwayatDisposisi.map((dt, i) => {
                    return (
                      <tr key={i}>
                        <td>{i + 1}</td>
                        <td>
                          {dt.status === 0
                            ? "Menunggu Disposisi"
                            : dt.status === 1
                            ? "Telah di disposisi"
                            : "Ditolak"}
                        </td>
                        <td>{dt.data_perangkat.data_jabatan.nama}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-warning"
                disabled={this.state.btnFormAcc}
                onClick={this.tutupForm}
              >
                Tutup
              </button>
              <button
                type="submit"
                className="btn btn-success"
                disabled={this.state.btnFormAcc}
              >
                Simpan
              </button>
            </div>
          </Form>
        </Modal>

        <div id="cetak-surat" style={{ display: "none" }}>
          <style
            dangerouslySetInnerHTML={{
              __html:
                "\n        table {\n            margin-top: 10px;\n        }\n\n        td {\n            vertical-align: top;\n        }\n\n        ol {\n            margin-top: 0px;\n            margin-bottom: 0px;\n            padding-left: 14px;\n        }\n\n        li {\n            margin-left: 30px;\n            font-size: 12px;\n        }\n    "
            }}
          />
          <table width="100%" border="0">
            <tr>
              <td width="100" align="center">
                <img alt="logo" src={logo} width="80px" />
              </td>
              <td align="center">
                <div style={{ fontWeight: "bold" }}>
                  <span
                    style={{ fontSize: "24px", textTransform: "uppercase" }}
                  >
                    pemerintah kabupaten {KABUPATEN}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "22px", textTransform: "uppercase" }}
                  >
                    kecamatan {KECAMATAN}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "28px", textTransform: "uppercase" }}
                  >
                    kantor kepala {Nama_DESA}
                  </span>
                  <br />
                  <span
                    style={{ fontSize: "16px", textTransform: "capitalize" }}
                  >
                    {ALAMAT_KANTOR_DESA}
                  </span>
                </div>
              </td>
            </tr>
          </table>
          <hr style={{ border: "2px solid black" }} />
          <table
            border="0"
            width="90%"
            style={{
              marginTop: "30px",
              marginLeft: "auto",
              marginRight: "auto",
              verticalAlign: "top"
            }}
          >
            <tr>
              <td align="center" style={{ paddingBottom: "20px" }}>
                {" "}
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    textDecoration: "underline"
                  }}
                >
                  surat keterangan kelahiran
                </span>
                <br />
                {this.state.cetak.nomor_surat}
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <p style={{ textIndent: "50px" }}>
                  Yang bertanda tangan dibawah ini Kepala {Nama_DESA},
                  Kecamatan {KECAMATAN}, Kabupaten {KABUPATEN}, Provinsi {PROVINSI},
                  dengan ini menerangkan bahwa :{" "}
                </p>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <table border="0" width="100%">
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pada Hari/Tanggal
                    </td>
                    <td>:</td>
                    <td>{HariTanggal(this.state.cetak.tanggal_lahir_anak)}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Jam
                    </td>
                    <td>:</td>
                    <td>
                      {new Date(
                        this.state.cetak.tanggal_lahir_anak
                      ).toLocaleTimeString("id-ID")}
                    </td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.tempat_lahir_anak}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Telah lahir seorang anak
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.jk_anak}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Diberi Nama
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nama_anak}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" colspan="2" style={{ paddingLeft: "50px" }}>
                      Dari Seorang Ibu
                    </td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nama_ibu}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat/Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td>
                      {this.state.cetak.tempat_lahir_ibu},{" "}
                      {Tanggal(new Date(this.state.cetak.tanggal_lahir_ibu))}
                    </td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik_ibu}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.pekerjaan_ibu}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.alamat_ibu}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Istri Dari
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nama_ayah}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat/Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td>
                      {this.state.cetak.tempat_lahir_ayah},{" "}
                      {Tanggal(new Date(this.state.cetak.tanggal_lahir_ayah))}
                    </td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik_ayah}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.pekerjaan_ayah}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.alamat_ayah}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ textAlign: "justify" }}>
                <span style={{ paddingLeft: "50px" }}>
                  Demikianlah surat keterangan ini dibuat berdasarkan laporan
                  dari :
                </span>
                <table border="0" width="100%">
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Nama Lengkap
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nama_pelapor}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Tempat/Tanggal Lahir
                    </td>
                    <td>:</td>
                    <td>
                      {this.state.cetak.tempat_lahir_pelapor},{" "}
                      {Tanggal(
                        new Date(this.state.cetak.tanggal_lahir_pelapor)
                      )}
                    </td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      NIK
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.nik_pelapor}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Agama
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.agama_pelapor}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Pekerjaan
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.pekerjaan_pelapor}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Alamat
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.alamat_pelapor}</td>
                  </tr>
                  <tr style={{ textTransform: "capitalize" }}>
                    <td width="35%" style={{ paddingLeft: "50px" }}>
                      Hubungan Pelapor
                    </td>
                    <td>:</td>
                    <td>{this.state.cetak.hubungan_pelapor}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table border="0" width="100%">
            <tr>
              <td style={{ textAlign: "justify" }} width="50%">
                &nbsp;
              </td>
              <td style={{ textAlign: "justify" }} width="50%">
                <table border="0" width="100%" cellpadding="0" cellspacing="0">
                  <tr>
                    <td width="35%">Dikeluarkan di</td>
                    <td>:</td>
                    <td>{this.state.cetak.nama_desa}</td>
                  </tr>
                  <tr>
                    <td width="35%" style={{ borderBottom: "1px solid black" }}>
                      Dikeluarkan pada
                    </td>
                    <td style={{ borderBottom: "1px solid black" }}>:</td>
                    <td style={{ borderBottom: "1px solid black" }}>
                      {Tanggal(new Date(this.state.cetak.tanggal_surat))}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table border="0" width="100%" style={{ marginTop: "20px" }}>
            <tr>
              <td width="50%">&nbsp;</td>
              <td
                width="50%"
                style={{ paddingLeft: "20px", textAlign: "center" }}
              >
                <span style={{ fontWeight: "bold" }}>
                  {this.state.cetak.status_ttd}{" "}
                  <span style={{ textTransform: "uppercase" }}>
                    {this.state.cetak.jabatan_ttd}
                  </span>
                  {this.state.cetak.jabatan_pengganti_ttd ? (
                    <>
                      <br />
                      {this.state.cetak.jabatan_pengganti_ttd}
                    </>
                  ) : (
                    ""
                  )}
                </span>
              </td>
            </tr>
            <tr>
              <td height="50" colspan="2">
                &nbsp;
              </td>
            </tr>
            <tr>
              <td align="center">&nbsp;</td>
              <td align="center">
                <span
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    borderBottom: "1px solid black"
                  }}
                >
                  {this.state.cetak.nama_ttd}
                </span>
                <br />
                {this.state.cetak.nip_ttd}
              </td>
            </tr>
          </table>
        </div>
      </div>
    );
  }
}

export default BasicTable;