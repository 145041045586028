import React, { Component } from "react";

// import Custom Componenets
// import Breadcrumb from "../components/common/breadcrumb.component";
import BootstrapTable from "react-bootstrap-table-next";
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
// 	PaginationProvider,
// 	PaginationTotalStandalone,
// 	PaginationListStandalone
// } from "react-bootstrap-table2-paginator";
// import Modal from "react-responsive-modal";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";
// import Dropzone from "react-dropzone";
// import Resizer from "react-image-file-resizer";
// import axios from "axios";

// import Select from "react-select";
// import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

// koneksi
// import { Post, Get, Delete, cekLogin, API_URL } from "../function/Koneksi";
// import { Tanggal, HariTanggal, DataToken } from "../function/Format";

// css files
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
// import { Nav, NavItem, TabContent, TabPane } from "react-bootstrap";
// import { NavLink } from "react-router-dom";
import classnames from 'classnames';

class DataMasterPotensi extends Component {
	constructor(props) {
		super(props);
		this.state = {
			judul: "Tambah Banner Desa",
			alert: null,
			show: false,
			basicTitle: "",
			basicType: "default",
      pesanAlert: "",
      // 
      active_tab_icon: '1',
      dataSumberAirMinum: [],
		};

	}

	componentDidMount() {

	}

  toggle_icon(tab) {
		if (this.state.active_tab_icon !== tab) {
			this.setState({
				active_tab_icon: tab
			});
		}
	}

	render() {

    const columnsSumberAirMinum = [
			{
				dataField: 'id',
				text: 'No.',
				sort: true
			},
			{
				dataField: 'uuid',
				text: 'uuid',
				sort: false
			},
			{
				dataField: 'nama_keldes',
				text: 'Jenis Sumber Air Minum',
				sort: false
			},
			{
				dataField: 'aksi',
				text: 'Aksi',
				isDummyField: true,
				csvExport: false,
				formatter: this.tombolAksi
			}
		];

		return (
			<div>
				{/* <Breadcrumb title="Data Banner Desa" parent="Admin" /> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										<div className="col-sm-6">
											{/* <span>DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function:<code>$().DataTable();</code>.</span><span>Searching, ordering and paging goodness will be immediately added to the table, as shown in this example.</span> */}
										</div>
									</div>
								</div>

								<div className="card-body">
                  <Nav tabs className={'tabs-color'}>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === '1'
                        })}
                        onClick={() => {
                          this.toggle_icon('1');
                        }}
                      >
                        <i className="icofont icofont-ui-home"> </i>
                        Sumber Air Minum
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === '2'
                        })}
                        onClick={() => {
                          this.toggle_icon('2');
                        }}
                      >
                        <i className="icofont icofont-ui-home"> </i>
                        Aset
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === '3'
                        })}
                        onClick={() => {
                          this.toggle_icon('3');
                        }}
                      >
                        <i className="icofont icofont-ui-home"> </i>
                        Komoditas
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === '4'
                        })}
                        onClick={() => {
                          this.toggle_icon('4');
                        }}
                      >
                        <i className="icofont icofont-ui-home"> </i>
                        Jenis Kepemilikan Rumah
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === '5'
                        })}
                        onClick={() => {
                          this.toggle_icon('5');
                        }}
                      >
                        <i className="icofont icofont-ui-home"> </i>
                        Jenis Kepemilikan Lahan
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.active_tab_icon === '6'
                        })}
                        onClick={() => {
                          this.toggle_icon('6');
                        }}
                      >
                        <i className="icofont icofont-ui-home"> </i>
                        Lembaga Ekonomi
                      </NavLink>
                    </NavItem>
                    
                  </Nav>
                  <TabContent activeTab={this.state.active_tab_icon}>
                    <TabPane tabId="1">
                      <div style={{ padding: 15 }}>
                        <div className="row">
                          <div className="col-md-12">
                            <BootstrapTable keyField="id" data={this.state.dataSumberAirMinum} columns={columnsSumberAirMinum} />
                          </div>
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
								</div>
							</div>
						</div>
					</div>
				</div>
			
				
			</div>
		);
	}
}

export default DataMasterPotensi;
