import React, { Component } from 'react';

// import Custom Componenets
// import Breadcrumb from '../components/common/breadcrumb.component';
// import BootstrapTable from 'react-bootstrap-table-next';
// import paginationFactory from 'react-bootstrap-table2-paginator';
// import paginationFactory, {
// 	PaginationProvider,
// 	PaginationTotalStandalone,
// 	PaginationListStandalone
// } from 'react-bootstrap-table2-paginator';
import Modal from 'react-responsive-modal';
import { Form, FormGroup, Label, Col } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

// import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

// koneksi
import { PostStatus, Get, } from '../function/Koneksi';

// css files
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { DataToken } from '../function/Format';

//json file
// var data = require('../assets/json/company');

const frmDef = {
	uuid: '',
	nama_role: ''
};
class EditProfil extends Component {
	constructor(props) {
		super(props);
		this.state = {
			// data: {
      //   uuid : '',
      //   id_role : '',
      //   username : '',
      //   email : '',
      //   password : '',
      //   no_hp : '',
      // },
			data : null,
			pagination: [],
			status: {
				form: false,
				btnForm: false,
				btnAksi: false
			},
			dt: frmDef,
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: ''
		};
	}

	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================

	// ================== simpan data ==================
	simpan = (e) => {
		e.preventDefault();
		this.setState({
			status:{
				...this.state.status,
				btnForm: true,
			},
		})
		this.forceUpdate();
		let bodyFormData = {
			uuid: this.state.data.uuid,
			id_role: this.state.data.id_role,
			username: this.state.data.username,
			email: document.getElementById('email').value,
			no_hp: document.getElementById('no_hp').value,
		};

    const password = document.getElementById("password").value

    if (password.trim() !== '') {
      bodyFormData.password = password
    }

    //console.log(bodyFormData)

		let psn = 'Edit';
		let resstat = 200;
		let metode = 'update';
    
		PostStatus("pengguna/operator/" + metode, bodyFormData.uuid, bodyFormData, res => {
      this.setState({ status : 
        {...this.state.status, 
          btnForm: false, 
          form : false
        } 
      })

      if (res === resstat) {
        this.setState({
          show: true,
          basicType: "success",
          basicTitle: "Edit Profil Pengguna",
          pesanAlert: "Berhasil " + psn + " Edit Profil"
        });
      } else {
        this.setState({
          show: true,
          basicType: "danger",
          basicTitle: "Edit Profil Pengguna",
          pesanAlert: "Gagal " + psn + " Edit Profil"
        });
      }
      this.componentDidMount();
			document.getElementById("password").value = ''
    });
	};
	// ========================================================================

	bukaForm = () => {
		this.setState({
			status:{
				...this.state.status,
				form: true,
			},
		})
		this.forceUpdate();
	};

	tutupForm = () => {
		this.setState({
			status:{
				...this.state.status,
				form: false,
			},
		})
		this.forceUpdate();
	};

	componentDidMount() {
    let token = DataToken(sessionStorage.getItem('access_token'));
    //console.log(token)

		if (token) {
			Get("pengguna/find", token.sub.uuid, response => {
				//console.log(response)
				if (response.results) {
					let data = {
						uuid : response.results.uuid,
						id_role : response.results.id_role,
						username : response.results.username,
						email : response.results.email,
						password : '',
						no_hp : response.results.no_hp,
					}
					this.setState({ data })
				}
			})
		}
	}

	render() {
		// var size = Math.ceil(this.state.pagination.total / this.state.pagination.per_page);
		// var size = this.state.pagination.total;
		// var from = this.state.pagination.from;
		// var page = this.state.pagination.current;
		// var to = this.state.pagination.to;
		// var total = this.state.pagination.total;
		// var data = this.state.data;
		// const frmDef = {
		// 	uuid: '',
		// 	nama_role: ''
		// };

		// const columns = [
		// 	{
		// 		dataField: 'uuid',
		// 		text: 'ID',
		// 		sort: false
		// 	},
		// 	{
		// 		dataField: 'nama_role',
		// 		text: 'Role',
		// 		sort: true
		// 	},
		// 	{
		// 		dataField: 'aksi',
		// 		text: 'Aksi',
		// 		isDummyField: true,
		// 		csvExport: false,
		// 		formatter: this.tombolAksi
		// 	}
		// ];

		return (
			<div>
				{/* <Breadcrumb title="Data Roles" parent="Admin"/> */}
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>

				{this.state.alert}

				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="card mb-0">
								<div className="card-header">
									<div className="row">
										{/* <div className="col-sm-6">
										</div>
										<div className="col-sm-6 text-right">
											<Button
												size="sm"
												color="success"
												className="btn-square"
												onClick={() => {
													this.setState({ dt: frmDef });
													this.bukaForm();
												}}
											>
												Tambah Data
											</Button>
										</div> */}
                    <div className="col-sm-12">
                      <h5>Edit Profil Pengguna</h5>
                    </div>
									</div>
								</div>
								<div className="card-body datatable-react">
									{/* <BootstrapTable keyField="uuid" data={data} columns={columns} />
									{this.pagination} */}
                  <div className="row" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                    <div className="col-sm-7">
											{this.state.data !== null ? (
                      <div>
                        <Form className="theme-form" onSubmit={this.simpan}>
                          <input type="hidden" id="uuid"/>
                          {/* Username */}
                          <FormGroup className="row">
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              <b>Username</b>
                            </Label>
                            <Col sm="8">
                              <input 
                                id="username"
                                className="form-control mb-0" 
                                type="text"
                                value={this.state.data.username}
                                style={{ backgroundColor:"#c9d1d8" }}
                                disabled
                              />
                            </Col>
                          </FormGroup>
                          {/* Email */}
                          <FormGroup className="row">
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              <b>Email</b>
                            </Label>
                            <Col sm="8">
                              <input 
                                id="email"
                                className="form-control mb-0" 
                                type="text"
                                defaultValue={this.state.data.email}
                              />
                            </Col>
                          </FormGroup>
                          {/* Password */}
                          <FormGroup className="row">
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              <b>Password</b>
                            </Label>
                            <Col sm="8">
                              <input 
                                id="password"
                                className="form-control mb-0" 
                                type="text"
                                defaultValue={this.state.data.password}
                                placeholder="Password baru (jika diperlukan)"
                              />
                            </Col>
                          </FormGroup>
                          {/* Nomor HP */}
                          <FormGroup className="row">
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            >
                              <b>Nomor HP</b>
                            </Label>
                            <Col sm="8">
                              <input 
                                id="no_hp"
                                className="form-control mb-0" 
                                type="text"
                                defaultValue={this.state.data.no_hp}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="row">
                            <Label
                              className="col-sm-4 col-form-label"
                              htmlFor="inputEmail3"
                            />
                            <Col sm="8">
                              <button className="btn btn-md btn-success" type="submit">Simpan</button>
                            </Col>
                          </FormGroup>
                        </Form>
                      </div>
											) : (
												<div>
													<h6 style={{ textAlign: "center" }}>Sedang memuat data...</h6>
												</div>
											)}
                    </div>
                  </div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br />
				<Modal
					open={this.state.status.form}
					onClose={this.tutupForm}
					closeOnEsc={false}
					closeOnOverlayClick={false}
				>
					<Form className="theme-form" onSubmit={this.simpan}>
						<div className="modal-header">
							<h5 className="modal-title">{this.state.judul}</h5>
						</div>
						<div className="modal-body">
							<input
								className="form-control"
								id="uuid"
								type="hidden"
								placeholder="Role"
								defaultValue={this.state.dt.uuid}
							/>
							<FormGroup className="row">
								<Label className="col-sm-3 col-form-label" htmlFor="inputEmail3">
									Nama Role
								</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="nama_role"
										type="text"
										placeholder="Role"
										defaultValue={this.state.dt.nama_role}
									/>
								</Col>
							</FormGroup>
						</div>
						<div className="modal-footer">
							<button
								type="button"
								className="btn btn-warning"
								disabled={this.state.status.btnForm}
								onClick={this.tutupForm}
							>
								Tutup
							</button>
							<button
								type="submit"
								className="btn btn-success"
								disabled={this.state.status.btnForm}
								onClick={this.simpan}
							>
								Simpan
							</button>
						</div>
					</Form>
				</Modal>
			</div>
		);
	}
}

export default EditProfil;
