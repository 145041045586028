import React, { Component } from 'react';
import { cekLogin } from '../function/Koneksi';

import { Form } from 'reactstrap';
// import { BrowserRouter as Router, Link } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			style: {},
			alert: null,
			show: false,
			basicTitle: '',
			basicType: 'default',
			pesanAlert: '',
			loading: true,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	// ================== Sweet Alert ==================
	closeAlert = () => {
		this.setState({
			show: false
		});
	};

	onRecieveInput = (value) => {
		this.setState({
			alert: (
				<SweetAlert success title="Nice!" onConfirm={this.hideAlert}>
					You wrote: {value}
				</SweetAlert>
			)
		});
	};

	hideAlert = () => {
		this.setState({
			alert: null
		});
	};

	// =================================================
	handleSubmit = (event) => {
		event.preventDefault();
		let username = document.getElementById('username').value
		let password = document.getElementById('password').value
		
		let cekLog = {
			username,
			password
		};
		// //console.log(cekLog);
		let resstat = 200;
		
		if (username !== '' && password !== '') {
			if (navigator.onLine) {
				cekLogin('auth/login', cekLog, (res) => {

					// For testing
					if (this.props.handleSubmit) {
						//console.log('ada props ' + res.status);
						this.props.handleSubmit(res.status)
					}

					if (res.status === resstat) {
						sessionStorage.setItem('access_token', res.data.token);
						this.props.history.push('/'); 
					} else if (res.status === 401) {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Login',
							pesanAlert: 'Akun belum diverifikasi, Silahkan hubungi admin!'
						});
					} else {
						this.setState({
							show: true,
							basicType: 'danger',
							basicTitle: 'Login',
							pesanAlert: 'Username atau password salah!'
						});
					}
				});
			} else {
				this.setState({
					show: true,
					basicType: 'info',
					basicTitle: 'Login',
					pesanAlert: 'Jaringan internet tidak aktif...'
				});
				if (this.props.handleSubmit) {
					this.props.handleSubmit('Internet tidak aktif')
				}
			}
		} else {
			if (this.props.handleSubmit) {
				this.props.handleSubmit('Username atau password kosong')
			}
		}
		
	};
	componentDidMount() {
		let cek = localStorage.getItem('SET_API_URL')
		console.log("APA ISI DIDMOUNT LOGIN")
		console.log(cek)
		if(cek==null){
			setTimeout(
				function() {
					window.location.reload();
				},
				2500
			);
		}
		else{
			this.setState({ loading: false })
		}
		setTimeout(
			function() {
				this.setState({ style: { display: 'none' } });
			}.bind(this),
			1000
		);
	}

	render() {
		let style = this.state.style;
		// const background = require('../assets/images/auth-layer.png');

		let logoAplikasi = <img src={require('../assets/images/logo/digital_desa_rohil.png')} alt="" style={{ maxHeight: '80px' }} />;

		let jenisAplikasi = localStorage.getItem("JENIS_APLIKASI")

		if (jenisAplikasi) {
			switch (jenisAplikasi.toLowerCase()) {
				case "halaman kampung":
					logoAplikasi = (
						<img
							src={require("../assets/images/hk_icon.png")}
							alt=""
							style={{ maxHeight: "100px" }}
						/>
					);
					break;
				case "amanat nagari":
					logoAplikasi = (
						<img
							src={require("../assets/images/amanat_nagari_logo.png")}
							alt=""
							style={{ maxHeight: "100px" }}
						/>
					);
					break;
				default:
					logoAplikasi = (
						<img
							src={require("../assets/images/logo/digital_desa_rohil.png")}
							alt=""
							style={{ maxHeight: "100px" }}
						/>
					);
					break;
			}
		}
		
		return (
			<div>
				<SweetAlert
					show={this.state.show}
					type={this.state.basicType}
					title={this.state.basicTitle}
					confirmBtnBsStyle="success"
					onConfirm={this.closeAlert}
				>
					{this.state.pesanAlert}
				</SweetAlert>
				{/* Loader starts */}
				<div className="loader-wrapper" style={style}>
					<div className="loader bg-white">
						<div className="line" />
						<div className="line" />
						<div className="line" />
						<div className="line" />
						{/* <h4>
              Have a great day at work today <span>&#x263A;</span>
            </h4> */}
					</div>
				</div>
				{/* Loader ends */}

				{/*page-wrapper Start*/}
				<div className="page-wrapper">
					<div className="container-fluid">
						{/*login page start*/}
						{/* <div className="authentication-main"> */}
						<div className="authentication-main " style={{ background: 'none' }}>
							{this.state.loading ? (
							<div className="loader-wrapper" style={style}>
								<div className="loader bg-white">
									<div className="line" />
									<div className="line" />
									<div className="line" />
									<div className="line" />
								</div>
							</div>
							) : (
							<div className="row">
								<div className="col-md-6 p-0">
									<div
										id="bg-login-digidesa-new"
										className="auth-innerright"
										style={{
											// backgroundImage: 'url(' + background + ')',
											// background: 'none',
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'center'
											//   background: "#ffffff"
										}}
									>
										<div className="text-center">
											<h3
												style={{
													fontWeight: 'bold',
													color: '#ffffff',
													textShadow: '5px 4px 7px #000000'
												}}
											>
												<div>Semua Kemudahan Layanan</div>
												<div>Dalam Satu Genggaman</div>
											</h3>
										</div>
									</div>
								</div>
								<div className="col-md-6 p-0">
									<div
										// id="bg-login-digidesa"
										className="auth-innerright"
										style={{
											// backgroundColor: '#E7FED5',
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'center',
											backgroundColor: '#ffffff'
											// background: 'none'
										}}
									>
										<div className="authentication-box">
											<div className="text-center">
												<h3
													style={{
														fontWeight: 'bold',
														color: '#ffffff'
													}}
												>
													{logoAplikasi}
													{/* {localStorage.getItem("JENIS_APLIKASI")} */}
												</h3>
											</div>
											{/* <div className="card mt-4 p-4 mb-0"> */}
											<div
												className="pl-4 pr-4 mb-0"
												style={{
													//   borderRadius: "40px",
													//   backgroundColor: "#ffffffc7",
													//   boxShadow:
													//     "0 4px 8px 0 #0000003d, 0 6px 20px 0 #0000003d"
													// backgroundImage: 'url(' + background + ')'
													// backgroundImage: "url('../images/kiosk/cv.png')",
												}}
											>
												<Form className="theme-form" data-testid="form-login" onSubmit={this.handleSubmit}>
													<div className="form-group inputss">
														<i className="fa fa-user icon" />
														<input
															required
															type="text"
															data-testid="username"
															id="username"
															name="username"
															className="form-control form-control-lg inputs"
															placeholder="Username"
															style={{
																borderRadius: '7px',
																border: '1px solid #98a6ad'
															}}
														/>
													</div>
													<div className="form-group inputss">
														<i className="fa fa-lock icon" />
														<input
															required
															type="password"
															id="password"
															data-testid="password"
															className="form-control form-control-lg inputs"
															placeholder="Password"
															name="password"
															style={{
																borderRadius: '7px',
																border: '1px solid #98a6ad'
															}}
														/>
													</div>
													<div className="text-right">
														{/* <h6><Link to="/forget-password" ><u className="text-success">Reset Kata Sandi</u></Link></h6> */}
													</div>
													<div className="form-group mt-4">
														<button
															data-testid="tombol-login"
															type="submit"
															className="btn btn-primary-custom form-control form-control-lg"
															style={{ borderRadius: '7px' }}
														>
															{' '}
															Masuk
														</button>
													</div>
												</Form>
											</div>
											<div className="text-center mt-4">
												{/* <h6>Belum punya akun? <Link to="#" ><u className="text-success">Daftar disini</u></Link></h6> */}
												{/* <h6>Belum punya akun? <Link to="/register" ><u className="text-success">Daftar disini</u></Link></h6> */}
											</div>
										</div>
									</div>
								</div>
							</div>
							)}
						</div>
						{/*login page end*/}
					</div>
				</div>
				{/*page-wrapper Ends*/}
			</div>
		);
	}
}

export default Login;
