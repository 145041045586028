import React, { Component } from 'react';
import Modal from 'react-responsive-modal';
import Select from 'react-select';
import { Col, FormGroup, Label, Button, Form } from 'reactstrap';
// import Keyboard from 'react-simple-keyboard';
// import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

// koneksi
import { Post, Get, JENIS_DUSUN } from '../function/Koneksi';

export default class SuratKeteranganUsaha extends Component {
	constructor(props) {
		super(props);
		this.state = {
			status: {
				form: false,
				btnForm: true,
				btnAksi: [],
				keyboard: false
			},
			showBio: true,
			showBio2: false,
			Basic: false,
			jenisForm: 'usaha',
			dataUsaha: [],

			scrollUp: null,

			//keyboard
			layoutName: 'default',
			inputName: 'input1',
			inputName1: 'kk',
			input: {},
			inputPattern: /^\d+$/,
			display: 'none',
			focus: false,
			hasFocus: false,
			style: {},
			
			// Pilih Data Keluarga
			frmNikKeluarga: [],
			nikStatTerlapor: null,
			dt_keluarga:[],
			dataKeluarga: "none",
		};
	}

	handleClick = (event) => {
		alert(event);
	};

	getForm = (e) => {
		//console.log(e);
		if (e) {
			if (e.value === 'Usaha') {
				this.setState({
					showBio: true,
					showBio2: false,
					jenisForm: 'usaha',
				})
				this.forceUpdate();
			} else {
				this.setState({
					showBio2: true,
					showBio: false,
					jenisForm: 'perkebunan',
				})
				this.forceUpdate();
			}
		}
	};

	hapusUsaha = (id) => {
		let data = this.state.dataUsaha;
		data.splice(id, 1);
		this.setState({ dataUsaha: data });
	};

	addUsaha = (e) => {
		e.preventDefault();
		if (this.state.jenisForm === 'usaha') {
			if (
				document.getElementById('jenis_usaha').value !== '' &&
				document.getElementById('nama_tempat_usaha').value !== '' &&
				document.getElementById('lokasi').value !== ''
			) {
				let data = {
					jenis_usaha: document.getElementById('jenis_usaha').value,
					nama_tempat_usaha: document.getElementById('nama_tempat_usaha').value,
					lokasi: document.getElementById('lokasi').value
				};

				this.setState({ dataUsaha: [ ...this.state.dataUsaha, data ], input : {} });

				document.getElementById('jenis_usaha').value = '';
				document.getElementById('nama_tempat_usaha').value = '';
				document.getElementById('lokasi').value = '';
			} else {
				this.props.isComplete(false);
			}
		} else {
			if (
				document.getElementById('jenis_kebun').value !== '' &&
				document.getElementById('umur').value !== '' &&
				document.getElementById('lokasi2').value !== '' &&
				document.getElementById('luas_lahan').value !== ''
			) {
				let data = {
					jenis_usaha: document.getElementById('jenis_kebun').value,
					umur: document.getElementById('umur').value,
					lokasi: document.getElementById('lokasi2').value,
					luas_kebun: document.getElementById('luas_lahan').value
				};

				this.setState({ dataUsaha: [ ...this.state.dataUsaha, data ], input : {} });

				document.getElementById('jenis_kebun').value = '';
				document.getElementById('lokasi2').value = '';
				document.getElementById('umur').value = '';
				document.getElementById('luas_lahan').value = '';
			} else {
				this.props.isComplete(false);
			}
		}
	};

	simpan = (e) => {
		e.preventDefault();
		if (this.state.dataUsaha.length === 0) {
			this.props.isComplete(false);
		} else {
			
			if (this.state.nikStatTerlapor) {
				// let alamat = '';
				// if (JENIS_DESA.toLowerCase() === 'kelurahan') {
				// 	alamat =
				// 		' RT. ' +
				// 		this.props.data.results.data_kk.data_rt.nama +
				// 		'/RW. ' +
				// 		this.props.data.results.data_kk.data_rt.data_rw.nama +
				// 		', ' +
				// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.nama +
				// 		', Kec. ' +
				// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.nama +
				// 		', ' +
				// 		this.props.data.results.data_kk.data_rt.data_rw.data_keldes.data_kecamatan.data_kabkota.nama;
				// } else {
				// 	// alamat =
				// 	// 	' RT. ' +
				// 	// 	this.props.data.results.data_kk.data_rt.nama +
				// 	// 	'/RW. ' +
				// 	// 	this.props.data.results.data_kk.data_rt.data_rw.nama +
				// 	// 	', ' +
				// 	// 	JENIS_DUSUN +
				// 	// 	' ' +
				// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
				// 	// 	', ' +
				// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
				// 	// 	', Kec. ' +
				// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.nama +
				// 	// 	', ' +
				// 	// 	this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.data_kecamatan.data_kabkota.nama;
		
				// 		if (this.props.data.results.data_kk.id_rt === '0') {
				// 			alamat = JENIS_DESA + " " + this.props.data.results.data_keldes.nama + 
				// 			", Kec. " + this.props.data.results.data_keldes.data_kecamatan.nama + 
				// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.nama +
				// 			", " + this.props.data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
				// 		} else {
				// 			alamat =
				// 			" RT. " + 
				// 			this.props.data.results.data_kk.data_rt.nama +
				// 			"/RW. " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.nama +
				// 			", " + JENIS_DUSUN + " " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.nama +
				// 			", " + JENIS_DESA + " " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
				// 			", Kec. " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
				// 				.data_kecamatan.nama +
				// 			", " +
				// 			this.props.data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
				// 				.data_kecamatan.data_kabkota.nama; 
				// 		}
				// }

				let frmDetail = [
					{
						nama_item: 'usaha',
						isi_item: JSON.stringify(this.state.dataUsaha)
					}
				];

				let addSkck = {
					id_jenis: this.props.id_jenis,
					nik_pelapor: this.props.data.results.id,
					nik: this.state.dt_keluarga.nik,
					kk: this.state.dt_keluarga.kk,
					nama_pemohon: this.state.dt_keluarga.nama,
					tempat_lahir: this.state.dt_keluarga.tempat_lahir,
					tanggal_lahir: this.state.dt_keluarga.tanggal_lahir,
					pekerjaan: this.state.dt_keluarga.pekerjaan,
					agama: this.state.dt_keluarga.agama,
					status_perkawinan: this.state.dt_keluarga.status_perkawinan,
					alamat: this.state.dt_keluarga.alamat,
					jenis_kelamin: this.state.dt_keluarga.jenis_kelamin,
					pendidikan_terakhir: this.state.dt_keluarga.pendidikan_terakhir,

					kewarganegaraan: 'Indonesia',
					tujuan_pembuatan: 'Surat Keterangan Usaha',
					detail: frmDetail
				};

				// let psn = '';
				let resstat = 201;
				let metode = 'create';

				Post('surat-keterangan/grup/' + metode + '/with-detail', null, addSkck, (res) => {
					this.onCloseModal('Basic');

					let dataAntrian = {
						nama: this.props.data.results.nama,
						nik: this.props.data.results.id,
						keterangan: '',
						uuid_surat:res.data.results,
					};
		
					if (res.status === resstat) {
						setTimeout(function () {
							Get('persetujuan/kiosk/' + dataAntrian.uuid_surat + '/' + this.props.id_jenis, null,  (res2) => {
								//console.log("Hasil Update Status")
								//console.log(res2)
								if (res2.code === 201) {
									//console.log("Berhasil Ganti Status Menjadi Selesai")
			
									this.props.getAntrian('Surat Keterangan Usaha', 'berhasil', false, dataAntrian);
								}
								else{
									//console.log("Gagal Ganti Status Menjadi Selesai")
			
									this.props.getAntrian('Surat Keterangan Usaha', 'gagal');
								}
							});
						}.bind(this),1000)
						// this.props.getAntrian('Surat Keterangan Usaha', 'berhasil', false, dataAntrian);
					} else {
						this.props.getAntrian('Surat Keterangan Usaha', 'gagal');
					}
					// reset cache
					this.setState({ dataUsaha: [] });
				});
			} else {
				this.props.getAntrian('Surat Keterangan Usaha', 'gagal', true);
			}
		}
	};

	// EMBED KEyBOARD

	onOpenModal = (key) => {
		//console.log(key);
		//console.log(this.props.data.results);
		this.setState({
			[key]: true,
			regis: true
		});
	};

	onCloseModal = (key) => {
		this.setState({
			[key]: false,
			display: 'none',
			regis: 'false',
			input: {},
			dataUsaha: [],
			dt_keluarga:{
				nik: '',
				kk: '',
				nama: '',
				agama: '',
				alamat: '',
				jenis_kelamin: '',
				pekerjaan: '',
				status_perkawinan: '',
				tanggal_lahir: '',
				tempat_lahir: '',
				pendidikan_terakhir: '',
			},
			dataKeluarga:'none',
			nikStatTerlapor:null,
		});
		this.forceUpdate();
	};

	// on focus
	setActiveInput = (inputName) => {
		this.setState(
			{
				inputName: inputName
			},
			() => {
				//console.log('Active input', inputName);
			}
		);
	};

	// end on focus

	// ketika Klik Keyboard atau mengisi data
	onChangeAll = (inputObj) => {
		this.setState({
			input: inputObj
		});

		//console.log('Input changed', inputObj);
	};

	onKeyPress = (button) => {
		//console.log('Button pressed', button);
		if (button === '{shift}' || button === '{lock}') this.handleShift();
	};

	handleShift = () => {
		const layoutName = this.state.layoutName;

		this.setState({
			layoutName: layoutName === 'default' ? 'shift' : 'default'
		});
	};

	// End ketika Klik Keyboard atau mengisi data

	showKeyboard = () => {
		if (!this.state.focus || this.state.display) {
			this.setState({
				focus: true,
				display: ''
			});
		}
		// this.setState({
		// 	display: '',
		// 	// onFocus:true

		//   });
		this.forceUpdate();
	};

	showKeyboard1 = () => {
		this.setState({
			display: 'none',
			regis: 'false'
		});
		this.forceUpdate();
	};

	// Input keyboard
	onChangeInput = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				if (typeof this.keyboard !== 'undefined') {
					this.keyboard.setInput(inputVal);
				} else {
				}
			}
		);
	};

	onChangeInput1 = (event) => {
		let inputVal = event.target.value;

		let updatedInputObj = {
			...this.state.input,
			[this.state.inputName]: inputVal
		};

		this.setState(
			{
				input: updatedInputObj
			},
			() => {
				this.keyboard.setInput(inputVal);
			}
		);
	};

	// end input keyboard

	// END EMBED KEYBOARD

	componentDidMount() {
		// Get list anggota keluarga from parent
		if (this.props.anggotaKeluarga) {
			this.setState({ frmNikKeluarga: this.props.anggotaKeluarga })
		}
		// Get("penduduk/kk/" + this.props.data.results.id_kk, null, data => {
		// 	console.log("Data Keluarga")
		// 	console.log(data)
		// 	if(data.results){
		// 	  let frmNikKeluarga = [];
		// 	  data.results.forEach(dt => {
		// 		frmNikKeluarga.push({ value: dt.id, label: dt.id + ' (' + dt.nama + ')' });
		// 	  });
		// 	  this.setState({ frmNikKeluarga });
			  
		// 	  // For testing
		// 	  if (this.props.getListNIKTerlapor) {
		// 		this.props.getListNIKTerlapor(frmNikKeluarga)
		// 	  }
		// 	}
		// });
	}

	handleInputChangeNIK = (e, fld) => {
		if (e) {
			if (fld === "nikTerlapor") {
				let newArray = this.state.frmNikKeluarga;
	
				newArray = newArray.filter(day => day !== e);

				this.setState({
					frmNikKeluarga:newArray,
				})
			}
		}
	  };
	
	getPenduduk = (e, sel) => {
		if (e) {
		  this.changeSelectValue(e, sel);
		  Get("penduduk/find", e.value, data => {
			let alamat = '';
			if (localStorage.getItem('JENIS_DESA').toLowerCase() === 'kelurahan') {
			  alamat =
			  " RT. " +
			  data.results.data_kk.data_rt.nama +
			  "/RW. " +
			  data.results.data_kk.data_rt.data_rw.nama +
			  ", " +
			  data.results.data_kk.data_rt.data_rw.data_keldes.nama +
			  ", Kec. " +
			  data.results.data_kk.data_rt.data_rw.data_keldes
				.data_kecamatan.nama +
			  ", " +
			  data.results.data_kk.data_rt.data_rw.data_keldes
				.data_kecamatan.data_kabkota.nama; 
			} else {
			  if (data.results.data_kk.id_rt === '0') {
				alamat = localStorage.getItem('JENIS_DESA') + " " + data.results.data_keldes.nama + 
				", Kec. " + data.results.data_keldes.data_kecamatan.nama + 
				", " + data.results.data_keldes.data_kecamatan.data_kabkota.nama +
				", " + data.results.data_keldes.data_kecamatan.data_kabkota.data_provinsi.nama; 
			  } else {
				alamat =
				" RT. " + 
				data.results.data_kk.data_rt.nama +
				"/RW. " +
				data.results.data_kk.data_rt.data_rw.nama +
				", " + JENIS_DUSUN + " " +
				data.results.data_kk.data_rt.data_rw.data_dusun.nama +
				", " + localStorage.getItem('JENIS_DESA') + " " +
				data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes.nama +
				", Kec. " +
				data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
				  .data_kecamatan.nama +
				", " +
				data.results.data_kk.data_rt.data_rw.data_dusun.data_keldes
				  .data_kecamatan.data_kabkota.nama; 
			  }
			}
	
			
			if (sel === "nikTerlapor") {
				this.setState({
					dt_keluarga:{
						nik: e.value,
						kk: data.results.id_kk,
						nama: data.results.nama,
						agama: data.results.data_agama.nama,
						alamat: alamat,
						jenis_kelamin: data.results.jk,
						pekerjaan: data.results.data_pekerjaan.nama,
						status_perkawinan: data.results.status_perkawinan,
						tanggal_lahir: data.results.tanggal_lahir,
						tempat_lahir: data.results.tempat_lahir,
						pendidikan_terakhir: data.results.data_pendidikan_terakhir.nama,
					},
					dataKeluarga: 'block',
				})
			}
			this.forceUpdate();
		  });
		} else {
			this.setState({
				dt_keluarga:{
					nik: '',
					kk: '',
					nama: '',
					agama: '',
					alamat: '',
					jenis_kelamin: '',
					pekerjaan: '',
					status_perkawinan: '',
					tanggal_lahir: '',
					tempat_lahir: '',
					pendidikan_terakhir: '',
				},
				dataKeluarga: 'none',
				nikStatTerlapor:null,
			});
		  	this.forceUpdate();
		}
	};

	changeSelectValue = (e, sel) => {
		if (e) {
		  switch (sel) {
			case "nikTerlapor":
			  this.setState({ nikStatTerlapor: e });
			  break;
			default:
			  // no default
			  break;
		  }
		} else {
		  switch (sel) {
			case "nikTerlapor":
			  this.setState({ nikStatTerlapor: e });
			  break;
			default:
			  // no default
			  break;
		  }
		}
	
		this.forceUpdate();
	};

	render() {
		const { Basic } = this.state;

		const footerStyle = {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		};

		const styleImgIcon = {
			height: '42,58px',
			width: '65,83px'
		};

		const kategori_usaha = [ { value: 'Usaha', label: 'Usaha' }, { value: 'Perkebunan', label: 'Perkebunan' } ];

		return (
			<div>
				<Modal
					classNames={{ overlay: 'card-scrollable' }}
					open={Basic}
					closeOnEsc={false}
					closeOnOverlayClick={false}
					onClose={() => this.onCloseModal('Basic')}
					// styles={{ modal: { marginBottom: '200px', width: '80%' } }}
					styles={{ modal: { width: '80%' } }}
				>
					<Form
						onSubmit={this.simpan}
						onMouseDown={() => {
							//console.log('sdfs');
						}}
					>
						<div
							// className="card-scrollable"
							onMouseDown={(e) => {
								let slider = document.querySelector('.card-scrollable');
								//console.log(e);
								this.setState({
									isDown: true,
									startY: e.pageY - slider.offsetTop,
									scrollUp: slider.scrollTop,
								})
								this.forceUpdate();
								//console.log(this.state.startY);
								// this.state.scrollUp = this.scrollUp;
							}}
							onMouseLeave={() => {
								this.setState({ isDown: false });
							}}
							onMouseUp={() => {
								this.setState({ isDown: false });
							}}
							onMouseMove={(e) => {
								let slider = document.querySelector('.card-scrollable');

								if (!this.state.isDown) {
									return;
								}

								e.preventDefault();
								const y = e.pageY - slider.offsetTop;
								const startY = this.state.startY;
								const walk = y - startY;
								slider.scrollTop = this.state.scrollUp - walk;
								//console.log({ y, startY });
							}}
						>
							<div className="modal-header">
								<h5 className="modal-title">Surat Keterangan Usaha</h5>
							</div>

							<div className="modal-body">
								<input id="jenis_kelamin" type="hidden" value={this.props.data.results.jk} />

								<input
									id="pekerjaan"
									type="hidden"
									value={this.props.data.results.pekerjaan}
									readOnly
								/>

								<input
									id="pendidikan_terakhir"
									type="hidden"
									value={this.props.data.results.pendidikan_terakhir}
									readOnly
								/>

								<input id="agama" type="hidden" value={this.props.data.results.agama} readOnly />

								<input
									id="status_perkawinan"
									type="hidden"
									value={this.props.data.results.status_perkawinan}
									readOnly
								/>

								<input id="alamat" type="hidden" value={this.props.data.results.alamat} readOnly />

								<input id="kewarganegaraan" type="hidden" readOnly value="Indonesia" />
								<input id="kk" type="hidden" value={this.props.data.results.id_kk} readOnly />

								<input
									id="tempat_lahir"
									type="hidden"
									value={this.props.data.results.tempat_lahir}
									readOnly
								/>

								<input id="tanggal_lahir" type="hidden" value={this.props.data.results.tanggal_lahir} />

								{/* <FormGroup className="row">
								<Label className="col-sm-3 col-form-label">No Antrian Anda</Label>
								<Col sm="9">
									<input
										className="form-control"
										id="no_antrian_anda"
										disabled
										type="text"
										placeholder="No Antrian Anda"
										//   value={this.state.dt.nama_pemohon}
									/>
								</Col>
							</FormGroup> */}

								{/* <FormGroup className="row">
									<Label className="col-sm-3 col-form-label">NIK</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nik"
											disabled
											type="text"
											placeholder="NIK"
											value={this.props.data.results.id}
										/>
									</Col>
								</FormGroup>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Nama Lengkap</Label>
									<Col sm="9">
										<input
											className="form-control"
											id="nama_pemohon"
											disabled
											type="text"
											placeholder=" Nama Lengkap"
											value={this.props.data.results.nama}
										/>
									</Col>
								</FormGroup> */}
								<FormGroup className="row">
									<Label
									className="col-sm-3 col-form-label"
									htmlFor="nik"
									>
									NIK Terlapor
									</Label>
									<Col sm="9">
									<div data-testid="selectNIKTerlapor">
										<Select
										inputId="nikTerlapor"
										classNamePrefix="select"
										onChange={e => {
											this.getPenduduk(e, "nikTerlapor");
										}}
										defaultValue={this.state.nikStatTerlapor}
										value={this.state.nikStatTerlapor}
										name="NIK"
										options={this.state.frmNikKeluarga}
										placeholder="Pilih NIK Terlapor"
										onInputChange={e => this.handleInputChangeNIK(e, "nikTerlapor")}
										isClearable
										/>
									</div>
									</Col>
								</FormGroup>

								<div style={{ display: this.state.dataKeluarga }}>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Nama Terlapor
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="nama"
										type="text"
										placeholder="Nama Terlapor"
										value={this.state.dt_keluarga.nama}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tempat Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tempat_lahir"
										type="text"
										placeholder="Tempat Lahir"
										value={this.state.dt_keluarga.tempat_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
									<FormGroup className="row">
									<Label
										className="col-sm-3 col-form-label"
										htmlFor="inputEmail3"
									>
										Tanggal Lahir
									</Label>
									<Col sm="9">
										<input
										className="form-control"
										id="tanggal_lahir"
										type="text"
										placeholder="Tanggal Lahir"
										value={this.state.dt_keluarga.tanggal_lahir}
										readOnly

										style={{ backgroundColor:"#c9d1d8" }}
										disabled
										/>
									</Col>
									</FormGroup>
								</div>

								<FormGroup className="row">
									<Label className="col-sm-3 col-form-label">Kategori Usaha</Label>
									<Col sm="9">
										<Select
											classNamePrefix="select"
											onChange={(e) => {
												this.getForm(e);
											}}
											defaultValue={{ value: 'Usaha', label: 'Usaha' }}
											name="kategori_usaha"
											options={kategori_usaha}
											placeholder="Kategori Usaha"
										/>
									</Col>
								</FormGroup>

								<div style={{ display: this.state.showBio === false ? 'none' : '' }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label">Jenis Usaha</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="jenis_usaha"
												type="text"
												placeholder=" Jenis Usaha"
												onFocus={() => this.setActiveInput('jenis_usaha')}
												value={this.state.input['jenis_usaha'] || ''}
												onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label">Nama Tempat Usaha</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="nama_tempat_usaha"
												type="text"
												placeholder=" Nama Tempat Usaha"
												onFocus={() => this.setActiveInput('nama_tempat_usaha')}
												value={this.state.input['nama_tempat_usaha'] || ''}
												onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label">Lokasi</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="lokasi"
												type="text"
												placeholder="Lokasi"
												onFocus={() => this.setActiveInput('lokasi')}
												value={this.state.input['lokasi'] || ''}
												onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>
								</div>

								<div style={{ display: this.state.showBio2 === false ? 'none' : '' }}>
									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label">Jenis Kebun</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="jenis_kebun"
												type="text"
												placeholder="Jenis Kebun"
												onFocus={() => this.setActiveInput('jenis_kebun')}
												value={this.state.input['jenis_kebun'] || ''}
												onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label">Umur</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="umur"
												type="text"
												placeholder="Contoh: 6 Bulan, atau 12 Tahun"
												onFocus={() => this.setActiveInput('umur')}
												value={this.state.input['umur'] || ''}
												onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label">Luas Lahan</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="luas_lahan"
												type="text"
												placeholder="Luas"
												onFocus={() => this.setActiveInput('luas_lahan')}
												value={this.state.input['luas_lahan'] || ''}
												onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>

									<FormGroup className="row">
										<Label className="col-sm-3 col-form-label">Lokasi</Label>
										<Col sm="9">
											<input
												className="form-control"
												id="lokasi2"
												type="text"
												placeholder="Lokasi"
												onFocus={() => this.setActiveInput('lokasi2')}
												value={this.state.input['lokasi2'] || ''}
												onChange={(e) => this.onChangeInput(e)}
											/>
										</Col>
									</FormGroup>
								</div>
								<FormGroup className="row">
									<Label className="col-sm-12 col-form-label text-right" htmlFor="inputEmail3">
										<button type="button" onClick={this.addUsaha} className="btn btn-success small">
											Tambah usaha
										</button>
									</Label>
								</FormGroup>
								{this.state.dataUsaha.length !== 0 ? (
									this.state.dataUsaha.map((dt, index) => {
										// //console.log(this.state.dataUsaha);
										return (
											<div>
												<Label className="col-sm-8 col-form-label" htmlFor="inputEmail3">
													{'Usaha ' + (index + 1)}
												</Label>
												<Label className="col-sm-4 col-form-label" htmlFor="inputEmail3">
													<Button
														size="xs"
														color="danger"
														className="btn-icon"
														onClick={() => this.hapusUsaha(index)}
														// disabled={btnAksi}
													>
														<i className="fa fa-trash" />
													</Button>{' '}
												</Label>

												{typeof dt.jenis_usaha !== 'undefined' ? dt.jenis_usaha !== '' ? (
													<div>
														<Label
															className="col-sm-4 col-form-label"
															htmlFor="inputEmail3"
														>
															Jenis Usaha
														</Label>
														<Label
															className="col-sm-2 col-form-label"
															htmlFor="inputEmail3"
														>
															:
														</Label>
														<Label
															className="col-sm-4 col-form-label"
															htmlFor="inputEmail3"
														>
															{dt.jenis_usaha}
														</Label>
													</div>
												) : (
													<div />
												) : (
													<div />
												)}
												{typeof dt.nama_tempat_usaha !== 'undefined' ? dt.nama_tempat_usaha !==
												'' ? (
													<div>
														<Label
															className="col-sm-4 col-form-label"
															htmlFor="inputEmail3"
														>
															Nama Tempat usaha
														</Label>
														<Label
															className="col-sm-2 col-form-label"
															htmlFor="inputEmail3"
														>
															:
														</Label>
														<Label
															className="col-sm-4 col-form-label"
															htmlFor="inputEmail3"
														>
															{dt.nama_tempat_usaha}
														</Label>
													</div>
												) : (
													<div />
												) : (
													<div />
												)}
												{typeof dt.lokasi !== 'undefined' ? dt.lokasi !== '' ? (
													<div>
														<Label
															className="col-sm-4 col-form-label"
															htmlFor="inputEmail3"
														>
															Lokasi
														</Label>
														<Label
															className="col-sm-2 col-form-label"
															htmlFor="inputEmail3"
														>
															:
														</Label>
														<Label
															className="col-sm-4 col-form-label"
															htmlFor="inputEmail3"
														>
															{dt.lokasi}
														</Label>
													</div>
												) : (
													<div />
												) : (
													<div />
												)}
												{typeof dt.umur !== 'undefined' ? dt.umur !== '' ? (
													<div>
														<Label
															className="col-sm-4 col-form-label"
															htmlFor="inputEmail3"
														>
															Umur
														</Label>
														<Label
															className="col-sm-2 col-form-label"
															htmlFor="inputEmail3"
														>
															:
														</Label>
														<Label
															className="col-sm-4 col-form-label"
															htmlFor="inputEmail3"
														>
															{dt.umur}
														</Label>
													</div>
												) : (
													<div />
												) : (
													<div />
												)}

												{typeof dt.luas_kebun !== 'undefined' ? dt.luas_kebun !== '' ? (
													<div>
														<Label
															className="col-sm-4 col-form-label"
															htmlFor="inputEmail3"
														>
															Luas Kebun
														</Label>
														<Label
															className="col-sm-2 col-form-label"
															htmlFor="inputEmail3"
														>
															:
														</Label>
														<Label
															className="col-sm-4 col-form-label"
															htmlFor="inputEmail3"
														>
															{dt.luas_kebun}
														</Label>
													</div>
												) : (
													<div />
												) : (
													<div />
												)}
											</div>
										);
									})
								) : (
									<div>
										<Label className="col-sm-12 col-form-label text-center" htmlFor="inputEmail3">
											Data usaha belum ada
										</Label>
									</div>
								)}
							</div>

							<div className="modal-footer" style={footerStyle}>
								<button
									type="button"
									className="btn btn-warning"
									onClick={() => this.onCloseModal('Basic')}
								>
									Tutup
								</button>
								<button type="submit" className="btn btn-success">
									Buat Surat
								</button>
							</div>
						</div>
					</Form>

					{/* {this.state.regis ? (
						<div style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 4, left: 0 }}>
							<Keyboard
								style={{ marginTop: '40px', zIndex: 3 }}
								keyboardRef={(r) => (this.keyboard = r)}
								inputName={this.state.inputName}
								layoutName={this.state.layoutName}
								onChangeAll={(inputObj) => this.onChangeAll(inputObj)}
								onKeyPress={(button) => this.onKeyPress(button)}
							/>
						</div>
					) : (
						<div />
					)} */}
				</Modal>

				<div
					className="card kiosk-card"
					onClick={() => this.onOpenModal(`Basic`)}
					style={{
						marginTop: '20px',
						marginBottom: '20px',
						marginLeft: '10px',
						marginRight: '10px',
						height: '170px',
						minWidth: 'max-content',
						borderRadius: '20px'
					}}
				>
					<div className="card-body">
						<div
							className="row"
							style={{ display: 'flex', justifyContent: 'center', marginBottom: '12px' }}
						>
							<img
								src={require('../assets/images/kiosk/surat/usaha.png').default}
								alt="logo"
								height="65"
								style={styleImgIcon}
							/>
						</div>
						<div className="row" style={{ display: 'flex', justifyContent: 'center' }}>
							<p
								className="mb-0"
								style={{
									fontSize: '20px',
									fontWeight: '600',
									color: '#000000'
								}}
							>
								Usaha
							</p>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
