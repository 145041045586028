import React, { Component } from "react";

import { Link} from "react-router-dom";

// Import custom components
// import UserPanel from "./user-panel.component";

// koneksi
// import { STATUS_LAYANAN_KUA, STATUS_LAYANAN_CAPIL, GetMarketplace, ID_DESA } from "../../../function/Koneksi";

class SidebarAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paid: this.props.paid,
      lisensi: this.props.lisensi,
    };
  }

  render() {
    return (
      <div className="bayu-sidebar custom-scrollbar bayu-sidebar-open">
        <ul className="sidebar-menu">
          <div className="dropdown-basic">
            <div className="row">
              <div className="dropdown">
                <Link to={process.env.PUBLIC_URL}>
                  <div className="btn-group m-1">
                    <button
                      type="button"
                      className="dropbtn btn-success"
                      style={{ borderRadius: "10px", width: "155px" }}
                    >
                      <i className="fa fa-home"></i> Dashboard
                    </button>
                  </div>
                </Link>
              </div>
              <div className="dropdown">
                <div className="btn-group m-1">
                  <button
                    type="button"
                    className="dropbtn btn-success"
                    style={{ borderRadius: "10px", width: "155px" }}
                  >
                    <i className="fa fa-envelope"></i> Administrasi
                    <span>
                      <i className="icofont icofont-arrow-down"></i>
                    </span>
                  </button>
                  <div className="dropdown-content">
                    <Link
                      to={{
                        pathname:  this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ?  process.env.PUBLIC_URL + "/admin/verifikasi" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us',
                        state: { title: "Verifikasi Data" }
                      }}
                    >
                      <i className="icon-check-box"></i> Verifikasi Data
                    </Link>
                    <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ?  process.env.PUBLIC_URL + "/admin/disposisi" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                      <i className="icon-arrow-right"></i> Disposisi Surat
                    </Link>
                    <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ?  process.env.PUBLIC_URL + "/admin/laporan" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                      <i className="fa fa-files-o"></i> Laporan
                    </Link>
                    <div className="liyu2">
                      <a href="/">
                        <i className="icon-pencil-alt"></i> Perubahan Data
                        <span style={{ float: "right" }}>
                          <i className="fa fa-angle-right"></i>
                        </span>
                      </a>
                      <div className="liyu3">
                        <Link
                          to={
                             this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ?  process.env.PUBLIC_URL +
                            "/admin/perubahan-data/nik-kk" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'
                          }
                        >
                          <i className="fa fa-user"></i> Nik & KK
                        </Link>
                        <Link
                          to={
                             this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ?  process.env.PUBLIC_URL +
                            "/admin/perubahan-data/pindah-kk" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'
                          }
                        >
                          <i className="fa fa-user"></i> Pindah KK
                        </Link>
                        <Link
                          to={
                             this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ?  process.env.PUBLIC_URL +
                            "/admin/perubahan-data/pengajuan" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'
                          }
                        >
                          <i className="fa fa-user"></i> Pengajuan
                        </Link>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
              <div className="dropdown">
                <div className="btn-group m-1">
                  <button
                    type="button"
                    className="dropbtn btn-success"
                    style={{ borderRadius: "10px", width: "155px" }}
                  >
                    <i className="icofont icofont-users-alt-4"></i> Kependudukan
                    <span>
                      <i className="icofont icofont-arrow-down"></i>
                    </span>
                  </button>
                  <div className="dropdown-content">
                    <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/penduduk" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                      <i className="fa fa-user"></i> Penduduk
                    </Link>
                    <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/kartu-keluarga" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                      <i className="fa fa-users"></i> Kartu Keluarga
                    </Link>
                    {
                      localStorage.getItem('JENIS_APLIKASI').toLowerCase() === "amanat nagari"
                      ?
                      <>
                        <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/suku" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                          <i className="fa fa-users"></i> Suku
                        </Link>
                        <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/datuak" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                          <i className="fa fa-users"></i> Datuak
                        </Link>
                        <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/penduduksuku" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                          <i className="fa fa-users"></i> Penduduk Suku
                        </Link>
                      </>
                      :
                      <>
                      </>
                    }
                  </div>
                </div>
              </div>


          {
            localStorage.getItem('STATUS_LAYANAN_KUA') === '1'  && localStorage.getItem('STATUS_LAYANAN_CAPIL') ==='1'
            ?
              <div className="dropdown">
                <div className="btn-group m-1">
                  <button
                    type="button"
                    className="dropbtn btn-success"
                    style={{ borderRadius: "10px", width: "155px" }}
                  >
                    <i className="fa fa-desktop"></i> Layanan
                    <span>
                      <i className="icofont icofont-arrow-down"></i>
                    </span>
                  </button>
                  <div className="dropdown-content">
                    <Link
                      to={
                         this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL +
                        "/admin/layanan/capil/disdukcapil" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'
                      }
                    >
                      <i className="fa fa-university"></i> Disdukcapil
                    </Link>
                    <Link
                      to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/layanan/kua/kua" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}
                    >
                      <i className="fa fa-university"></i> KUA
                    </Link>
                    <Link
                      to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/layanan/kecamatan/beranda" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}
                    >
                      <i className="fa fa-university"></i> Kecamatan
                    </Link>
                  </div>
                </div>
              </div>
            : localStorage.getItem('STATUS_LAYANAN_KUA') === '1'
            ? 
              <div className="dropdown">
                <div className="btn-group m-1">
                  <button
                    type="button"
                    className="dropbtn btn-success"
                    style={{ borderRadius: "10px", width: "155px" }}
                  >
                    <i className="fa fa-desktop"></i> Layanan
                    <span>
                      <i className="icofont icofont-arrow-down"></i>
                    </span>
                  </button>
                  <div className="dropdown-content">
                    <Link
                      to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/layanan/kua/kua" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}
                    >
                      <i className="fa fa-university"></i> KUA
                    </Link>
                  </div>
                </div>
              </div>
            : localStorage.getItem('STATUS_LAYANAN_CAPIL') === '1'
            ?
              <div className="dropdown">
                <div className="btn-group m-1">
                  <button
                    type="button"
                    className="dropbtn btn-success"
                    style={{ borderRadius: "10px", width: "155px" }}
                  >
                    <i className="fa fa-desktop"></i> Layanan
                    <span>
                      <i className="icofont icofont-arrow-down"></i>
                    </span>
                  </button>
                  <div className="dropdown-content">
                    <Link
                      to={
                        this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL +
                        "/admin/layanan/capil/disdukcapil" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'
                      }
                    >
                      <i className="fa fa-university"></i> Disdukcapil
                    </Link>
                  </div>
                </div>
              </div>
            :
            <>
            </>
          }
              <div className="dropdown">
                <div className="btn-group m-1">
                  <button
                    type="button"
                    className="dropbtn btn-success"
                    style={{ borderRadius: "10px", width: "155px" }}
                  >
                    <i className="icofont icofont-ui-note"></i> Informasi
                    <span>
                      <i className="icofont icofont-arrow-down"></i>
                    </span>
                  </button>
                  <div className="dropdown-content">
                    <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/berita" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                      <i className="icon-rss-alt"></i> Berita / Info
                    </Link>
                    <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/banner" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                      <i className="icon-gallery"></i> Banner
                    </Link>
                    <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/catatan" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                      <i className="fa fa-edit"></i> Catatan
                    </Link>
                    <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/notif-desa" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                      <i className="fa fa-bullhorn"></i> Notifikasi Desa
                    </Link>
                  </div>
                </div>
              </div>
              <div className="dropdown">
                <div className="btn-group m-1">
                  <button
                    type="button"
                    className="dropbtn btn-success"
                    style={{ borderRadius: "10px", width: "155px" }}
                  >
                    <i className="fa fa-bar-chart"></i> Data Potensi
                    <span>
                      <i className="icofont icofont-arrow-down"></i>
                    </span>
                  </button>
                  <div className="dropdown-content">
                    <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/data-potensi" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                      <i className="icofont icofont-growth"></i> Grafik
                    </Link>
                    <Link
                      to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/laporan-potensi" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}
                    >
                      <i className="fa fa-files-o"></i> Laporan
                    </Link>
                  </div>
                </div>
              </div>
              <div className="dropdown">
                <div className="btn-group m-1">
                  <button
                    type="button"
                    className="dropbtn btn-success"
                    style={{ borderRadius: "10px", width: "155px" }}
                  >
                    <i className="fa fa-cogs"></i> Pengaturan
                    <span>
                      <i className="icofont icofont-arrow-down"></i>
                    </span>
                  </button>
                  <div className="dropdown-content">
                    <Link to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/perangkat" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}>
                      <i className="fa fa-building"></i> Perangkat Desa
                    </Link>
                    <Link
                      to={ this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi ? process.env.PUBLIC_URL + "/admin/konfigurasi-kiosk" : this.state.paid !== null && this.state.paid && this.state.lisensi !== null && this.state.lisensi===false ? '/admin/layanan/expired': this.state.paid !== null && this.state.paid && this.state.lisensi === null ? '/admin/layanan/belum-setting' : '/admin/layanan/join-us'}
                    >
                      <i className="fa fa-desktop"></i> Layanan KiosK
                    </Link>
                  </div>
                </div>
              </div>
              {/* <div className="dropdown">
                <Link to={process.env.PUBLIC_URL + "/admin/perangkat"}>
                  <div className="btn-group m-1">
                    <button
                      type="button"
                      className="dropbtn btn-success"
                      style={{ borderRadius: "10px", width: "155px" }}
                    >
                      <i className="fa fa-building"></i> Perangkat Desa
                    </button>
                  </div>
                </Link>
              </div> */}
            </div>
          </div>
        </ul>
      </div>
    );
  }
}

export default SidebarAdmin;
